import React, { useEffect, useRef, useState } from "react";
import { CheckCircleOutlined, RobotFilled } from "@ant-design/icons";
import { Button, message, notification, Popconfirm, Popover, Tag } from "antd";
import { useAppDispatch } from "../../redux/hooks";
import { bulkUpdateComplaince } from "../../redux/actions/compliancesAction";
import {
  bulkActionCampaignResponse,
  uploadCampaignDocument,
} from "../../redux/actions/CampaignManager/campaignManagerAction";
import ApprovalCommentModal from "./ApprovalCommentModal";
import { PopoverProps } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import {
  actAiValidator,
  bulkUpdateMaterial,
  updateMaterial,
} from "../../redux/actions/materialsAction";
import { bulkUpdateScip } from "../../redux/actions/scipAction";

interface AddComplianceApproveProps {
  type: string;
  fetchData: () => void;
  formData?: any;
  sendgridCampaignId?: string;
  isBulk?: boolean;
  fetchParentData?: any;
  supplierResponseCount?: number;
  subscriptionEnable?: boolean;
  showNotification?: any;
}

const AddComplianceApprove: React.FC<AddComplianceApproveProps> = ({
  type,
  fetchData,
  formData,
  sendgridCampaignId,
  isBulk,
  fetchParentData,
  supplierResponseCount,
  subscriptionEnable,
  showNotification,
}) => {
  const dispatch = useAppDispatch();
  const [hovered, setHovered] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [doubleClick, setDoubleClick] = useState(false);
  const clickCountRef = useRef(0);
  const singleClickTimerRef = useRef<NodeJS.Timeout | null>(null);

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setHovered(false);
    clickCountRef.current++;
    if (singleClickTimerRef.current) {
      clearTimeout(singleClickTimerRef.current);
    }

    if (clickCountRef.current === 1) {
      singleClickTimerRef.current = setTimeout(() => {
        if (!doubleClick) {
          clickCountRef.current = 0;
          return;
        }
        setHovered(false);
        clickCountRef.current = 0;
        setDoubleClick(false);
      }, 300);
    } else if (clickCountRef.current === 2) {
      if (singleClickTimerRef.current) {
        clearTimeout(singleClickTimerRef.current);
        clickCountRef.current = 0;
      }
      setDoubleClick(true);
      clickCountRef.current = 0;
      setIsModalVisible(true);
      setHovered(false);
    }
  };

  const hoverContent = (
    <div
      style={{
        backgroundColor: "#1F1F1F",
        padding: "8px",
        borderRadius: "4px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            color: "#FFFFFF",
            fontSize: "14px",
            lineHeight: "20px",
            cursor: "pointer",
          }}
        >
          <CheckCircleOutlined style={{ color: "#52C41A" }} />
          <span>Single Click to Approve</span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            color: "#FFFFFF",
            fontSize: "14px",
            lineHeight: "20px",
            cursor: "pointer",
          }}
        >
          <CheckCircleOutlined style={{ color: "#52C41A" }} />
          <span>Double Click to Approve with Comment</span>
        </div>
      </div>
    </div>
  );
  const handleConfirm = (comment?: string, supplierPdfData?: any) => {
    const action =
      formData.regulation === "Material"
        ? bulkUpdateMaterial
        : formData.regulation === "Scip"
        ? bulkUpdateScip
        : bulkUpdateComplaince;
    dispatch(
      action({
        updates: {
          manufacturer: formData.manufacturer,
          sendgridCampaignId: formData.sendgridCampaignId,
          ...(!isBulk ? { componentId: formData.componentId } : {}),
        },
        type: "approved",
        ...(comment && { feedback: comment || null }),
        responseType: formData.regulation,
        submitType: isBulk ? "bulk" : "individual",
      })
    )
      .then((response) => {
        if (
          formData?.supplierComplianceResponse?.regulationNumber ===
          "gadsl_(mds)"
        ) {
          if (
            !isBulk &&
            supplierPdfData?.supplierPdfReader?.answers?.materials?.length > 0
          )
            dispatch(
              updateMaterial({
                updates: {
                  supplierPdfReader: supplierPdfData?.supplierPdfReader,
                  componentId: formData.componentId,
                  partWeight:
                    supplierPdfData?.supplierPdfReader.answers.partWeight,
                  partWeightUnit:
                    supplierPdfData?.supplierPdfReader.answers.partWeightUnit,
                  fmdDocument:
                    formData.supplierComplianceResponse.complianceDocument,
                },
                campaignData: formData.sendgridCampaignId,
              })
            ).then((response: any) => {
              setIsModalVisible(false);
              fetchData();
            });
        } else {
          setIsModalVisible(false);
          fetchData();
          isBulk &&
            showNotification(
              "info",
              "Update in Progress",
              "Your update is being processed. The respective page will be updated once the process is complete."
            );
        }
      })
      .catch((error) => {
        message.error(
          `Approval failed: ${error.response?.data?.message || error.message}`
        );
      });
  };

  const handleCancel = () => {
    setDoubleClick(false);
    clickCountRef.current = 0;
  };

  const popoverProps: PopoverProps = {
    content: hoverContent,
    trigger: "hover",
    open: hovered,
    onOpenChange: setHovered,
    overlayStyle: {
      maxWidth: "300px",
    },
    overlayInnerStyle: {
      backgroundColor: "transparent",
      padding: 0,
      boxShadow: "none",
    },
    placement: "top" as TooltipPlacement,
    arrow: false,
  };
  const [loading, setLoading] = useState(false);

  const actAI = (comment?: string) => {
    setLoading(true);
    notification.info({
      message: "Processing",
      description: "Updating Component data. Please wait...",
    });

    dispatch(
      actAiValidator({
        updates: formData,
        campaignId: sendgridCampaignId,
        componentId: formData.componentId,
      })
    )
      .then((response: any) => {
        setLoading(false);
        notification.success({
          message: "Success",
          description: `Component data successfully updated. For Manufacturer Name: ${response.manufacturer.name} and Item Number: ${response.manufacturer.itemNumber}.`,
        });
        fetchData();
        handleConfirm(comment, response);
      })
      .catch(() => {
        setLoading(false);
        notification.error({
          message: "Error",
          description:
            "An error occurred while Updating Component data. Please try again.",
        });
      });
  };

  return (
    <div>
      <Popover {...popoverProps}>
        <Popconfirm
          title={isBulk ? "Bulk Approve Compliance" : "Approve Compliance"}
          description={
            isBulk
              ? "Are you sure you want to approve all selected Component Compliances?"
              : "Are you sure you want to approve selected Component Compliance?"
          }
          onConfirm={() =>
            formData.regulation === "gadsl_(mds)" && subscriptionEnable
              ? actAI()
              : handleConfirm()
          }
          onCancel={handleCancel}
          okText="Ok"
          cancelText="Cancel"
          placement="topLeft"
        >
          <Button
            type={type === "icon" ? "text" : "primary"}
            icon={
              <CheckCircleOutlined
                style={{
                  fontSize: "16px",
                }}
              />
            }
            onClick={handleClick}
            disabled={formData.status === "approved"}
            style={
              type !== "icon"
                ? {
                    backgroundColor:
                      formData.status === "approved" ? "white" : "#52C41A",
                    borderColor: "#52C41A",
                    color: formData.status === "approved" ? "#52C41A" : "white",
                    width: formData.status === "approved" ? "200px" : "",
                  }
                : undefined
            }
          >
            {formData.status === "approved"
              ? "Approved"
              : type === "button" && "Approve"}
          </Button>
        </Popconfirm>
      </Popover>

      <ApprovalCommentModal
        isVisible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          setDoubleClick(false);
          clickCountRef.current = 0;
        }}
        onSubmit={(comment) =>
          formData.regulation === "gadsl_(mds)" && subscriptionEnable
            ? actAI(comment)
            : handleConfirm(comment)
        }
      />
    </div>
  );
};
export default AddComplianceApprove;
