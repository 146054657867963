import React, { useEffect, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  Space,
  Button,
  Drawer,
  Typography,
  Divider,
  DatePicker,
} from "antd";

import { Component } from "../../utils/types/component";
import { useAppDispatch } from "../../redux/hooks";
import { bulkUpdateSpecifications } from "../../redux/actions/componentsAction";
import { listProducts } from "../../redux/actions/productsAction";
import FileUploader from "../layouts/FileUploader";
import { getLocalStorage } from "../../utils/localStore";

const { Title } = Typography;
const EditComponentSpecifications: React.FC<{
  fetchData: any;
  formData?: any;
  onChildClick?: any;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { tenantNumber } = getLocalStorage("user");
  const [searchProduct, setSearchProduct] = useState<any[]>([]);
  const [isUploading, setIsUploading] = useState(false);

  const user = getLocalStorage("user");
  const parseUser = user ? user : null;

  const handleSearch = (search: any) => {
    dispatch(
      listProducts({
        search: true,
        pageNum: 1,
        pageSize: 10,
        query: search,
        softDelete: false,
        filters: encodeURIComponent(
          JSON.stringify({
            $or: [
              { number: { $regex: search, $options: "i" } },
              { name: { $regex: search, $options: "i" } },
            ],
          })
        ),
      })
    )
      .then((response: any) => {
        setSearchProduct(
          response.products.map((e: any) => ({
            value: e.name,
            ...e,
          }))
        );
      })
      .catch((e) => console.log(e));
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    form.resetFields();
    setOpen(false);
    props.onChildClick();
  };

  const onFormSubmit = (values: Component) => {
    setLoading(true);
    dispatch(
      bulkUpdateSpecifications({
        updates: values,
        filter: {
          _id: props?.formData?.map((item: any) => item._id),
        },
      })
    )
      .then(() => {
        props.fetchData();
        form.resetFields();
        setLoading(false);
        setOpen(false);
        onClose();
      })
      .catch((e) => setLoading(false));
  };

  useEffect(() => {
    setSearchProduct(
      props?.formData?.map((item: any) => ({
        ...item,
        value: item?.manufacturer?.itemNumber,
      }))
    );
  }, [open]);

  return (
    <div>
      <Button
        type="primary"
        onClick={showDrawer}
        icon={<EditOutlined />}
        data-testid="edit-specifications-button"
        title="Update Specfications"
      >
        {" "}
        Update Specifications
      </Button>
      <Drawer
        title="Edit Component Specifications"
        width={720}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button
              onClick={onClose}
              data-testid="cancel-specifications-button"
            >
              Cancel
            </Button>
            <Button
              onClick={form.submit}
              type="primary"
              loading={loading}
              disabled={isUploading}
              data-testid="submit-specifications-button"
            >
              Save
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{ ...props.formData }}
          onFinish={onFormSubmit}
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col span={24} sm={24} md={24}>
              <Form.Item
                name="componentId"
                label="Component Reference"
                initialValue={props.formData?.map(
                  (item: any) => item?.manufacturer?.itemNumber
                )}
              >
                <Select
                  options={searchProduct}
                  onSearch={handleSearch}
                  allowClear
                  mode="multiple"
                  disabled
                  maxTagCount="responsive"
                />
              </Form.Item>
            </Col>
          </Row>
          {parseUser.specifications && parseUser.specifications?.length > 0 && (
            <>
              <Title level={5}>Component/ Item Specification</Title>
              <Divider />
              <Row gutter={24}>
                {parseUser.specifications?.map((specification: any) => (
                  <Col key={specification.value} span={12} sm={12} md={12}>
                    <Form.Item
                      name={["specification", specification.value]}
                      label={specification.text}
                    >
                      {specification.dataType === "Textfield" && <Input />}
                      {specification.dataType === "Dropdown" && (
                        <Select
                          allowClear
                          placeholder={`Select ${specification.text}`}
                          options={specification.dataValue
                            .split(",")
                            .map((value: string) => ({ label: value, value }))}
                        />
                      )}
                      {specification.dataType === "Fileinput" && (
                        <FileUploader
                          postUrl="products/components/document/upload"
                          tenantNumber={tenantNumber}
                          setFormField={(value: string) =>
                            form.setFieldValue(
                              ["specification", specification.value],
                              value
                            )
                          }
                          formField={""}
                          onUploadStatusChange={setIsUploading}
                        />
                      )}
                      {specification.dataType === "Dateinput" && <DatePicker />}
                    </Form.Item>
                  </Col>
                ))}
              </Row>
            </>
          )}
        </Form>
      </Drawer>
    </div>
  );
};

export default EditComponentSpecifications;
