import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  ExportOutlined,
  RollbackOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Space, Typography, Button, Table, Input, Popconfirm, Tag } from "antd";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";

import type { FilterValue, SorterResult } from "antd/es/table/interface";

import { Component } from "../../../../../utils/types/component";
import { useAppDispatch } from "../../../../../redux/hooks";
import {
  readListsComponents,
  deleteComponent,
} from "../../../../../redux/actions/CampaignManager/listsAction";
import { Regulation } from "../../../../../utils/types/regulation";
import EditListForm from "../../../../../components/forms/EditListForm";
import {
  getRegulationsFromLocalStorage,
  setLocalStorage,
} from "../../../../../utils/localStore";
import ReadSupportingDocument from "../../../../../components/modals/ReadSupportingDocuments";
import ErrorBoundary from "../../../../../utils/errorBoundary";
const { Search } = Input;
const { Text } = Typography;

const SurveyListProfileComponents: React.FC<{
  components: Component[];
  count: number;
  childLoading: boolean;
  type: string;
}> = ({ components, count, childLoading, type }) => {
  const { id } = useParams<{ id: any }>();
  const dispatch = useAppDispatch();

  const [listComponents, setListComponents] = useState<Component[]>([]);
  const [listComponentCount, setListComponentCount] = useState<number>(0);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<Component>>({});
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});

  const onClickViewLink = (filename: string) => {
    window.open(filename, "_blank");
  };

  const regulations = getRegulationsFromLocalStorage() ?? [];

  const columns: ColumnsType<Component> = [
    {
      title: "Action",
      key: "operation",
      width: 100,
      render: (_: any, record: any) => (
        <Space>
          <Popconfirm
            title={`Are you sure want to delete ${record?.manufacturer?.name}`}
            onConfirm={() => deleteSurveyListComponent(record)}
            okText="Ok"
            cancelText="Cancel"
          >
            <Button
              type="text"
              icon={<DeleteOutlined />}
              data-testid="delete-component-button"
              title="Delete Component"
            />
          </Popconfirm>
        </Space>
      ),
    },
    {
      title: "Internal #",
      dataIndex: "internalItemNumber",
      key: "internalItemNumber",
      sorter: true,
      width: 150,
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
      sorter: true,
      render: (manufacturer) => (
        <Text>
          {manufacturer.itemNumber}
          <br></br>
          <small>{manufacturer.name}</small>
        </Text>
      ),
      width: 150,
      // `${manufacturer.name} ${manufacturer.itemNumber}`,
    },
    // ...(parseUser.specifications
    //   ? parseUser.specifications.map((e: any) => ({
    //       title: e.name.charAt(0).toUpperCase() + e.name.slice(1),
    //       dataIndex: `specification.${e.value}`,
    //       key: `specification.${e.value}`,
    //       sorter: true,
    //       width: 150,
    //     }))
    //   : []),
    {
      title: "FMD Document",
      dataIndex: "fmdDocument",
      key: "fmdDocument",
      width: 150,
      render: (text: string) => {
        return text ? (
          text?.includes("http") ? (
            <span>
              <ExportOutlined onClick={() => onClickViewLink(text)} /> Link
            </span>
          ) : (
            <ReadSupportingDocument
              postUrl="materials/document/download"
              filename={text}
              foldername="materials"
            />
          )
        ) : null;
      },
    },
    {
      title: "Part Weight",
      dataIndex: "partWeight",
      key: "partWeight",
      width: 150,
    },
    ...(regulations || []).map((e: Regulation) => ({
      title: e.name,
      children: e.configuration
        .filter(
          (o) =>
            !o.hidden &&
            o.value !== "manufacturer" &&
            o.value !== "internalItemNumber" &&
            o.value !== "operations"
        )
        .map((k: any) => ({
          title: k.text,
          dataIndex: `${e.regulationNumber}.${k.value}`,
          key: `${e.regulationNumber}.${k.value}`,
          render: (value: any, record: any) => {
            const compliances = record.compliances.find(
              (item: any) => item.regulationNumber === e.regulationNumber
            );
            if (k.value === "complianceDocument") {
              return compliances && compliances.complianceDocument ? (
                compliances?.complianceDocument?.includes("http") ? (
                  <span>
                    <ExportOutlined
                      onClick={() =>
                        onClickViewLink(compliances?.complianceDocument)
                      }
                    />{" "}
                    Link
                  </span>
                ) : (
                  <ReadSupportingDocument
                    postUrl="compliances/document/download"
                    filename={compliances.complianceDocument}
                    foldername="compliances"
                  />
                )
              ) : null;
            } else if (k.value.includes("complianceOutputs.compliant")) {
              if (
                compliances &&
                compliances.complianceOutputs &&
                compliances.complianceOutputs?.compliant?.toUpperCase() ===
                  "YES"
              ) {
                return <Tag color="green"> YES </Tag>;
              } else if (
                compliances &&
                compliances.complianceOutputs &&
                compliances.complianceOutputs?.compliant?.toUpperCase() === "NO"
              ) {
                return <Tag color="red"> NO </Tag>;
              } else if (
                compliances &&
                compliances.complianceOutputs &&
                compliances.complianceOutputs.compliant?.toUpperCase() ===
                  "YES WITH EXEMPTION"
              ) {
                return <Tag color="orange"> YES WITH EXEMPTION </Tag>;
              } else if (
                compliances &&
                compliances.complianceOutputs &&
                compliances.complianceOutputs?.compliant?.toUpperCase() ===
                  "UNKNOWN"
              ) {
                return <Tag color="default"> UNKNOWN </Tag>;
              } else {
                return compliances && compliances.complianceOutputs ? (
                  <Tag color="grey">
                    {compliances &&
                      compliances.complianceOutputs &&
                      compliances.complianceOutputs.compliant}
                  </Tag>
                ) : (
                  ""
                );
              }
            } else if (k.value.includes("complianceOutputs.status")) {
              if (
                compliances &&
                compliances.complianceOutputs &&
                compliances.complianceOutputs.status?.toUpperCase() ===
                  "ACCEPTED"
              ) {
                return <Tag color="green"> ACCEPTED </Tag>;
              } else if (
                compliances &&
                compliances.complianceOutputs &&
                compliances.complianceOutputs.status?.toUpperCase() ===
                  "REJECTED"
              ) {
                return <Tag color="red"> REJECTED </Tag>;
              } else if (
                compliances &&
                compliances.complianceOutputs &&
                compliances.complianceOutputs.status?.toUpperCase() ===
                  "MODIFIED"
              ) {
                return <Tag color="orange"> MODIFIED </Tag>;
              } else if (
                compliances &&
                compliances.complianceOutputs &&
                compliances.complianceOutputs.status?.toUpperCase() ===
                  "BROWSED"
              ) {
                return <Tag color="orange"> BROWSED </Tag>;
              } else if (
                compliances &&
                compliances.complianceOutputs &&
                compliances.complianceOutputs.status?.toUpperCase() ===
                  "NOT YET BROWSED"
              ) {
                return <Tag color="red"> NOT YET BROWSED </Tag>;
              }
            } else if (k.value.includes("complianceOutputs.version")) {
              return (
                <Text>{`${
                  compliances &&
                  compliances.complianceOutputs &&
                  compliances.complianceOutputs.version
                    ? compliances.complianceOutputs.version
                    : ""
                }`}</Text>
              );
            } else if (k.value.includes("complianceOutputs.exemption")) {
              return (
                <Text
                  style={{ width: 200 }}
                  ellipsis={{
                    tooltip:
                      compliances?.complianceOutputs?.exemption?.toString(),
                  }}
                >
                  {compliances &&
                  compliances.complianceOutputs &&
                  compliances.complianceOutputs.exemption
                    ? compliances.complianceOutputs.exemption?.toString()
                    : ""}
                </Text>
              );
            } else if (k.value.includes("complianceOutputs.substance")) {
              return (
                <Text
                  style={{ width: 200 }}
                  ellipsis={{
                    tooltip:
                      compliances?.complianceOutputs?.substance?.toString(),
                  }}
                >
                  {compliances &&
                  compliances.complianceOutputs &&
                  compliances.complianceOutputs.substance
                    ? compliances.complianceOutputs.substance?.toString()
                    : ""}
                </Text>
              );
            } else {
              return (
                <Text>
                  {compliances && compliances?.complianceOutputs
                    ? compliances?.complianceOutputs[
                        k.value?.split(".").pop().toString()
                      ]
                    : ""}
                </Text>
              );
            }
          },
        })),
    })),
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      width: 150,
      render: (text: string, record: any) => (
        <Text>{new Date(text).toLocaleString()}</Text>
      ),
    },
  ];

  const deleteSurveyListComponent = (record: any) => {
    dispatch(deleteComponent({ id: id, componentId: record._id })).then(() => {
      fetchData();
    });
  };

  const fetchData = () => {
    setLoading(true);
    dispatch(
      readListsComponents({
        id: id,
        pageSize: tablePagination.pageSize,
        pageNum: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.order === "descend" ? false : true,
        softDelete: false,
        search: "",
      })
    )
      .then((response: any) => {
        setListComponents(response.listComponents);
        setListComponentCount(response.listComponentCount);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleTableChange: TableProps<Component>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Component> | SorterResult<Component>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<Component>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      return;
    }
  };

  const onClickBack = () => {
    navigate(-2);
    setLocalStorage("BackTabActivate", "list");
  };

  useEffect(() => {
    fetchData();
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
  ]);

  return (
    <div>
      <ErrorBoundary>
        <Table
          bordered
          loading={type === "emitChild" ? childLoading : loading}
          dataSource={type === "emitChild" ? components : listComponents}
          scroll={{
            x: 1000 + 1000 * regulations.length,
            y: "calc(70vh - 285px)",
          }}
          columns={columns}
          rowKey="_id"
          pagination={{
            ...tablePagination,
            total: type === "emitChild" ? count : listComponentCount,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (totalCount) => `Total  ${totalCount}  items`,
          }}
          onChange={handleTableChange}
        />
      </ErrorBoundary>
    </div>
  );
};

export default SurveyListProfileComponents;
