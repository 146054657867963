import React, { FC, useEffect, useState } from "react";
import { Space, Typography, Row, Col, Statistic, Card, Timeline, Select, Spin, Alert } from "antd";
import { listProducts, productAnalytics } from "../../../redux/actions/productsAction";
import { AnalyticsAdmin } from "../../../redux/actions/analyticsAction";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import {
  getLocalStorage,
  setLocalStorage,
  getRegulationsFromLocalStorage,
} from "../../../utils/localStore";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import ReactFC from "react-fusioncharts";
import { useNavigate } from "react-router-dom";
import ErrorBoundary from "../../../utils/errorBoundary";
import { readTenantDetails } from "../../../redux/actions/tenantsAction";
import { Tenant } from "../../../utils/types/tenant";
import dayjs from "dayjs";

const { Title, Text } = Typography;
const { Option } = Select;

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

interface ChartProps {
  data: any[];
  chartConfig?: any;
  chartType: string;
  chartSpecificConfig?: any;
  colorRange?: any;
  uniqueId?: any;
}

const Chart: FC<ChartProps> = ({
  data,
  chartConfig,
  chartType,
  chartSpecificConfig,
  colorRange,
  uniqueId,
}) => {
  useEffect(() => {
    FusionCharts.ready(function () {
      new FusionCharts({
        type: chartType,
        width: "100%",
        height: "400",
        dataFormat: "json",
        dataSource: {
          data,
          chart: { ...chartConfig, ...chartSpecificConfig },
          colorRange,
        },
      }).render(`chart-container-${uniqueId}`);
    });
  }, [data, chartConfig, chartType, chartSpecificConfig, colorRange, uniqueId]);

  return <div id={`chart-container-${uniqueId}`} />;
};

const Dashboard: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [products, setProducts] = useState<any>([]);
  const [options, setOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [analyticsLoading, setAnalyticsLoading] = useState<boolean>(true);
  const [inputValue, setInputValue] = useState<string>("");
  const [tokenLimit, setTokenLimit] = useState<boolean>(false);
  const [userActivity, setuserActivity] = useState<any[]>([]);
  const [adminAnalytics, setadminAnalytics] = useState<any>({});
  const [analytics, setanalytics] = useState<any>();
  const [user, setuser] = useState(null);
  const [tenantDetails, setTenantDetails] = useState<Tenant>();
  const permissions = getLocalStorage("role") ? getLocalStorage("role").permissions : null;
  const regulations = getRegulationsFromLocalStorage() ?? [];
  const [sortedAnalytics, setsortedAnalytics] = useState<any>();

  const fetchAdminAnalyticsData = async () => {
    setLoading(true);
    try {
      const response = await AnalyticsAdmin({ skipMutation: true }, "overall");
      if (response) {
        setuserActivity(response.userActivities);
        setadminAnalytics(response.analytics || {});
      } else {
        console.error("Invalid or missing data in the API response.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const chartConfig = {
    theme: "fusion",
  };
  const smelterChartConfig = {
    caption: "Smelters",
    showValues: "1",
    enableMultiSlicing: "1",
    showPercentValues: 0,
  };
  const subscriptionChartConfig = {
    caption: "Subscription",
    showValues: "1",
    enableMultiSlicing: "1",
    showPercentValues: 0,
  };
  const tenantDataChartConfig = {
    caption: "Tenants",
    showValues: "1",
    enableMultiSlicing: "1",
    showPercentValues: 0,
  };

  const chartStyle: React.CSSProperties = {
    padding: 50,
    background: "rgba(0, 0, 0, 0.05)",
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100%",
  };
  const chart = <div style={chartStyle} />;

  useEffect(() => {
    async function fetchUser() {
      try {
        const getuser = await getLocalStorage("user");
        setuser(getuser.tenantNumber);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchUser();
    if (getLocalStorage("user").tenantNumber !== "acquiscompliance") {
      fetchTenantData();
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      if (user === "acquiscompliance") {
        fetchAdminAnalyticsData();
      }

      if (user !== "acquiscompliance" && permissions?.read.includes("components")) {
        if (permissions?.read.includes("products")) {
          fetchData();
          productsAnalytics("overall");
        }
        if (getLocalStorage("overall")) {
          const parsedData = getLocalStorage("overall");
          setanalytics(parsedData);
        } else {
          productsAnalytics("overall");
        }
      } else if (user === "acquiscompliance") {
        navigate("/app", { replace: true });
      } else {
        navigate("/app/responsibleminerals/conflictminerals", {
          replace: true,
        });
      }
    }
  }, [user, inputValue]);

  const fetchTenantData = () => {
    setLoading(true);
    const user = getLocalStorage("user");
    const tenantNumber = user.tenantNumber;
    dispatch(readTenantDetails(tenantNumber))
      .then((response) => {
        setTenantDetails(response.tenant);
        setTokenLimit(response.actAiConfiguration);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const productsAnalytics = (id: any) => {
    setAnalyticsLoading(true);
    dispatch(productAnalytics({ skipMutation: true }, id))
      .then((response) => {
        if (response) {
          if (id === "overall") {
            setLocalStorage("overall", response);
            setanalytics(response);
            setAnalyticsLoading(false);
          } else {
            setanalytics(response);
            setAnalyticsLoading(false);
          }
        } else {
          setAnalyticsLoading(false);
          console.error("Invalid or missing data in the API response.");
        }
      })
      .catch(() => {
        setAnalyticsLoading(false);
      });
  };
  const fetchData = (search?: any) => {
    setLoading(true);
    dispatch(
      listProducts({
        search: true,
        pageSize: 10,
        pageNum: 1,
        softDelete: false,
        path: search?.productsPath,
        searchField: search?.searchField ? search?.query : "",
        filtersUsed: "",
        filters:
          search?.query && search?.query.length
            ? encodeURIComponent(
                JSON.stringify({
                  elements: search?.query,
                  path: search?.productsPath,
                })
              )
            : [],
      })
    )
      .then((response) => {
        setProducts(response.products);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const onSearch = (value: any) => {
    fetchData({
      searchField: true,
      query: value?.key === "Enter" ? value?.target?.value : value,
    });
  };
  const handleClear = () => {
    setOptions([]);
    setInputValue("");
    fetchData();
    productsAnalytics("overall");
  };
  const handleSelectChange = (value: any) => {
    const selectedData = products.find((option: any) => option.name === value);
    setOptions(value);
    if (value && selectedData?._id) {
      productsAnalytics(selectedData?._id);
    }
  };

  useEffect(() => {
    const regulationOrder = new Map(
      regulations?.map((item: any, index: any) => [item.regulationNumber, index])
    );

    const analyticsOrder = analytics?.regulations?.sort((a: any, b: any) => {
      const orderA = regulationOrder.get(a.number) as number;
      const orderB = regulationOrder.get(b.number) as number;
      return orderA - orderB;
    });

    setsortedAnalytics(analyticsOrder);
  }, [analytics]);

  return (
    <div>
      <ErrorBoundary>
        <Space
          align="end"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <div>
            <Title level={5} style={{ marginBottom: 0 }}>
              Welcome Back,
            </Title>
            <Text type="secondary">Dashboard Overview</Text>
          </div>
          {/* <Space>
          <Button type="default" icon={<ReloadOutlined />} />
          <Button type="default" icon={<FilterOutlined />}>
            Filter by
          </Button>
          <Button type="primary" icon={<FilePdfOutlined />}>
            Export
          </Button>
        </Space> */}
        </Space>
        {user === "acquiscompliance" ? (
          <>
            <Row gutter={[24, 24]}>
              <Col span={12}>
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <Card>
                      <Row>
                        <Col span={6}>
                          <Statistic
                            title="Total Tenants"
                            value={
                              adminAnalytics?.tenants?.total ? adminAnalytics.tenants?.total : "NA"
                            }
                          />
                        </Col>
                        <Col span={6}>
                          <Statistic
                            title="Total Users"
                            value={adminAnalytics?.users ? adminAnalytics.users : "NA"}
                          />
                        </Col>
                        <Col span={6}>
                          <Statistic
                            title="Total Suppliers"
                            value={adminAnalytics?.suppliers ? adminAnalytics.suppliers : "NA"}
                          />
                        </Col>
                        <Col span={6}>
                          <Statistic
                            title="Total Smelters"
                            value={adminAnalytics?.smelters ? adminAnalytics.smelters?.total : "NA"}
                          />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col span={24}>
                    <Card title="Recent Activities">
                      <Timeline
                        items={userActivity.map((item, index) => ({
                          key: index,
                          children: `User ${item.firstName} ${
                            item.requestMethod === "POST" && item.requestUrl !== "/login"
                              ? "created"
                              : item.requestUrl !== "/login"
                              ? "updated"
                              : "logged in"
                          } ${item.requestUrl.split("/").slice(2).join(" ")} where name : ${
                            item.name
                          } on ${
                            item.requestMethod === "POST"
                              ? new Date(item.createdAt).toLocaleString("en-GB", {
                                  hour12: false,
                                })
                              : new Date(item.updatedAt).toLocaleString("en-GB", {
                                  hour12: false,
                                })
                          }.`,
                        }))}
                      />
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <Card key="smelter">
                      <Chart
                        data={adminAnalytics?.smelters?.smelterRisk}
                        chartConfig={chartConfig}
                        chartType="pie2d"
                        chartSpecificConfig={smelterChartConfig}
                        uniqueId="smelter"
                      />
                    </Card>
                  </Col>
                  <Col span={24}>
                    <Card key="tenant">
                      <Chart
                        data={adminAnalytics?.tenants?.tenantActive}
                        chartConfig={chartConfig}
                        chartType="pie2d"
                        chartSpecificConfig={tenantDataChartConfig}
                        uniqueId="tenant"
                      />
                    </Card>
                  </Col>
                  <Col span={24}>
                    <Card key="subscription">
                      <Chart
                        data={adminAnalytics?.subscription}
                        chartConfig={chartConfig}
                        chartType="pie2d"
                        chartSpecificConfig={subscriptionChartConfig}
                        uniqueId="subscription"
                      />
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        ) : (
          <div>
            {tenantDetails &&
            tenantDetails.actAiConfigurations?.subscriptionEnable === true &&
            tokenLimit &&
            dayjs(tenantDetails?.actAiConfigurations.startDate).add(30, "days") <= dayjs() ? (
              <Alert
                message="Act AI subscription has ended because both the credit limit and subscription date have exhausted. Contact admin for renewal and adding more credits."
                type="info"
                showIcon
              />
            ) : (
              <>
                {tenantDetails &&
                  tenantDetails.actAiConfigurations?.subscriptionEnable === true &&
                  tokenLimit && (
                    <Alert
                      message="Act AI subscription credit limit has exhausted. Contact admin to add more credits."
                      type="info"
                      showIcon
                    />
                  )}

                {tenantDetails &&
                  tenantDetails.actAiConfigurations?.subscriptionEnable === true &&
                  dayjs(tenantDetails?.actAiConfigurations.startDate).add(30, "days") <=
                    dayjs() && (
                    <Alert
                      message="Act AI subscription has ended due to date expiration. Contact admin to renew subscription."
                      type="info"
                      showIcon
                    />
                  )}
              </>
            )}

            <br></br>
            <Row>
              <Col xs={24} md={24}>
                {permissions?.read.includes("products") && (
                  <Select
                    showSearch
                    style={{ height: 50, width: "100%", marginBottom: "1rem" }}
                    value={inputValue || options}
                    placeholder="Start typing to search for a product"
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    onClear={handleClear}
                    allowClear
                    onSearch={onSearch}
                    onChange={handleSelectChange}
                    notFoundContent={loading ? <Spin size="small" /> : null}
                  >
                    {products?.map((option: any) => (
                      <Option key={option.name} value={option.name}>
                        {option.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Col>
            </Row>
            {analyticsLoading ? (
              <Spin tip="Loading" spinning={analyticsLoading} size="large">
                {chart}
              </Spin>
            ) : (
              <Row gutter={[24, 24]}>
                {sortedAnalytics?.map((regulation: any, index: any) => (
                  <Col key={index} xs={24} md={12}>
                    <Card key={index} className="mr-3">
                      <Chart
                        data={regulation.statistics}
                        chartConfig={chartConfig}
                        chartType="doughnut2d"
                        chartSpecificConfig={{
                          caption: regulation.name,
                          subcaption: regulation.description,
                          enablesmartlabels: "1",
                          showlabels: "1",
                          numbersuffix: " %",
                          usedataplotcolorforlabels: "1",
                          plottooltext: "$label, <b>$value</b>",
                          theme: "fusion",
                        }}
                        uniqueId={`${index}-regulation`}
                      />
                    </Card>
                  </Col>
                ))}

                {analytics?.specifications?.map((specification: any, index: any) => (
                  <Col key={index} xs={24} md={12}>
                    <Card key={index} className="mr-3">
                      <Chart
                        data={specification.statistics}
                        chartType="doughnut2d"
                        chartSpecificConfig={{
                          caption:
                            specification.value?.charAt(0).toUpperCase() +
                            specification.value?.slice(1),
                          subcaption: specification.description,
                          enablesmartlabels: "1",
                          showlabels: "1",
                          numbersuffix: " %",
                          usedataplotcolorforlabels: "1",
                          plottooltext: "$label, <b>$value</b>",
                          theme: "fusion",
                        }}
                        uniqueId={`${index}-specification`}
                      />
                    </Card>
                  </Col>
                ))}
                {permissions?.read.includes("materials") &&
                  analytics?.materials?.map((material: any, index: any) => (
                    <Col key={index} xs={24} md={12}>
                      <Card key={index} className="mr-3">
                        <Chart
                          data={material.statistics}
                          chartType="doughnut2d"
                          chartSpecificConfig={{
                            caption: material.name,
                            enablesmartlabels: "1",
                            showlabels: "1",
                            numbersuffix: " %",
                            usedataplotcolorforlabels: "1",
                            plottooltext: "$label, <b>$value</b>",
                            theme: "fusion",
                          }}
                          uniqueId={`${index}-materials`}
                        />
                      </Card>
                    </Col>
                  ))}
              </Row>
            )}
          </div>
        )}
      </ErrorBoundary>
    </div>
  );
};

export default Dashboard;
