import React, { useEffect, useState } from "react";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import {
  Tabs,
  Form,
  Input,
  Row,
  Col,
  Select,
  Space,
  Button,
  Drawer,
  Typography,
  Divider,
  DatePicker,
  Tag,
  Table,
  InputNumber,
  Spin,
  notification,
} from "antd";
import { useAppDispatch } from "../../redux/hooks";

import { Component } from "../../utils/types/component";
import type { TabsProps, SelectProps, DatePickerProps } from "antd";
import {
  bulkUpdateComponent,
  getAutoCompleteData,
  getComponentReferenceData,
  readAlternatesFromComponent,
  createComponent,
} from "../../redux/actions/componentsAction";
import {
  listProducts,
  getProductReferenceData,
} from "../../redux/actions/productsAction";
import {
  listRegulationSubtances,
  listRegulationExemptions,
} from "../../redux/actions/regulationsAction";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";
import type {
  FilterValue,
  SorterResult,
  TableRowSelection,
} from "antd/es/table/interface";
import FileUploader from "../layouts/FileUploader";
import {
  getLocalStorage,
  getRegulationsFromLocalStorage,
} from "../../utils/localStore";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import TextArea from "antd/es/input/TextArea";

dayjs.extend(customParseFormat);

const { Option } = Select;
const { Title, Text } = Typography;

const EditComponentModal: React.FC<{
  fetchData: any;
  activeTab: string;
  formData?: any;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState<string>("all");
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [alternateForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingReference, setLoadingReference] = useState(false);
  const { tenantNumber } = getLocalStorage("user");
  const [childrenDrawer, setChildrenDrawer] = useState(false);

  const regulations = getRegulationsFromLocalStorage() ?? [];
  const options: SelectProps["options"] = [];
  const [searchProduct, setSearchProduct] = useState<any[]>([]);
  const [searchComponent, setSearchComponent] = useState<any[]>([]);
  const [searchExemptions, setSearchExemptions] = useState<any[]>([]);
  const [searchSubstances, setSearchSubstances] = useState<any[]>([]);
  const [productReference, setProductReference] = useState<any[]>([]);
  const [componentReference, setComponentReference] = useState<any[]>([]);
  const [alternates, setAlternates] = useState<Component[]>([]);
  const [alternatesCount, setAlternatesCount] = useState<number>(0);
  const [alternateSelect, setAlternateSelection] = useState<any[]>([]);
  const [dropdownDisabled, setDropdownDisabled] = useState("");
  const [substanceDisabled, setSubstanceDisabled] = useState("");
  const [exemptions, setExemptions] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<Component>>({});
  const [tableFilters, setTableFilters] =
    useState<Record<string, FilterValue | null>>();

  const user = getLocalStorage("user");
  const parseUser = user ? user : null;

  const HoverStyle = `
  .file-container:hover .delete-button {
    display: inline-flex !important;
  }
`;
  const handleProductSearch = (search?: any) => {
    dispatch(
      listProducts({
        search: true,
        pageNum: 1,
        pageSize: 10,
        searchField: search?.length ? search : "",
        softDelete: false,
        filters: encodeURIComponent(
          JSON.stringify({
            $or: [
              { number: { $regex: search, $options: "i" } },
              { name: { $regex: search, $options: "i" } },
            ],
          })
        ),
      })
    )
      .then((response: any) => {
        setSearchProduct(
          response.products.map((e: any) => ({
            value: e.name,
            ...e,
          }))
        );
        setLoadingReference(false);
      })
      .catch((e) => console.log(e));
  };

  const handleComponentSearch = (search: any) => {
    if (search?.length > 0) {
      dispatch(
        getAutoCompleteData({
          search: true,
          pageNum: 1,
          pageSize: 10,
          query: search,
          softDelete: false,
          filters: encodeURIComponent(
            JSON.stringify({
              $or: [
                {
                  "manufacturer.itemNumber": { $regex: search, $options: "i" },
                },
                { "manufacturer.name": { $regex: search, $options: "i" } },
              ],
            })
          ),
        })
      )
        .then((response: any) => {
          setSearchComponent(
            response?.autoCompleteComponents[0]?.paginatedResults?.map(
              (e: any) => ({
                value: e.manufacturer?.itemNumber,
                ...e,
              })
            )
          );
          setLoadingReference(false);
        })
        .catch((e) => console.log(e));
    }
  };

  const handleSearchExemptions = (search: any, regulation: any) => {
    dispatch(
      listRegulationExemptions({
        search: true,
        pageNum: 1,
        pageSize: 10,
        query: search,
        softDelete: false,
        filters: search
          ? JSON.stringify({
              softDelete: false,
              $or: [
                {
                  exemption: {
                    $regex: search.replace(/[()/[\]]/g, "\\$&"),
                    $options: "i",
                  },
                },
              ],
              regulationNumber: regulation,
            })
          : JSON.stringify({
              regulationNumber: regulation,
            }),
      })
    )
      .then((response: any) => {
        setSearchExemptions(
          response.exemptions.map((e: any) => ({
            value: e.exemption,
            ...e,
          }))
        );
      })
      .catch((e) => console.log(e));
  };

  const handleSearchSubstances = (search: any, regulation: any) => {
    dispatch(
      listRegulationSubtances({
        search: true,
        pageNum: 1,
        pageSize: 10,
        query: search,
        softDelete: false,
        filters: search
          ? JSON.stringify({
              softDelete: false,
              $or: [
                {
                  substance: {
                    $regex: search.replace(/[()/[\]]/g, "\\$&"),
                    $options: "i",
                  },
                },
              ],
              regulationNumber: regulation,
            })
          : JSON.stringify({
              regulationNumber: regulation,
            }),
      })
    )
      .then((response: any) => {
        setSearchSubstances(
          response.substances.map((e: any) => ({
            value: e.substance,
            ...e,
          }))
        );
      })
      .catch((e) => console.log(e));
  };

  const fetchAlternateData = () => {
    setLoading(true);
    dispatch(
      readAlternatesFromComponent(props?.formData?._id, {
        componentNumber: props?.formData?.manufacturer.itemNumber,
        internalNumber: props?.formData?.internalItemNumber,
        pageSize: tablePagination.pageSize,
        pageNum: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.order === "descend" ? false : true,
        softDelete: false,
      })
    )
      .then((response) => {
        setAlternates(response?.alternateComponent);
        setAlternatesCount(response?.count);
        setAlternateSelection(
          response?.alternateComponent
            .filter((e: any) => e._id === props.formData?._id)
            .map((v: any) => v._id)
        );
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleTableChange: TableProps<Component>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Component> | SorterResult<Component>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<Component>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      return;
    }
  };

  const handleTabChange = (key: any) => {
    setActiveTab(key);
    handleSearchExemptions("", key);
    handleSearchSubstances("", key);
  };

  const getProductRefs = (productRefs: any) => {
    dispatch(
      getProductReferenceData({
        filters: JSON.stringify(productRefs),
      })
    ).then((response: any) => {
      setProductReference(
        response?.productReference?.map((e: any) => ({
          value: e.name,
          _id: e._id,
          ...e,
        }))
      );
      form.setFieldValue(
        "productRefs",
        response?.productReference?.map((e: any) => e.name)
      );
    });
  };

  const getComponentRefs = (componentRefs: any) => {
    dispatch(
      getComponentReferenceData({
        filters: JSON.stringify(componentRefs),
      })
    ).then((response: any) => {
      setComponentReference(
        response?.componentReference?.map((e: any) => ({
          value: e.manufacturer?.itemNumber,
          _id: e._id,
          ...e,
        }))
      );
      form.setFieldValue(
        "componentRefs",
        response?.componentReference?.map(
          (e: any) => e.manufacturer?.itemNumber
        )
      );
    });
  };

  const handleSelectProduct = (value: any, option: any) => {
    const filteredOptions = option.filter((e: any) => Object.keys(e).length);
    setProductReference((prev) => {
      const filteredPrev = prev.filter(
        (e: any) =>
          Object.keys(e).length &&
          !filteredOptions.map((o: any) => o.value).includes(e.value)
      );
      return [...filteredPrev, ...filteredOptions];
    });
  };

  const handleSelectComponent = (value: any, option: any) => {
    const filteredOptions = option.filter((e: any) => Object.keys(e).length);
    setComponentReference((prev) => {
      const filteredPrev = prev.filter(
        (e: any) =>
          Object.keys(e).length &&
          !filteredOptions.map((o: any) => o.value).includes(e.value)
      );
      return [...filteredPrev, ...filteredOptions];
    });
  };

  const showChildrenDrawer = () => {
    setChildrenDrawer(true);
  };

  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };

  regulations?.forEach((regulation: any) => {
    options.push({
      label: regulation.name,
      value: regulation.regulationNumber,
    });
  });

  const onFinish = (record: any) => {
    setLoading(true);
    const productRefs =
      record?.productRefs && record?.productRefs?.length
        ? record?.productRefs?.map(
            (value: any) => productReference.find((e) => e.value === value)?._id
          )
        : props.formData?.productRefs?.length
        ? productReference.map((e) => e._id)
        : [];
    const componentRefs =
      record?.componentRefs && record?.componentRefs.length
        ? record?.componentRefs?.map(
            (value: any) =>
              componentReference.find((e) => e.value === value)?._id
          )
        : props.formData?.componentRefs?.length
        ? componentReference.map((e) => e._id)
        : [];

    const componentUpdates = {
      internalItemNumber: props?.formData?.internalItemNumber,
      manufacturer: props?.formData?.manufacturer,
      specification: record?.specification,
      outOfScope: record?.outOfScope,
      productRefs: productRefs,
      componentRefs: componentRefs,
    };

    const materialsUpdates = {
      fmdDocument: record?.fmdDocument,
      fmdComments: record?.fmdComments,
      partWeight: record?.partWeight,
      partUnitOfMeasure: record?.partUnitOfMeasure,
    };

    const finalUpdates: { [key: string]: any } = {};

    regulations?.forEach((e: any) => {
      const regulationNumberKey = e.regulationNumber as string;
      finalUpdates[regulationNumberKey] = {};
      if (typeof form.getFieldValue("complianceForm") !== "undefined") {
        Object.keys(form.getFieldValue("complianceForm")).forEach((k) => {
          const a = k.split(".");
          const regulationNumber = a.shift();
          if (e.regulationNumber === regulationNumber) {
            finalUpdates[regulationNumberKey].regulationNumber =
              regulationNumber;
            finalUpdates[regulationNumberKey].complianceId = regulationNumber;
            finalUpdates[regulationNumberKey][a.join(".")] =
              form.getFieldValue("complianceForm")[k];
          }
        });
      }
    });

    const alternates = {
      componentRefs: record?.alternate,
      productRefs: record?.productRefs,
    };

    const updates = {
      componentUpdates: { ...componentUpdates },
      materialsUpdates: { ...materialsUpdates },
      complianceUpdates: { ...finalUpdates },
      alternateComponentUpdates:
        record?.alternate?.length > 0 ? alternates : null,
    };
    const isUpdate = Object.keys(finalUpdates).find(
      (key) => Object.keys(finalUpdates[key]).length > 0
    );
    const gadsl_filteredData = finalUpdates["gadsl_(mds)"]?.complianceDocument
      ? true
      : false;

    if (isUpdate === "gadsl_(mds)") {
      setLoading(false);
      setOpen(false);
      onClose();
      if (gadsl_filteredData) {
        notification.info({
          message: "Processing",
          description: "Updating Component data. Please wait...",
        });
      }
    }
    dispatch(
      bulkUpdateComponent({
        id: props?.formData?._id,
        updates: updates,
      })
    )
      .then(() => {
        props.fetchData();
        if (isUpdate !== "gadsl_(mds)") {
          setLoading(false);
          setOpen(false);
          onClose();
        } else {
          if (gadsl_filteredData) {
            notification.success({
              message: "Success",
              description: "Component data successfully updated.",
            });
          }
        }
      })
      .catch((e) => {
        setLoading(false);
        if (isUpdate !== "gadsl_(mds)" && gadsl_filteredData) {
          notification.error({
            message: "Error",
            description:
              "An error occurred while Updating Component data. Please try again.",
          });
        }
      });
  };

  const onFinishAlternate = (record: Component) => {
    setLoading(true);
    dispatch(
      createComponent({
        ...record,
        componentRefs: record?.componentRefs,
      })
    )
      .then(() => {
        fetchAlternateData();
        alternateForm.resetFields();
        setLoading(false);
        setChildrenDrawer(false);
      })
      .catch((e) => setLoading(false));
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    form.resetFields();
    setActiveTab("all");
    setDropdownDisabled("");
    setOpen(false);
  };

  // const regulationsTabs = regulations?.map((regulation: any) => ({
  //   key: regulation.regulationNumber,
  //   label: regulation.name,
  //   children: (
  //     <>
  //       <Row gutter={24}>
  //         {props?.formData &&
  //           props?.formData[regulation.regulationNumber] &&
  //           regulation.configuration
  //             .filter((e: any) => e.edit)
  //             .map((item: any) => (
  //               <Form.List
  //                 name="complianceForm"
  //                 key={`${regulation.regulationNumber}.${item.dataValue}`}
  //               >
  //                 {(fields, operation, meta) => (
  //                   <>
  //                     <Col span={12} sm={12} md={12}>
  //                       <Form.Item
  //                         name={`${regulation.regulationNumber}.${item.value}`}
  //                         label={item.text}
  //                         initialValue={
  //                           props?.formData[regulation.regulationNumber][
  //                             item.value
  //                           ]
  //                         }
  //                         rules={
  //                           item.value === "complianceOutputs.compliant" ||
  //                           (dropdownDisabled
  //                             .toLowerCase()
  //                             .includes("exemption") &&
  //                             item.dataValue === "exemption")
  //                             ? [
  //                                 {
  //                                   required: true,
  //                                   message: `Please input ${item.text}!`,
  //                                 },
  //                               ]
  //                             : dropdownDisabled.toLowerCase() === "no" &&
  //                               item.dataValue === "substance"
  //                             ? [
  //                                 {
  //                                   required: true,
  //                                   message: `Please input ${item.text}!`,
  //                                 },
  //                               ]
  //                             : []
  //                         }
  //                       >
  //                         {item.dataType === "Multilist" &&
  //                         item.dataValue === "exemption" ? (
  //                           <Select
  //                             options={searchExemptions.map((option) => ({
  //                               ...option,
  //                               label: (
  //                                 <Text>
  //                                   {option.exemption}
  //                                   {/* <br></br>
  //                                   <small>{option.description}</small> */}
  //                                 </Text>
  //                               ),
  //                             }))}
  //                             mode="multiple"
  //                             onSearch={(value) =>
  //                               handleSearchExemptions(
  //                                 value,
  //                                 regulation.regulationNumber
  //                               )
  //                             }
  //                             onChange={(value: any) => {
  //                               setExemptions(value);
  //                             }}
  //                             placeholder="Type to search Exemptions"
  //                             allowClear
  //                             showSearch
  //                             disabled={
  //                               dropdownDisabled
  //                                 .toLowerCase()
  //                                 .includes("exemption")
  //                                 ? false
  //                                 : true
  //                             }
  //                           />
  //                         ) : item.dataType === "Multilist" &&
  //                           item.dataValue === "substance" ? (
  //                           <Select
  //                             options={searchSubstances.map((option) => ({
  //                               ...option,
  //                               label: (
  //                                 <Text>
  //                                   {option.substance}
  //                                   <br></br>
  //                                   <small>{option.casNumber}</small>
  //                                 </Text>
  //                               ),
  //                             }))}
  //                             mode="tags"
  //                             onSearch={(value) =>
  //                               handleSearchSubstances(
  //                                 value,
  //                                 regulation.regulationNumber
  //                               )
  //                             }
  //                             onChange={(value: any) => {
  //                               setSubstanceDisabled(value);
  //                             }}
  //                             placeholder="Type to search Substance of Concern"
  //                             allowClear
  //                             showSearch
  //                             disabled={
  //                               dropdownDisabled.toLowerCase() === "no"
  //                                 ? false
  //                                 : true
  //                             }
  //                           />
  //                         ) : item.dataType === "Dropdown" ? (
  //                           <Select
  //                             placeholder={`Select ${item.text}`}
  //                             options={item.dataValue
  //                               .split(",")
  //                               .map((value: string) => ({
  //                                 label: value,
  //                                 value,
  //                               }))}
  //                             onChange={(value) => {
  //                               item.value === "complianceOutputs.compliant" &&
  //                                 setDropdownDisabled(value);
  //                               if (
  //                                 item.value ===
  //                                   "complianceOutputs.compliant" &&
  //                                 value.toLowerCase() !== "no"
  //                               ) {
  //                                 form.setFieldValue(
  //                                   [
  //                                     "complianceForm",
  //                                     `${regulation.regulationNumber}.complianceOutputs.substance`,
  //                                   ],
  //                                   []
  //                                 );
  //                                 setDropdownDisabled(value);
  //                               }
  //                               if (
  //                                 item.value ===
  //                                   "complianceOutputs.compliant" &&
  //                                 !value.toLowerCase().includes("exemption")
  //                               ) {
  //                                 form.setFieldValue(
  //                                   [
  //                                     "complianceForm",
  //                                     `${regulation.regulationNumber}.complianceOutputs.exemption`,
  //                                   ],
  //                                   []
  //                                 );
  //                                 setDropdownDisabled(value);
  //                               }
  //                             }}
  //                             disabled={
  //                               item.value ===
  //                                 "complianceOutputs.concentrationUnit" &&
  //                               (dropdownDisabled.toLowerCase() === "no" &&
  //                               substanceDisabled.length
  //                                 ? false
  //                                 : true)
  //                             }
  //                           />
  //                         ) : item.dataType === "Fileinput" ? (
  //                           <FileUploader
  //                             postUrl="compliances/document/upload"
  //                             tenantNumber={tenantNumber}
  //                             setFormField={(value: string) =>
  //                               form.setFieldValue(
  //                                 [
  //                                   "complianceForm",
  //                                   `${regulation.regulationNumber}.${item.value}`,
  //                                 ],
  //                                 value
  //                               )
  //                             }
  //                             onUploadStatusChange={setIsUploading}
  //                           />
  //                         ) : item.dataType === "Number" ? (
  //                           <InputNumber
  //                             disabled={
  //                               dropdownDisabled.toLowerCase() === "no" &&
  //                               substanceDisabled.length
  //                                 ? false
  //                                 : true
  //                             }
  //                             style={{ width: 324 }}
  //                             min={0}
  //                           />
  //                         ) : (
  //                           <Input />
  //                         )}
  //                       </Form.Item>
  //                     </Col>
  //                   </>
  //                 )}
  //               </Form.List>
  //             ))}
  //       </Row>
  //     </>
  //   ),
  // }));

  const regulationsTabs = regulations?.map((regulation: any) => ({
    key: regulation.regulationNumber,
    label: regulation.name,
    children: (
      <>
        <Row gutter={24}>
          {props?.formData &&
            regulation.configuration
              .filter((e: any) => e.edit)
              .map((item: any) => (
                <Form.List
                  name="complianceForm"
                  key={`${regulation.regulationNumber}.${item.dataValue}`}
                >
                  {(fields, operation, meta) => (
                    <>
                      <Col span={12} sm={12} md={12}>
                        <Form.Item
                          name={`${regulation.regulationNumber}.${item.value}`}
                          label={
                            regulation.regulationNumber === "gadsl_(mds)" &&
                            item.value === "complianceDocument" ? (
                              <Text>
                                {item.text}{" "}
                                <small>
                                  <b>{"(PDF)"}</b>
                                </small>
                              </Text>
                            ) : (
                              item.text
                            )
                          }
                          initialValue={
                            item.dataType === "Fileinput"
                              ? props?.formData?.compliances?.find(
                                  (compliance: { regulationNumber: any }) =>
                                    compliance.regulationNumber ===
                                    regulation.regulationNumber
                                )?.complianceDocument ||
                                props?.formData?.[regulation.regulationNumber]
                                  ?.complianceDocument
                              : props?.formData?.compliances?.find(
                                  (compliance: { regulationNumber: any }) =>
                                    compliance.regulationNumber ===
                                    regulation.regulationNumber
                                )?.complianceOutputs?.[
                                  item.value.split(".").pop()
                                ] ||
                                props?.formData?.[regulation.regulationNumber]
                                  ?.complianceOutputs?.[
                                  item.value.split(".").pop()
                                ]
                          }
                          rules={
                            item.value === "complianceOutputs.compliant" ||
                            (dropdownDisabled
                              .toLowerCase()
                              .includes("exemption") &&
                              item.dataValue === "exemption")
                              ? [
                                  {
                                    required: true,
                                    message: `Please input ${item.text}!`,
                                  },
                                ]
                              : dropdownDisabled.toLowerCase() === "no" &&
                                item.dataValue === "substance"
                              ? [
                                  {
                                    required: true,
                                    message: `Please input ${item.text}!`,
                                  },
                                ]
                              : []
                          }
                        >
                          {item.dataType === "Multilist" &&
                          item.dataValue === "exemption" ? (
                            <Select
                              options={searchExemptions.map((option) => ({
                                ...option,
                                label: <Text>{option.exemption}</Text>,
                              }))}
                              mode="multiple"
                              onSearch={(value) =>
                                handleSearchExemptions(
                                  value,
                                  regulation.regulationNumber
                                )
                              }
                              onChange={(value: any) => {
                                setExemptions(value);
                              }}
                              placeholder="Type to search Exemptions"
                              allowClear
                              showSearch
                              disabled={
                                dropdownDisabled
                                  .toLowerCase()
                                  .includes("exemption")
                                  ? false
                                  : true
                              }
                            />
                          ) : item.dataType === "Multilist" &&
                            item.dataValue === "substance" ? (
                            <Select
                              options={searchSubstances.map((option) => ({
                                ...option,
                                label: (
                                  <Text>
                                    {option.substance}
                                    <br></br>
                                    <small>{option.casNumber}</small>
                                  </Text>
                                ),
                              }))}
                              mode="tags"
                              onSearch={(value) =>
                                handleSearchSubstances(
                                  value,
                                  regulation.regulationNumber
                                )
                              }
                              onChange={(value: any) => {
                                setSubstanceDisabled(value);
                              }}
                              placeholder="Type to search Substance of Concern"
                              allowClear
                              showSearch
                              disabled={
                                dropdownDisabled.toLowerCase() === "no"
                                  ? false
                                  : true
                              }
                            />
                          ) : item.dataType === "Dropdown" ? (
                            <Select
                              placeholder={`Select ${item.text}`}
                              options={item.dataValue
                                .split(",")
                                .map((value: string) => ({
                                  label: value,
                                  value,
                                }))}
                              onChange={(value) => {
                                item.value === "complianceOutputs.compliant" &&
                                  setDropdownDisabled(value);
                                if (
                                  item.value ===
                                    "complianceOutputs.compliant" &&
                                  value.toLowerCase() !== "no"
                                ) {
                                  form.setFieldValue(
                                    [
                                      "complianceForm",
                                      `${regulation.regulationNumber}.complianceOutputs.substance`,
                                    ],
                                    []
                                  );
                                  setDropdownDisabled(value);
                                }
                                if (
                                  item.value ===
                                    "complianceOutputs.compliant" &&
                                  !value.toLowerCase().includes("exemption")
                                ) {
                                  form.setFieldValue(
                                    [
                                      "complianceForm",
                                      `${regulation.regulationNumber}.complianceOutputs.exemption`,
                                    ],
                                    []
                                  );
                                  setDropdownDisabled(value);
                                }
                              }}
                              disabled={
                                item.value ===
                                  "complianceOutputs.concentrationUnit" &&
                                (dropdownDisabled.toLowerCase() === "no" &&
                                substanceDisabled.length
                                  ? false
                                  : true)
                              }
                            />
                          ) : item.dataType === "Fileinput" ? (
                            <div style={{ position: "relative" }}>
                              <FileUploader
                                postUrl="compliances/document/upload"
                                tenantNumber={tenantNumber}
                                setFormField={(value: string) =>
                                  form.setFieldValue(
                                    [
                                      "complianceForm",
                                      `${regulation.regulationNumber}.complianceDocument`,
                                    ],
                                    value
                                  )
                                }
                                onUploadStatusChange={setIsUploading}
                                fileValidations={{
                                  acceptedTypes:
                                    regulation.regulationNumber ===
                                    "gadsl_(mds)"
                                      ? ["application/pdf"]
                                      : null,
                                  maxSizeMB: 25,
                                }}
                                importType={
                                  regulation.regulationNumber === "gadsl_(mds)"
                                    ? "gadsl_(mds)"
                                    : ""
                                }
                              />
                              {(props?.formData?.compliances?.find(
                                (compliance: { regulationNumber: any }) =>
                                  compliance.regulationNumber ===
                                  regulation.regulationNumber
                              )?.complianceDocument ||
                                props?.formData?.[regulation.regulationNumber]
                                  ?.complianceDocument) && (
                                <div
                                  style={{
                                    marginTop: "8px",
                                    position: "relative",
                                    backgroundColor: "#f5f5f5",
                                    padding: "8px",
                                    borderRadius: "4px",
                                  }}
                                  className="file-container"
                                >
                                  <Text
                                    ellipsis
                                    style={{ paddingRight: "24px" }}
                                  >
                                    {props?.formData?.compliances?.find(
                                      (compliance: { regulationNumber: any }) =>
                                        compliance.regulationNumber ===
                                        regulation.regulationNumber
                                    )?.complianceDocument ||
                                      props?.formData?.[
                                        regulation.regulationNumber
                                      ]?.complianceDocument}
                                  </Text>
                                  <Button
                                    type="text"
                                    size="small"
                                    style={{
                                      position: "absolute",
                                      right: "4px",
                                      top: "50%",
                                      transform: "translateY(-50%)",
                                      display: "none",
                                    }}
                                    className="delete-button"
                                    onClick={() => {
                                      form.setFieldValue(
                                        [
                                          "complianceForm",
                                          `${regulation.regulationNumber}.complianceDocument`,
                                        ],
                                        null
                                      );

                                      const uploadList = document.querySelector(
                                        `.ant-form-item-${CSS.escape(
                                          regulation.regulationNumber
                                        )} .ant-upload-list`
                                      );
                                      if (uploadList) {
                                        const deleteButton =
                                          uploadList.querySelector(
                                            ".ant-upload-list-item-delete"
                                          ) as HTMLElement;
                                        if (deleteButton) {
                                          deleteButton.click();
                                        }
                                      }

                                      form.validateFields([
                                        `${regulation.regulationNumber}.complianceDocument`,
                                      ]);
                                    }}
                                    icon={<CloseOutlined />}
                                  />
                                </div>
                              )}
                            </div>
                          ) : item.dataType === "Number" ? (
                            <InputNumber
                              disabled={
                                dropdownDisabled.toLowerCase() === "no" &&
                                substanceDisabled.length
                                  ? false
                                  : true
                              }
                              style={{ width: 324 }}
                              min={0}
                            />
                          ) : (
                            <Input />
                          )}
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Form.List>
              ))}
        </Row>
      </>
    ),
  }));

  const columns: ColumnsType<Component> = [
    {
      title: "Internal #",
      dataIndex: "internalItemNumber",
      key: "internalItemNumber",
      sorter: true,
      width: 150,
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
      sorter: true,
      render: (manufacturer) => (
        <Text>
          {" "}
          {manufacturer.itemNumber} <br></br>
          <small>{manufacturer.name}</small>
        </Text>
      ),
      width: 200,
    },
    ...(parseUser?.specifications || []).map((e: any) => ({
      title: e.text.charAt(0).toUpperCase() + e.text.slice(1),
      dataIndex: `specification.${e.value}`,
      key: `specification.${e.value}`,
      sorter: true,
      width: 150,
      render(_: any, record: any) {
        if (e.dataType === "Dropdown") {
          if (
            record.specification?.lifecycle?.toUpperCase().trim() === "ACTIVE"
          ) {
            return <Tag color="green">{record.specification?.lifecycle}</Tag>;
          } else if (
            record.specification?.lifecycle?.toUpperCase().trim() === "NRND"
          ) {
            return <Tag color="orange">{record.specification?.lifecycle}</Tag>;
          } else if (
            record.specification?.lifecycle?.toUpperCase().trim() === "UNKNOWN"
          ) {
            return <Tag color="grey">{record.specification?.lifecycle}</Tag>;
          } else {
            return record.specification && record.specification?.lifecycle ? (
              <Tag color="red">{record.specification?.lifecycle}</Tag>
            ) : (
              ""
            );
          }
        } else if (e.dataType === "Dateinput") {
          return record?.specification && record?.specification[e.value]
            ? new Date(record?.specification[e.value])?.toLocaleDateString()
            : "";
        } else {
          return record.specification ? record.specification[e.value] : "";
        }
      },
    })),
    {
      title: "FMD Document",
      dataIndex: "fmdDocument",
      key: "fmdDocument",
      width: 150,
    },
    {
      title: "Part Weight",
      dataIndex: "partWeight",
      key: "partWeight",
      width: 150,
    },

    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      width: 150,
      render: (text: string, record: any) => (
        <Text>{new Date(text).toLocaleString()}</Text>
      ),
    },
  ];

  const onSelectComponents = (
    newSelectedRowKeys: React.Key[],
    selectedRows: Component[]
  ) => {
    setAlternateSelection(newSelectedRowKeys);
    form.setFieldValue("alternate", newSelectedRowKeys);
  };

  const alternateRowSelection: TableRowSelection<Component> = {
    selectedRowKeys: alternateSelect,
    onChange: onSelectComponents,
    preserveSelectedRowKeys: true,
  };

  const items: TabsProps["items"] = [
    {
      key: "all",
      label: "Specification",
      children: (
        <>
          <Title level={5}>Link to Parent</Title>
          <Divider />
          <Row gutter={24}>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="productRefs" label="Product Reference">
                <Select
                  options={searchProduct.map((option) => ({
                    ...option,
                    label: (
                      <>
                        <div>{option.name} </div>
                        <div> {option.number} </div>
                      </>
                    ),
                  }))}
                  onSearch={handleProductSearch}
                  onChange={handleSelectProduct}
                  placeholder="Product Reference"
                  allowClear
                  mode="multiple"
                />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="componentRefs" label="Component Reference">
                <Select
                  options={searchComponent.map((option) => ({
                    ...option,
                    label: (
                      <>
                        <div>{option?.manufacturer?.name} </div>
                        <div> {option?.manufacturer?.itemNumber} </div>
                      </>
                    ),
                  }))}
                  onSearch={handleComponentSearch}
                  onChange={handleSelectComponent}
                  placeholder="Component Reference"
                  allowClear
                  mode="multiple"
                />
              </Form.Item>
            </Col>
          </Row>
          <Title level={5}>Component/ Item Identifier</Title>
          <Divider />
          <Form.List name="manufacturer">
            {(fields, operation, meta) => (
              <>
                <Row gutter={16}>
                  <Col span={12} sm={12} md={12}>
                    <Form.Item
                      name="name"
                      label="Manufacturer Name"
                      rules={[
                        {
                          required: true,
                          message: "Please input Manufacturer Name!",
                        },
                      ]}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col span={12} sm={12} md={12}>
                    <Form.Item
                      name="itemNumber"
                      label="Manufacturer Item Number"
                      rules={[
                        {
                          required: true,
                          message: "Please input Manufacturer Item Number!",
                        },
                      ]}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Form.List>
          <Row gutter={16}>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name="internalItemNumber"
                label="Internal Item Number"
                rules={[
                  {
                    required: true,
                    message: "Please input Internal item number!",
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="outOfScope" label="Out of Scope">
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Select out of scope values"
                  options={options}
                />
              </Form.Item>
            </Col>
          </Row>
          {parseUser.specifications && parseUser.specifications?.length > 0 && (
            <>
              <Title level={5}>Component/ Item Specification</Title>
              <Divider />
              <Row gutter={24}>
                {parseUser.specifications?.map((specification: any) => (
                  <Col key={specification.value} span={12} sm={12} md={12}>
                    <Form.Item
                      name={["specification", specification.value]}
                      label={specification.text}
                      initialValue={
                        specification.dataType === "Dateinput" &&
                        props?.formData?.specification &&
                        props?.formData?.specification[specification.value]
                          ? dayjs(
                              props?.formData?.specification[
                                specification.value
                              ]
                            )
                          : props?.formData?.specification &&
                            props?.formData?.specification[specification.value]
                      }
                    >
                      {specification.dataType === "Textfield" && <Input />}
                      {specification.dataType === "Dropdown" && (
                        <Select
                          allowClear
                          placeholder={`Select ${specification.text}`}
                          options={specification.dataValue
                            .split(",")
                            .map((value: string) => ({ label: value, value }))}
                        />
                      )}
                      {specification.dataType === "Fileinput" && (
                        <FileUploader
                          postUrl="products/components/document/upload"
                          tenantNumber={tenantNumber}
                          setFormField={(value: string) =>
                            form.setFieldValue(
                              ["specification", specification.value],
                              value
                            )
                          }
                          onUploadStatusChange={setIsUploading}
                        />
                      )}
                      {specification.dataType === "Dateinput" && <DatePicker />}
                    </Form.Item>
                  </Col>
                ))}
              </Row>
            </>
          )}
        </>
      ),
    },
    {
      key: "alternates",
      label: "Alternate Component",
      children: (
        <>
          <Space
            align="end"
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "1rem",
            }}
          >
            <Button type="primary" onClick={showChildrenDrawer}>
              {" "}
              Add Alternate{" "}
            </Button>
          </Space>
          <Form.Item name="alternate">
            <Table
              loading={loading}
              dataSource={alternates}
              columns={columns}
              scroll={{
                x: 1000,
                y: "calc(100vh - 285px)",
              }}
              pagination={{ ...tablePagination, total: alternatesCount }}
              rowKey="_id"
              onChange={handleTableChange}
              rowSelection={{
                type: "radio",
                ...alternateRowSelection,
              }}
              // rowSelection={componentSelection}
            />
          </Form.Item>
        </>
      ),
    },
    ...regulationsTabs,
    {
      key: "materials",
      label: "Materials Disclosure",
      children: (
        <>
          <Row gutter={24}>
            <Col>
              <Form.List name="manufacturer">
                {(fields, operation, meta) => (
                  <>
                    <Row gutter={24}>
                      <Col span={24} sm={24} md={24}>
                        <Form.Item
                          name="name"
                          label="Manufacturer Name"
                          rules={[
                            {
                              required: true,
                              message: "Please input Manufacturer Name!",
                            },
                          ]}
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
              </Form.List>
            </Col>
            <Col span={8} sm={8} md={8}>
              <Form.Item
                name="partWeight"
                label="Part Weight"
                // rules={[
                //   { required: true, message: "Please input Part weight!" },
                // ]}
              >
                <InputNumber
                  placeholder="Part Weight"
                  style={{ width: 200 }}
                  min={0}
                />
              </Form.Item>
            </Col>
            <Col span={8} sm={8} md={8}>
              <Form.Item name="partUnitOfMeasure" label="Part Weight Unit">
                <Select
                  allowClear
                  placeholder="Select Part Weight Unit"
                  options={[
                    { label: "kg", value: "kg" },
                    { label: "g", value: "g" },
                    { label: "mg", value: "mg" },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="fmdDocument" label="FMD Document">
                <FileUploader
                  postUrl="materials/document/upload"
                  tenantNumber={tenantNumber}
                  setFormField={(value: string) =>
                    form.setFieldValue("fmdDocument", value)
                  }
                  onUploadStatusChange={setIsUploading}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item name={"fmdComments"} label={"FMD Comments"}>
                <TextArea placeholder="Enter" rows={5}></TextArea>
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
  ];

  useEffect(() => {
    const styleElement = document.createElement("style");
    styleElement.innerHTML = HoverStyle;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  useEffect(() => {
    if (open && props?.formData) {
      setLoadingReference(true);
      if (props?.formData?.productRefs?.length) {
        getProductRefs(props?.formData?.productRefs);
      }
      if (props?.formData?.componentRefs?.length) {
        getComponentRefs(props?.formData?.componentRefs);
      }
      handleComponentSearch("aa");
      handleProductSearch();
      fetchAlternateData();
      setActiveTab(props.activeTab);
      handleTabChange(props.activeTab);
    }
  }, [open]);

  return (
    <div>
      <Button
        type="text"
        onClick={showDrawer}
        icon={<EditOutlined />}
        data-testid="edit-component-button"
        title="Edit Component"
      />
      <Drawer
        title="Edit Component"
        width={720}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button onClick={onClose} data-testid="cancel-component-button">
              Cancel
            </Button>
            <Button
              onClick={form.submit}
              type="primary"
              loading={loading}
              data-testid="submit-component-button"
              disabled={isUploading}
            >
              Submit
            </Button>
          </Space>
        }
      >
        {loadingReference ? (
          <Spin fullscreen spinning={loadingReference} />
        ) : (
          <>
            <Drawer
              title="Add Alternate"
              width={500}
              onClose={onChildrenDrawerClose}
              open={childrenDrawer}
              extra={
                <Space>
                  <Button onClick={onChildrenDrawerClose}>Cancel</Button>
                  <Button
                    onClick={alternateForm.submit}
                    type="primary"
                    loading={loading}
                  >
                    Save
                  </Button>
                </Space>
              }
            >
              <Form
                form={alternateForm}
                layout="vertical"
                onFinish={onFinishAlternate}
                autoComplete="off"
              >
                <Title level={5}>Link to Parent</Title>
                <Divider />
                <Row gutter={24}>
                  <Col span={12} sm={12} md={12}>
                    <Form.Item
                      name="componentRefs"
                      label="Component Reference"
                      initialValue={props?.formData?.manufacturer?.itemNumber}
                    >
                      <Select
                        options={[
                          {
                            label: props?.formData?.manufacturer?.itemNumber,
                            value: props?.formData?._id,
                          },
                        ]}
                        onSearch={handleComponentSearch}
                        onChange={handleSelectComponent}
                        placeholder="Component Reference"
                        allowClear
                        mode="multiple"
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} sm={12} md={12}>
                    <Form.Item
                      name="internalItemNumber"
                      label="Internal Item Number"
                      rules={[
                        {
                          required: true,
                          message: "Please input Internal item number!",
                        },
                      ]}
                      initialValue={props?.formData?.internalItemNumber}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Title level={5}>Component/ Item Identifier</Title>
                <Divider />
                <Form.List name="manufacturer">
                  {(fields, operation, meta) => (
                    <>
                      <Row gutter={16}>
                        <Col span={12} sm={12} md={12}>
                          <Form.Item
                            name="name"
                            label="Manufacturer Name"
                            rules={[
                              {
                                required: true,
                                message: "Please input Manufacturer Name!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={12} sm={12} md={12}>
                          <Form.Item
                            name="itemNumber"
                            label="Manufacturer Item Number"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Please input Manufacturer Item Number!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={12} sm={12} md={12}>
                          <Form.Item name="outOfScope" label="Out of Scope">
                            <Select
                              mode="multiple"
                              allowClear
                              placeholder="Select out of scope values"
                              options={options}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}
                </Form.List>
                {parseUser.specifications &&
                  parseUser.specifications?.length > 0 && (
                    <>
                      <Title level={5}>Component/ Item Specification</Title>
                      <Divider />
                      <Row gutter={24}>
                        {parseUser.specifications?.map((specification: any) => (
                          <Col
                            key={specification.value}
                            span={12}
                            sm={12}
                            md={12}
                          >
                            <Form.Item
                              name={["specification", specification.value]}
                              label={specification.text}
                            >
                              {specification.dataType === "Textfield" && (
                                <Input />
                              )}
                              {specification.dataType === "Dropdown" && (
                                <Select
                                  allowClear
                                  placeholder={`Select ${specification.text}`}
                                  options={specification.dataValue
                                    .split(",")
                                    .map((value: string) => ({
                                      label: value,
                                      value,
                                    }))}
                                />
                              )}
                              {specification.dataType === "Fileinput" && (
                                <FileUploader
                                  postUrl="products/components/document/upload"
                                  tenantNumber={tenantNumber}
                                  setFormField={(value: string) =>
                                    form.setFieldValue(
                                      ["specification", specification.value],
                                      value
                                    )
                                  }
                                  onUploadStatusChange={setIsUploading}
                                />
                              )}
                              {specification.dataType === "Dateinput" && (
                                <DatePicker />
                              )}
                            </Form.Item>
                          </Col>
                        ))}
                      </Row>
                    </>
                  )}
              </Form>
            </Drawer>
            <Form
              form={form}
              layout="vertical"
              initialValues={{
                ...props?.formData,
                productRefs: productReference,
                componentRefs: componentReference,
                specification: {},
              }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Tabs
                defaultActiveKey="all"
                activeKey={activeTab}
                onChange={handleTabChange}
                items={items}
              />
            </Form>
          </>
        )}
      </Drawer>
    </div>
  );
};

export default EditComponentModal;
