import React, { useEffect, useState } from "react";
import {
  Alert,
  Form,
  Input,
  Button,
  DatePicker,
  Radio,
  Select,
  Space,
  Table,
  TabsProps,
  Tabs,
  Result,
  Card,
  Steps,
  Col,
  Row,
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import TextArea from "antd/es/input/TextArea";
import { ColumnsType } from "antd/es/table";
import {
  createExtendedMinerals,
  extendedMineralsAnalytics,
  generateExtendedMinerals,
  listSmelter,
} from "../../../../redux/actions/extendedMineralsActions";
import { updateSupplierExtendedMinerals } from "../../../../redux/actions/supplierPortalAction";
import {
  CheckCircleFilled,
  ExclamationCircleFilled,
  PlusOutlined,
  WarningFilled,
} from "@ant-design/icons";
import AddSmelter from "../../../../components/modals/add-smelter-modal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { analyticsEMRT, generateCMRT } from "../../../../utils/types/summary";
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "../../../../utils/localStore";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ErrorBoundary from "../../../../utils/errorBoundary";

dayjs.extend(customParseFormat);

interface Declaration {
  question: string | null;
  name: string | null;
  state: string | null;
  comments: string | null;
}

interface Policy {
  question: string | null;
  name: string | null;
  state: string | null;
  comments: string | null;
}
interface Smelter {
  name: string;
  number: string;
  metal: string;
  lookUp: string;
  sourceOfIdentificationNumber: string;
  address: {
    country: string;
    city: string;
    state: string;
  };
  _id: string;
}
interface Products {
  manufacturer: {
    itemNumber: string;
    name: string;
  };
  comments: string;
  _id: string;
}
interface FormGenerate {
  emrtVersion: null | string;
  company: {
    name: string;
    declarationScope: string;
    declarationDescription: string;
    uniqueId: string;
    uniqueIdAuthority: string;
    address: string;
    contact: {
      name: string;
      emailAddress: string;
      phoneNumber: string;
    };
    authorizer: {
      name: string;
      title: string;
      emailAddress: string;
      phoneNumber: string;
      effectiveDate: Dayjs | null;
    };
  };
  declaration: Declaration[];
  declarationScope: Declaration[];
  policy: Policy[];
  smelter: Smelter[];
  products: Products[];
  rawFileName: string;
}

const ExtendedGenerator: React.FC = () => {
  const [EMRTformGenerate, setEMRTformGenerate] = useState<FormGenerate>({
    emrtVersion: "",
    company: {
      name: "",
      declarationScope: "",
      declarationDescription: "",
      uniqueId: "",
      uniqueIdAuthority: "",
      address: "",
      contact: {
        name: "",
        emailAddress: "",
        phoneNumber: "",
      },
      authorizer: {
        name: "",
        title: "",
        emailAddress: "",
        phoneNumber: "",
        effectiveDate: dayjs(),
      },
    },
    declaration: [
      {
        question:
          "Is any of the cobalt or natural mica intentionally added or used in the product(s) or in the production process?",
        name: "Cobalt",
        state: null,
        comments: null,
      },
      {
        question:
          "Is any of the cobalt or natural mica intentionally added or used in the product(s) or in the production process?",
        name: "Mica",
        state: null,
        comments: null,
      },
      {
        question: "Does any cobalt or natural mica remain in the product(s)?",
        name: "Cobalt",
        state: null,
        comments: null,
      },
      {
        question: "Does any cobalt or natural mica remain in the product(s)?",
        name: "Mica",
        state: null,
        comments: null,
      },
    ],
    products: [],
    declarationScope: [
      {
        question:
          "Do any of the smelters or processors in your supply chain source the cobalt or natural mica from conflict-affected and high-risk areas? (OECD Due Diligence Guidance, see definitions tab)",
        name: "Cobalt",
        state: null,
        comments: null,
      },
      {
        question:
          "Do any of the smelters or processors in your supply chain source the cobalt or natural mica from conflict-affected and high-risk areas? (OECD Due Diligence Guidance, see definitions tab)",
        name: "Mica",
        state: null,
        comments: null,
      },
      {
        question:
          "Does 100 percent of the cobalt originate from recycled or scrap sources?",
        name: "Cobalt",
        state: null,
        comments: null,
      },
      {
        question:
          "What percentage of relevant suppliers have provided a response to your supply chain survey?",
        name: "Cobalt",
        state: null,
        comments: null,
      },
      {
        question:
          "What percentage of relevant suppliers have provided a response to your supply chain survey?",
        name: "Mica",
        state: null,
        comments: null,
      },
      {
        question:
          "Have you identified all of the smelters or processors supplying the cobalt or natural mica to your supply chain?",
        name: "Cobalt",
        state: null,
        comments: null,
      },
      {
        question:
          "Have you identified all of the smelters or processors supplying the cobalt or natural mica to your supply chain?",
        name: "Mica",
        state: null,
        comments: null,
      },
      {
        question:
          "Has all applicable smelter or processor information received by your company been reported in this declaration?",
        name: "Cobalt",
        state: null,
        comments: null,
      },
      {
        question:
          "Has all applicable smelter or processor information received by your company been reported in this declaration?",
        name: "Mica",
        state: null,
        comments: null,
      },
    ],
    policy: [
      {
        question:
          "A. Have you established a responsible minerals sourcing policy?",
        name: "Point A",
        state: null,
        comments: null,
      },
      {
        question:
          "B. Is your responsible minerals sourcing policy publicly available on your website? (Note – If yes, the user shall specify the URL in the comment field.)",
        name: "Point B",
        state: null,
        comments: null,
      },
      {
        question:
          "C. Do you require your direct suppliers to source cobalt from smelters or natural mica from processors whose due diligence practices have been validated by an independent third-party audit program?",
        name: "Point C",
        state: null,
        comments: null,
      },
      {
        question:
          "C. Do you require your direct suppliers to source cobalt from smelters or natural mica from processors whose due diligence practices have been validated by an independent third-party audit program?",
        name: "Point C",
        state: null,
        comments: null,
      },
      {
        question:
          "D. Have you implemented due diligence measures for responsible sourcing?",
        name: "Point D",
        state: null,
        comments: null,
      },
      {
        question:
          "E. Does your company conduct cobalt and/or natural mica supply chain survey(s) of your relevant supplier(s)?",
        name: "Point E",
        state: null,
        comments: null,
      },
      {
        question:
          "F. Do you review due diligence information received from your suppliers against your company’s expectations?",
        name: "Point F",
        state: null,
        comments: null,
      },
      {
        question:
          "G. Does your review process include corrective action management?",
        name: "Point G",
        state: null,
        comments: null,
      },
    ],
    smelter: [],
    rawFileName: "",
  });
  const location = useLocation();
  const selectedTableRows = location.state;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { uniqueName, linkTenantNumber, linkCampaignId } = useParams();
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    sorter: {
      field: "metal",
      order: ["ascend"],
    },
  });
  const [disabledtab, setdisabledtab] = useState<boolean>(true);
  const [disabledcobalt, setDisabledcobalt] = useState<boolean>(false || true);
  const [disabledmica, setDisabledmica] = useState<boolean>(false || true);
  const [isUpdated, setisUpdated] = useState<boolean>(false);
  const [dec, setdec] = useState<any[]>([]);
  const [policyField, setpolicyField] = useState<any[]>([]);

  const [scope, setscope] = useState<any[]>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [smelterData, setSmelterData] = useState<any[]>([]);
  const [smelterCount, setSmelterCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [companyIcon, setcompanyIcon] = useState<boolean>(false);
  const [declarationIcon, setdeclarationIcon] = useState<boolean>(false);
  const [declarationScopeIcon, setdeclarationScopeIcon] =
    useState<boolean>(false);
  const [policyIcon, setpolicyIcon] = useState<boolean>(false);
  const [smelterIcon, setsmelterIcon] = useState<boolean>(false);
  const [productIcon, setproductIcon] = useState<boolean>(false);
  const [smelterMetalsRemove, setSmelterMetalsRemove] = useState<string[]>([]);
  const [smelterMetalsAdd, setSmelterMetalsAdd] = useState<string[]>([]);

  const [fieldvalidation, setfieldvalidation] = useState<boolean>(
    false || true
  );
  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    EMRTformGenerate.company.declarationScope
  );
  const [totalSmelterListDocument, setTotalSmelterListDocument] = useState<
    any[]
  >([]);
  const columns: ColumnsType<Smelter> = [
    {
      title: "Number #",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Look Up Name",
      dataIndex: "lookUp ",
      key: "lookUp",
      render: (text, record) => record.lookUp || record.name,
    },
    {
      title: "Metal",
      dataIndex: "metal",
      key: "metal",
    },
    {
      title: "Country",
      dataIndex: ["address", "country"],
      key: "country",
    },
  ];

  const columns1: ColumnsType<Products> = [
    {
      title: "Manufacturer Name",
      dataIndex: ["manufacturer", "name"],
      key: "manuname",
    },
    {
      title: "Manufacturer Item Number",
      dataIndex: ["manufacturer", "itemNumber"],
      key: "itemNumber",
    },
  ];

  useEffect(() => {
    const storedDataString = getLocalStorage("extendedMinerals");
    const fetchData = async () => {
      try {
        if (storedDataString) {
          try {
            setdisabledtab(false);
            const storedData: FormGenerate = storedDataString;
            setEMRTformGenerate(storedData);
            form.setFieldsValue(storedData);
          } catch (error) {
            console.error("Error parsing stored data:", error);
          }
        } else {
          readExtendedMineralAnalytic();
        }
      } catch (error) {
        console.error("Error parsing stored data:", error);
      }
    };
    fetchData();
    if (getLocalStorage("accessToken")) {
      handleTableChange(
        { pageSize: 10, current: 1 },
        {},
        { field: tableParams.sorter.field, order: tableParams.sorter.order }
      );
    }
  }, [form, selectedTableRows]);

  useEffect(() => {
    if (!dayjs.isDayjs(EMRTformGenerate?.company?.authorizer?.effectiveDate)) {
      EMRTformGenerate.company.authorizer.effectiveDate = dayjs(
        EMRTformGenerate.company.authorizer.effectiveDate
      );
    }
    if (disabledtab === true) {
      setCurrentStep(0);
    } else if (
      disabledtab === false &&
      selectedOption === "B. Product (or List of Products)"
    ) {
      setCurrentStep(7);
    } else {
      setCurrentStep(6);
    }
    form.setFieldsValue(EMRTformGenerate);
    if (
      EMRTformGenerate?.declaration &&
      EMRTformGenerate?.declaration?.length > 0 &&
      !isUpdated
    ) {
      setDisabledcobalt(
        EMRTformGenerate.declaration.some(
          (val) =>
            val.name === "Cobalt" &&
            val.question ===
              "Is any of the cobalt or natural mica intentionally added or used in the product(s) or in the production process?" &&
            val.state &&
            ["No", "Unknown", "Not applicable for this declaration"].includes(
              val.state
            )
        ) ||
          EMRTformGenerate.declaration.some(
            (val) =>
              val.name === "Cobalt" &&
              val.question ===
                "Does any cobalt or natural mica remain in the product(s)?" &&
              val.state &&
              ["No", "Unknown", "Not applicable for this declaration"].includes(
                val.state
              )
          )
      );
      setDisabledmica(
        EMRTformGenerate.declaration.some(
          (val) =>
            val.name === "Mica" &&
            val.question ===
              "Is any of the cobalt or natural mica intentionally added or used in the product(s) or in the production process?" &&
            val.state &&
            ["No", "Unknown", "Not applicable for this declaration"].includes(
              val.state
            )
        ) ||
          EMRTformGenerate.declaration.some(
            (val) =>
              val.name === "Mica" &&
              val.question ===
                "Does any cobalt or natural mica remain in the product(s)?" &&
              val.state &&
              ["No", "Unknown", "Not applicable for this declaration"].includes(
                val.state
              )
          )
      );
    }

    setSelectedOption(EMRTformGenerate.company.declarationScope);
    const allFields = form.getFieldsValue();
    const fieldNames = Object.keys(allFields);
    const policyValFields: string[] = [];
    fieldNames.forEach((name) => {
      if (
        (name.startsWith("A.") ||
          name.startsWith("B.") ||
          name.startsWith("C.") ||
          name.startsWith("D.") ||
          name.startsWith("E.") ||
          name.startsWith("F.") ||
          name.startsWith("G.")) &&
        name.endsWith("state")
      ) {
        policyValFields.push(name);
      }
    });

    setpolicyField(policyValFields);
    if (!getLocalStorage("extendedMinerals")) {
      setfieldvalidation(false);
    }
  }, [EMRTformGenerate, form]);

  const readExtendedMineralAnalytic = async () => {
    setLoading(true);
    try {
      const response: analyticsEMRT = await extendedMineralsAnalytics({
        version: getLocalStorage("emrtversion"),
      });
      if (response) {
        const suppliers = selectedTableRows
          ? selectedTableRows?.selectedTableRows?.length
          : response.suppliers;
        const supplierResponses = selectedTableRows
          ? selectedTableRows?.selectedTableRows?.flatMap(
              (e: { emrt: any }) => e?.emrt || []
            ).length
          : response.supplierResponses;
        await readExtendedMineralsGenerate(suppliers, supplierResponses);
      } else {
        console.error("Invalid or missing data in the API response.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const readExtendedMineralsGenerate = async (
    suppliers: any,
    supplierResponses: any
  ) => {
    setLoading(true);
    const user = getLocalStorage("user");
    const tenantNumber = user.tenantNumber;

    try {
      const response: generateCMRT = await generateExtendedMinerals({
        tenantNumber: tenantNumber,
      });
      if (response) {
        const updateDeclaration = EMRTformGenerate.declaration?.map((e) => {
          const obj = response.declarationDocument.find(
            (f) => f.name === e.name && f.question === e.question
          );
          return {
            ...e,
            state: obj?.state || null,
            comments: obj?.comments || null,
          };
        });

        const updateDeclarationScope = EMRTformGenerate.declarationScope?.map(
          (e) => {
            const obj = response?.declarationScopeDocument?.find(
              (f) => f.name === e.name && f.question === e.question
            );

            if (
              obj?.question ===
              "What percentage of relevant suppliers have provided a response to your supply chain survey?"
            ) {
              let state = obj?.state;
              if (supplierResponses === 0) {
                state = "None";
              } else if ((supplierResponses / suppliers) * 100 < 50) {
                state = "50% or less";
              } else if ((supplierResponses / suppliers) * 100 < 75) {
                state = "Greater than 50%";
              } else if ((supplierResponses / suppliers) * 100 < 90) {
                state = "Greater than 75%";
              } else if ((supplierResponses / suppliers) * 100 < 100) {
                state = "Greater than 90%";
              } else if ((supplierResponses / suppliers) * 100 === 100) {
                state = 1;
              }

              e.state = state;
              e.comments = obj?.comments;
            } else {
              e.state = obj?.state;
              e.comments = obj?.comments;
            }

            return e;
          }
        );
        const updatePolicy = EMRTformGenerate.policy?.map((e, eindex) => {
          const obj = response.policyDocument.find(
            (f, findex) =>
              `${e.name}${findex}` === `${e.name}${eindex}` &&
              `${e.question}${findex}` === `${e.question}${eindex}`
          );
          return {
            ...e,
            state: obj?.state || null,
            comments: obj?.comments || null,
          };
        });
        let companyDetails: any;

        if (user) {
          const parsedData = user;
          if (response.company === undefined) {
            companyDetails = {
              name: parsedData.companyName,
              declarationScope: "A. Company",
              declarationDescription: "",
              uniqueId: "",
              uniqueIdAuthority: "",
              address: "",
              contact: {
                name:
                  parsedData.firstName +
                  " " +
                  (parsedData.lastName ? parsedData.lastName : ""),
                emailAddress: parsedData.emailAddress,
                phoneNumber: parsedData.phoneNumber,
              },
              authorizer: {
                name: "",
                title: "",
                emailAddress: "",
                phoneNumber: "",
                effectiveDate: dayjs().format("DD-MMM-YYYY HH:mm:ss"),
              },
            };
          } else {
            const updatedCompanyDetails = response.company;
            companyDetails = {
              ...updatedCompanyDetails,
              authorizer: {
                name: updatedCompanyDetails.authorizer.name,
                title: updatedCompanyDetails.authorizer.title,
                emailAddress: updatedCompanyDetails.authorizer.emailAddress,
                phoneNumber: updatedCompanyDetails.authorizer.phoneNumber,
                effectiveDate: dayjs().format("DD-MMM-YYYY HH:mm:ss"),
              },
            };
          }
          setEMRTformGenerate((prevFormData) => ({
            ...prevFormData,
            company: companyDetails,
            declaration: updateDeclaration,
            declarationScope: updateDeclarationScope,
            policy: updatePolicy,
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeTab = (key: string) => {
    setCurrentStep(parseInt(key));
  };

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      const declaration = [];
      const declarationscope = [];
      for (const question of [
        "Is any of the cobalt or natural mica intentionally added or used in the product(s) or in the production process?",
        "Does any cobalt or natural mica remain in the product(s)?",
      ]) {
        for (const name of ["Cobalt", "Mica"]) {
          const keyPrefix = `${question}-${name}`;
          const stateKey = Object.keys(values).find(
            (key) => key.startsWith(keyPrefix) && key.endsWith("-state")
          );
          const commentkey = Object.keys(values).find(
            (key) => key.startsWith(keyPrefix) && key.endsWith("-comments")
          );

          if (stateKey && commentkey) {
            const stateValue = values[stateKey];
            const state = stateValue === null ? "None" : stateValue;
            const comment = values[commentkey];

            declaration.push({
              question,
              name,
              state,
              comments: comment,
            });
          }
        }
      }
      for (const question of [
        "Do any of the smelters or processors in your supply chain source the cobalt or natural mica from conflict-affected and high-risk areas? (OECD Due Diligence Guidance, see definitions tab)",
        "Does 100 percent of the cobalt originate from recycled or scrap sources?",
        "What percentage of relevant suppliers have provided a response to your supply chain survey?",
        "Have you identified all of the smelters or processors supplying the cobalt or natural mica to your supply chain?",
        "Has all applicable smelter or processor information received by your company been reported in this declaration?",
      ]) {
        for (const name of ["Cobalt", "Mica"]) {
          if (
            question ===
              "Does 100 percent of the cobalt originate from recycled or scrap sources?" &&
            name === "Mica"
          ) {
            continue;
          }
          const keyPrefix = `${question}-${name}`;
          const stateKey = Object.keys(values).find(
            (key) => key.startsWith(keyPrefix) && key.endsWith("-state")
          );
          const commentkey = Object.keys(values).find(
            (key) => key.startsWith(keyPrefix) && key.endsWith("-comments")
          );
          if (stateKey && commentkey) {
            const stateValue = values[stateKey];
            const state = stateValue === null ? "None" : stateValue;
            const comment = values[commentkey];

            declarationscope.push({
              question,
              name,
              state,
              comments: comment,
            });
          }
        }
      }

      const policy = EMRTformGenerate?.policy.map(
        (
          item: { question: any; name: any; comments: any; state: any },
          index
        ) => {
          const statekey = `${item.question}-${item.name}-${item.state}-${index}-state`;
          const commentkey = `${item.question}-${item.name}-${item.comments}-${index}-comments`;
          return {
            question: item.question,
            name: item.name,
            state: values[statekey],
            comments: values[commentkey],
          };
        }
      );

      const companyDetails = {
        name: values.company.name,
        declarationScope: values.company.declarationScope,
        declarationDescription: values.company.declarationDescription,
        uniqueId: values.company.uniqueId,
        uniqueIdAuthority: values.company.uniqueIdAuthority,
        address: values.company.address,
        contact: {
          name: values.company.contact?.name,
          emailAddress: values.company.contact?.emailAddress,
          phoneNumber: values.company.contact?.phoneNumber,
        },
        authorizer: {
          name: values.company.authorizer?.name,
          title: values.company.authorizer?.title,
          emailAddress: values.company.authorizer?.emailAddress,
          phoneNumber: values.company.authorizer?.phoneNumber,
          effectiveDate: (
            values.company.authorizer?.effectiveDate || ""
          ).format("DD-MMM-YYYY HH:mm:ss"),
        },
      };

      const user = getLocalStorage("user");
      const supplier = getLocalStorage("supplier");
      if (user) {
        let smelterVal;
        const parsedData = user;
        const companyName = parsedData.companyName;
        const storedDataString = getLocalStorage("extendedMinerals");
        if (storedDataString) {
          smelterVal = EMRTformGenerate?.smelter;
        } else {
          smelterVal = smelterData;
        }
        if (getLocalStorage("accessToken")) {
          await createExtendedMinerals({
            emrtVersion: EMRTformGenerate.emrtVersion,
            declaration: declaration,
            declarationScope: declarationscope,
            policy: policy,
            company: companyDetails,
            smelters: disabledcobalt && disabledmica ? [] : smelterVal,
            products: EMRTformGenerate?.products,
            rawFileName: EMRTformGenerate?.rawFileName,
            supplierName: companyName,
            emrtSupplier: supplier,
            sendgridCampaignId: null,
            supportingDocuments: null,
            type: storedDataString ? "" : "generator",
            version: getLocalStorage("emrtversion"),
          });
        } else {
          await updateSupplierExtendedMinerals({
            emrtVersion: EMRTformGenerate.emrtVersion,
            declaration: declaration,
            declarationScope: declarationscope,
            policy: policy,
            company: companyDetails,
            smelters: smelterVal,
            products: EMRTformGenerate?.products,
            rawFileName: EMRTformGenerate?.rawFileName,
            supplierName: uniqueName,
            emrtSupplier: supplier,
            supportingDocuments: null,
            tenantNumber: window.atob(linkTenantNumber!),
            sendgridCampaignId: window.atob(linkCampaignId!),
          });
        }
      }
    } catch (error) {
      console.error("Error sending data:", error);
    } finally {
      if (getLocalStorage("accessToken")) {
        if (getLocalStorage("extendedMinerals")) {
          navigate(
            "/app/responsibleminerals/extendedminerals/responsetracker",
            {
              replace: true,
            }
          );
        } else {
          navigate("/app/responsibleminerals/extendedminerals", {
            replace: true,
          });
          setLocalStorage("autoDownload", "consolidated");
        }
        setTimeout(() => {
          removeLocalStorage("extendedMinerals");
          setLoading(false);
        }, 1000);
      } else {
        navigate("../campaign/extended minerals", { replace: true });
        removeLocalStorage("extendedMinerals");
        setLoading(false);
      }
    }
  };

  const handleTableChange = async (
    pagination: any,
    filters: any,
    sorter: any
  ) => {
    setLoading(true);
    try {
      const response = await listSmelter({
        sortBy: sorter?.field,
        sortDesc: sorter?.order === "descend",
        pageSize: pagination.pageSize,
        pageNum: pagination.current,
        filters: selectedTableRows?.selectedTableRows
          ? JSON.stringify({
              "references.mineral": "ExtendedMinerals",
              version: getLocalStorage("emrtversion"),
              "references.supplier": {
                $in: selectedTableRows?.selectedTableRows.map(
                  (e: { name: any }) => e.name
                ),
              },
            })
          : JSON.stringify({
              "references.mineral": "ExtendedMinerals",
              version: getLocalStorage("emrtversion"),
            }),
      });

      setTableParams({
        pagination: { ...pagination },
        sorter: sorter,
      });
      setSmelterData(response.data.smelters);
      setTotalSmelterListDocument(
        response.data.totalSmelterListDocuments || []
      );
      setSmelterCount(response.data.count);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const openSmelterModal = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleAddToTable = (formData: any) => {
    if (getLocalStorage("extendedMinerals")) {
      EMRTformGenerate.smelter = [
        formData,
        ...(EMRTformGenerate?.smelter || []),
      ];
    } else {
      setSmelterData([formData, ...smelterData]);
    }
  };

  const handleFieldChange = (fieldName: string, name: any) => {
    const value = form.getFieldValue(fieldName);
    if (name === "Cobalt") {
      setDisabledcobalt(
        value === "No" ||
          value === "Unknown" ||
          value === "Not applicable for this declaration"
      );
    }
    if (name === "Mica") {
      setDisabledmica(
        value === "No" ||
          value === "Unknown" ||
          value === "Not applicable for this declaration"
      );
    }
    setisUpdated(true);
  };

  const handleNext = async () => {
    try {
      const allFields = form.getFieldsValue();
      const fieldNames = Object.keys(allFields);
      const declarationScopeFields: string[] = [];
      const declarationFields: string[] = [];

      fieldNames.forEach((name) => {
        if (
          (name.startsWith(
            "Is any of the cobalt or natural mica intentionally added or used in the product(s) or in the production process?"
          ) ||
            name.startsWith(
              "Does any cobalt or natural mica remain in the product(s)?"
            )) &&
          name.endsWith("state")
        ) {
          declarationFields.push(name);
        }
        if (
          (name.startsWith(
            "Do any of the smelters or processors in your supply chain source the cobalt or natural mica from conflict-affected and high-risk areas? (OECD Due Diligence Guidance, see definitions tab)"
          ) ||
            name.startsWith(
              "Does 100 percent of the cobalt originate from recycled or scrap sources?"
            ) ||
            name.startsWith(
              "What percentage of relevant suppliers have provided a response to your supply chain survey?"
            ) ||
            name.startsWith(
              "Have you identified all of the smelters or processors supplying the cobalt or natural mica to your supply chain?"
            ) ||
            name.startsWith(
              "Has all applicable smelter or processor information received by your company been reported in this declaration?"
            )) &&
          name.endsWith("state")
        ) {
          declarationScopeFields.push(name);
        }
      });

      setdec(declarationFields);
      setscope(declarationScopeFields);
      if (currentStep === 0) {
        await form.validateFields([
          ["company", "name"],
          ["company", "authorizer", "effectiveDate"],
          ["company", "contact", "emailAddress"],
          ["company", "contact", "name"],
          ["company", "contact", "phoneNumber"],
          ["company", "declarationScope"],
          ["company", "authorizer", "name"],
          ["company", "authorizer", "emailAddress"],
          ["company", "declarationDescription"],
        ]);
        setcompanyIcon(false);
      } else if (currentStep === 1) {
        await form.validateFields(dec);
        setdeclarationIcon(false);
      } else if (currentStep === 2) {
        await form.validateFields(scope);
        setdeclarationScopeIcon(false);
      }
      if (
        currentStep === 3 &&
        !Object.values(form.getFieldsValue(policyField)).some(
          (value) => value === null
        )
      ) {
        setpolicyIcon(false);
      } else if (currentStep === 3 && !(disabledcobalt && disabledmica)) {
        await form.validateFields(policyField);
        setpolicyIcon(false);
      }
      if (
        currentStep === 4 &&
        selectedOption !== "B. Product (or List of Products)"
      ) {
        const nextStep = currentStep + 2;
        setCurrentStep(nextStep);
      } else {
        const nextStep = currentStep + 1;
        setCurrentStep(nextStep);
        setExecuted(false);
      }

      const Cobalt = form.getFieldValue(
        Object.keys(form.getFieldsValue()).find(
          (key) =>
            key.includes(
              "Is any of the cobalt or natural mica intentionally added or used in the product(s) or in the production process?-Cobalt"
            ) && key.endsWith("-state")
        )
      );
      const Mica = form.getFieldValue(
        Object.keys(form.getFieldsValue()).find(
          (key) =>
            key.includes(
              "Is any of the cobalt or natural mica intentionally added or used in the product(s) or in the production process?-Mica"
            ) && key.endsWith("-state")
        )
      );
      if (
        Cobalt === "Yes" &&
        (getLocalStorage("extendedMinerals")
          ? EMRTformGenerate?.smelter?.filter((e: any) => e.metal === "Cobalt")
              .length === 0
          : totalSmelterListDocument?.filter((e: any) => e.metal === "Cobalt")
              .length === 0)
      ) {
        setSmelterMetalsAdd((prev: any) =>
          !prev.includes("Cobalt") ? [...prev, "Cobalt"] : prev
        );
        setsmelterIcon(smelterMetalsAdd.length > 0 ? true : false);
      } else {
        setSmelterMetalsAdd((prev: any) =>
          prev.filter((e: any) => e !== "Cobalt")
        );
        setsmelterIcon(smelterMetalsAdd.length > 0 ? true : false);
      }
      if (
        Mica === "Yes" &&
        (getLocalStorage("extendedMinerals")
          ? EMRTformGenerate?.smelter?.filter((e: any) => e.metal === "Mica")
              .length === 0
          : totalSmelterListDocument?.filter((e: any) => e.metal === "Mica")
              .length === 0)
      ) {
        setSmelterMetalsAdd((prev: any) =>
          !prev.includes("Mica") ? [...prev, "Mica"] : prev
        );
        setsmelterIcon(smelterMetalsAdd.length > 0 ? true : false);
      } else {
        setSmelterMetalsAdd((prev: any) =>
          prev.filter((e: any) => e !== "Mica")
        );
        setsmelterIcon(smelterMetalsAdd.length > 0 ? true : false);
      }
      if (
        (Cobalt === "No" ||
          Cobalt === "Unknown" ||
          Cobalt === "Not applicable for this declaration") &&
        (getLocalStorage("extendedMinerals")
          ? EMRTformGenerate?.smelter?.filter((e: any) => e.metal === "Cobalt")
              .length > 0
          : totalSmelterListDocument?.filter((e: any) => e.metal === "Cobalt")
              .length > 0)
      ) {
        setSmelterMetalsRemove((prev: any) =>
          !prev.includes("Cobalt") ? [...prev, "Cobalt"] : prev
        );
        setsmelterIcon(smelterMetalsRemove.length > 0 ? true : false);
      } else {
        setSmelterMetalsRemove((prev: any) =>
          prev.filter((e: any) => e !== "Cobalt")
        );
        setsmelterIcon(smelterMetalsRemove.length > 0 ? true : false);
      }
      if (
        (Mica === "No" ||
          Mica === "Unknown" ||
          Mica === "Not applicable for this declaration") &&
        (getLocalStorage("extendedMinerals")
          ? EMRTformGenerate?.smelter?.filter((e: any) => e.metal === "Mica")
              .length > 0
          : totalSmelterListDocument?.filter((e: any) => e.metal === "Mica")
              .length > 0)
      ) {
        setSmelterMetalsRemove((prev: any) =>
          !prev.includes("Mica") ? [...prev, "Mica"] : prev
        );
        setsmelterIcon(smelterMetalsRemove.length > 0 ? true : false);
      } else {
        setSmelterMetalsRemove((prev: any) =>
          prev.filter((e: any) => e !== "Mica")
        );
        setsmelterIcon(smelterMetalsRemove.length > 0 ? true : false);
      }

      if (EMRTformGenerate.products.length !== 0) {
        setproductIcon(false);
      }
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };
  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSelectChange = (value: string) => {
    setSelectedOption(value);
  };
  const actionCards = [
    <Space key="1" style={{ display: "flex", justifyContent: "end" }}>
      {currentStep > 0 && (
        <Button style={{ margin: "0 8px" }} type="primary" onClick={handlePrev}>
          Back
        </Button>
      )}
      {currentStep <= 5 && (
        <Button
          style={{ background: "#045B7C", color: "white", marginRight: 8 }}
          onClick={handleNext}
          loading={loading}
        >
          Next
        </Button>
      )}
    </Space>,
  ];
  const [executed, setExecuted] = useState(false);
  if (getLocalStorage("extendedMinerals") && currentStep === 6 && !executed) {
    form
      .validateFields()
      .then((value: any) => {
        if (Object.values(value).length > 0) {
          setfieldvalidation(false);
        } else {
          setfieldvalidation(true);
        }
      })
      .catch((error) => {
        if (error.errorFields.length > 0) {
          if (error.errorFields.some((e: any) => e.name.includes("company"))) {
            setcompanyIcon(true);
          }
          if (
            error.errorFields.some((e: any) =>
              JSON.stringify(e.name).includes(
                "Is any of the cobalt or natural mica intentionally added or used in the product(s) or in the production process?"
              )
            ) ||
            error.errorFields.some((e: any) =>
              JSON.stringify(e.name).includes(
                "Does any cobalt or natural mica remain in the product(s)?"
              )
            )
          ) {
            setdeclarationIcon(true);
          }
          if (
            error.errorFields.some((e: any) =>
              JSON.stringify(e.name).includes(
                "Do any of the smelters or processors in your supply chain source the cobalt or natural mica from conflict-affected and high-risk areas? (OECD Due Diligence Guidance, see definitions tab)"
              )
            ) ||
            error.errorFields.some((e: any) =>
              JSON.stringify(e.name).includes(
                "Does 100 percent of the cobalt originate from recycled or scrap sources?"
              )
            ) ||
            error.errorFields.some((e: any) =>
              JSON.stringify(e.name).includes(
                "What percentage of relevant suppliers have provided a response to your supply chain survey?"
              )
            ) ||
            error.errorFields.some((e: any) =>
              JSON.stringify(e.name).includes(
                "Have you identified all of the smelters or processors supplying the cobalt or natural mica to your supply chain?"
              )
            ) ||
            error.errorFields.some((e: any) =>
              JSON.stringify(e.name).includes(
                "Has all applicable smelter or processor information received by your company been reported in this declaration?"
              )
            )
          ) {
            setdeclarationScopeIcon(true);
          }
        }

        if (EMRTformGenerate?.products?.length === 0) {
          setproductIcon(true);
        }
        if (
          Object.values(form.getFieldsValue(policyField)).some(
            (value) => value === null
          )
        ) {
          setpolicyIcon(true);
        }
      });
    const Cobalt = form.getFieldValue(
      Object.keys(form.getFieldsValue()).find(
        (key) =>
          key.includes(
            "Is any of the cobalt or natural mica intentionally added or used in the product(s) or in the production process?-Cobalt"
          ) && key.endsWith("-state")
      )
    );
    const Mica = form.getFieldValue(
      Object.keys(form.getFieldsValue()).find(
        (key) =>
          key.includes(
            "Is any of the cobalt or natural mica intentionally added or used in the product(s) or in the production process?-Mica"
          ) && key.endsWith("-state")
      )
    );

    if (
      Cobalt === "Yes" &&
      (getLocalStorage("extendedMinerals")
        ? EMRTformGenerate?.smelter?.filter((e: any) => e.metal === "Cobalt")
            .length === 0
        : totalSmelterListDocument?.filter((e: any) => e.metal === "Cobalt")
            .length === 0)
    ) {
      setSmelterMetalsAdd((prev: any) =>
        !prev.includes("Cobalt") ? [...prev, "Cobalt"] : prev
      );
      setsmelterIcon(true);
    } else {
      setSmelterMetalsAdd((prev: any) =>
        prev.filter((e: any) => e !== "Cobalt")
      );
      setsmelterIcon(
        smelterMetalsRemove.length > 0 || smelterMetalsAdd.length > 0
      );
    }
    if (
      Mica === "Yes" &&
      (getLocalStorage("extendedMinerals")
        ? EMRTformGenerate?.smelter?.filter((e: any) => e.metal === "Mica")
            .length === 0
        : totalSmelterListDocument?.filter((e: any) => e.metal === "Mica")
            .length === 0)
    ) {
      setSmelterMetalsAdd((prev: any) =>
        !prev.includes("Mica") ? [...prev, "Mica"] : prev
      );
      setsmelterIcon(true);
    } else {
      setSmelterMetalsAdd((prev: any) => prev.filter((e: any) => e !== "Mica"));
      setsmelterIcon(
        smelterMetalsRemove.length > 0 || smelterMetalsAdd.length > 0
      );
    }
    if (
      (Cobalt === "No" ||
        Cobalt === "Unknown" ||
        Cobalt === "Not applicable for this declaration") &&
      (getLocalStorage("extendedMinerals")
        ? EMRTformGenerate?.smelter?.filter((e: any) => e.metal === "Cobalt")
            .length > 0
        : totalSmelterListDocument?.filter((e: any) => e.metal === "Cobalt")
            .length > 0)
    ) {
      setSmelterMetalsRemove((prev: any) =>
        !prev.includes("Cobalt") ? [...prev, "Cobalt"] : prev
      );
      setsmelterIcon(true);
    } else {
      setSmelterMetalsRemove((prev: any) =>
        prev.filter((e: any) => e !== "Cobalt")
      );
      setsmelterIcon(
        smelterMetalsRemove.length > 0 || smelterMetalsAdd.length > 0
      );
    }
    if (
      (Mica === "No" ||
        Mica === "Unknown" ||
        Mica === "Not applicable for this declaration") &&
      (getLocalStorage("extendedMinerals")
        ? EMRTformGenerate?.smelter?.filter((e: any) => e.metal === "Mica")
            .length > 0
        : totalSmelterListDocument?.filter((e: any) => e.metal === "Mica")
            .length > 0)
    ) {
      setSmelterMetalsRemove((prev: any) =>
        !prev.includes("Mica") ? [...prev, "Mica"] : prev
      );
      setsmelterIcon(true);
    } else {
      setSmelterMetalsRemove((prev: any) =>
        prev.filter((e: any) => e !== "Mica")
      );
      setsmelterIcon(
        smelterMetalsRemove.length > 0 || smelterMetalsAdd.length > 0
      );
    }

    setExecuted(true);
  }

  const fixError = () => {
    form
      .validateFields()
      .then((value: any) => {
        return false;
      })
      .catch((error) => {
        if (error.errorFields.some((e: any) => e.name.includes("company"))) {
          setCurrentStep(0);
        } else if (
          error.errorFields.some((e: any) =>
            JSON.stringify(e.name).includes(
              "Is any of the cobalt or natural mica intentionally added or used in the product(s) or in the production process?"
            )
          ) ||
          error.errorFields.some((e: any) =>
            JSON.stringify(e.name).includes(
              "Does any cobalt or natural mica remain in the product(s)?"
            )
          )
        ) {
          setCurrentStep(1);
        } else if (
          error.errorFields.some((e: any) =>
            JSON.stringify(e.name).includes(
              "Do any of the smelters or processors in your supply chain source the cobalt or natural mica from conflict-affected and high-risk areas? (OECD Due Diligence Guidance, see definitions tab)"
            )
          ) ||
          error.errorFields.some((e: any) =>
            JSON.stringify(e.name).includes(
              "Does 100 percent of the cobalt originate from recycled or scrap sources?"
            )
          ) ||
          error.errorFields.some((e: any) =>
            JSON.stringify(e.name).includes(
              "What percentage of relevant suppliers have provided a response to your supply chain survey?"
            )
          ) ||
          error.errorFields.some((e: any) =>
            JSON.stringify(e.name).includes(
              "Have you identified all of the smelters or processors supplying the cobalt or natural mica to your supply chain?"
            )
          ) ||
          error.errorFields.some((e: any) =>
            JSON.stringify(e.name).includes(
              "Has all applicable smelter or processor information received by your company been reported in this declaration?"
            )
          )
        ) {
          setCurrentStep(2);
        } else if (
          error.errorFields.some((e: any) =>
            JSON.stringify(e.name).includes(
              "A. Have you established a responsible minerals sourcing policy?"
            )
          ) ||
          error.errorFields.some((e: any) =>
            JSON.stringify(e.name).includes(
              "B. Is your responsible minerals sourcing policy publicly available on your website? (Note – If yes, the user shall specify the URL in the comment field.)"
            )
          ) ||
          error.errorFields.some((e: any) =>
            JSON.stringify(e.name).includes(
              "C. Do you require your direct suppliers to source cobalt from smelters or natural mica from processors whose due diligence practices have been validated by an independent third-party audit program?"
            )
          ) ||
          error.errorFields.some((e: any) =>
            JSON.stringify(e.name).includes(
              "D. Have you implemented due diligence measures for responsible sourcing?"
            )
          ) ||
          error.errorFields.some((e: any) =>
            JSON.stringify(e.name).includes(
              "E. Does your company conduct cobalt and/or natural mica supply chain survey(s) of your relevant supplier(s)?"
            )
          ) ||
          error.errorFields.some((e: any) =>
            JSON.stringify(e.name).includes(
              "F. Do you review due diligence information received from your suppliers against your company’s expectations?"
            )
          ) ||
          error.errorFields.some((e: any) =>
            JSON.stringify(e.name).includes(
              "G. Does your review process include corrective action management?"
            )
          )
        ) {
          setCurrentStep(3);
        }
      });
  };
  const items: TabsProps["items"] = [
    {
      label: (
        <>
          COMPANY INFORMATION
          {getLocalStorage("extendedMinerals") && (
            <span style={{ marginLeft: "8px" }}>
              {companyIcon ? (
                <ExclamationCircleFilled
                  style={{ color: "#F5222D", fontSize: "16px" }}
                />
              ) : (
                <CheckCircleFilled
                  style={{ color: "#237804", fontSize: "16px" }}
                />
              )}
            </span>
          )}
        </>
      ),
      key: "0",
      forceRender: true,
      disabled: disabledtab === false && currentStep < 0,
      children: (
        <Card
          title="Company Information"
          style={{ overflow: "auto", height: "64vh" }}
          actions={actionCards}
        >
          <Row>
            <Col span={6}>
              <Form.Item>Company name *</Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name={["company", "name"]}
                initialValue={EMRTformGenerate?.company.name}
                rules={[
                  {
                    required: true,
                    message: "Company name is required",
                  },
                  {
                    validator: (_, value) => {
                      if (
                        (value &&
                          !getLocalStorage("extendedMinerals") &&
                          !/^(?!.*\s{2,})[a-zA-Z0-9()-/.,&_@*\u00C0-\u024F\u00A9|\u2122|\u00AEu4E00-\u9FFF\u{20000}-\u{2FA1F}\p{L}\p{Mn}\p{P}\p{N}\s]+$/u.test(
                            value
                          )) ||
                        (getLocalStorage("extendedMinerals")?.company?.name ===
                          null &&
                          !/^(?!.*\s{2,})[a-zA-Z0-9()-/.,&_@*\u00C0-\u024F\u00A9|\u2122|\u00AEu4E00-\u9FFF\u{20000}-\u{2FA1F}\p{L}\p{Mn}\p{P}\p{N}\s]+$/u.test(
                            value
                          ))
                      ) {
                        return Promise.reject("Not Allowed");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item>Declaration Scope or Class *</Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name={["company", "declarationScope"]}
                initialValue={EMRTformGenerate?.company.declarationScope}
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Select onChange={handleSelectChange}>
                  <Select.Option value="A. Company">A. Company</Select.Option>
                  <Select.Option value="B. Product (or List of Products)">
                    B. Product (or List of Products)
                  </Select.Option>
                  <Select.Option value="C. User defined [Specify in 'Description of scope']">
                    C. User defined [Specify in &#39;Description of scope&#39;]
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item>Description of Scope</Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name={["company", "declarationDescription"]}
                initialValue={EMRTformGenerate?.company.declarationDescription}
                rules={[
                  {
                    required:
                      selectedOption ===
                      "C. User defined [Specify in 'Description of scope']",
                    message: "Required",
                  },
                ]}
              >
                <TextArea />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item>Company Unique ID</Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name={["company", "uniqueId"]}
                initialValue={EMRTformGenerate?.company.uniqueId}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item>Company Unique ID Authority</Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name={["company", "uniqueIdAuthority"]}
                initialValue={EMRTformGenerate?.company.uniqueIdAuthority}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item>Address</Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name={["company", "address"]}
                initialValue={EMRTformGenerate?.company.address}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item>Contact Name *</Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name={["company", "contact", "name"]}
                initialValue={EMRTformGenerate?.company.contact.name}
                rules={[
                  {
                    required: true,
                    message: "Name is required",
                  },
                  {
                    validator: (_, value) => {
                      if (
                        (value &&
                          !getLocalStorage("extendedMinerals") &&
                          !/^([a-zA-Z0-9().-/&]+\s?)*$/i.test(value)) ||
                        (value &&
                          getLocalStorage("extendedMinerals")?.company?.contact
                            ?.name === null &&
                          !/^([a-zA-Z0-9().-/&]+\s?)*$/i.test(value))
                      ) {
                        return Promise.reject("Not Allowed");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item>Contact Number *</Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name={["company", "contact", "phoneNumber"]}
                initialValue={EMRTformGenerate?.company.contact.phoneNumber}
                rules={[
                  {
                    required: true,
                    message: "Number is required",
                  },
                  {
                    validator: (_, value) => {
                      if (
                        (value &&
                          !getLocalStorage("extendedMinerals") &&
                          !/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i.test(
                            value
                          )) ||
                        (value &&
                          getLocalStorage("extendedMinerals")?.company?.contact
                            ?.phoneNumber === null &&
                          !/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i.test(
                            value
                          ))
                      ) {
                        return Promise.reject("Phone Number must be valid");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item>Contact Email *</Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name={["company", "contact", "emailAddress"]}
                initialValue={EMRTformGenerate?.company.contact.emailAddress}
                rules={[
                  {
                    required: true,
                    message: "Email is required",
                  },
                  {
                    validator: (_, value) => {
                      if (
                        (value &&
                          !getLocalStorage("extendedMinerals") &&
                          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                            value
                          )) ||
                        (value &&
                          getLocalStorage("extendedMinerals")?.company?.contact
                            ?.emailAddress === null &&
                          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                            value
                          ))
                      ) {
                        return Promise.reject("E-mail must be valid");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item>Authorizer *</Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name={["company", "authorizer", "name"]}
                initialValue={EMRTformGenerate?.company.authorizer.name}
                rules={[
                  {
                    required: true,
                    message: "Name is required",
                  },
                  {
                    validator: (_, value) => {
                      if (
                        (value &&
                          !getLocalStorage("extendedMinerals") &&
                          !/^([a-zA-Z0-9().-/&]+\s?)*$/i.test(value)) ||
                        (value &&
                          getLocalStorage("extendedMinerals")?.company
                            ?.authorizer?.name === null &&
                          !/^([a-zA-Z0-9().-/&]+\s?)*$/i.test(value))
                      ) {
                        return Promise.reject("Not Allowed");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item>Title-Authorizer</Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name={["company", "authorizer", "title"]}
                initialValue={EMRTformGenerate?.company.authorizer.title}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item>Email-Authorizer *</Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name={["company", "authorizer", "emailAddress"]}
                initialValue={EMRTformGenerate?.company.authorizer.emailAddress}
                rules={[
                  {
                    required: true,
                    message: "Email is required",
                  },
                  {
                    validator: (_, value) => {
                      if (
                        (value &&
                          !getLocalStorage("extendedMinerals") &&
                          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                            value
                          )) ||
                        (value &&
                          getLocalStorage("extendedMinerals")?.company
                            ?.authorizer?.emailAddress === null &&
                          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                            value
                          ))
                      ) {
                        return Promise.reject("E-mail must be valid");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item>Phone-Authorizer</Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name={["company", "authorizer", "phoneNumber"]}
                initialValue={EMRTformGenerate?.company.authorizer.phoneNumber}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item>Effective Date *</Form.Item>
            </Col>
            <Col span={16}>
              {EMRTformGenerate?.company?.authorizer?.effectiveDate &&
                EMRTformGenerate?.company?.authorizer?.effectiveDate !== null &&
                dayjs.isDayjs(
                  EMRTformGenerate?.company?.authorizer?.effectiveDate
                ) && (
                  <Form.Item
                    name={["company", "authorizer", "effectiveDate"]}
                    rules={[{ required: true, message: "Date is required" }]}
                  >
                    <DatePicker
                      format={"DD-MMM-YYYY"}
                      defaultValue={
                        EMRTformGenerate?.company?.authorizer?.effectiveDate
                          ? dayjs(
                              EMRTformGenerate?.company?.authorizer
                                ?.effectiveDate
                            )
                          : undefined
                      }
                    />
                  </Form.Item>
                )}
            </Col>
          </Row>
        </Card>
      ),
    },
    {
      label: (
        <>
          DECLARATION
          {getLocalStorage("extendedMinerals") && (
            <span style={{ marginLeft: "8px" }}>
              {declarationIcon ? (
                <ExclamationCircleFilled
                  style={{ color: "#F5222D", fontSize: "16px" }}
                />
              ) : (
                <CheckCircleFilled
                  style={{ color: "#237804", fontSize: "16px" }}
                />
              )}
            </span>
          )}
        </>
      ),
      key: "1",
      disabled: currentStep < 1,

      forceRender: true,
      children: (
        <Card
          title="Declaration"
          style={{ overflow: "auto", height: "64vh" }}
          actions={actionCards}
        >
          <Card.Meta description="Answer the following questions based on the selected declaration scope" />
          <br></br>
          <b>
            1. Is any of the cobalt or natural mica intentionally added or used
            in the product(s) or in the production process? *
          </b>

          {EMRTformGenerate?.declaration
            .filter(
              (item) =>
                item.question ===
                "Is any of the cobalt or natural mica intentionally added or used in the product(s) or in the production process?"
            )
            .map((item, index) => (
              <Row
                key={`Is any of the cobalt or natural mica intentionally added or used in the product(s) or in the production process?-${item.name}`}
                style={{ display: "flex", marginBottom: 8 }}
              >
                <Col span={4}>
                  <Form.Item>{item.name}</Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={`${item.question}-${item.name}-${item.state}-state`}
                    initialValue={item.state}
                    dependencies={[
                      `Is any of the cobalt or natural mica intentionally added or used in the product(s) or in the production process?-${item.name}-${item.state}-state`,
                    ]}
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Radio.Group
                      onChange={() =>
                        handleFieldChange(
                          `Is any of the cobalt or natural mica intentionally added or used in the product(s) or in the production process?-${item.name}-${item.state}-state`,
                          item.name
                        )
                      }
                    >
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                      <Radio value="Unknown">Unknown</Radio>
                      <Radio value="Not applicable for this declaration">
                        Not applicable for this declaration
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ textAlign: "center" }}>
                  <Form.Item
                    name={`${item.question}-${item.name}-${item.comments}-comments`}
                    initialValue={item.comments}
                  >
                    <Input placeholder="Comments" />
                  </Form.Item>
                </Col>
              </Row>
            ))}

          <b>2. Does any cobalt or natural mica remain in the product(s)? *</b>

          {EMRTformGenerate?.declaration
            .filter(
              (item) =>
                item.question ===
                "Does any cobalt or natural mica remain in the product(s)?"
            )
            .map((item, index) => (
              <Row
                key={`Does any cobalt or natural mica remain in the product(s)?-${item.name}`}
                style={{ display: "flex", marginBottom: 8 }}
              >
                <Col span={4}>
                  <Form.Item>{item.name}</Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={`Does any cobalt or natural mica remain in the product(s)?-${item.name}-${item.state}-state`}
                    initialValue={item.state}
                    rules={[
                      index === 0 && !disabledcobalt
                        ? { required: true, message: "Required" }
                        : index === 1 && !disabledmica
                        ? { required: true, message: "Required" }
                        : {},
                    ]}
                    dependencies={[
                      `Does any cobalt or natural mica remain in the product(s)?-${item.name}-${item.state}-state`,
                    ]}
                  >
                    <Radio.Group
                      disabled={
                        ([
                          "No",
                          "Unknown",
                          "Not applicable for this declaration",
                        ].includes(
                          form.getFieldValue(
                            Object.keys(form.getFieldsValue()).find(
                              (key) =>
                                key.includes(
                                  "Is any of the cobalt or natural mica intentionally added or used in the product(s) or in the production process?-Cobalt"
                                ) && key.endsWith("-state")
                            )
                          )
                        ) &&
                          index === 0) ||
                        ([
                          "No",
                          "Unknown",
                          "Not applicable for this declaration",
                        ].includes(
                          form.getFieldValue(
                            Object.keys(form.getFieldsValue()).find(
                              (key) =>
                                key.includes(
                                  "Is any of the cobalt or natural mica intentionally added or used in the product(s) or in the production process?-Mica"
                                ) && key.endsWith("-state")
                            )
                          )
                        ) &&
                          index === 1)
                      }
                      onChange={() =>
                        handleFieldChange(
                          `Does any cobalt or natural mica remain in the product(s)?-${item.name}-${item.state}-state`,
                          item.name
                        )
                      }
                    >
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                      <Radio value="Unknown">Unknown</Radio>
                      <Radio value="Not applicable for this declaration">
                        Not applicable for this declaration
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ textAlign: "center" }}>
                  <Form.Item
                    name={`Does any cobalt or natural mica remain in the product(s)?-${item.name}-${item.comments}-comments`}
                    initialValue={item.comments}
                  >
                    <Input placeholder="Comments" />
                  </Form.Item>
                </Col>
              </Row>
            ))}
        </Card>
      ),
    },
    {
      label: (
        <>
          DECLARATION SCOPE
          {getLocalStorage("extendedMinerals") && (
            <span style={{ marginLeft: "8px" }}>
              {declarationScopeIcon ? (
                <ExclamationCircleFilled
                  style={{ color: "#F5222D", fontSize: "16px" }}
                />
              ) : (
                <CheckCircleFilled
                  style={{ color: "#237804", fontSize: "16px" }}
                />
              )}
            </span>
          )}
        </>
      ),
      key: "2",
      disabled: currentStep < 2,
      forceRender: true,
      children: (
        <Card
          title="Declaration Scope"
          style={{ overflow: "auto", height: "64vh" }}
          actions={actionCards}
        >
          <Card.Meta description="Answer the following questions based on the selected declaration scope" />
          <br></br>
          <b>
            3. Do any of the smelters or processors in your supply chain source
            the cobalt or natural mica from conflict-affected and high-risk
            areas? (OECD Due Diligence Guidance, see definitions tab) *
          </b>

          {EMRTformGenerate?.declarationScope
            .filter(
              (item) =>
                item.question ===
                "Do any of the smelters or processors in your supply chain source the cobalt or natural mica from conflict-affected and high-risk areas? (OECD Due Diligence Guidance, see definitions tab)"
            )
            .map((item, index) => (
              <Row
                key={`Do any of the smelters or processors in your supply chain source the cobalt or natural mica from conflict-affected and high-risk areas? (OECD Due Diligence Guidance, see definitions tab)-${item.name}`}
                style={{ display: "flex", marginBottom: 8 }}
              >
                <Col span={4}>
                  <Form.Item>{item.name}</Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={`Do any of the smelters or processors in your supply chain source the cobalt or natural mica from conflict-affected and high-risk areas? (OECD Due Diligence Guidance, see definitions tab)-${item.name}-${item.state}-state`}
                    initialValue={item.state}
                    rules={[
                      index === 0 && !disabledcobalt
                        ? { required: true, message: "Required" }
                        : index === 1 && !disabledmica
                        ? { required: true, message: "Required" }
                        : {},
                    ]}
                  >
                    <Radio.Group
                      disabled={
                        (index === 0 && disabledcobalt === true) ||
                        (index === 1 && disabledmica === true)
                      }
                    >
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                      <Radio value="Unknown">Unknown</Radio>
                      {index === 0 && <Radio value="DRC only">DRC only</Radio>}
                      {index === 1 && (
                        <Radio value="India and/or Madagascar only">
                          India and/or Madagascar only
                        </Radio>
                      )}
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ textAlign: "center" }}>
                  <Form.Item
                    name={`Do any of the smelters or processors in your supply chain source the cobalt or natural mica from conflict-affected and high-risk areas? (OECD Due Diligence Guidance, see definitions tab)-${item.name}-${item.comments}-comments`}
                    initialValue={item.comments}
                  >
                    <Input placeholder="Comments" />
                  </Form.Item>
                </Col>
              </Row>
            ))}
          <b>
            4. Does 100 percent of the cobalt originate from recycled or scrap
            sources? *
          </b>

          {EMRTformGenerate?.declarationScope
            .filter(
              (item) =>
                item.question ===
                "Does 100 percent of the cobalt originate from recycled or scrap sources?"
            )
            .map((item, index) => (
              <Row
                key={`Does 100 percent of the cobalt originate from recycled or scrap sources?-${item.name}`}
                style={{ display: "flex", marginBottom: 8 }}
              >
                <Col span={4}>
                  <Form.Item>{item.name}</Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={`Does 100 percent of the cobalt originate from recycled or scrap sources?-${item.name}-${item.state}-state`}
                    initialValue={item.state}
                    rules={[
                      index === 0 && !disabledcobalt
                        ? { required: true, message: "Required" }
                        : index === 1 && !disabledmica
                        ? { required: true, message: "Required" }
                        : {},
                    ]}
                  >
                    <Radio.Group
                      disabled={
                        (index === 0 && disabledcobalt === true) ||
                        (index === 1 && disabledmica === true)
                      }
                    >
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                      <Radio value="Unknown">Unknown</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ textAlign: "center" }}>
                  <Form.Item
                    name={`Does 100 percent of the cobalt originate from recycled or scrap sources?-${item.name}-${item.comments}-comments`}
                    initialValue={item.comments}
                  >
                    <Input placeholder="Comments" />
                  </Form.Item>
                </Col>
              </Row>
            ))}
          <b>
            5. What percentage of relevant suppliers have provided a response to
            your supply chain survey? *
          </b>

          {EMRTformGenerate?.declarationScope
            .filter(
              (item) =>
                item.question ===
                "What percentage of relevant suppliers have provided a response to your supply chain survey?"
            )
            .map((item, index) => (
              <Row
                key={`What percentage of relevant suppliers have provided a response to your supply chain survey?-${item.name}`}
                style={{ display: "flex", marginBottom: 8 }}
              >
                <Col span={4}>
                  <Form.Item>{item.name}</Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={`What percentage of relevant suppliers have provided a response to your supply chain survey?-${item.name}-${item.state}-state`}
                    initialValue={item.state}
                    rules={[
                      index === 0
                        ? { required: !disabledcobalt, message: "Required" }
                        : index === 1
                        ? { required: !disabledmica, message: "Required" }
                        : {},
                    ]}
                  >
                    <Select
                      disabled={
                        (index === 0 && disabledcobalt === true) ||
                        (index === 1 && disabledmica === true)
                      }
                      options={[
                        { label: "100%", value: 1 },
                        {
                          label: "Greater than 90%",
                          value: "Greater than 90%",
                        },
                        {
                          label: "Greater than 75%",
                          value: "Greater than 75%",
                        },
                        {
                          label: "Greater than 50%",
                          value: "Greater than 50%",
                        },
                        { label: "50% or less", value: "50% or less" },
                        { label: "None", value: "None" },
                      ]}
                      style={{ width: 250 }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8} style={{ textAlign: "center" }}>
                  <Form.Item
                    name={`What percentage of relevant suppliers have provided a response to your supply chain survey?-${item.name}-${item.comments}-comments`}
                    initialValue={item.comments}
                  >
                    <Input placeholder="Comments" />
                  </Form.Item>
                </Col>
              </Row>
            ))}
          <b>
            6. Have you identified all of the smelters or processors supplying
            the cobalt or natural mica to your supply chain? *
          </b>

          {EMRTformGenerate?.declarationScope
            .filter(
              (item) =>
                item.question ===
                "Have you identified all of the smelters or processors supplying the cobalt or natural mica to your supply chain?"
            )
            .map((item, index) => (
              <Row
                key={`Have you identified all of the smelters or processors supplying the cobalt or natural mica to your supply chain?-${item.name}`}
                style={{ display: "flex", marginBottom: 8 }}
              >
                <Col span={4}>
                  <Form.Item>{item.name}</Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={`Have you identified all of the smelters or processors supplying the cobalt or natural mica to your supply chain?-${item.name}-${item.state}-state`}
                    initialValue={item.state}
                    rules={[
                      index === 0 && !disabledcobalt
                        ? { required: true, message: "Required" }
                        : index === 1 && !disabledmica
                        ? { required: true, message: "Required" }
                        : {},
                    ]}
                  >
                    <Radio.Group
                      disabled={
                        (index === 0 && disabledcobalt === true) ||
                        (index === 1 && disabledmica === true)
                      }
                    >
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                      <Radio value="Unknown">Unknown</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ textAlign: "center" }}>
                  <Form.Item
                    name={`Have you identified all of the smelters or processors supplying the cobalt or natural mica to your supply chain?-${item.name}-${item.comments}-comments`}
                    initialValue={item.comments}
                  >
                    <Input placeholder="Comments" />
                  </Form.Item>
                </Col>
              </Row>
            ))}
          <b>
            7. Has all applicable smelter or processor information received by
            your company been reported in this declaration? *
          </b>

          {EMRTformGenerate?.declarationScope
            .filter(
              (item) =>
                item.question ===
                "Has all applicable smelter or processor information received by your company been reported in this declaration?"
            )
            .map((item, index) => (
              <Row
                key={`Has all applicable smelter or processor information received by your company been reported in this declaration?-${item.name}`}
                style={{ display: "flex", marginBottom: 8 }}
              >
                <Col span={4}>
                  <Form.Item>{item.name}</Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={`Has all applicable smelter or processor information received by your company been reported in this declaration?-${item.name}-${item.state}-state`}
                    initialValue={item.state}
                    rules={[
                      index === 0 && !disabledcobalt
                        ? { required: true, message: "Required" }
                        : index === 1 && !disabledmica
                        ? { required: true, message: "Required" }
                        : {},
                    ]}
                  >
                    <Radio.Group
                      disabled={
                        (index === 0 && disabledcobalt === true) ||
                        (index === 1 && disabledmica === true)
                      }
                    >
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                      <Radio value="Unknown">Unknown</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ textAlign: "center" }}>
                  <Form.Item
                    name={`Has all applicable smelter or processor information received by your company been reported in this declaration?-${item.name}-${item.comments}-comments`}
                    initialValue={item.comments}
                  >
                    <Input placeholder="Comments" />
                  </Form.Item>
                </Col>
              </Row>
            ))}
        </Card>
      ),
    },
    {
      label: (
        <>
          POLICY
          {getLocalStorage("extendedMinerals") && (
            <span style={{ marginLeft: "8px" }}>
              {policyIcon ? (
                <WarningFilled style={{ color: "orange", fontSize: "16px" }} />
              ) : (
                <CheckCircleFilled
                  style={{ color: "#237804", fontSize: "16px" }}
                />
              )}
            </span>
          )}
        </>
      ),
      key: "3",
      disabled: currentStep < 3,
      forceRender: true,
      children: (
        <Card
          title="Policy"
          style={{ overflow: "auto", height: "64vh" }}
          actions={actionCards}
        >
          <Card.Meta description="Answer the Following Questions at a Company Level" />
          <br></br>
          {EMRTformGenerate?.policy.map((item, index) => (
            <div key={`${item.question}-${index}`}>
              <b>{item.question}</b>
              {index === 2 && (
                <b>
                  <br></br>Cobalt:<br></br>
                </b>
              )}
              {index === 3 && (
                <b>
                  <br></br>Mica:<br></br>
                </b>
              )}
              <Row style={{ display: "flex", marginBottom: 8 }}>
                <Col span={8}>
                  <Form.Item
                    name={`${item.question}-${item.name}-${item.state}-${index}-state`}
                    initialValue={item.state}
                    rules={[
                      {
                        required: !(disabledcobalt && disabledmica),
                        message: "Required",
                      },
                    ]}
                  >
                    <Radio.Group>
                      {index === 3 && (
                        <Radio value="Yes, when more processors are validated">
                          Yes, when more processors are validated
                        </Radio>
                      )}
                      <br></br>
                      {index === 5 && (
                        <Radio value="Yes, in conformance with ipc1755 (e.g. emrt)">
                          Yes, in conformance with IPC1755 (e.g. EMRT)
                        </Radio>
                      )}
                      <br></br>
                      {index === 5 && (
                        <Radio value="Yes, using other format (describe)">
                          Yes, Using Other Format (Describe)
                        </Radio>
                      )}
                      <br></br>
                      {index !== 5 && <Radio value="Yes">Yes</Radio>}
                      <br></br>
                      <Radio value="No">No</Radio>
                      <br></br>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    initialValue={item.comments}
                    name={`${item.question}-${item.name}-${item.comments}-${index}-comments`}
                  >
                    <Input placeholder="Comments" />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          ))}
        </Card>
      ),
    },
    {
      label: (
        <>
          SMELTER LIST
          {getLocalStorage("extendedMinerals") && (
            <span style={{ marginLeft: "8px" }}>
              {smelterIcon ? (
                <WarningFilled style={{ color: "orange", fontSize: "16px" }} />
              ) : (
                <CheckCircleFilled
                  style={{ color: "#237804", fontSize: "16px" }}
                />
              )}
            </span>
          )}
        </>
      ),
      key: "4",
      disabled: currentStep < 4,
      forceRender: true,
      children: (
        <Card
          title="Smelter List"
          style={{ height: "64vh" }}
          actions={actionCards}
          extra={[
            <Button
              key="1"
              type="primary"
              onClick={openSmelterModal}
              data-testid="add-smelter-button"
              title="Add Smelter"
            >
              <PlusOutlined />
              CREATE
            </Button>,
            <AddSmelter
              key="2"
              onAdd={handleAddToTable}
              open={modalVisible}
              onClose={handleModalClose}
              smelterList={
                getLocalStorage("extendedMinerals")
                  ? EMRTformGenerate?.smelter
                  : smelterData
              }
              type={"ExtendedMinerals"}
            />,
          ]}
        >
          {smelterMetalsAdd.length > 0 && (
            <>
              <Alert
                message={
                  "Please add missing smelters for " +
                  smelterMetalsAdd.join(", ") +
                  " in the template and reimport the EMRT. Or update answer in Declaration 'Is any of the cobalt or natural mica intentionally added or used in the product(s) or in the production process?'"
                }
                type="warning"
                showIcon
              />
              <br></br>
            </>
          )}
          {smelterMetalsRemove.length > 0 && (
            <>
              <Alert
                message={
                  "Please remove additional smelters for " +
                  smelterMetalsRemove.join(", ") +
                  " in the template and reimport the EMRT. Or update answer in Declaration 'Is any of the cobalt or natural mica intentionally added or used in the product(s) or in the production process?'"
                }
                type="warning"
                showIcon
              />
              <br></br>
            </>
          )}
          <Card.Meta description="Selected Smelters" />
          <br></br>
          <Table
            dataSource={
              getLocalStorage("extendedMinerals")
                ? EMRTformGenerate?.smelter
                : smelterData
            }
            columns={columns}
            rowKey="_id"
            scroll={{ y: 200 }}
            pagination={{
              ...tableParams.pagination,
              total: getLocalStorage("extendedMinerals")
                ? EMRTformGenerate?.smelter?.length
                : smelterCount,
              showTotal: (total: number, range: [number, number]) =>
                `${range[0]}-${range[1]} of ${total} items`,
              showSizeChanger: true,
            }}
            onChange={handleTableChange}
          />
        </Card>
      ),
    },
    {
      label: "EMRT",
      key: "6",
      disabled: currentStep < 6,
      forceRender: true,
      children: (
        <Card
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "78vh",
          }}
        >
          {fieldvalidation ? (
            <Card style={{ width: "70vh", textAlign: "center" }}>
              <Result
                status="warning"
                title="There are some problems with your imported data."
                extra={[
                  <Form.Item key="1">
                    <Button onClick={() => fixError()} type="primary">
                      Fix Errors
                    </Button>
                  </Form.Item>,
                ]}
              />
            </Card>
          ) : (
            <Card style={{ width: "70vh", textAlign: "center" }}>
              <Result
                status="success"
                title={`You have successfully ${
                  disabledtab ? "consolidated" : "imported"
                } the EMRT`}
                extra={[
                  <Form.Item key="1">
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={() => form.submit}
                      loading={loading}
                      data-testid="submit-emrt-button"
                      title="Submit EMRT"
                    >
                      Save & Close
                    </Button>
                  </Form.Item>,
                ]}
              />
            </Card>
          )}
        </Card>
      ),
    },
  ];

  const productList = {
    label: (
      <>
        PRODUCT LIST
        {getLocalStorage("extendedMinerals") && (
          <span style={{ marginLeft: "8px" }}>
            {productIcon ? (
              <WarningFilled style={{ color: "orange", fontSize: "16px" }} />
            ) : (
              <CheckCircleFilled
                style={{ color: "#237804", fontSize: "16px" }}
              />
            )}
          </span>
        )}
      </>
    ),
    key: "5",
    disabled: currentStep < 5,
    forceRender: true,
    children: (
      <Card
        title="Product List"
        style={{ height: "64vh" }}
        actions={actionCards}
      >
        <Card.Meta description="Selected Products" />
        <br></br>
        <Table
          dataSource={EMRTformGenerate?.products}
          columns={columns1}
          rowKey="manufacturer.itemNumber"
          scroll={{ y: 200 }}
          pagination={{
            total: EMRTformGenerate?.products?.length,
            showTotal: (total: number, range: [number, number]) =>
              `${range[0]}-${range[1]} of ${total} items`,
            showSizeChanger: true,
          }}
        />
      </Card>
    ),
  };

  const stepsItems = [
    {
      title: "",
    },
    {
      title: "",
    },
    {
      title: "",
    },
    {
      title: "",
    },
    {
      title: "",
    },
    {
      title: "",
    },
  ];

  const productStepItem = {
    title: "",
  };

  if (selectedOption === "B. Product (or List of Products)") {
    items.splice(5, 0, productList);
    stepsItems.splice(5, 0, productStepItem);
  }
  return (
    <ErrorBoundary>
      <Card>
        <Steps
          size="small"
          style={{ maxWidth: 500, margin: "auto", marginBottom: "2rem" }}
          items={stepsItems}
          current={currentStep}
        />
        <Form form={form} onFinish={onFinish}>
          <Tabs
            defaultActiveKey={"0"}
            activeKey={String(currentStep)}
            onChange={handleChangeTab}
            items={items}
            tabPosition="top"
            className="card-tabs"
          ></Tabs>
        </Form>
      </Card>
    </ErrorBoundary>
  );
};

export default ExtendedGenerator;
