const regulations = {
  eu_rohs: {
    name: "eu_rohs",
    description: `
    <Typography.Paragraph
      ellipsis={{
        rows: 2,
        expandable: expanded,
        symbol: "more",
      }}
    >
      RoHS, also known as Directive 2002/95/EC, originated in
      the European Union and restricts the use of specific
      hazardous materials found in electrical and electronic
      products (known as EEE). All applicable products in the EU
      market after July 1, 2006 must pass RoHS compliance.
      <br></br>
      Any business that sells or distributes applicable EEE
      products, sub-assemblies, components, or cables directly
      to EU countries, or sells to resellers, distributors or
      integrators that in turn sell products to EU countries, is
      impacted if they utilize any of the restricted materials.
      Since RoHS-like regulations have spread to a number of
      other countries, this just doesn't apply to EU countries
      anymore.
      <br></br>
      The EU RoHS Directive 2011/65/EU and EU 2015/863 restricts
      ten substances and substance groups above a specific
      threshold at the homogeneous material level. It also
      allows certain exemptions for the use of the restricted
      substances in particular components and/or specific
      product applications. These specific exemptions are
      documented under Annex III and Annex IV of the 2011/65/EU
      Directive. Suppliers cannot assume that the exemptions
      automatically apply to their components, parts, materials,
      or products and are encouraged to request approval for use
      of any required EU RoHS exemptions via their Supply Chain
      representative. Improperly applying an exemption could be
      termed as a non-compliance leading to enforcement action
      and/or your disqualification as a supplier.
      <br></br>
      <a
        href="https://environment.ec.europa.eu/topics/waste-and-recycling/rohs-directive_en"
        target="_blank"
      >
        EU RoHS Directive
      </a>
    </Typography.Paragraph>
    `,
    filename: "EU ROHS Declaration Template.docx",
    categoryId: "63c6728770cfa800125e3bf0",
  },
  eu_reach: {
    name: "eu_reach",
    description: `
    <Typography.Paragraph
      ellipsis={{
        rows: 2,
        expandable: expanded,
        symbol: "more",
      }}
    >
      Registration, Evaluation, Authorization and Restriction of 
      Chemicals or REACH was introduced by the EU in 2007 to 
      address the use of harmful chemical substances and their 
      dangerous impacts on humans and the ecosystem. REACH mandates 
      all manufacturers, suppliers and importers and exporters of 
      chemical substances within the EU market, producing up to 1 
      ton per year or more, to adhere to the regulation. The REACH 
      regulations are regularly refreshed, every once in six months.
      <br></br>
      According to the REACH requirements on Substances of Very High 
      Concern (SVHC) a supplier of manufactured and imported articles 
      shall provide customers with information on SVHC if they are 
      present above a concentration limit of 0.1 % on an article level. 
      SVHC will be defined through the list of candidate substances for 
      authorization that is produced by the European Chemicals Agency.
      <br></br>
      Companies or manufacturers failing to comply with REACH regulations 
      will be fined, denied market access or subject to legal measures.
      <br></br>
      <a 
        href="https://echa.europa.eu/guidance-documents/guidance-on-reach"
        target="_blank"
      >
        ECHA Guidance on REACH 
      </a>
      <br></br>
      <a
        href="https://echa.europa.eu/documents/10162/23036412/articles_en.pdf/cc2e3f93-8391-4944-88e4-efed5fb5112c"
        target="_blank"
      >
        ECHA Guidance on Substances in Articles
      </a>
      <br></br>
      <a
        href="https://echa.europa.eu/web/guest/candidate-list-table"
        target="_blank"
      >
        Candidate List of Substances of Very High Concern (SVHC)
      </a>
    </Typography.Paragraph>
    `,
    filename: "EU REACH Declaration Template.docx",
    categoryId: "63c6729870cfa800125e3bf1",
  },
  ca_prop_65: {
    name: "ca_prop_65",
    description: `
    <Typography.Paragraph
      ellipsis={{
        rows: 2,
        expandable: expanded,
        symbol: "more",
      }}
    >
      Proposition 65 requires businesses to provide warnings to 
      Californians about significant exposures to chemicals that 
      cause cancer, birth defects or other reproductive harm. 
      These chemicals can be in the products that Californians 
      purchase, in their homes or workplaces, or that are released 
      into the environment. By requiring that this information be 
      provided, Proposition 65 enables Californians to make 
      informed decisions about their exposures to these chemicals.
      <br></br>
      Proposition 65 also prohibits California businesses from 
      knowingly discharging significant amounts of listed chemicals 
      into sources of drinking water.
      <br></br>
      <a
        href="https://oehha.ca.gov/proposition-65/about-proposition-65#:~:text=Proposition%2065%20requires%20businesses%20to,are%20released%20into%20the%20environment."
        target="_blank"
      >
        About Proposition 65
      </a>
      <br></br>
      <a
        href="https://oehha.ca.gov/proposition-65/proposition-65-list"
        target="_blank"
      >
        List of Chemicals
      </a>
    </Typography.Paragraph>
    `,
    filename: "CA PROP 65 Declaration Template.docx",
    categoryId: "63c6735d70cfa800125e3bf2",
  },
  tsca_pbt: {
    name: "tsca_pbt",
    description: `
    <Typography.Paragraph
      ellipsis={{
        rows: 2,
        expandable: expanded,
        symbol: "more",
      }}
    >
      TSCA also known as Toxic Substances Control Act was reformed 
      by the US federal government in 2016 by passing the Frank R. 
      Lautenberg Chemical Safety for the 21st Century Act. Significant 
      changes cover the assessment of chemicals for better protection 
      of the public and the environment.
      <br></br>
      As a result, the Environmental Protection Agency (EPA) has 
      targeted some persistent, bio accumulative, and toxic (PBT) 
      substances in articles, per Section 6(h) of TSCA. These amendments 
      impact industries worldwide. To comply with the American laws, the 
      manufacturers and importer will have to make sure that their supply 
      chain is aware of these obligations and meets the TSCA requirements.
      <br></br>
      <a
        href="https://www.epa.gov/assessing-and-managing-chemicals-under-tsca/persistent-bioaccumulative-and-toxic-pbt-chemicals#risk"
        target="_blank"
      >
        Chemicals Under TSCA section 6(h)
      </a>
    </Typography.Paragraph>
    `,
    filename: "TSCA PBT Declaration Template.docx",
    categoryId: "63c673d270cfa800125e3bf5",
  },
  eu_pops: {
    name: "eu_pops",
    description: `
    <Typography.Paragraph
      ellipsis={{
        rows: 2,
        expandable: expanded,
        symbol: "more",
      }}
    >
      Persistent organic pollutants (POPs) are organic substances that 
      persist in the environment, accumulate in living organisms and 
      pose a risk to our health and the environment. They can be 
      transported by air, water or migratory species across international 
      borders, reaching regions where they have never been produced or used.
      <br></br>
      POPs are regulated worldwide by the Stockholm Convention and by the 
      Aarhus Protocol. These legislations are implemented in the European 
      Union by the POPs Regulation (EU) No 2019/1021.
      <br></br>
      The member countries decide for themselves the penalties for breach of 
      the POPs Regulation.
      <br></br>
      <a
        href="https://echa.europa.eu/list-of-substances-subject-to-pops-regulation"
        target="_blank"
      >
        List of substances subject to POP’s Regulation
      </a>
      <br></br>
      <a
        href="https://echa.europa.eu/list-of-substances-proposed-as-pops"
        target="_blank"
      >
        List of substances proposed as POP’s
      </a>
    </Typography.Paragraph>
    `,
    filename: "EU POPs Declaration Template.docx",
    categoryId: "63c6744170cfa800125e3bf9",
  },
  "conflict minerals": {
    name: "conflict minerals",
    description: `
    <Typography.Paragraph
      ellipsis={{
        rows: 2,
        expandable: expanded,
        symbol: "more",
      }}
    >
      Under section 1502 of the Dodd Frank Act, the rules of the US Securities 
      and Exchange Commission (SEC) provide that companies reporting to SEC 
      under the 1934 Securities Exchange Act determine annually whether they 
      use ‘Conflict Minerals’ in their manufacturing process.
      <br></br>
      Conflict Minerals are defined as Gold, Tin, Tungsten and Tantalum (3TG) 
      originating in the Democratic Republic of Congo (DRC) and its 
      neighbouring countries where warfare is funded by sale of these minerals. 
      Companies must disclose by filling a Form SD and a Conflict Minerals 
      Report (CMR) the source of the minerals if they come from DRC or 
      surrounding countries and they must determine whether the 3TG they use is 
      ‘DRC conflict free’, ‘DRC conflict undeterminable’, or ‘Not found to be 
      DRC conflict free’, as determined by the SEC’s final rules on conflict 
      minerals of 22 August 2012.
      <br></br>
      <a
        href="https://www.responsiblemineralsinitiative.org/reporting-templates/cmrt/?"
        target="_blank"
      >
        Conflict Minerals Reporting Template
      </a>
      <br></br>
      The EU Conflict Minerals Regulation is a law that came into full force 
      across the EU on 1st January 20211. The regulation aims to help stem the 
      trade in 3TG. The regulation requires EU companies in the supply chain to 
      ensure they import these minerals and metals from responsible and conflict-free 
      sources only. The European Commission has published an indicative, 
      non-exhaustive list of CAHRAs (Conflict-Affected and High-Risk Areas) under 
      Regulation (EU) 2017/8212. This list is intended to help companies better 
      understand the definition of conflict-affected and high-risk areas and to help 
      them identify such areas and other risks in their mineral supply chains.
      <br></br>
      <a
        href="https://www.cahraslist.net/cahras"
        target="_blank"
      >
        List of CAHRAs
      </a>
    </Typography.Paragraph>
    `,
    filename: "RMI_CMRT_6.31.xlsx",
    categoryId: "63c6737570cfa800125e3bf3",
  },
  "extended minerals": {
    name: "extended minerals",
    description: `
    <Typography.Paragraph
      ellipsis={{
        rows: 2,
        expandable: expanded,
        symbol: "more",
      }}
    >
      Extended Minerals refer to specific minerals, such as Cobalt and Mica, 
      that are commonly used in various industries, including electronics, 
      automotive, and cosmetics. The EMRT is a standardized reporting template 
      designed to disclose information on multiple minerals.
      <br></br>
      It replaces and combines the previously-existing Cobalt Reporting Template 
      (CRT) and Mica Reporting Template (MRT) into a unified format. Over time 
      it is expected to expand and include additional minerals as well. Although 
      regulations may not explicitly mandate the disclosure of minerals beyond 
      the "3TGs" (tin, tantalum, tungsten, and gold), there is a growing 
      expectation for voluntary declaration and due diligence across many different 
      industries. The EMRT provides a structured framework for companies to fulfil 
      customer & Investor expectations and showcase their commitment to responsible 
      sourcing practices.
      <br></br>
      <a
        href="https://www.responsiblemineralsinitiative.org/reporting-templates/emrt/"
        target="_blank"
      >
        Extended Minerals Reporting Template
      </a>
    </Typography.Paragraph>
    `,
    filename: "RMI_EMRT_1.2.xlsx",
    categoryId: "63c6728770cfa800125e3bf0",
  },
  "material disclosure": {
    name: "material disclosure",
    description: `
    <Typography.Paragraph
      ellipsis={{
        rows: 2,
        expandable: expanded,
        symbol: "more",
      }}
    >
      A full material disclosure (FMD) is a comprehensive list of all the ingredients 
      and substances within a particular product. It does allow for proprietary 
      protections in some cases, as long as hazard information about the chemical 
      can be shared. 
      <br></br>
      As companies continue to face new and constantly changing regulations and 
      diverse customer requests, many are turning to FMD to be proactive in 
      approaching regulatory needs and risk reduction in their supply chain. The 
      substances restricted by REACH and California Prop 65 are constantly changing, 
      furthering the need to have measures in place to effectively manage your supply 
      chain. FMD in a Class D IPC-1752A XML is preferred as it declares every 
      substance in the product down to the Homogeneous Level, including location, 
      mass, and/or PPM.
    </Typography.Paragraph>
    `,
    filename: "Materials Template.csv",
    categoryId: "63c6739970cfa800125e3bf4",
  },
  eu_batteries: {
    name: "eu_batteries",
    description: `
    <Typography.Paragraph
      ellipsis={{
        rows: 2,
        expandable: expanded,
        symbol: "more",
      }}
    >
      The European Union Battery Directive, officially known as Directive 2006/66/EC, 
      is a regulatory framework that sets requirements for the safe production, 
      usage, and disposal of batteries and accumulators in the European Union. It 
      was adopted in September 2006 and has been implemented by member states to 
      regulate the life cycle of batteries, including their manufacturing, placing 
      on the market, and end-of-life management.
      <br></br>
      The EU Battery Directive restricts the use of certain hazardous substances in 
      batteries, including mercury, cadmium, and lead. The primary goal of the EU 
      Battery Directive is to minimize the negative environmental and health impacts 
      associated with the production, use, and disposal of batteries. It aims to 
      promote the efficient use of resources, reduce hazardous substances, and 
      increase the recycling and proper treatment of batteries to minimize their 
      potential harm to the environment and human health.
      <br></br>
      <a 
        href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A02006L0066-20180704"
        target="_blank"
      >
        Batteries Directive 
      </a>
      <br></br>
      <a 
        href="https://echa.europa.eu/en/batteries-accumulators-waste?p_p_id=eucleflegislationlist_WAR_euclefportlet&p_p_lifecycle=0"
        target="_blank"
      >
        Batteries Directive - Prohibited Substances & Labelling 
      </a>
    </Typography.Paragraph>
    `,
    filename: "EU Batteries Declaration Template.docx",
    categoryId: " ",
  },
  eu_packaging: {
    name: "eu_packaging",
    description: `
    <Typography.Paragraph
      ellipsis={{
        rows: 2,
        expandable: expanded,
        symbol: "more",
      }}
    >
      The European Union Packaging Directive, officially known as 
      Directive 94/62/EC, is a legislative framework established 
      by the European Union to regulate the management and 
      recycling of packaging and packaging waste. Adopted in 
      December 1994 and as amended by Directive 2018/852/EU, 14 
      June 2018 the directive aims to reduce the environmental 
      impact of packaging materials, promote sustainable resource 
      management, and encourage the use of more environmentally 
      friendly packaging practices within the EU member states.
      <br></br>
      The EU Packaging Directive limits the sum of the concentrations 
      of lead (Pb), cadmium (Cd), mercury (Hg), and hexavalent chromium 
      (Cr(VI)) in packaging or packaging components to a maximum of 100 
      ppm by weight. These substances are harmful to human health and 
      the environment, and their use in packaging can lead to pollution 
      and contamination.
      <br></br>
      <a 
        href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A31994L0062"
        target="_blank"
      >
        Packaging Directive 
      </a>
      <br></br>
      <a 
        href="https://echa.europa.eu/en/heavy-metals-packaging-waste?p_p_id=eucleflegislationlist_WAR_euclefportlet&p_p_lifecycle=0"
        target="_blank"
      >
        Heavy Metals in EU Packaging and Packaging Waste (Article 11) 
      </a>
    </Typography.Paragraph>
    `,
    filename: "EU Packaging Declaration Template.docx",
    categoryId: " ",
  },
  eu_mdr: {
    name: "eu_mdr",
    description: `
    <Typography.Paragraph
      ellipsis={{
        rows: 2,
        expandable: expanded,
        symbol: "more",
      }}
    >
      The European Medical Device Regulation (MDR) is a set of regulations 
      that governs the production and distribution of medical devices in 
      Europe, and compliance with the regulation is mandatory for medical 
      device companies that want to sell their products in the European 
      marketplace. The EU MDR, which came into effect on May 26, 2021, 
      replaced the previous Medical Device Directive (MDD) and 
      significantly strengthened the regulatory framework for medical 
      devices in the EU.
      <br></br>
      Section of 10.4. 1 of the EU Medical Device Regulation (EU MDR) 
      requires the identification of carcinogens, mutagens, and 
      reproductive toxins (CMRs) and endocrine-disrupting chemicals 
      (EDCs) over 0.1% w/w in invasive, or fluid or gas contacting 
      materials. The list of EU MDR regulated substances can be found 
      in Chapter II Section 10.4 of 
      <a 
        href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32017R0745#d1e32-94-1"
        target="_blank"
      >
        Annex I of EU MDR. 
      </a>
      <br></br>
      There are three component lists mentioned in this section:
      <br></br>
      <ol>
        <li>
          Carcinogenic, Mutagenic, or Toxic to Reproduction (CMR) Substances: These are substances categorized as 1A or 1B, according to Part 3 of 
          <a 
            href="https://echa.europa.eu/information-on-chemicals/annex-vi-to-clp"
            target="_blank"
          >
            Annex VI of the CLP Regulation 
          </a>  
          (Regulation No. 1272/2008). The CLP Regulation provides a comprehensive list of chemicals, and the category codes help identify substances known for their carcinogenic, mutagenic, or toxic effects on reproduction.
        </li>
        <li>
          Endocrine-Disrupting Substances: These are substances with endocrine-disrupting properties identified through the procedure outlined in Article 59 of the REACH Regulation (Regulation No. 1907/2006). 
          <a 
            href="https://echa.europa.eu/candidate-list-table"
            target="_blank"
          >
            The REACH SVHC Candidate List 
          </a>  
          specifies substances known to have endocrine-disrupting properties, as indicated in the "Reason for inclusion" field of the List.
        </li>
        <li>
          Endocrine-Disrupting Substances under 
          <a 
            href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=uriserv%3AOJ.L_.2012.167.01.0001.01.ENG"
            target="_blank"
          >
              Biocidal Properties Regulation (BPR)  
          </a>
          : These are substances with endocrine-disrupting properties mentioned in 
            <a 
            href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=uriserv%3AOJ.L_.2012.167.01.0001.01.ENG&toc=OJ%3AL%3A2012%3A167%3ATOC#d1e1733-1-1"
            target="_blank"
          >
              Article 5(3) of the EU BPR  
          </a>  
          (Regulation No. 528/2012). The Endocrine Disruptor Assessment List, maintained by the European Chemicals Agency (ECHA), provides the assessment status for substances that are potential endocrine disruptors according to the EU BPR or the REACH Regulation.<br>
          Furthermore, the EU MDR requires manufacturers of implantable devices to perform full material disclosure (FMD) reporting for those products. This means that manufacturers need to provide comprehensive information about the materials used in implantable devices.
        </li><br>
      </ol>
    </Typography.Paragraph>
    `,
    filename: "EU MDR Declaration Template.docx",
    categoryId: "63c6746270cfa800125e3bfb",
  },
  pfas: {
    name: "pfas",
    description: `
    <Typography.Paragraph
      ellipsis={{
        rows: 2,
        expandable: expanded,
        symbol: "more",
      }}
    >
      The Toxic Substances Control Act (TSCA) has long been a critical 
      piece of legislation aimed at ensuring the safety of chemicals 
      used in the United States.
      <br></br>
      In accordance with obligations under TSCA, as amended by the 
      National Defense Authorization Act for Fiscal Year 2020, EPA is 
      requiring any person that manufactures (including import) or has 
      manufactured (including imported) PFAS or PFAS-containing articles 
      in any year since January 1, 2011, to electronically report 
      information regarding PFAS uses, production volumes, disposal, 
      exposures, and hazards. In response to this concern, the 
      Environmental Protection Agency (EPA) has implemented new reporting 
      and recordkeeping requirements for PFAS under TSCA Section 8(a)(7).
      <br></br>
      <a
        href="https://www.epa.gov/assessing-and-managing-chemicals-under-tsca/tsca-section-8a7-reporting-and-recordkeeping"
        target="_blank"
      >
        TSCA Section 8(a)(7)
      </a>
      <br></br>
      <a
        href="https://www.epa.gov/system/files/documents/2023-11/tsca-8a7-reporting-instructions-10-11-23.pdf"
        target="_blank"
      >
        Instructions for Reporting PFAS Under TSCA Section 8(a)(7)
      </a>
    </Typography.Paragraph>
    `,
    filename: "TSCA 8(a)(7) PFAS Declaration Template.docx",
    categoryId: "63c6743370cfa800125e3bf8",
  },
  china_rohs: {
    name: "china_rohs",
    description: `
    <Typography.Paragraph
      ellipsis={{
        rows: 2,
        expandable: expanded,
        symbol: "more",
      }}
    >
      China RoHS (GB/T 26572-2011), officially known as the "Administrative
      Measure on the Control of Pollution Caused by Electronic Information 
      Products" (ACPEIP), was implemented on March 1, 2007. This regulation 
      was introduced to address concerns about the presence of hazardous 
      substances in electronic products and their impact on human health and
      the environment. Initially focusing on electronic information products 
      (EIPs), China RoHS has since expanded to include a broader range of 
      electrical and electronic products under China RoHS 2.
      <br></br>
      China RoHS controls the legal thresholds for several hazardous substances
      in electrical and electronic products, including lead (0.1%), cadmium (0.01%), 
      mercury (0.1%), hexavalent chromium (0.1%), polybrominated biphenyls (PBBs) (0.1%), 
      and polybrominated diphenyl ethers (PBDs) (0.1%). Compliance with these regulations 
      is mandatory for any electrical and electronic products sold in China. Suppliers, 
      manufacturers, importers, and distributors must ensure that their products meet 
      these limits and adhere to the Declaration and Labelling Requirements of China RoHS.
      <br></br>
      The regulation has been further extended to cover a total of ten restricted hazardous 
      substances. This includes the addition of four phthalates: bis(2-ethylhexyl) phthalate 
      (DEHP) (0.1%), butyl benzyl phthalate (BBP) (0.1%), dibutyl phthalate (DBP) (0.1%), 
      and diisobutyl phthalate (DIBP) (0.1%). These updates were published on June 29, 2024,
      through China National Standard Announcement No. 14, with an 18-month transitional period
      ending on January 1, 2026, to allow manufacturers to prepare for the new obligations.
      <br></br>
      By enforcing China RoHS, the Chinese government promotes the use of safer materials in 
      electronics manufacturing and encourages the industry to adopt environmentally friendly 
      practices, contributing to sustainable development and environmental protection.
      <br></br>
      <a
        href="https://www.acquiscompliance.com/blog/understanding-china-rohs-compliance/"
        target="_blank"
      >
        About China RoHS
      </a>
    </Typography.Paragraph>
    `,
    filename: "China ROHS Declaration Template.docx",
    categoryId: "63c6728770cfa800125e3bf0",
  },
  "eu_reach_xvii_(restricted)": {
    name: "eu_reach_xvii_(restricted)",
    description: `
    <Typography.Paragraph
      ellipsis={{
        rows: 2,
        expandable: expanded,
        symbol: "more",
      }}
    >
      Annex XVII of the REACH regulation contains a comprehensive list of 
      restrictions on the marketing and use of certain hazardous substances, 
      mixtures, and articles within the European market. These restrictions 
      aim to protect human health and the environment from the potential risks 
      posed by these chemicals.
      <br></br>
      A restriction can apply to any substance on its own, in a mixture, or in an 
      article, including those that do not require registration under REACH. The 
      substances listed in Annex XVII may face limitations or prohibitions on their 
      production, use, or sale within the EU.
      <br></br>
      This list is often referred to as the REACH Restricted Substances List or simply 
      as REACH Annex XVII. By enforcing these restrictions, REACH ensures that hazardous 
      chemicals are managed safely and responsibly, promoting a higher standard of health 
      and environmental protection across the EU.
      <br></br>
      <a
        href="https://echa.europa.eu/regulations/reach/restriction"
        target="_blank"
      >
        About REACH Annex XVII 
      </a>
      <br></br>
      <a
        href="https://echa.europa.eu/substances-restricted-under-reach"
        target="_blank"
      >
        List of Restricted Substances
      </a>
    </Typography.Paragraph>
    `,
    filename: "EU REACH XVII (Restricted) Declaration Template.docx",
    categoryId: "63c6729870cfa800125e3bf1",
  },
  "eu_reach_xiv_(authorisation)": {
    name: "eu_reach_xiv_(authorisation)",
    description: `
    <Typography.Paragraph
      ellipsis={{
        rows: 2,
        expandable: expanded,
        symbol: "more",
      }}
    >
      REACH Annex XIV, also known as the REACH Authorisation List, 
      contains substances that require authorization for use under 
      the EU REACH regulation. Substances on this list are selected 
      from the REACH SVHC (Substances of Very High Concern) list and 
      cannot be placed on the market or used after a specified "sunset date" 
      unless an authorization is granted for their specific use or the use 
      is exempted from authorization.
      <br></br>
      The goal of Annex XIV is to ensure that substances of very high concern 
      are properly controlled and gradually replaced by safer alternatives where 
      feasible. Companies must apply for authorization to continue using these 
      substances, demonstrating that the risks are adequately controlled or that 
      the socio-economic benefits outweigh the risks
      <br></br>
      By enforcing these authorizations, REACH promotes the safe use of hazardous 
      chemicals and encourages the transition to safer alternatives, thereby enhancing 
      human health and environmental protection across the EU.
      <br></br>
      <a
        href="https://echa.europa.eu/regulations/reach/authorisation/recommendation-for-inclusion-in-the-authorisation-list"
        target="_blank"
      >
        About REACH Annex XIV 
      </a>
      <br></br>
      <a
        href="https://echa.europa.eu/authorisation-list"
        target="_blank"
      >
        List of Authorised Substances
      </a>
    </Typography.Paragraph>
    `,
    filename: "EU REACH XIV (Authorisation) Declaration Template.docx",
    categoryId: "63c6729870cfa800125e3bf1",
  },
  uk_reach_svhc: {
    name: "uk_reach_svhc",
    description: `
    <Typography.Paragraph
      ellipsis={{
        rows: 2,
        expandable: expanded,
        symbol: "more",
      }}
    >
      UK REACH SVHC, or Substances of Very High Concern, refers to a list of 
      chemicals that have been identified as having serious effects on human 
      health or the environment. These substances are selected based on their 
      hazardous properties, such as being carcinogenic, mutagenic, toxic to 
      reproduction, persistent, bioaccumulative, and toxic, or having other 
      equally concerning properties.
      <br></br>
      The inclusion of a substance on the UK REACH SVHC list is the first step 
      towards its potential inclusion in Annex XIV (Authorisation List) or 
      Annex XVII (Restricted Substances) of UK REACH. Companies that manufacture, 
      import, or use these substances must provide information on safe use and, 
      in some cases, obtain authorization for specific uses.
      <br></br>
      The aim of the UK REACH SVHC list is to ensure that risks from hazardous 
      substances are properly managed and to encourage the replacement of these 
      substances with safer alternatives where possible. This enhances the protection 
      of human health and the environment in the UK.
      <br></br>
      <a
        href="https://www.hse.gov.uk/reach/svhc-overview.htm"
        target="_blank"
      >
        About UK REACH SVHC 
      </a>
      <br></br>
      <a
        href="https://www.hse.gov.uk/reach/candidate-list.htm"
        target="_blank"
      >
        List of SVHC Substances
      </a>
    </Typography.Paragraph>
    `,
    filename: "UK REACH SVHC Declaration Template.docx",
    categoryId: "63c6729870cfa800125e3bf1",
  },
  uk_rohs: {
    name: "uk_rohs",
    description: `
    <Typography.Paragraph
      ellipsis={{
        rows: 2,
        expandable: expanded,
        symbol: "more",
      }}
    >
      The United Kingdom's commitment to safe and sustainable technology is 
      evident through the Restriction of the Use of Certain Hazardous Substances 
      in Electrical and Electronic Equipment Regulations 2012, commonly referred 
      to as the UK RoHS Regulation. Based on the RoHS Directive of the European Union, 
      these regulations govern the utilization of hazardous substances in electrical 
      and electronic equipment (EEE). Since their implementation, they have played a 
      pivotal role in safeguarding both human well-being and environmental integrity.
      <br></br>
      As of January 1, 2021, the UK RoHS Regulations have been implemented in both Great 
      Britain (England, Scotland, and Wales) and Northern Ireland, with certain provisions 
      applying differently between the two regions. All applicable products in the UK market 
      must comply with UK RoHS regulations.
      <br></br>
      Any business that sells or distributes applicable EEE products, sub-assemblies, components, 
      or cables directly to the UK, or sells to resellers, distributors, or integrators that in turn 
      sell products to the UK, is impacted if they utilize any of the restricted materials. Since 
      RoHS-like regulations have spread to a number of other countries, this doesn't just apply to 
      the UK anymore.
      <br></br>
      The UK RoHS regulations restrict ten substances and substance groups above a specific threshold 
      at the homogeneous material level. These substances include lead, mercury, cadmium, hexavalent 
      chromium, polybrominated biphenyls (PBBs), polybrominated diphenyl ethers (PBDEs), and four specific 
      phthalates (DEHP, BBP, DBP, and DIBP). Certain exemptions for the use of these restricted substances 
      in specific components and/or product applications are allowed and are documented in the relevant UK 
      regulations. Suppliers cannot assume that these exemptions automatically apply to their components, 
      parts, materials, or products and are encouraged to request approval for the use of any required UK RoHS 
      exemptions via their supply chain representative. Improperly applying an exemption could be termed as 
      non-compliance, leading to enforcement action and/or disqualification as a supplier.
      <br></br>
      By enforcing UK RoHS, the UK government promotes the use of safer materials in electronics manufacturing 
      and encourages the industry to adopt environmentally friendly practices, contributing to sustainable development 
      and environmental protection.
      <br></br>
      <a
        href="https://www.gov.uk/guidance/rohs-compliance-and-guidance"
        target="_blank"
      >
        About UK RoHS 
      </a>
      <br></br>
      <a
        href="https://www.gov.uk/guidance/restriction-of-hazardous-substances-rohs-exemptions-how-to-apply"
        target="_blank"
      >
        UK RoHS Exemptions
      </a>
    </Typography.Paragraph>
    `,
    filename: "UK ROHS Declaration Template.docx",
    categoryId: "63c6728770cfa800125e3bf0",
  },
  scip: {
    name: "scip",
    description: `
    <Typography.Paragraph
      ellipsis={{
        rows: 2,
        expandable: expanded,
        symbol: "more",
      }}
    >
      In 2008, the EU introduced the Waste Framework Directive (WFD), a comprehensive framework aimed at promoting sustainable waste management. In January 2021, a new provision was added to the WFD that requires certain manufacturers to submit information to the SCIP (Substances of Concern In articles, as such or in complex products) database.
      <br></br>
      The SCIP database is a new provision of the WFD that requires manufacturers to provide information on candidate list of substances of very high concern (SVHCs) at a concentration above 0.1% weight by weight (w/w) in their products/Articles. The database is maintained by the European Chemicals Agency (ECHA) and is intended to improve the transparency and traceability of SVHCs in products.
      </Typography.Paragraph>
      <br></br>
      <a
        href="https://echa.europa.eu/nl/understanding-wfd"
        target="_blank"
      >
        Understanding WFD 
      </a>
      <br></br>
      <a
        href="https://echa.europa.eu/nl/scip-database"
        target="_blank"
      >
        Introduction to SCIP Database
      </a>
      <br></br>
      <a
        href="https://echa.europa.eu/nl/scip-infographic"
        target="_blank"
      >
        SCIP infographic
      </a>
    `,
    filename: "",
    categoryId: "63c6742570cfa800125e3bf7",
  },
  "gadsl_(mds)": {
    name: "gadsl_(mds)",
    description: `
    <Typography.Paragraph
      ellipsis={{
        rows: 2,
        expandable: expanded,
        symbol: "more",
      }}
    >
      The Global Automotive Declarable Substance List (GADSL) is an industry-wide list of chemical substances that automotive manufacturers and suppliers must report if present in parts and materials used in vehicles. Developed by the Global Automotive Stakeholders Group (GASG), GADSL aims to enhance transparency and regulatory compliance while promoting sustainable material use. It categorizes substances as "Declarable" (requiring disclosure) or "Prohibited" (banned from use).
      <br></br>
      To comply with GADSL and other regulations, suppliers submit Material Data Sheets (MDS) in the International Material Data System (IMDS) Alternatively, tools like Acquis Compliance Tool can facilitate this process for customers by streamlining supplier data collection and material reporting. MDS provides a detailed breakdown of material composition, ensuring full transparency in the supply chain and compliance with global environmental regulations such as EU REACH and the ELV Directive.
      <br></br>
      <a
        href="https://www.gadsl.org/"
        target="_blank"
      >
        Download GADSL
      </a>
      </Typography.Paragraph>
    `,
    filename: "GADSL Declaration Template.docx",
    categoryId: "",
  },
};

export default regulations;
