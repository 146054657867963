import React, { useEffect, useState } from "react";
import {
  Table,
  Space,
  Tag,
  Typography,
  Modal,
  Input,
  Button,
  message,
  Tooltip,
} from "antd";
import {
  CloseCircleOutlined,
  ExportOutlined,
  ExclamationCircleFilled,
  WarningFilled,
  InfoCircleFilled,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import { SupplierResponse } from "../../../../utils/types/CampaignManager/campaignManager";
import ReadSupportingDocument from "../../../../components/modals/ReadSupportingDocuments";
import AddComplianceApprove from "../../../../components/modals/addApproveComplianceModal";
import {
  updateCampaignResponse,
  readCampaignSupplierResponse,
} from "../../../../redux/actions/CampaignManager/campaignManagerAction";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  ColumnsType,
  SorterResult,
  TablePaginationConfig,
} from "antd/es/table/interface";
import { getRegulationsFromLocalStorage } from "../../../../utils/localStore";
import { Regulation } from "../../../../utils/types/regulation";

const { Text } = Typography;

const ExpandPendingResponse: React.FC<{
  formData: any;
  fetchData: any;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [feedbackText, setFeedbackText] = useState<string>("");
  const regulations = getRegulationsFromLocalStorage() ?? [];

  const [selectedItem, setSelectedItem] = useState<any | null>(null);
  const [subscriptionEnable, setSubscriptionEnable] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [supplierResponse, setSupplierResponse] = useState<any[]>([]);
  const [fieldError, setFieldError] = useState("");

  const handleOpenModal = (record: any) => {
    setSelectedItem(record);
    setIsModalVisible(true);
    setFieldError("");
  };

  const handleReject = (withFeedback: boolean) => {
    if (withFeedback) {
      if (!feedbackText.trim()) {
        setFieldError("Feedback is required!");
        return;
      }
    } else {
      setFieldError("");
    }
    if (selectedItem) {
      let bulkApprovePayload;
      if (
        props.formData.regulation !== "Material" &&
        props.formData.regulation !== "Scip"
      ) {
        bulkApprovePayload = {
          supplierComplianceResponse: selectedItem?.supplierComplianceResponse,
          type: "rejected",
          ...(withFeedback && { feedback: feedbackText || null }),
          componentId: selectedItem.componentId,
        };
      } else {
        bulkApprovePayload = {
          type: "rejected",
          ...(withFeedback && { feedback: feedbackText || null }),
          componentId: selectedItem.componentId,
        };
      }
      dispatch(
        updateCampaignResponse({
          filter: {
            sendgridCampaignId: selectedItem.sendgridCampaignId,
            componentId: selectedItem.componentId,
          },
          updates: bulkApprovePayload,
          responseType: props.formData.regulation,
        })
      )
        .then(() => {
          message.success("Component rejected successfully");
          fetchData();
          setIsModalVisible(false);
          setFeedbackText("");
          setSelectedItem(null);
          setFieldError("");
          props.fetchData();
        })
        .catch((error) => {
          console.error("Reject error:", error);
          message.error(
            `Rejection failed: ${
              error.response?.data?.message || error.message
            }`
          );
        });
    }
  };

  const fetchData = () => {
    setLoading(true);
    dispatch(
      readCampaignSupplierResponse({
        sendgridCampaignId: props.formData?.sendgridCampaignId,
        manufacturer: props.formData?.manufacturer,
        regulation: props.formData?.regulation,
        limit: tablePagination.pageSize,
        page: tablePagination.current,
        sortBy: ["createdAt"],
        sortDesc: tableSorter.column
          ? tableSorter.order === "descend"
            ? false
            : true
          : false,
        statusType: "pending",
      })
    )
      .then((response: any) => {
        setSupplierResponse(response?.supplierResponse);
        setSubscriptionEnable(response.subscriptionEnable);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );

  const [tableSorter, setTableSorter] = useState<
    SorterResult<SupplierResponse>
  >({});

  const onClickViewLink = (filename: string) => {
    window.open(filename, "_blank");
  };

  const handleReload = () => {
    setTimeout(() => {
      fetchData();
      props.fetchData();
    }, 2000);
  };

  const columns: ColumnsType<SupplierResponse> = [
    {
      title: "Action",
      key: "operation",
      width: 150,
      render: (text: any, record: any) => (
        <Space style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <CloseCircleOutlined
            style={{ cursor: "pointer", fontSize: "16px", marginTop: "4px" }}
            onClick={() => handleOpenModal(record)}
            title="Reject"
          />
          <AddComplianceApprove
            type="icon"
            fetchData={handleReload}
            formData={{ ...record, regulation: props.formData.regulation }}
          />
        </Space>
      ),
    },
  ];

  const regulationHeaders = regulations
    ?.filter(
      (regulation: Regulation) =>
        regulation.regulationNumber === props.formData.regulation
    )
    .flatMap((config: any) =>
      config.configuration
        .filter(
          (o: any) =>
            !o.hidden &&
            o.value !== "manufacturer" &&
            o.value !== "internalItemNumber" &&
            o.value !== "operations"
        )
        .map((k: any) => ({
          title: `${k.text}`,
          dataIndex: `${k.value}`,
          key: `${
            k.value.includes("complianceOutputs")
              ? props.formData.regulation + "." + k.value
              : k.value
          }`,
          render: (value: any, record: any) => {
            const supplierResponse = record?.supplierComplianceResponse;
            const pdfReader = supplierResponse?.supplierPdfReader?.answers;
            if (k.value === "complianceDocument") {
              return record.supplierComplianceResponse?.complianceDocument ? (
                record.supplierComplianceResponse?.complianceDocument?.includes(
                  "http"
                ) ? (
                  <span>
                    <ExportOutlined
                      onClick={() =>
                        onClickViewLink(
                          record.supplierComplianceResponse?.complianceDocument
                        )
                      }
                    />{" "}
                    Link
                  </span>
                ) : (
                  <ReadSupportingDocument
                    postUrl="campaignmanager/document/download"
                    filename={
                      record.supplierComplianceResponse?.complianceDocument
                    }
                    foldername="campaign"
                    campaignId={record.sendgridCampaignId}
                  />
                )
              ) : null;
            } else if (k.value.includes("complianceOutputs.compliant")) {
              const complianceOutputsCompliant =
                pdfReader?.complianceOutputsCompliant?.toUpperCase();
              const complianceStatus =
                supplierResponse?.complianceOutputs?.compliant?.toUpperCase();

              const defaultComplianceTag = complianceStatus ? (
                <Tag
                  color={
                    complianceStatus === "YES"
                      ? "green"
                      : complianceStatus === "NO"
                      ? "red"
                      : complianceStatus === "YES WITH EXEMPTION"
                      ? "orange"
                      : "default"
                  }
                >
                  {complianceStatus}
                </Tag>
              ) : (
                <Tag color="grey">UNKNOWN</Tag>
              );
              if (
                subscriptionEnable &&
                pdfReader &&
                pdfReader !== null &&
                pdfReader?.regulationNames
                  ?.toLowerCase()
                  .includes("regulation not mentioned")
              ) {
                return (
                  <Space>
                    {/* <Tooltip title="Act AI: Regulation not explicitly mentioned in the document.">
                      <WarningFilled
                        style={{ color: "orange", fontSize: "16px" }}
                      />
                    </Tooltip> */}
                    <Text>{defaultComplianceTag}</Text>
                  </Space>
                );
              }
              if (
                subscriptionEnable &&
                pdfReader &&
                pdfReader !== null &&
                complianceOutputsCompliant
                  ?.toUpperCase()
                  .includes("NOT PROVIDED")
              ) {
                return (
                  <Space>
                    <Tooltip
                      title={"Act AI: Error extracting compliant output."}
                    >
                      <WarningFilled
                        style={{ color: "orange", fontSize: "16px" }}
                      />
                    </Tooltip>
                    {defaultComplianceTag}
                  </Space>
                );
              }

              const isMismatch =
                subscriptionEnable &&
                complianceStatus &&
                pdfReader &&
                pdfReader !== null &&
                complianceOutputsCompliant !== complianceStatus;

              return (
                <>
                  {defaultComplianceTag}
                  {isMismatch && (
                    <Tooltip title="Act AI: Status not validated. Review document for compliance confirmation.">
                      <ExclamationCircleFilled
                        style={{ color: "red", marginLeft: "3px" }}
                      />
                    </Tooltip>
                  )}
                </>
              );
            } else if (k.value.includes("complianceOutputs.status")) {
              if (
                record &&
                record.supplierComplianceResponse?.complianceOutputs &&
                record.supplierComplianceResponse?.complianceOutputs?.status?.toUpperCase() ===
                  "ACCEPTED"
              ) {
                return <Tag color="green"> ACCEPTED </Tag>;
              } else if (
                record &&
                record.supplierComplianceResponse?.complianceOutputs &&
                record.supplierComplianceResponse?.complianceOutputs?.status?.toUpperCase() ===
                  "REJECTED"
              ) {
                return <Tag color="red"> REJECTED </Tag>;
              } else if (
                record &&
                record.supplierComplianceResponse?.complianceOutputs &&
                record.supplierComplianceResponse?.complianceOutputs?.status?.toUpperCase() ===
                  "MODIFIED"
              ) {
                return <Tag color="orange"> MODIFIED </Tag>;
              } else if (
                record &&
                record.supplierComplianceResponse?.complianceOutputs &&
                record.supplierComplianceResponse?.complianceOutputs?.status?.toUpperCase() ===
                  "BROWSED"
              ) {
                return <Tag color="orange"> BROWSED </Tag>;
              } else if (
                record &&
                record.supplierComplianceResponse?.complianceOutputs &&
                record.supplierComplianceResponse?.complianceOutputs?.status?.toUpperCase() ===
                  "NOT YET BROWSED"
              ) {
                return <Tag color="red"> NOT YET BROWSED </Tag>;
              }
            }
            // Handling compliance version
            if (k.value.includes("complianceOutputs.version")) {
              const complianceOutputsVersion =
                pdfReader?.complianceOutputsVersion;
              const complianceVersion =
                supplierResponse?.complianceOutputs?.version;
              if (
                subscriptionEnable &&
                pdfReader &&
                pdfReader !== null &&
                pdfReader?.regulationNames
                  ?.toLowerCase()
                  .includes("regulation not mentioned")
              ) {
                return (
                  <Space>
                    {/* <Tooltip title="Act AI: Regulation not explicitly mentioned in the document.">
                      <WarningFilled
                        style={{ color: "orange", fontSize: "16px" }}
                      />
                    </Tooltip> */}
                    <Text>{complianceVersion || "N/A"}</Text>
                  </Space>
                );
              }

              if (
                subscriptionEnable &&
                pdfReader !== null &&
                pdfReader &&
                complianceOutputsVersion?.toUpperCase().includes("NOT PROVIDED")
              ) {
                return (
                  <Space>
                    <Tooltip
                      title={"Act AI: Error extracting compliant version."}
                    >
                      <WarningFilled
                        style={{ color: "orange", fontSize: "16px" }}
                      />
                    </Tooltip>
                    <Text>{complianceVersion || "N/A"}</Text>
                  </Space>
                );
              }

              const isMismatch =
                complianceVersion &&
                subscriptionEnable &&
                pdfReader !== null &&
                pdfReader &&
                complianceOutputsVersion?.replace(/\s/g, "") !==
                  complianceVersion?.replace(/\s/g, "");

              return (
                <Text>
                  {complianceVersion || "N/A"}
                  {isMismatch && (
                    <Tooltip title="Act AI: Regulation version missing/mismatched or document date unknown. Verify.">
                      <ExclamationCircleFilled
                        style={{ color: "red", marginLeft: "3px" }}
                      />
                    </Tooltip>
                  )}
                </Text>
              );
            }

            // Handling exemptions
            if (k.value.includes("complianceOutputs.exemption")) {
              const complianceOutputsExemption =
                pdfReader?.complianceOutputsExemption;
              const complianceExemption =
                supplierResponse?.complianceOutputs?.exemption?.join(", ") ||
                "N/A";
              if (
                subscriptionEnable &&
                pdfReader &&
                pdfReader !== null &&
                pdfReader?.regulationNames
                  ?.toLowerCase()
                  .includes("regulation not mentioned")
              ) {
                return (
                  <Space>
                    {/* <Tooltip title="Act AI: Regulation not explicitly mentioned in the document.">
                      <WarningFilled
                        style={{ color: "orange", fontSize: "16px" }}
                      />
                    </Tooltip> */}
                    <Text>{complianceExemption}</Text>
                  </Space>
                );
              }
              if (
                subscriptionEnable &&
                pdfReader &&
                pdfReader !== null &&
                complianceOutputsExemption
                  ?.toUpperCase()
                  .includes("NOT PROVIDED")
              ) {
                return (
                  <Space>
                    <Tooltip
                      title={"Act AI: Error extracting compliant exemption."}
                    >
                      <WarningFilled
                        style={{ color: "orange", fontSize: "16px" }}
                      />
                    </Tooltip>
                    <Text>{complianceExemption}</Text>
                  </Space>
                );
              }

              const isMismatch =
                complianceOutputsExemption &&
                pdfReader !== null &&
                pdfReader &&
                !complianceOutputsExemption
                  .replace(/\s/g, "")
                  .includes(complianceExemption.replace(/\s/g, ""));

              return (
                <Text
                  style={{ width: 200 }}
                  ellipsis={{ tooltip: complianceExemption }}
                >
                  {complianceExemption}
                  {isMismatch && (
                    <Tooltip title="Act AI: Exemption(s) not found. Check document for specified exemptions.">
                      <ExclamationCircleFilled
                        style={{ color: "red", marginLeft: "3px" }}
                      />
                    </Tooltip>
                  )}
                </Text>
              );
            }

            // Handling substances
            if (k.value.includes("complianceOutputs.substance")) {
              const complianceOutputsSubstances =
                pdfReader?.complianceOutputsSubstances;
              const complianceSubstance =
                supplierResponse?.complianceOutputs?.substance || "N/A";
              if (
                subscriptionEnable &&
                pdfReader &&
                pdfReader !== null &&
                pdfReader?.regulationNames
                  ?.toLowerCase()
                  .includes("regulation not mentioned")
              ) {
                return (
                  <Space>
                    {/* <Tooltip title="Act AI: Regulation not explicitly mentioned in the document.">
                      <WarningFilled
                        style={{ color: "orange", fontSize: "16px" }}
                      />
                    </Tooltip> */}
                    <Text>{complianceSubstance}</Text>
                  </Space>
                );
              }
              if (
                subscriptionEnable &&
                pdfReader &&
                pdfReader !== null &&
                complianceOutputsSubstances
                  ?.toUpperCase()
                  .includes("NOT PROVIDED")
              ) {
                return (
                  <Space>
                    <Tooltip
                      title={"Act AI: Error extracting compliant substance."}
                    >
                      <WarningFilled
                        style={{ color: "orange", fontSize: "16px" }}
                      />
                    </Tooltip>
                    <Text>{complianceSubstance}</Text>
                  </Space>
                );
              }

              const isMismatch =
                complianceOutputsSubstances &&
                !complianceOutputsSubstances
                  .replace(/\s/g, "")
                  .includes(complianceSubstance.replace(/\s/g, ""));

              return (
                <Text
                  style={{ width: 200 }}
                  ellipsis={{ tooltip: complianceSubstance }}
                >
                  {complianceSubstance}
                  {isMismatch && (
                    <Tooltip title="Act AI: Substance of Concern not identified. Review document for details.">
                      <ExclamationCircleFilled
                        style={{ color: "red", marginLeft: "3px" }}
                      />
                    </Tooltip>
                  )}
                </Text>
              );
            }

            // Default case for other compliance outputs
            return (
              <Text>
                {record.supplierComplianceResponse &&
                record.supplierComplianceResponse?.complianceOutputs
                  ? record.supplierComplianceResponse?.complianceOutputs[
                      k.value?.split(".").pop().toString()
                    ]
                  : ""}
              </Text>
            );
          },
        }))
    );

  const regulationColumns = [
    {
      title: "Manufacturer Part Number",
      dataIndex: ["manufacturer", "itemNumber"],
      key: "manufacturerPartNumber",
      sorter: true,
      width: 150,
      hidden: false,
      render: (manufacturer: any, record: any) => {
        const supplierResponse = record?.supplierComplianceResponse;
        const pdfReader = supplierResponse?.supplierPdfReader?.answers;
        const manufacturerItemNumber = pdfReader?.manufacturerItemNumber;
        const regulationNames = pdfReader?.regulationNames;

        const defaultManufacturerPartNumber = manufacturer || "";
        if (
          subscriptionEnable &&
          pdfReader &&
          pdfReader !== null &&
          regulationNames &&
          regulationNames.toLowerCase().includes("regulation not mentioned")
        ) {
          return (
            <Space>
              {/* <Tooltip
                title={
                  "Act AI:Error extracting manufacturer name as regulation not menstioned."
                }
              >
                <WarningFilled style={{ color: "orange", fontSize: "16px" }} />
              </Tooltip> */}
              <Text>{defaultManufacturerPartNumber}</Text>
            </Space>
          );
        }

        // If "not provided" is found, show the warning along with the default manufacturer part number
        if (
          subscriptionEnable &&
          pdfReader &&
          pdfReader !== null &&
          manufacturerItemNumber &&
          manufacturerItemNumber.toLowerCase().includes("not provided")
        ) {
          return (
            <Space>
              <Tooltip
                title={"Act AI: Error extracting manufacturer item number."}
              >
                <WarningFilled style={{ color: "orange", fontSize: "16px" }} />
              </Tooltip>
              <Text>{defaultManufacturerPartNumber}</Text>
            </Space>
          );
        }

        // Check if manufacturer part number in PDF does not match the given manufacturer number
        const itemNumberMismatch =
          manufacturer && !manufacturerItemNumber?.includes(manufacturer);

        let tooltipTitle = "";

        if (itemNumberMismatch) {
          tooltipTitle =
            "Act AI: Exact match not found. Check for series/part family/division-level declarations.";
        }

        // Main rendering logic when PDF reader exists
        if (subscriptionEnable && pdfReader && pdfReader !== null) {
          return (
            <Text>
              {defaultManufacturerPartNumber}
              <br />
              {itemNumberMismatch && (
                <Tooltip title={tooltipTitle}>
                  <ExclamationCircleFilled
                    style={{ color: "red", marginLeft: "5px" }}
                  />
                </Tooltip>
              )}
            </Text>
          );
        }

        // Default case when PDF reader is missing
        return (
          <Text>
            {defaultManufacturerPartNumber}
            <br />
          </Text>
        );
      },
    },
    {
      title: "Regulation",
      dataIndex: "supplierComplianceResponse.regulationNumber",
      key: "supplierComplianceResponse.regulationNumber",
      sorter: true,
      width: 150,
      hidden: false,
      render: (text: any, record: any) => {
        const supplierResponse = record?.supplierComplianceResponse;
        const pdfReader = supplierResponse?.supplierPdfReader?.answers;
        const regulationNames = pdfReader?.regulationNames;
        const regulationNumber = supplierResponse?.regulationNumber
          ?.replaceAll("_", " ")
          .toUpperCase();

        const defaultRegulationName =
          regulations?.find(
            (regulation: any) =>
              regulation.regulationNumber === supplierResponse?.regulationNumber
          )?.name || "";
        if (
          subscriptionEnable &&
          pdfReader &&
          pdfReader !== null &&
          regulationNames &&
          regulationNames.toLowerCase().includes("regulation not mentioned")
        ) {
          return (
            <Space>
              <Tooltip
                title={
                  "Act AI:Error extracting regulation reference as currently ACT AI is available for EU RoHS and EU REACH only."
                }
              >
                <InfoCircleTwoTone />
              </Tooltip>
              <Text>{defaultRegulationName}</Text>
            </Space>
          );
        }
        if (subscriptionEnable && pdfReader && pdfReader !== null) {
          const normalizeText = (text: string | undefined) =>
            text?.toLowerCase().replace(/[_\s-]/g, "") || "";

          const normalizedRegulationNumber = normalizeText(regulationNumber);
          const normalizedRegulationNames = normalizeText(regulationNames);

          const regulationMatches =
            normalizedRegulationNames.includes(normalizedRegulationNumber) ||
            normalizedRegulationNames.includes(
              normalizeText(supplierResponse?.regulationNumber)
            ) ||
            normalizedRegulationNames.includes(
              normalizeText(
                supplierResponse?.regulationNumber?.replace(/EU/g, "")
              )
            );

          return (
            <Text>
              {regulationNumber}
              {!regulationMatches && (
                <Tooltip title="Act AI: Regulation reference missing. Please validate.">
                  <ExclamationCircleFilled
                    style={{ color: "red", marginLeft: "3px" }}
                  />
                </Tooltip>
              )}
            </Text>
          );
        }

        // Default case when PDF reader is missing
        return <Text>{defaultRegulationName}</Text>;
      },
    },
    ...regulationHeaders,
    {
      title: "Submitted",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      render: (_: string, record: any) => (
        <Text>
          {record.supplierComplianceResponse
            ? new Date(
                record.supplierComplianceResponse?.updatedAt
              ).toLocaleString()
            : ""}
          <br />
          {record.supplierComplianceResponse?.updatedBy}
        </Text>
      ),
    },
  ];

  const materialColumns = [
    {
      title: "Manufacturer Part Number",
      dataIndex: ["manufacturer", "itemNumber"],
      key: "manufacturer.itemNumber",
      //sorter: true,
      width: 200,
      render: (manufacturer: any) => <Text>{manufacturer}</Text>,
    },
    {
      title: "FMD Document",
      dataIndex: "supplierMaterialsResponse.fmdDocument",
      key: "supplierMaterialsResponse.fmdDocument",
      width: 150,
      render: (text: string, record: any) => {
        return record.supplierMaterialsResponse?.fmdDocument ? (
          <ReadSupportingDocument
            postUrl="public/suppliers/document/download"
            filename={record.supplierMaterialsResponse?.fmdDocument}
            foldername="campaign"
            subfolder="materials"
            campaignId={record.sendgridCampaignId}
          />
        ) : null;
      },
    },
    {
      title: "Weight",
      dataIndex: "partWeight",
      key: "partWeight",
      width: 150,
      render: (_: string, record: any) => (
        <Text>
          {record.supplierMaterialsResponse?.partWeight}{" "}
          <small>{record.supplierMaterialsResponse?.partWeightUnit}</small>
        </Text>
      ),
    },
    {
      title: "Submitted",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      render: (_: string, record: any) => (
        <Text>
          {record.supplierMaterialsResponse
            ? new Date(
                record.supplierMaterialsResponse?.updatedAt
              ).toLocaleString()
            : ""}
          <br />
          {record.supplierMaterialsResponse?.updatedBy}
        </Text>
      ),
    },
  ];

  const scipColumns = [
    {
      title: "Manufacturer Part Number",
      dataIndex: ["manufacturer", "itemNumber"],
      key: "manufacturer.itemNumber",
      //sorter: true,
      width: 200,
      render: (manufacturer: any) => <Text>{manufacturer}</Text>,
    },
    {
      title: "Article ID Type",
      dataIndex: "supplierScipResponse.PrimaryArticleIdentifierType",
      key: "supplierScipResponse.PrimaryArticleIdentifierType",
      //sorter: true,
      width: 150,
      render: (_: string, record: any) => (
        <Text>
          {record.supplierScipResponse
            ? record?.supplierScipResponse?.PrimaryArticleIdentifierType?.text
            : ""}
        </Text>
      ),
    },
    {
      title: "Article ID Value",
      dataIndex: "supplierScipResponse.PrimaryArticleIdentifierValue",
      key: "supplierScipResponse.PrimaryArticleIdentifierValue",
      //sorter: true,
      width: 150,
      render: (_: string, record: any) => (
        <Text>
          {record.supplierScipResponse?.PrimaryArticleIdentifierValue}
        </Text>
      ),
    },
    {
      title: "Submitted",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      render: (text: string, record: any) => (
        <Text>
          {record.supplierScipResponse
            ? new Date(record.supplierScipResponse?.updatedAt).toLocaleString()
            : ""}
          <br />
          {record.supplierScipResponse?.updatedBy}
        </Text>
      ),
    },
  ];

  if (props.formData.regulation === "Material") {
    columns.push(...materialColumns);
  } else if (props.formData.regulation === "Scip") {
    columns.push(...scipColumns);
  } else {
    columns.push(...regulationColumns);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={supplierResponse}
        pagination={false}
        rowKey="componentId"
        style={{
          background: "#E6F7FF",
          marginLeft: -16,
          marginRight: -16,
          width: "calc(100% + 32px)",
        }}
        scroll={{ y: "calc(70vh - 280px)" }}
        components={{
          header: {
            cell: (props: React.HTMLAttributes<HTMLTableHeaderCellElement>) => (
              <th
                {...props}
                style={{
                  backgroundColor: "#045B7C",
                  color: "white",
                  fontWeight: "bold",
                  padding: "12px 16px",
                }}
              />
            ),
          },
        }}
      />
      <Modal
        title="Feedback for Rejection"
        open={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          setFeedbackText("");
        }}
        footer={[
          <>
            <Button
              onClick={() => handleReject(false)}
              style={{
                backgroundColor: "#00516E",
                color: "white",
                borderColor: "#00516E",
                marginRight: "8px",
              }}
            >
              Reject without Feedback
            </Button>
            <Button
              type="primary"
              onClick={() => handleReject(true)}
              style={{
                backgroundColor: "#F7863E",
                borderColor: "#F7863E",
              }}
            >
              Submit
            </Button>
          </>,
        ]}
        centered
        maskClosable={false}
      >
        <Input.TextArea
          rows={4}
          placeholder="Type Feedback"
          onChange={(e) => {
            setFeedbackText(e.target.value);
            setFieldError("");
          }}
          value={feedbackText}
          style={{
            borderColor: fieldError ? "red" : "#ACE8FF",
            marginBottom: "8px",
          }}
        />
        {fieldError && (
          <div style={{ color: "red", fontSize: 12, marginTop: -5 }}>
            {fieldError}
          </div>
        )}
      </Modal>
    </>
  );
};

export default ExpandPendingResponse;
