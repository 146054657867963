import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FilePdfOutlined,
  FilterOutlined,
  FileSyncOutlined,
  MoreOutlined,
  EyeOutlined,
  ControlOutlined,
} from "@ant-design/icons";

import {
  Space,
  Typography,
  Button,
  Table,
  Input,
  Spin,
  Tag,
  Dropdown,
  Checkbox,
} from "antd";
import type { MenuProps } from "antd";

import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";

import type {
  FilterValue,
  SorterResult,
  TableRowSelection,
} from "antd/es/table/interface";

import { Supplier } from "../../../../utils/types/supplier";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  listSuppliers,
  listSuppliersFilters,
  generateSupplier,
} from "../../../../redux/actions/suppliersAction";

import SupplierModal from "../../../../components/forms/SupplierForm";
import { setLocalStorage, getLocalStorage } from "../../../../utils/localStore";
import ErrorBoundary from "antd/es/alert/ErrorBoundary";
import AddToCampaignListModal from "../../../../components/modals/AddToCampaignListModal";
import AdvanceFilters from "../../../../components/modals/AdvanceFilters";

const { Search } = Input;
const { Title, Text } = Typography;

const Suppliers: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [supplierCount, setSupplierCount] = useState<number>(0);
  const [advanceFilter, setAdvanceFilter] = useState<boolean>(false);
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);

  const [search, setSearch] = useState<any>();

  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;

  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<Supplier>>({});
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});
  const [filterData, setFilterData] = useState<any>({});
  const [filterOptions, setFilterOptions] = useState<any>({});

  const onSelectSuppliers = (
    newSelectedRowKeys: React.Key[],
    selectedRows: Supplier[]
  ) => {
    setSelectedProducts(selectedRows);
  };

  const supplierSelection: TableRowSelection<Supplier> = {
    selectedRowKeys: selectedProducts.map((e) => e._id),
    onChange: onSelectSuppliers,
    preserveSelectedRowKeys: true,
  };

  const onSearch = (value: any) => {
    setSearch({
      searchField: true,
      query:
        value?.key === "Enter"
          ? value?.target?.value.replace(/[()/[\]]/g, "\\$&").trim()
          : value.replace(/[()/[\]]/g, "\\$&").trim(),
    });
    setTablePagination({
      pageSize: tablePagination.pageSize,
      current: 1,
    });
  };

  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  interface SupplierProfileParams {
    id: string;
  }

  const handlePreviewClick = (record: any) => {
    const params: SupplierProfileParams = {
      id: record._id,
    };
    navigate(`${params.id}`);
    // setLocalStorage("supplier", record.name);
  };

  const handleDeleteClick = (record: any) => {
    console.log("Delete record", record);
  };

  const fetchData = () => {
    const filters: { [x: string]: FilterValue | null }[] = [];
    Object.keys(tableFilters).forEach((key) => {
      if (tableFilters[key] && tableFilters[key]?.length) {
        filters.push({ [key]: tableFilters[key] });
      }
    });
    setLoading(true);
    dispatch(
      listSuppliers({
        pageSize: tablePagination.pageSize,
        pageNum: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["name"],
        sortDesc: tableSorter.order === "descend" ? false : true,
        softDelete: false,
        searchField:
          !filters.length && search?.searchField ? search?.query : "",
        filtersUsed: filters.length ? "useFilters" : false,
        filters: filters.length
          ? JSON.stringify({
              elements: filters,
              path: [],
            })
          : [],
      })
    )
      .then((response: any) => {
        setSuppliers(response.suppliers);
        setSupplierCount(response.count);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchFilterData = (columnName: string) => {
    setLoadingData(true);
    dispatch(listSuppliersFilters({ column: columnName })).then(
      (response: any) => {
        setFilterData((prev: any) => ({ ...prev, [columnName]: response }));
        setFilterOptions((prev: any) => ({ ...prev, [columnName]: response }));
        // setCurrentFilter("");
        setLoadingData(false);
      }
    );
  };

  const onClickGenerate = () => {
    dispatch(generateSupplier({ alert: true })).then(() => {
      fetchData();
    });
  };
  const handleTableChange: TableProps<Supplier>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Supplier> | SorterResult<Supplier>[]
  ) => {
    const entries = Object.entries(filters);
    const filteredEntries = entries.filter(([key, value]) => value !== null);
    const cleanFilters = Object.fromEntries(filteredEntries);
    setTablePagination(pagination);
    !advanceFilter && setTableFilters(cleanFilters);
    setTableSorter(sorter as SorterResult<Supplier>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      return;
    }
  };

  const columns: ColumnsType<Supplier> = [
    {
      title: "Action",
      key: "operation",
      width: 150,
      render: (text: any, record: any) => (
        <Space>
          {permissions?.update?.includes("suppliers") && (
            <SupplierModal
              type="edit"
              formData={record}
              fetchData={fetchData}
            />
          )}
          <Button
            type="text"
            icon={<EyeOutlined />}
            onClick={() => handlePreviewClick(record)}
            data-testid="preview-supplier-button"
            title="Preview"
          />
          {/* <Dropdown menu={menu(record)}>
            <Space>
              <Button type="text" icon={<MoreOutlined />} />
            </Space>
          </Dropdown> */}
        </Space>
      ),
    },
    {
      title: "Supplier ID",
      dataIndex: "number",
      key: "number",
      sorter: true,
    },
    {
      title: "Supplier Name",
      dataIndex: "name",
      key: "name",
      onFilterDropdownOpenChange: (open: any) =>
        open && fetchFilterData("name"),
      filters: filterData?.name || [],
      filteredValue: tableFilters.name || null,
      filterSearch: true,
      sorter: true,
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      onFilterDropdownOpenChange: (open: any) =>
        open && fetchFilterData("country"),
      filters: filterData?.country || [],
      filteredValue: tableFilters.country || null,
      filterSearch: true,
      sorter: true,
    },
    {
      title: "Active Contacts",
      dataIndex: "activeContacts",
      key: "activeContacts",
    },
    permissions?.read?.includes("components") && {
      title: "Active Components",
      dataIndex: "activeComponents",
      key: "activeComponents",
    },
    {
      title: "In Scope for",
      dataIndex: "inScope",
      key: "inScope",
      ellipsis: true,
      render: (inScope: any) => (
        <Text>{inScope?.map((e: any) => e.name).join(", ")}</Text>
      ),
    },
    {
      title: "Last Reported",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      render: (text: string, record: any) => (
        <Text>{new Date(text).toLocaleString()}</Text>
      ),
    },
  ].filter(Boolean);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const showDrawer = () => {
    setIsDrawerVisible(true);
  };

  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };
  const handleFiltersApply = (filters: Record<string, string[]>) => {
    setAdvanceFilter(true);
    setTableFilters(filters);
  };
  useEffect(() => {
    fetchData();
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
    search,
  ]);

  return (
    <>
      <ErrorBoundary>
        <Spin fullscreen spinning={loadingData} />
        <Space
          align="end"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <div>
            <Title level={5} style={{ marginBottom: 0 }}>
              Suppliers
            </Title>
          </div>
          <Space
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "1rem",
              flexWrap: "wrap",
            }}
          >
            {Object.values(tableFilters).some(
              (arr) => Array.isArray(arr) && arr.length > 0
            ) ? (
              <Button
                icon={<FilterOutlined />}
                onClick={() => {
                  setTableFilters({});
                  setSearch({});
                  setAdvanceFilter(false);
                }}
              >
                Reset Filters
              </Button>
            ) : (
              <Search
                placeholder="Search using supplier name, number, country, status, Last reported, email, contact number, country of origin, supplier type,assigned to"
                onSearch={onSearch}
                onPressEnter={onSearch}
                allowClear
                style={{ width: 350 }}
              />
            )}

            <div>
              <Button
                type="primary"
                onClick={showDrawer}
                icon={<ControlOutlined />}
              >
                Advanced Filters
              </Button>
              <AdvanceFilters
                visible={isDrawerVisible}
                onClose={closeDrawer}
                onFiltersApply={handleFiltersApply}
                parentType="suppliers"
                fetchFilterData={fetchFilterData}
                filterData={filterData}
              />
            </div>
            <AddToCampaignListModal
              selectedItems={selectedProducts}
              onCancel={() => setSelectedProducts([])}
              type="suppliersAdd"
            />
            {permissions?.create?.includes("components") && (
              <Button
                type="primary"
                onClick={onClickGenerate}
                icon={<FileSyncOutlined />}
                data-testid="generate-button"
                title="Generate Suppliers"
              >
                Generate
              </Button>
            )}
            {permissions?.create?.includes("suppliers") && (
              <SupplierModal type="create" fetchData={fetchData} />
            )}
            {/* <Button type="text" icon={<FilterOutlined />} /> */}
          </Space>
        </Space>
        {Object.keys(tableFilters).length > 0 &&
          Object.keys(tableFilters).reduce<JSX.Element[]>((acc, key) => {
            const elements =
              tableFilters[key]?.map((item, idx) => (
                <Tag
                  className="filter-tag"
                  key={`${key}-${idx}`}
                  bordered={false}
                  closable
                  onClose={() => {
                    const tablefiltrs = { ...tableFilters };
                    const arr = tablefiltrs[key];
                    if (arr) {
                      const arrIndex = arr?.indexOf(item);
                      if (arrIndex !== -1) {
                        arr.splice(arrIndex, 1);
                      }
                      setTableFilters({ ...tablefiltrs });
                    }
                  }}
                >
                  {`${item}`}
                  {/* {typeof item === "string" && item} */}
                </Tag>
              )) || [];
            acc.push(...elements);
            return acc;
          }, [])}
        {permissions?.read?.includes("suppliers") && (
          <Table
            loading={loading}
            dataSource={suppliers}
            scroll={{
              x: 1000,
              y: "calc(100vh - 285px)",
            }}
            columns={columns}
            rowKey="_id"
            rowSelection={supplierSelection}
            pagination={{
              ...tablePagination,
              total: supplierCount,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (totalCount) => `Total  ${totalCount}  items`,
            }}
            onChange={handleTableChange}
          />
        )}
      </ErrorBoundary>
    </>
  );
};

export default Suppliers;
