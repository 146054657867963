import { useEffect } from "react";
import { AppDispatch } from "../../redux/store";
import { privateConnectSSE, disconnectSSE } from "./notificationHelper";

const useVisibilityChange = (url: string, dispatch: AppDispatch) => {
  useEffect(() => {
    let visibilityTimeout: NodeJS.Timeout | null = null;

    const handleVisibilityChange = () => {
      if (document.hidden) {
        visibilityTimeout = setTimeout(() => {
          disconnectSSE();
        }, 30000); // Delay disconnection by 30 seconds
      } else {
        if (visibilityTimeout) {
          clearTimeout(visibilityTimeout);
          visibilityTimeout = null;
        }
        privateConnectSSE(url, dispatch);
      }
    };

    const handleFocus = () => {
      if (visibilityTimeout) {
        clearTimeout(visibilityTimeout);
        visibilityTimeout = null;
      }
      privateConnectSSE(url, dispatch);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("focus", handleFocus);

    return () => {
      if (visibilityTimeout) {
        clearTimeout(visibilityTimeout);
      }
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("focus", handleFocus);
    };
  }, [url, dispatch]);
};

export default useVisibilityChange;
