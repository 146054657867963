import React from "react";
import { SSE } from "sse.js";
import { getLocalStorage } from "../localStore";
import { notification, message } from "antd";
import { Notification } from "../types/notification";
import { AppDispatch } from "../../redux/store";
import { fetchUnreadCount } from "../../redux/actions/notificationsAction";

export const baseUrl: string = process.env.REACT_APP_BASE_URL!;

let sseInstance: SSE | null = null;
// To connect to the SSE Server
export const privateConnectSSE = async (url: string, dispatch: AppDispatch) => {
  return new Promise((resolve, reject) => {
    const token = getLocalStorage("accessToken");
    const expireAt = parseInt(getLocalStorage("expireAt")) || 0;

    if (!token || expireAt - new Date().getTime() < 0) {
      message.error("Session expired, please login again", 6);
      reject({ message: "Session expired, please login again" });
      return;
    }

    // If SSE already connected, skip duplicate connection.
    if (sseInstance) {
      return;
    }

    const sse = new SSE(`${baseUrl}${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-service-call": "notifications",
      },
      withCredentials: false,
    });

    sseInstance = sse;

    // SSE connection established successfully
    sse.addEventListener("open", () => {
      resolve("Connected");
    });

    sse.addEventListener("message", (event: any) => {
      const notificationData = JSON.parse(event.data);
      if (notificationData.message !== "SSE connection established") {
        dispatch(fetchUnreadCount());

        let notificationMethod;
        switch (notificationData.type) {
          case "success":
            notificationMethod = notification.success;
            break;
          case "error":
            notificationMethod = notification.error;
            break;
          case "warning":
            notificationMethod = notification.warning;
            break;
          case "info":
          default:
            notificationMethod = notification.info;
            break;
        }

        notificationMethod({
          message: (
            <div className={"sse-notification"}>
              <span>{notificationData.message}</span>
            </div>
          ),
          description: notificationData.metadata.startDate
            ? `${notificationData.metadata.description} ${new Date(
                notificationData.metadata.startDate
              ).toLocaleString()}.`
            : notificationData.metadata.description,
          duration: 6,
        });
      }
    });

    // SSE Error
    sse.addEventListener("error", (error: string) => {
      sse.close();
      sseInstance = null;
      setTimeout(() => privateConnectSSE(url, dispatch), 5000); // Retry connection after 5 seconds
    });
  });
};

// To disconnect from the SSE Server
export const disconnectSSE = () => {
  if (sseInstance) {
    sseInstance.close();
    sseInstance = null;
  }
};

// Used to group notifications and add timeAgo to the notification
export const addTimestamp = (notifications: Notification[]): Notification[] => {
  notifications.forEach((notification) => {
    const createdAt = new Date(notification.createdAt || notification.timestamp);
    const now = new Date();
    const diffSeconds = Math.floor((now.getTime() - createdAt.getTime()) / 1000);

    let timeAgo = "";
    if (diffSeconds < 60) {
      timeAgo = `${diffSeconds} second${diffSeconds === 1 ? "" : "s"} ago`;
    } else if (diffSeconds < 3600) {
      const minutes = Math.floor(diffSeconds / 60);
      timeAgo = `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
    } else if (diffSeconds < 86400) {
      const hours = Math.floor(diffSeconds / 3600);
      timeAgo = `${hours} hour${hours === 1 ? "" : "s"} ago`;
    } else if (diffSeconds < 604800) {
      const days = Math.floor(diffSeconds / 86400);
      timeAgo = `${days} day${days === 1 ? "" : "s"} ago`;
    } else if (diffSeconds < 2592000) {
      const weeks = Math.floor(diffSeconds / 604800);
      timeAgo = `${weeks} week${weeks === 1 ? "" : "s"} ago`;
    } else {
      const months = Math.floor(diffSeconds / 2592000);
      timeAgo = `${months} month${months === 1 ? "" : "s"} ago`;
    }

    notification.timeAgo = timeAgo;
  });

  return notifications.sort(
    (a, b) =>
      new Date(b.createdAt || b.timestamp).getTime() -
      new Date(a.createdAt || a.timestamp).getTime()
  );
};
