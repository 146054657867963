import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Typography,
  Divider,
  Row,
  Col,
  Table,
  notification,
  message,
} from "antd";
import logo1 from "../../assets/logo-text-color.png";
import { FormInstance } from "antd/lib/form";
import dayjs from "dayjs";

const { Title } = Typography;

interface PageContent {
  title: string;
  content: JSX.Element;
}

const DeclarationPreview: React.FC<{
  onSubmit: (values: any) => void;
  form: FormInstance;
  fieldLock: any;
}> = (props) => {
  const [visible, setVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [formData, setFormData] = useState<any>(null);

  useEffect(() => {
    setFormData(props.form.getFieldsValue());
  }, [visible]);

  const itemColumns = [
    {
      title:
        formData?.productDeclarationType === "Single"
          ? "Product Name"
          : "SubProduct Name",
      dataIndex: "name",
      key: "name",
      render: (text: string) =>
        formData?.productDeclarationType === "Single"
          ? formData?.requesterInformation?.itemName
          : text,
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
      render: (text: string) =>
        formData?.productDeclarationType === "Single"
          ? formData?.supplierInformation?.weight
          : text,
    },
    {
      title: "UoM",
      dataIndex: "uom",
      key: "uom",
      render: (text: string) =>
        formData?.productDeclarationType === "Single"
          ? formData?.supplierInformation?.uom
          : text,
    },
    {
      title: "Unit Type",
      dataIndex: "unitType",
      key: "unitType",
      render: (text: string) =>
        formData?.productDeclarationType === "Single"
          ? formData?.supplierInformation?.unitType
          : text,
    },
  ];

  const materialColumns = [
    { title: "Material Name", dataIndex: "name", key: "name" },
    { title: "Weight", dataIndex: "weight", key: "weight" },
    { title: "UoM", dataIndex: "uom", key: "uom" },
    { title: "Level", dataIndex: "level", key: "level" },
    {
      title: "Substance Category",
      dataIndex: "substanceCategory",
      key: "substanceCategory",
    },
  ];

  const substanceColumns = [
    { title: "Substance Name", dataIndex: "substance", key: "name" },
    { title: "Weight", dataIndex: "weight", key: "weight" },
    { title: "CAS", dataIndex: "cas", key: "cas" },
    { title: "Exempt", dataIndex: "exempt", key: "exempt" },
    { title: "UoM", dataIndex: "uom", key: "uom" },
    {
      title: "Tolerance-",
      dataIndex: "toleranceM",
      key: "toleranceM",
    },
    {
      title: "Tolerance+",
      dataIndex: "toleranceP",
      key: "toleranceP",
    },
    { title: "PPM", dataIndex: "ppm", key: "ppm" },
  ];

  const pages: PageContent[] = [
    {
      title: "Page 1",
      content: (
        <div style={{ overflow: "auto" }}>
          <Divider style={{ borderColor: "#F7863E" }} orientation="left">
            Business Information
          </Divider>
          <Row>
            <Col span={12}>
              <b>Form Type :</b> {formData?.formType ? formData.formType : ""}
            </Col>
            <Col span={12}>
              <b>Declaration Class :</b>{" "}
              {formData?.declarationClass ? formData.declarationClass : ""}
            </Col>
          </Row>
          {formData?.formType && formData?.formType === "Request/Reply" && (
            <>
              <Divider style={{ borderColor: "#F7863E" }} orientation="left">
                Requester Information
              </Divider>
              <Row>
                <Col span={8}>
                  <b>Company Name : </b>
                  {formData?.requesterInformation?.companyName
                    ? formData?.requesterInformation?.companyName
                    : ""}
                </Col>
                <Col span={8}>
                  <b>Company Unique ID : </b>
                  {formData?.requesterInformation?.companyUniqueId
                    ? formData?.requesterInformation?.companyUniqueId
                    : ""}
                </Col>
                <Col span={8}>
                  <b> Unique ID Authority : </b>
                  {formData?.requesterInformation?.uniqueIdAuthority
                    ? formData?.requesterInformation?.uniqueIdAuthority
                    : ""}
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <b>Response Date : </b>
                  {formData?.requesterInformation?.responseDate
                    ? dayjs(
                        formData?.requesterInformation?.responseDate
                      ).format("YYYY-MM-DD")
                    : ""}{" "}
                </Col>
                <Col span={8}>
                  <b>Request Document ID : </b>
                  {formData?.requesterInformation?.requestDocumentId
                    ? formData?.requesterInformation?.requestDocumentId
                    : ""}
                </Col>
                <Col span={8}>
                  <b>Respond By Date : </b>
                  {formData?.requesterInformation?.respondByDate
                    ? dayjs(
                        formData?.requesterInformation?.respondByDate
                      ).format("YYYY-MM-DD")
                    : ""}
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <b>Contact Name : </b>
                  {formData?.requesterInformation?.contactName
                    ? formData?.requesterInformation?.contactName
                    : ""}
                </Col>
                <Col span={8}>
                  <b>Contact Title : </b>
                  {formData?.requesterInformation?.contactTitle
                    ? formData?.requesterInformation?.contactTitle
                    : ""}
                </Col>
                <Col span={8}>
                  <b>Contact Phone : </b>
                  {formData?.requesterInformation?.contactPhone
                    ? formData?.requesterInformation?.contactPhone
                    : ""}
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <b>Contact Email : </b>
                  {formData?.requesterInformation?.contactEmail
                    ? formData?.requesterInformation?.contactEmail
                    : ""}
                </Col>
                <Col span={16}>
                  <b>Requester Comments or URL for Additional Information : </b>
                  {formData?.requesterInformation?.requesterComments
                    ? formData?.requesterInformation?.requesterComments
                    : ""}
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <b>My Supplier ID : </b>
                  {formData?.requesterInformation?.mySupplierId
                    ? formData?.requesterInformation?.mySupplierId
                    : ""}
                </Col>
                <Col span={8}>
                  <b>File Type : </b>
                  {formData?.requesterInformation?.fileType
                    ? formData?.requesterInformation?.fileType
                    : ""}
                </Col>
                <Col span={8}>
                  <b>Destination - URL or Email Address : </b>
                  {formData?.requesterInformation?.destinationEmail
                    ? formData?.requesterInformation?.destinationEmail
                    : ""}
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <b>
                    Supplier provides Mfr Item Version & Manufacturing Site :{" "}
                  </b>
                  {formData?.requesterInformation
                    ?.mfrItemVersionManufacturingSite
                    ? formData?.requesterInformation
                        ?.mfrItemVersionManufacturingSite
                    : ""}
                </Col>
                <Col span={8}>
                  <b>Item Number : </b>
                  {formData?.requesterInformation?.itemNumber
                    ? formData?.requesterInformation?.itemNumber
                    : ""}{" "}
                </Col>
                <Col span={8}>
                  <b>Item Name : </b>
                  {formData?.requesterInformation?.itemName
                    ? formData?.requesterInformation?.itemName
                    : ""}{" "}
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <b>Mfr Item Version : </b>
                  {formData?.requesterInformation?.mfrItemVersion
                    ? formData?.requesterInformation?.mfrItemVersion
                    : ""}{" "}
                </Col>
                <Col span={8}>
                  <b>Manufacturing Site : </b>
                  {formData?.requesterInformation?.manufacturingSite
                    ? formData?.requesterInformation?.manufacturingSite
                    : ""}
                </Col>
              </Row>
            </>
          )}
          <Divider style={{ borderColor: "#F7863E" }} orientation="left">
            Supplier Information
          </Divider>
          <Row>
            <Col span={8}>
              <b>Company Name : </b>
              {formData?.supplierInformation?.companyName
                ? formData?.supplierInformation?.companyName
                : ""}
            </Col>
            <Col span={8}>
              <b>Company Unique ID : </b>
              {formData?.supplierInformation?.companyUniqueId
                ? formData?.supplierInformation?.companyUniqueId
                : ""}
            </Col>
            <Col span={8}>
              <b>Unique ID Authority : </b>
              {formData?.supplierInformation?.uniqueIdAuthority
                ? formData?.supplierInformation?.uniqueIdAuthority
                : ""}
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <b>Response Date : </b>
              {formData?.supplierInformation?.responseDate
                ? dayjs(formData?.supplierInformation?.responseDate).format(
                    "YYYY-MM-DD"
                  )
                : ""}
            </Col>
            <Col span={8}>
              <b>Response Document ID : </b>
              {formData?.supplierInformation?.responseDocumentId
                ? formData?.supplierInformation?.responseDocumentId
                : ""}
            </Col>
            <Col span={8}>
              <b>Contact Name : </b>
              {formData?.supplierInformation?.contactName
                ? formData?.supplierInformation?.contactName
                : ""}
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <b>Title Contact : </b>
              {formData?.supplierInformation?.titleContact
                ? formData?.supplierInformation?.titleContact
                : ""}
            </Col>
            <Col span={8}>
              <b>Phone Contact : </b>
              {formData?.supplierInformation?.phoneContact
                ? formData?.supplierInformation?.phoneContact
                : ""}
            </Col>
            <Col span={8}>
              <b>Email Contact : </b>
              {formData?.supplierInformation?.emailContact
                ? formData?.supplierInformation?.emailContact
                : ""}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>Authorised Representative : </b>
              {formData?.supplierInformation?.authorisedRepresentative
                ? formData?.supplierInformation?.authorisedRepresentative
                : ""}
            </Col>
            <Col span={12}>
              <b> Title Representative : </b>
              {formData?.supplierInformation?.titleRepresentative
                ? formData?.supplierInformation?.titleRepresentative
                : ""}
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <b>Phone Representative : </b>
              {formData?.supplierInformation?.phoneRepresentative
                ? formData?.supplierInformation?.phoneRepresentative
                : ""}
            </Col>
            <Col span={8}>
              <b>Requester Item Number : </b>
              {formData?.supplierInformation?.requesterItemNumber
                ? formData?.supplierInformation?.requesterItemNumber
                : ""}
            </Col>
            <Col span={8}>
              <b>Mfr Item Number : </b>
              {formData?.supplierInformation?.mfrItemNumber
                ? formData?.supplierInformation?.mfrItemNumber
                : ""}
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <b>Mfr Item Name : </b>
              {formData?.supplierInformation?.mfrItemName
                ? formData?.supplierInformation?.mfrItemName
                : ""}
            </Col>
            <Col span={8}>
              <b>Effective Date : </b>
              {formData?.supplierInformation?.effectiveDate
                ? dayjs(formData?.supplierInformation?.effectiveDate).format(
                    "YYYY-MM-DD"
                  )
                : ""}
            </Col>
            <Col span={8}>
              <b>Version : </b>
              {formData?.supplierInformation?.version
                ? formData?.supplierInformation?.version
                : ""}
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <b>Manufacturing Site : </b>
              {formData?.supplierInformation?.manufacturingSite
                ? formData?.supplierInformation?.manufacturingSite
                : ""}
            </Col>
            <Col span={8}>
              <b>Weight : </b>
              {formData?.supplierInformation?.weight
                ? formData?.supplierInformation?.weight
                : ""}
            </Col>
            <Col span={8}>
              <b>UoM : </b>
              {formData?.supplierInformation?.uom
                ? formData?.supplierInformation?.uom
                : ""}
            </Col>
            <Col span={8}>
              <b>Unit Type : </b>
              {formData?.supplierInformation?.unitType
                ? formData?.supplierInformation?.unitType
                : ""}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>Alternate Recommendation : </b>
              {formData?.supplierInformation?.alternateRecommendation
                ? formData?.supplierInformation?.alternateRecommendation
                : ""}
            </Col>
            <Col span={12}>
              <b>Alternate Item Comments : </b>
              {formData?.supplierInformation?.alternateItemComments
                ? formData?.supplierInformation?.alternateItemComments
                : ""}
            </Col>
          </Row>
          {formData?.declarationClass ===
            "Class 2 - RoHS Yes/No Mfg Process Information" ||
            (formData?.declarationClass ===
              "Class 6 - RoHS Yes/No Homogeneous Materials and Mfg Information" && (
              <>
                <Divider style={{ borderColor: "#F7863E" }} orientation="left">
                  Manufacturing Process Information
                </Divider>
                <Row>
                  <Col span={12}>
                    <b> Terminal Plating / Grid Array Material : </b>
                    {formData?.manufacturingProcessInformation
                      ?.gridArrayMaterial
                      ? formData?.manufacturingProcessInformation
                          ?.gridArrayMaterial
                      : ""}
                  </Col>{" "}
                  <Col span={12}>
                    <b>Terminal Base Alloy : </b>
                    {formData?.manufacturingProcessInformation
                      ?.terminalBaseAlloy
                      ? formData?.manufacturingProcessInformation
                          ?.terminalBaseAlloy
                      : ""}
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <b>J-STD-020 MSL Rating : </b>
                    {formData?.manufacturingProcessInformation?.mslRating
                      ? formData?.manufacturingProcessInformation?.mslRating
                      : ""}
                  </Col>{" "}
                  <Col span={12}>
                    <b>Peak Process Body Temperature - in Celsius : </b>
                    {formData?.manufacturingProcessInformation
                      ?.peakProcessBodyTemperature
                      ? formData?.manufacturingProcessInformation
                          ?.peakProcessBodyTemperature
                      : ""}
                  </Col>
                </Row>{" "}
                <Row>
                  <Col span={12}>
                    <b>Max Time at Peak Temperature - in Seconds : </b>
                    {formData?.manufacturingProcessInformation
                      ?.maxTimeAtPeakTemperature
                      ? formData?.manufacturingProcessInformation
                          ?.maxTimeAtPeakTemperature
                      : ""}
                  </Col>{" "}
                  <Col span={12}>
                    <b>Number of Reflow Cycles : </b>
                    {formData?.manufacturingProcessInformation
                      ?.numberOfReflowCycles
                      ? formData?.manufacturingProcessInformation
                          ?.numberOfReflowCycles
                      : ""}
                  </Col>
                </Row>
                <Row>
                  <b>Comments : </b>
                  {formData?.manufacturingProcessInformation?.comments
                    ? formData?.manufacturingProcessInformation?.comments
                    : ""}
                </Row>
              </>
            ))}
          <Divider style={{ borderColor: "#F7863E" }} orientation="left">
            RoHS Material Composition Declaration
          </Divider>
          <Row>
            <b>Declaration Type : </b>
            {formData?.declarationType ? formData?.declarationType : ""}
          </Row>
          <Row>
            <b> Description : </b>
            {formData?.declarationTypeDescription
              ? formData?.declarationTypeDescription
              : ""}
          </Row>
          <Row>
            <b> RoHS Declaration : </b>
            {formData?.rohsDeclaration ? formData?.rohsDeclaration : ""}
          </Row>
          <Row>
            <b>Excemption List Version - EL-2006/690/EC : </b>
            {formData?.exemptionListVersion?.map((value: any) => (
              <>{value}</>
            ))}
          </Row>
          <Row>
            <Col span={12}>
              <b>Supplier Acceptance :</b>
              {formData?.supplierAcceptance ? formData?.supplierAcceptance : ""}
            </Col>
            {/* <Col span={12}>
              <b>Declaration Signature : </b>
              {formData?.declarationSignature
                ? formData?.declarationSignature
                : ""}
            </Col> */}
          </Row>
        </div>
      ),
    },

    {
      title: "Page 2",
      content: (
        <div style={{ overflow: "auto" }}>
          <Divider style={{ borderColor: "#F7863E" }} orientation="left">
            Homogeneous Material Composition Declaration for Electronic Products
          </Divider>

          <Table
            columns={
              formData?.productDeclarationType === "Multiple"
                ? itemColumns
                : materialColumns
            }
            dataSource={
              formData?.productDeclarationType === "Multiple"
                ? formData?.items
                : formData?.items?.flatMap(
                    (item: { materials: any }) => item.materials
                  )
            }
            rowKey={
              formData?.productDeclarationType === "Multiple" ? "name" : "name"
            }
            expandable={
              formData?.productDeclarationType !== "Multiple"
                ? {
                    expandedRowRender: (record: any) => (
                      <Table
                        columns={substanceColumns}
                        dataSource={record.substances}
                        pagination={false}
                        rowKey="name"
                      />
                    ),
                  }
                : {
                    expandedRowRender: (record: any) => (
                      <Table
                        columns={materialColumns}
                        dataSource={record.materials}
                        pagination={false}
                        rowKey="name"
                        expandable={{
                          expandedRowRender: (material: any) => (
                            <Table
                              columns={substanceColumns}
                              dataSource={material.substances}
                              pagination={false}
                              rowKey="name"
                            />
                          ),
                        }}
                      />
                    ),
                  }
            }
          />
        </div>
      ),
    },
  ];
  const openNotificationWithErrors = (errors: string[]) => {
    {
      errors &&
        notification.error({
          message: "Validation Errors",
          description: (
            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
              <ul>
                {errors.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </div>
          ),
          duration: 0,
        });
    }
  };
  const calculateWeights = (data: any) => {
    const errors: string[] = [];
    if (data.items && data.items?.length > 0) {
      let totalItemWeight = 0;

      for (const item of data.items) {
        let totalMaterialWeight = 0;

        for (const material of item.materials) {
          let totalSubstanceWeight = 0;

          for (const substance of material.substances) {
            totalSubstanceWeight += convertToMg(
              substance.weight,
              substance.uom
            );
          }

          const materialWeight = convertToMg(material.weight, material.uom);
          if (totalSubstanceWeight.toFixed(9) !== materialWeight.toFixed(9)) {
            // message.error(
            //   `Substance weight does not match Material weight for ${material.name} its ${totalSubstanceWeight} for ${item.name}`
            // );
            // throw new Error(
            //   `Substance weight does not match Material weight for ${material.name} its ${totalSubstanceWeight}`
            // );
            errors.push(
              `Substance weight does not match Material weight for ${material.name}.`
            );
          }
          totalMaterialWeight += materialWeight;
        }
        if (data.productDeclarationType === "Multiple") {
          const itemWeight = convertToMg(item.weight, item.uom);
          if (totalMaterialWeight.toFixed(9) !== itemWeight.toFixed(9)) {
            // message.error(
            //   `Material weight does not match Item weight for ${item.name} its ${totalMaterialWeight}`
            // );
            // throw new Error(
            //   `Material weight does not match Item weight for ${item.name} its ${totalMaterialWeight}`
            // );
            errors.push(
              `Material weight does not match Item weight for ${item.name}.`
            );
          }

          totalItemWeight += convertToMg(item.weight, item.uom);
        } else {
          totalItemWeight = totalMaterialWeight;
        }
      }

      const totalProductWeight = convertToMg(
        data.supplierInformation.weight,
        data.supplierInformation.uom
      );

      if (totalItemWeight.toFixed(9) !== totalProductWeight.toFixed(9)) {
        // message.error(
        //   `Total SubProduct weight does not match Product weight. Current total is ${totalItemWeight}`
        // );
        // throw new Error(
        //   `Total SubProduct weight does not match Product weight. Current total is ${totalItemWeight}`
        // );
        errors.push(
          `Total SubProduct weight does not match Product weight. Current total is ${totalItemWeight} mg.`
        );
      }
      if (errors.length > 0) {
        notification.error({
          message: "Validation Errors",
          description: (
            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
              <ul>
                {errors.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </div>
          ),
          duration: 0,
        });

        throw new Error("Validation failed, see notification for details.");
      }
    }
    return null;
  };

  const convertToMg = (weight: number, unit: string): number => {
    const parsedWeight = weight;
    let weightInMg: number;
    switch (unit) {
      case "kg":
        weightInMg = parsedWeight * 1_000_000;
        break;
      case "g":
        weightInMg = parsedWeight * 1_000;
        break;
      case "mg":
        weightInMg = parsedWeight;
        break;
      default:
        message.error(`provide unit of measure: ${unit}.`);
        throw new Error(`provide unit of measure: ${unit}`);
    }

    return parseFloat(weightInMg.toFixed(9));
  };
  const handleButtonClick = async () => {
    try {
      await props.form.validateFields();
      setFormData(props.form.getFieldsValue());
      calculateWeights(props.form.getFieldsValue());
      setVisible(true);
    } catch (errorInfo: any) {
      const errorList = errorInfo?.errorFields?.map(
        (fieldError: any) => `${fieldError.name[0]}: ${fieldError.errors[0]}`
      );
      openNotificationWithErrors(errorList);
      // console.error(error);
    }
  };

  return (
    <>
      <Button
        style={{
          background: "#F7863E",
          color: "white",
          marginRight: 8,
          borderRadius: 0,
        }}
        onClick={handleButtonClick}
      >
        Validate & Preview
      </Button>
      <Modal
        open={visible}
        title={"Declaration Template"}
        centered
        style={{ height: 700 }}
        footer={[
          currentPage !== 0 && (
            <Button
              key="back"
              onClick={() => {
                setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
              }}
              style={{
                background: "#00516E",
                color: "white",
                borderRadius: 0,
              }}
              disabled={currentPage === 0}
            >
              Back
            </Button>
          ),
          currentPage < pages.length - 1 && (
            <Button
              key="next"
              type="primary"
              onClick={() => {
                setCurrentPage((prevPage) =>
                  Math.min(prevPage + 1, pages.length - 1)
                );
              }}
              style={{
                background: "#F7863E",
                color: "white",
                borderRadius: 0,
              }}
              disabled={currentPage === pages.length - 1}
            >
              Next Page
            </Button>
          ),
        ]}
        onCancel={() => {
          setVisible(false);
        }}
        width={800}
      >
        <div
          style={{
            margin: "40px",
            border: "1px solid lightgrey",
            padding: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ flex: "0 0 auto" }}>
              <img src={logo1} alt="Logo" style={{ height: "25px" }} />
            </div>
            <div
              style={{
                color: "#F7863E",
                fontSize: "15px",
              }}
            >{`0 ${currentPage + 1}`}</div>
          </div>
          <Title
            level={2}
            style={{
              color: "#F7863E",
              margin: 0,
              justifyContent: "center",
              display: "flex",
            }}
          >
            Acquis Compliance
          </Title>
          <Title
            level={5}
            style={{
              color: "#00516E",
              justifyContent: "center",
              display: "flex",
            }}
          >
            IPC 1752 A
          </Title>
          <div style={{ overflow: "auto", height: 500 }}>
            {pages[currentPage].content}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeclarationPreview;
