import React, { useEffect, useState } from "react";

import {
  ExportOutlined,
  LinkOutlined,
  DeleteOutlined,
  RollbackOutlined,
  EnterOutlined,
  PlusCircleOutlined,
  DownOutlined,
  SendOutlined,
  FileOutlined,
} from "@ant-design/icons";
import {
  Space,
  Typography,
  List,
  Row,
  Col,
  Card,
  Divider,
  Table,
  Tabs,
  Input,
  Menu,
  Dropdown,
  message,
  Tag,
  Tooltip,
} from "antd";
import type {
  TableProps,
  TablePaginationConfig,
  ColumnsType,
} from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";

import { useAppDispatch } from "../../../redux/hooks";
import {
  readCampaignInbox,
  updateCampaignInbox,
  deleteCampaignInbox,
} from "../../../redux/actions/CampaignManager/campaignManagerAction";
import ReadSupportingDocument from "../../../components/modals/ReadSupportingDocuments";
import ErrorBoundary from "../../../utils/errorBoundary";
import { InboxOutlined } from "@ant-design/icons";
import ComposeMailModal from "../../../components/modals/ComposeMailModal";

const { Text, Title } = Typography;

interface Inbox {
  read: boolean;
  _id: string;
  from: {
    name: string;
    address: string;
  };
  subject: string;
  date: string;
  attachments: any[];
  messageText: string;
  messageHtml: string;
  to?: string[];
}
const { Search } = Input;

const CampaignInbox: React.FC = () => {
  const dispatch = useAppDispatch();
  const [inbox, setInbox] = useState<any[]>([]);
  const [inboxCount, setInboxCount] = useState<number>(0);
  const [inboxMessage, setInboxMessage] = useState<Inbox>();

  const [loading, setLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<Inbox>>({});
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});

  const [activeTab, setActiveTab] = React.useState("all");
  const [filteredData, setFilteredData] = React.useState(inbox);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [type, setType] = useState("");
  const [replyTo, setReplyTo] = useState<any>(null);
  const [subject, setSubject] = useState("");
  const [activeFolder, setActiveFolder] = useState("inbox");
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [draftMessage, setDraftMessage] = useState<any>(null);
  const [attachedFiles, setAttachedFiles] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const handleTabChange = (key: any) => {
    setActiveTab(key);
  };

  const handleMessageSelection = async (value: any) => {
    if (value.read === false) {
      try {
        await dispatch(updateCampaignInbox({ id: value._id }));
        fetchData(undefined, value);
      } catch (error) {
        console.error("Error updating campaign inbox:", error);
      }
    } else {
      setInboxMessage(value);
    }
  };

  const handleSearch = (value: string) => {
    setSearchQuery(value);
    setTablePagination((prev) => ({ ...prev, current: 1 }))
    if (!value) {
      fetchData({ query: "" });
      setIsSearchActive(false);
    } else {
      setIsSearchActive(true);
      fetchData({ query: value });
    }
  };
  

  const handleMailDelete = async (value: any) => {
    try {
      await dispatch(deleteCampaignInbox({ id: value._id }));

      message.success("Mail deleted successfully.");

      fetchData();
    } catch (error) {
      message.error("Failed to delete mail. Please try again.");
    }
  };

  // const truncateMessage = (message: any, wordLimit = 9) => {
  //   console.log(message, wordLimit);
  //   const words = message.split(/\s+/);
  //   if (words.length <= wordLimit) {
  //     return message;
  //   }
  //   return words.slice(0, wordLimit).join(" ") + "...";
  // };

  const openModal = (mailType?: any) => {
    setType(mailType);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setReplyTo("");
    setType("");
    setSubject("");
  };

  const capitalize = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1);

  const folderIcons: Record<string, JSX.Element> = {
    inbox: <InboxOutlined style={{ fontSize: "18px" }} />,
    delete: <DeleteOutlined style={{ fontSize: "18px" }} />,
    draft: <FileOutlined style={{ fontSize: "18px" }} />,
    sent: <SendOutlined style={{ fontSize: "18px" }} />,
  };

  const columns: ColumnsType<Inbox> = [
    {
      title: (
        <div style={{ textAlign: "center" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "left",
              gap: "12px",
              marginBottom: "10px",
            }}
          >
            <Dropdown
              overlay={
                <Menu
                  onClick={({ key }) => setActiveFolder(key)}
                  items={["inbox", "sent", "draft", "delete"]
                    .filter((folder) => folder !== activeFolder)
                    .map((folder) => ({
                      key: folder,
                      label: (
                        <span
                          style={{
                            fontSize: "18px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {folderIcons[folder]}
                          <span style={{ fontSize: "16px", marginLeft: "4px" }}>
                            {capitalize(folder)}
                          </span>
                        </span>
                      ),
                    }))}
                />
              }
              trigger={["click"]}
              placement="bottomLeft"
              overlayStyle={{ marginLeft: "-14px" }}
            >
              <span
                style={{
                  cursor: "pointer",
                  fontSize: "18px",
                  fontWeight: "500",
                  color: "#333",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    fontSize: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {folderIcons[activeFolder]}
                </span>
                <span style={{ marginLeft: "3px" }}>
                  {capitalize(activeFolder)}
                </span>
                <DownOutlined style={{ fontSize: "20px", marginLeft: "6px" }} />
              </span>
            </Dropdown>

            <Input.Search
              placeholder="Search mail"
              style={{ width: 220 }}
              allowClear
              onSearch={handleSearch}
            />
            

            <Tooltip title="Compose Mail">
              <PlusCircleOutlined
                style={{
                  fontSize: "40px",
                  color: "white",
                  backgroundColor: "orange",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                onClick={() => openModal("compose")}
              />
            </Tooltip>
          </div>
          {isSearchActive && (
            <div
              style={{
                fontSize: "18px",
                fontWeight: "600",
                textAlign: "center",
                paddingBottom: "8px",
              }}
            >
              All Mail Boxes
            </div>
          )}
          {!isSearchActive && activeFolder === "inbox" && (
            <Tabs
              activeKey={activeTab}
              onChange={handleTabChange}
              centered
              tabBarStyle={{
                fontSize: "18px",
                fontWeight: "600",
                display: "flex",
                justifyContent: "center",
                gap: "20px",
                paddingBottom: "8px",
              }}
              size="large"
            >
              <Tabs.TabPane tab="All" key="all" />
              <Tabs.TabPane tab="Read" key="read" />
              <Tabs.TabPane tab="Unread" key="unread" />
            </Tabs>
          )}
        </div>
      ),
      dataIndex: "message",
      key: "message",
      width: 150,
      render: (_, record: any) => (
        <List.Item.Meta
          title={
            <Space
              align="start"
              size={0}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                position: "relative",
                padding: "10px 12px",
                backgroundColor: record.read ? "transparent" : "#f5f5f5",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              <Text
                style={{
                  fontWeight: record.read ? "400" : "700",
                  fontSize: "16px",
                  marginBottom: "4px",
                  color: record.read ? "#333" : "#000",
                }}
              >
                {record?.from?.name}
              </Text>

              <Text
                style={{
                  fontWeight: record.read ? "400" : "700",
                  fontSize: "14px",
                  marginBottom: "4px",
                }}
              >
                {new Date(record.date).toLocaleString()}
              </Text>

              <Text
                style={{
                  fontWeight: record.read ? "400" : "700",
                  fontSize: "16px",
                  marginBottom: "8px",
                  color: record.read ? "#333" : "#000",
                }}
              >
                <span>Subject:</span> {record.subject.replace(/^Re:\s*/, "")}
              </Text>

              {isSearchActive && (
                <Tag
                  color="orange"
                  style={{
                    fontSize: "12px",
                    padding: "4px 8px",
                    borderRadius: "12px",
                    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.2)",
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                  }}
                >
                  {record.folder}
                </Tag>
              )}
            </Space>
          }
          description={""}
        />
      ),
    },
  ];

  const onClickViewLink = (filename: string) => {
    window.open(filename, "_blank");
  };

  const fetchData = (search?: any, selected?: any) => {
    setLoading(true);
    const query = search?.query !== undefined ? search.query : searchQuery;
    const currentPage = search?.page !== undefined ? search.page : tablePagination.current;
    dispatch(
      readCampaignInbox({
        limit: tablePagination.pageSize,
        page: currentPage,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.order === "descend" ? false : true,
        softDelete: false,
        search: query,
        folder: activeFolder,
        activeTab: activeTab,
      })
    )
      .then((response: any) => {
        const inboxData = response?.inbox || [];
        setInbox(inboxData);

        setFilteredData(inboxData);
        if (selected) {
          setInboxMessage(selected);
        } else {
          setInboxMessage(undefined);
        }

        setInboxCount(response?.count);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleTableChange: TableProps<Inbox>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Inbox> | SorterResult<Inbox>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<Inbox>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      return;
    }
  };

  useEffect(() => {
    setInboxMessage(undefined);
    fetchData();
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
    activeTab,
    activeFolder,
    searchQuery
  ]);

  return (
    <div>
      <ErrorBoundary>
        <Row>
          <Col span={inboxMessage ? "7" : "24"} style={{ marginRight: "20px" }}>
            <Table
              loading={loading}
              dataSource={filteredData}
              scroll={{
                y: "calc(100vh - 285px)",
              }}
              columns={columns}
              rowKey="_id"
              pagination={{ ...tablePagination, total: inboxCount }}
              onChange={handleTableChange}
              onRow={(record) => ({
                onClick: () => handleMessageSelection(record),
              })}
            />
          </Col>
          <Col span={16} style={{ fontWeight: 400 }}>
            {inboxMessage && (
              <div>
                <Card
                  title={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        lineHeight: 1,
                      }}
                    >
                      <div style={{ padding: "15px 0" }}>
                        <Text
                          style={{
                            margin: 0,
                            display: "block",
                            fontWeight: 300,
                          }}
                        >
                          {inboxMessage?.from?.name}
                        </Text>
                        <Text style={{ margin: 0, display: "block" }}>
                          {inboxMessage?.subject.replace(/^Re:\s*/, "")}
                        </Text>
                        <Text
                          style={{
                            margin: 0,
                            display: "block",
                            fontWeight: 300,
                          }}
                        >
                          {activeFolder === "draft" ||
                          activeFolder === "sent" ||
                          activeFolder === "delete" ? (
                            Array.isArray(inboxMessage?.to) &&
                            inboxMessage?.to.length > 0 ? (
                              <>
                                <span>{inboxMessage?.to[0]}</span>
                                {inboxMessage?.to.length > 1 && (
                                  <Tooltip
                                    title={inboxMessage?.to.slice(1).join(", ")}
                                  >
                                    <span style={{ color: "white" }}>
                                      {" "}
                                      +{inboxMessage?.to.length - 1} more
                                    </span>
                                  </Tooltip>
                                )}
                              </>
                            ) : (
                              inboxMessage?.from?.address || ""
                            )
                          ) : (
                            inboxMessage?.from?.address || ""
                          )}
                        </Text>
                      </div>
                      <Space
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "right",
                        }}
                      >
                        <Text
                          style={{
                            margin: 0,
                            fontSize: "small",
                            color: "gray",
                            textAlign: "right",
                          }}
                        >
                          {new Date(inboxMessage?.date).toLocaleString()}
                        </Text>
                        {activeFolder !== "delete" && (
                          <Space>
                            {activeFolder !== "draft" && (
                              <Tooltip title="Reply">
                                <RollbackOutlined
                                  onClick={() => {
                                    if (activeFolder === "sent") {
                                      openModal("sent");
                                      setReplyTo(inboxMessage?.to);
                                      setSubject(inboxMessage?.subject);
                                    } else {
                                      openModal("reply");
                                      setReplyTo(inboxMessage?.from?.address);
                                      setSubject(inboxMessage?.subject);
                                    }
                                  }}
                                  style={{ marginLeft: "15px" }}
                                />
                              </Tooltip>
                            )}
                            <Tooltip title="Delete Mail">
                              <DeleteOutlined
                                onClick={() => handleMailDelete(inboxMessage)}
                                style={{ marginLeft: "15px" }}
                              />
                            </Tooltip>
                            {activeFolder === "draft" && (
                              <Tooltip title="Send Mail">
                                <SendOutlined
                                  style={{ marginLeft: "15px" }}
                                  onClick={() => {
                                    openModal("draft");
                                    setReplyTo(inboxMessage?.to);
                                    setSubject(inboxMessage?.subject);
                                    setDraftMessage(inboxMessage?.messageHtml);
                                    setAttachedFiles(inboxMessage?.attachments);
                                  }}
                                />
                              </Tooltip>
                            )}
                          </Space>
                        )}
                      </Space>
                    </div>
                  }
                  bordered={false}
                  style={{ height: "100%" }}
                >
                  <Space
                    align="end"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Title level={5}>
                      {"Subject: "}
                      {inboxMessage?.subject.replace(/^Re:\s*/, "")}{" "}
                    </Title>
                  </Space>

                  <Text>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: inboxMessage?.messageHtml,
                      }}
                    />
                  </Text>
                  {inboxMessage?.attachments?.length > 0 && (
                    <div
                      style={{
                        marginTop: "10px",
                        padding: "10px",
                        backgroundColor: "#f8f8f8",
                        borderRadius: "8px",
                        border: "1px solid #ddd",
                      }}
                    >
                      <h4
                        style={{
                          marginBottom: "8px",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        Attachments
                      </h4>
                      <div
                        style={{
                          marginTop: "5px",
                        }}
                      >
                        {inboxMessage?.attachments?.map((item, index) =>
                          item?.includes("http") ? (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "8px",
                              }}
                            >
                              <ExportOutlined
                                onClick={() => onClickViewLink(item)}
                                style={{ cursor: "pointer" }}
                              />
                              <span
                                onClick={() => onClickViewLink(item)}
                                style={{
                                  marginLeft: "8px",
                                  fontSize: "14px",
                                  color: "#1a73e8",
                                  cursor: "pointer",
                                }}
                                onMouseEnter={(e) => {
                                  const target = e.target as HTMLElement;
                                  target.style.textDecoration = "underline";
                                }}
                                onMouseLeave={(e) => {
                                  const target = e.target as HTMLElement;
                                  target.style.textDecoration = "none";
                                }}
                              >
                                {`Link ${index + 1}`}
                              </span>
                            </div>
                          ) : (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "8px",
                              }}
                            >
                              <ReadSupportingDocument
                                postUrl="campaignmanager/inbox/document/download"
                                filename={item}
                                foldername="campaign-inbox"
                              />
                              <span
                                style={{
                                  color: "#333",
                                  fontSize: "14px",
                                  marginLeft: "8px",
                                }}
                              >
                                {item}
                              </span>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )}
                </Card>
              </div>
            )}
          </Col>
        </Row>
      </ErrorBoundary>
      <ComposeMailModal
        isModalOpen={isModalOpen}
        openModal={openModal}
        closeModal={closeModal}
        type={type}
        replyTo={replyTo}
        subject={subject}
        message={draftMessage}
        attachedFiles={attachedFiles}
      />
    </div>
  );
};

export default CampaignInbox;
