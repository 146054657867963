import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  Modal,
  Input,
  Space,
  Select,
  Tag,
  message,
  Spin,
  Tooltip,
} from "antd";
import { Editor } from "@tinymce/tinymce-react";
import {
  BoldOutlined,
  ItalicOutlined,
  PaperClipOutlined,
  AlignLeftOutlined,
  AlignCenterOutlined,
  AlignRightOutlined,
  UnderlineOutlined,
} from "@ant-design/icons";
import {
  sendMail,
  draftCampaignInbox,
} from "../../redux/actions/CampaignManager/campaignManagerAction";
import { useAppDispatch } from "../../redux/hooks";
import { getLocalStorage } from "../../utils/localStore";
import FileUploader from "../layouts/FileUploader";

const { Option } = Select;

const ComposeMailModal: React.FC<{
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  type: string;
  replyTo: any;
  subject: string;
  message: any;
  attachedFiles: any;
}> = (props) => {
  const editorRef = useRef<any>(null);

  const verifiedEmail = getLocalStorage("user").authorisedSender;

  const [from, setFrom] = useState<string>(verifiedEmail || "");
  const [emails, setEmails] = useState<string[]>([]);
  const [allEmails, setAllEmails] = useState<string[]>([]);
  const [subject, setSubject] = useState<string>("");
  const [attachedFiles, setAttachedFiles] = React.useState<string[]>([]);

  const [fileLoader, setFileLoader] = useState<boolean>(false);
  const { tenantNumber } = getLocalStorage("user");

  const dispatch = useAppDispatch();
  useEffect(() => {
    const savedEmails = (() => {
      try {
        return JSON.parse(localStorage.getItem("emailHistory") || "[]");
      } catch (error) {
        console.error("Error parsing email history:", error);
        return [];
      }
    })();

    setAllEmails(savedEmails);

    const { type, replyTo, subject, attachedFiles, message } = props;

    const setEditorContent = (content: string) => {
      if (editorRef.current) {
        editorRef.current.setContent(content);
      }
    };

    if (type === "draft") {
      setEmails(replyTo);
      setSubject(subject);
      setAttachedFiles(attachedFiles);
      setEditorContent(message || "");
    } else if (type === "reply" && replyTo) {
      setEmails([replyTo]);
      setSubject(subject);
      setEditorContent("");
    } else if (type === "compose") {
      setEmails([]);
      setSubject("");
      setEditorContent("");
    } else if (type === "sent") {
      setEmails(replyTo);
      setSubject("Re: " + subject);
      setEditorContent("");
    }
  }, [
    props.type,
    props.replyTo,
    props.message,
    props.subject,
    props.attachedFiles,
  ]);

  const handleModalClose = () => {
    setAttachedFiles([]);
    props.closeModal();
  };

  const handleFormat = (command: string, value?: string) => {
    if (editorRef.current) {
      editorRef.current.execCommand(command, false, value);
    }
  };

  const handleDraftMail = () => {
    const messageContent = editorRef.current?.getContent() || "";

    if (!from) {
      message.error("The 'From' field is required.");
      return;
    }

    if (emails.length === 0) {
      message.error("Please add at least one recipient in the 'To' field.");
      return;
    }

    const invalidEmails = emails.filter((email) => !isValidEmail(email));
    if (invalidEmails.length > 0) {
      message.error(`Invalid email address(es): ${invalidEmails.join(", ")}`);
      return;
    }

    if (!subject.trim()) {
      message.error("The 'Subject' field cannot be empty.");
      return;
    }

    if (!messageContent.trim()) {
      message.error("The email body cannot be empty.");
      return;
    }

    const emailData = {
      from,
      to: emails,
      subject,
      message: messageContent,
      file: attachedFiles,
    };

    dispatch(draftCampaignInbox(emailData))
      .then((response) => {
        message.success("Draft saved successfully.");
      })
      .catch((error) => {
        console.error("Error saving draft:", error);
        message.error("Failed to save draft. Please try again.");
      });
    handleModalClose();
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    try {
      const messageContent = editorRef.current?.getContent() || "";

      if (!from) {
        message.error("The 'From' field is required.");
        return;
      }

      if (emails.length === 0) {
        message.error("Please add at least one recipient in the 'To' field.");
        return;
      }

      const invalidEmails = emails.filter((email) => !isValidEmail(email));
      if (invalidEmails.length > 0) {
        message.error(`Invalid email address(es): ${invalidEmails.join(", ")}`);
        return;
      }

      if (!subject.trim()) {
        message.error("The 'Subject' field cannot be empty.");
        return;
      }

      if (!messageContent.trim()) {
        message.error("The email body cannot be empty.");
        return;
      }

      const emailData = {
        from,
        to: emails,
        subject,
        message: messageContent,
        file: attachedFiles,
      };

      const loadingKey = "sendingMail";
      message.loading({
        content: "Sending mail...",
        key: loadingKey,
        duration: 0,
      });
      await dispatch(sendMail(emailData));

      message.success({ content: "Mail Sent Successfully", key: loadingKey });

      const updatedEmails = Array.from(new Set([...allEmails, ...emails]));
      localStorage.setItem("emailHistory", JSON.stringify(updatedEmails));
      setAllEmails(updatedEmails);

      setEmails([]);
      setSubject("");
      if (editorRef.current) editorRef.current.setContent("");

      handleModalClose();
    } catch (error) {
      console.error("Error sending email:", error);
      message.error({
        content: "Failed to send email. Please try again.",
        key: "sendingMail",
      });
    }
  };

  return (
    <Modal
      open={props.isModalOpen}
      onCancel={handleModalClose}
      centered
      width={650}
      title={
        props.type === "draft"
          ? "Edit Draft"
          : props.type === "reply"
          ? "Reply to Email"
          : "New Email"
      }
      maskClosable={false}
      footer={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <Tooltip title="Align Left">
              <Button
                icon={<AlignLeftOutlined />}
                onClick={() => handleFormat("JustifyLeft")}
              />
            </Tooltip>

            <Tooltip title="Align Center">
              <Button
                icon={<AlignCenterOutlined />}
                onClick={() => handleFormat("JustifyCenter")}
              />
            </Tooltip>

            <Tooltip title="Align Right">
              <Button
                icon={<AlignRightOutlined />}
                onClick={() => handleFormat("JustifyRight")}
              />
            </Tooltip>

            <Tooltip title="Italic">
              <Button
                icon={<ItalicOutlined />}
                onClick={() => handleFormat("Italic")}
              />
            </Tooltip>

            <Tooltip title="Bold">
              <Button
                icon={<BoldOutlined />}
                onClick={() => handleFormat("Bold")}
              />
            </Tooltip>

            <Tooltip title="Underline">
              <Button
                icon={<UnderlineOutlined />}
                onClick={() => handleFormat("Underline")}
              />
            </Tooltip>
            <FileUploader
              postUrl="campaignmanager/maildocument/upload"
              tenantNumber={tenantNumber}
              additionalData={{
                foldername: "campaign-inbox",
                setAttachedFiles: (fileName: string) =>
                  setAttachedFiles((prevFiles) => [...prevFiles, fileName]),
              }}
              onUploadStatusChange={(status) => {
                setFileLoader(status);
              }}
              type="icon"
              buttonIcon={true}
            />
          </div>
          <Space>
            {props.type !== "reply" && (
              <Button onClick={handleDraftMail}>Save Draft</Button>
            )}
            <Button type="primary" onClick={handleSubmit}>
              Send
            </Button>
          </Space>
        </div>
      }
    >
      <div style={{ marginBottom: "16px" }}>
        <Input
          placeholder="From"
          value={from}
          onChange={(e) => setFrom(e.target.value)}
          disabled={true}
        />
      </div>
      <div style={{ marginBottom: "16px" }}>
        <Select
          mode="tags"
          placeholder="To"
          tokenSeparators={[","]}
          value={emails}
          onChange={(newEmails) => {
            if (newEmails.length > 10) {
              message.warning("You can only add up to 10 emails.");
            } else {
              setEmails(newEmails);
            }
          }}
          style={{ width: "100%" }}
        >
          {allEmails.map((email) => (
            <Option key={email} value={email}>
              <Tag>{email}</Tag>
            </Option>
          ))}
        </Select>
      </div>
      <div style={{ marginBottom: "16px" }}>
        <Input
          placeholder="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
      </div>
      <Editor
        apiKey="lc2q65m1dbclf7a2wtial0i5j4fix95ualjrk6angeytzprr"
        onInit={(evt, editor) => (editorRef.current = editor)}
        init={{
          height: 200,
          menubar: false,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar: false,
        }}
      />
      <div style={{ marginTop: "16px" }}>
        {fileLoader && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              marginBottom: "16px",
            }}
          >
            <Spin size="small" />
            <p style={{ margin: 0, color: "#888", fontStyle: "italic" }}>
              Attaching file(s)...
            </p>
          </div>
        )}
        {attachedFiles.length > 0 && (
          <div style={{ marginTop: "16px" }}>
            <p style={{ marginBottom: "8px", fontWeight: "bold" }}>
              Attached Files:
            </p>
            <ul style={{ padding: 0, listStyle: "none" }}>
              {attachedFiles.map((file, index) => (
                <li
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "8px 12px",
                    marginBottom: "8px",
                    border: "1px solid #d9d9d9",
                    borderRadius: "8px",
                    background: "#f5f5f5",
                  }}
                >
                  <span style={{ fontSize: "14px", color: "#333" }}>
                    {file}
                  </span>
                  <Button
                    type="text"
                    danger
                    size="small"
                    onClick={() =>
                      setAttachedFiles((prevFiles) =>
                        prevFiles.filter((_, i) => i !== index)
                      )
                    }
                  >
                    Remove
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ComposeMailModal;
