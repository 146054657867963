// This component is a drawer that displays the notification history in 2 tabs, "All" & "Unread", using the NotificationList component

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";
import {
  fetchNotificationHistory,
  markNotificationsAsRead,
} from "../../../redux/actions/notificationsAction";
import { Tabs, Drawer, Button, Spin } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import NotificationList from "./NotificationList";
import { NotificationDrawerType } from "../../../utils/types/notification";
import { getLocalStorage } from "../../../utils/localStore";

const NotificationDrawer: React.FC<NotificationDrawerType> = ({
  isDrawerOpen,
  setIsDrawerOpen,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { notifications, unreadCount, loadingNotifs } = useSelector(
    (state: RootState) => state.notifications
  );
  const [activeTab, setActiveTab] = useState<string>("all");
  const [hasMore, setHasMore] = useState<boolean>(true); // To track if there are more notifications to load
  const [page, setPage] = useState<number>(1); // To track the current page
  const user = getLocalStorage("user");

  // Fetch the first page of notifications when drawer is opened
  useEffect(() => {
    if (isDrawerOpen) {
      dispatch(fetchNotificationHistory(1));
      setHasMore(true); // Reset hasMore state when drawer is opened
      setPage(1); // Reset page state when drawer is opened
    }
  }, [isDrawerOpen, dispatch]);

  const handleRefresh = () => {
    dispatch(fetchNotificationHistory(1));
    setHasMore(true); // Reset hasMore state when refreshing
    setPage(1); // Reset page state when refreshing
  };

  const handleTabChange = (key: string) => {
    if (activeTab === "unread" && key === "all") {
      const unreadNotificationIds = notifications
        .filter((n) => !n.readBy.includes(user.emailAddress))
        .map((n) => n._id);
      if (unreadNotificationIds.length > 0) {
        dispatch(markNotificationsAsRead(unreadNotificationIds));
      }
    }
    setActiveTab(key);
    setHasMore(true); // Reset hasMore state when changing tabs
    setPage(1); // Reset page state when changing tabs
  };

  const handleClose = () => {
    if (activeTab === "unread") {
      const unreadNotificationIds = notifications
        .filter((n) => !n.readBy.includes(user.emailAddress))
        .map((n) => n._id);
      if (unreadNotificationIds.length > 0) {
        dispatch(markNotificationsAsRead(unreadNotificationIds));
      }
    }
    setIsDrawerOpen(false);
    setHasMore(true); // Reset hasMore state when closing the drawer
    setPage(1); // Reset page state when closing the drawer
  };

  const tabs = [
    {
      key: "all",
      label: `All`,
      children: (
        <NotificationList
          notifications={notifications}
          setIsDrawerOpen={setIsDrawerOpen}
          hasMore={hasMore}
          setHasMore={setHasMore}
          page={page}
          setPage={setPage}
        />
      ),
    },
    {
      key: "unread",
      label: `Unread (${unreadCount})`,
      children: (
        <NotificationList
          notifications={notifications.filter((n) => !n.readBy.includes(user.emailAddress))}
          setIsDrawerOpen={setIsDrawerOpen}
          hasMore={hasMore}
          setHasMore={setHasMore}
          page={page}
          setPage={setPage}
        />
      ),
    },
  ];

  return (
    <Drawer
      title="Notifications"
      placement="right"
      onClose={handleClose}
      open={isDrawerOpen}
      size={"large"}
      extra={
        <Button icon={<ReloadOutlined />} onClick={handleRefresh}>
          Refresh
        </Button>
      }
      styles={{
        body: {
          padding: "0px 12px 10px 12px",
          overflow: "auto",
        },
      }}
    >
      <Spin spinning={loadingNotifs}>
        <Tabs activeKey={activeTab} onChange={handleTabChange} items={tabs} />
      </Spin>
    </Drawer>
  );
};

export default NotificationDrawer;
