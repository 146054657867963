import React, { useState, useEffect } from "react";
import { Space, Typography, Button, Table, Input, Popconfirm, Tag } from "antd";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  bulkActionCampaignResponse,
  readCampaignResponses,
} from "../../../../redux/actions/CampaignManager/campaignManagerAction";
import { getRegulationsFromLocalStorage } from "../../../../utils/localStore";
import ErrorBoundary from "../../../../utils/errorBoundary";
import PendingApprovalSubTable from "./ExpandRejectedResponse";
import { SupplierResponse } from "../../../../utils/types/CampaignManager/campaignManager";
import { ReactComponent as RevertIcon } from "../../../../assets/icons/reply icon.svg";

const { Search } = Input;
const { Text } = Typography;

const CampaignManagerRejectedResponse: React.FC = () => {
  const dispatch = useAppDispatch();
  const [rejectedResponse, setRejectedResponse] = useState<SupplierResponse[]>(
    []
  );
  const [RejectedResponseCount, setRejectedResponseCount] = useState<number>(0);

  const [loading, setLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<
    SorterResult<SupplierResponse>
  >({});
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});
  const [search, setSearch] = useState<any>();

  const handleRevert = (record?: any) => {
    dispatch(
      bulkActionCampaignResponse({
        filter: {
          manufacturer: record.manufacturer,
          sendgridCampaignId: record.sendgridCampaignId,
          status: "rejected",
        },
        submitType: "bulk",
        updates: {
          type: "pending",
        },
        responseType: record.regulation,
      })
    ).then(() => {
      handleReload();
    });
  };

  const handleReload = () => {
    const pageSize = tablePagination.pageSize ?? 10;
    const currentPage = tablePagination.current ?? 1;
    const newTotal = RejectedResponseCount - 1;
    const lastPage = Math.ceil(newTotal / pageSize);
    const newPage = currentPage > lastPage ? lastPage : currentPage;
    fetchData(newPage, pageSize);
  };

  const columns: ColumnsType<SupplierResponse> = [
    {
      title: "Action",
      key: "operation",
      width: 100,
      render: (text: any, record: any) => (
        <Space style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Popconfirm
            title="Revert back to Pending Approval"
            description={`Are you sure want to revert ${record.manufacturer} and ${record.regulation} back to Pending Approval?`}
            onConfirm={() => handleRevert(record)}
            okText="Yes"
            cancelText="Cancel"
            overlayStyle={{ width: 300 }}
          >
            <Button
              type="text"
              icon={<RevertIcon style={{ width: 20, height: 20 }} />}
              title="Revert back to Pending Approval"
            />
          </Popconfirm>
        </Space>
      ),
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
      sorter: true,
      width: 150,
      render: (text: string, record: any) => (
        <Space>
          <Tag bordered={false} color="processing">
            {record?.totalComponents}
          </Tag>
          <Text>{text}</Text>
        </Space>
      ),
    },
    {
      title: "Campaign Name",
      dataIndex: "campaign",
      key: "campaign",
      sorter: true,
      width: 150,
    },
    {
      title: "Regulation",
      dataIndex: "regulation",
      key: "regulation",
      sorter: true,
      width: 150,
      render: (value: string[], record: any) => {
        if (record.regulation !== "Material" || record.regulation !== "Scip") {
          const regulations = getRegulationsFromLocalStorage();
          const regulationData = value
            ? regulations?.find(
                (regulation: any) => regulation.regulationNumber === value
              )?.name || value
            : "";
          return <Text>{regulationData}</Text>;
        } else {
          <Text>{value}</Text>;
        }
      },
    },
    {
      title: "Rejected",
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: 150,
      render: (text: string, record: any) => (
        <Text>
          {text ? new Date(text).toLocaleString() : ""}
          <br />
          {record?.updatedBy}
        </Text>
      ),
    },
  ].filter(Boolean);

  const onSearch = (value: any) => {
    setSearch({
      query: value?.key === "Enter" ? value?.target?.value : value,
    });
    setTablePagination({
      pageSize: tablePagination.pageSize,
      current: 1,
    });
  };

  const fetchData = (pageNum?: number, pageSize?: number) => {
    setLoading(true);
    dispatch(
      readCampaignResponses({
        limit: pageSize ? pageSize : tablePagination.pageSize,
        page: pageNum ? pageNum : tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.order === "descend" ? false : true,
        softDelete: false,
        search: search?.query ? search?.query : "",
        statusType: "rejected",
      })
    )
      .then((response: any) => {
        setRejectedResponse(response?.supplierResponse || []);
        setRejectedResponseCount(response?.count || 0);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleTableChange: TableProps<SupplierResponse>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<SupplierResponse> | SorterResult<SupplierResponse>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<SupplierResponse>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      return;
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
    search,
  ]);

  return (
    <div>
      <ErrorBoundary>
        <Space
          align="end"
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "1rem",
          }}
        >
          <Space>
            <Search
              placeholder="Search using manufacturer name text"
              onSearch={onSearch}
              onPressEnter={onSearch}
              allowClear
              style={{ width: 350 }}
            />
          </Space>
        </Space>
        <Table
          loading={loading}
          dataSource={rejectedResponse}
          scroll={{
            x: 1000,
            y: "calc(100vh - 285px)",
          }}
          columns={columns}
          pagination={{
            ...tablePagination,
            total: RejectedResponseCount,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (totalCount) => `Total  ${totalCount}  items`,
          }}
          expandable={{
            expandedRowRender: (record) => (
              <PendingApprovalSubTable
                formData={record}
                fetchData={fetchData}
              />
            ),
          }}
          rowKey={(record) =>
            `${record.manufacturer}+${record.sendgridCampaignId}+${
              record?.regulation || ""
            }`
          }
          onChange={handleTableChange}
        />
      </ErrorBoundary>
    </div>
  );
};

export default CampaignManagerRejectedResponse;
