// This component is a button that displays a bell icon with a badge displaying the number of unread notifications.

import React from "react";
import { BellOutlined } from "@ant-design/icons";
import { Badge, Button, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { NotificationBellType } from "../../../utils/types/notification";

const NotificationBell: React.FC<NotificationBellType> = ({ setIsDrawerOpen }) => {
  const { unreadCount } = useSelector((state: RootState) => state.notifications);

  return (
    <Badge count={unreadCount}>
      <Tooltip title="Notifications">
        <Button
          type="text"
          icon={<BellOutlined style={{ color: "#fff", fontSize: 20 }} />}
          onClick={() => setIsDrawerOpen(true)}
          style={{ display: "flex" }}
        />
      </Tooltip>
    </Badge>
  );
};

export default NotificationBell;
