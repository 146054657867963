import React from "react";
import { Modal, Input, Button, message } from "antd";

interface FeedbackModalProps {
  isModalVisible: boolean;
  handleReject: (feedback?: any) => void;
  closeModal: () => void;
}

const RejectModal: React.FC<FeedbackModalProps> = ({
  isModalVisible,
  handleReject,
  closeModal,
}) => {
  const [feedback, setFeedback] = React.useState("");

  const onSubmit = () => {
    if (feedback.trim() === "") {
      message.warning("Feedback cannot be empty.");
      return;
    }
    handleReject(feedback);
    setFeedback("");
  };
  const onSubmitWithoutFeeback = () => {
    handleReject();
    setFeedback("");
  };

  const onClose = () => {
    closeModal();
  };

  return (
    <Modal
      title="Feedback for Rejection"
      visible={isModalVisible}
      onCancel={onClose}
      centered
      cancelButtonProps={{
        style: { backgroundColor: "#001d66", color: "white", border: "none" },
      }}
      footer={[
        <Button style={{background:"#003366",color:"white"}} key="Reject without Feedback" onClick={onSubmitWithoutFeeback}>
          Reject without Feedback
        </Button>,
         <Button type="primary" key="Submit" onClick={onSubmit}>
         Submit
       </Button>,
      ]}
    >
      <Input.TextArea
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
        placeholder="Write your feedback here..."
        rows={4}
      />
    </Modal>
  );
};

export default RejectModal;
