import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Drawer,
  Space,
  Row,
  Col,
  Select,
  Form,
  InputNumber,
  Input,
  TabsProps,
  Tabs,
  Card,
  message,
} from "antd";
import Icon from "./../../Icon";
import Checkbox from "antd/es/checkbox/Checkbox";
import {
  CloseOutlined,
  CodeSandboxOutlined,
  EditOutlined,
  PlusOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Component } from "../../utils/types/component";
import { useAppDispatch } from "../../redux/hooks";
import { updateComponentFMD } from "../../redux/actions/componentsAction";
import FileUploader from "../layouts/FileUploader";
import { getLocalStorage } from "../../utils/localStore";
import TextArea from "antd/es/input/TextArea";

const EditMaterialDisclosureComponents: React.FC<{
  type: any;
  formData: any;
  fetchData: any;
  editPage?: string;
}> = (props) => {
  const [componentData, setcomponentData] = useState<any>(
    props?.formData?.component
  );
  const [initialMaterialData, setinitialMaterialData] = useState<any>(
    props?.formData?.component?.materials
      ? props?.formData?.component?.materials
      : []
  );
  const { tenantNumber } = getLocalStorage("user");
  const [isUploading, setIsUploading] = useState(false);
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    form.resetFields();
    setOpen(false);
    setActiveTab("Component");
    setselectedSubstanceData(null);
    setDisabledFields({});
  };
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const onFinish = (values: any) => {
    setLoading(true);
    handleFormValuesChange(formValues);

    let updatedData;
    if (props?.type === "Material") {
      updatedData = {
        ...componentData,
        materials: componentData.materials
          ? [...componentData.materials, values]
          : [values],
      };
    } else if (props?.type === "Substance") {
      updatedData = {
        ...componentData,
        materials: componentData.materials?.map(
          (material: { name: any; substances: any }) => {
            if (material.name === props.formData?.material?.name) {
              return {
                ...material,
                substances: material?.substances
                  ? [...material.substances, values]
                  : [values],
              };
            }
            return material;
          }
        ),
      };
    } else {
      updatedData = {
        ...componentData,
        materials: componentData.materials,
        partUnitOfMeasure: values.partUnitOfMeasure,
        partWeight: values.partWeight,
        fmdDocument: values.fmdDocument,
        fmdComments: values.fmdComments,
      };
    }

    calculateWeights(updatedData);
    dispatch(
      updateComponentFMD(
        {
          productRefs: updatedData.productRefs,
          componentRefs: updatedData.componentRefs,
          componentId: updatedData.componentId,
          internalItemNumber: updatedData.internalItemNumber,
          manufacturer: updatedData.manufacturer,
          bomLevel: updatedData.bomLevel,
          createdVia: updatedData.createdVia,
          createdAt: updatedData.createdAt,
          createdBy: updatedData.createdBy,
          compliances: updatedData.compliances,
          status: updatedData.status,
          risk: updatedData.risk,
          assignedTo: updatedData.assignedTo,
          specification: updatedData.specification,
          materials: updatedData.materials,
          partWeight: updatedData.partWeight,
          partUnitOfMeasure: updatedData.partUnitOfMeasure,
          outOfScope: updatedData.outOfScope,
          importRecord: updatedData.importRecord,
          softDelete: updatedData.softDelete,
          fmdDocument: updatedData.fmdDocument,
          fmdComments: updatedData.fmdComments,
        },
        updatedData._id
      )
    )
      .then(() => {
        setLoading(false);
        setOpen(false);
        form.resetFields();
        props.fetchData();
        setDisabledFields({});
      })
      .catch((e) => setLoading(false));
  };

  const calculateWeights = (data: any) => {
    if (!data.partWeight) {
      setLoading(false);
      message.error(`Component part weight is not defined`);
    }
    let totalMaterialWeight = 0;
    const materials = data.materials?.map((material: any) => {
      let substancesWeight = 0;
      material.substances?.forEach((substances: any) => {
        substancesWeight += convertToMg(
          substances.weight,
          substances.unitOfMeasure
        );
      });

      const materialWeightInMg = convertToMg(
        material.weight,
        material.unitOfMeasure
      );

      if (props.type === "Component") {
        if (materialWeightInMg.toFixed(9) !== substancesWeight.toFixed(9)) {
          setLoading(false);
          message.error(
            `The weight of ${material.name} does not match the sum of its substances.`
          );
          throw new Error(
            `The weight of ${material.name} does not match the sum of its substances.`
          );
        }
      }
      totalMaterialWeight += materialWeightInMg;

      return {
        ...material,
        substancesWeight,
      };
    });
    const totalWeightInMg = convertToMg(
      data.partWeight,
      data.partUnitOfMeasure
    );

    if (props.type === "Component") {
      if (totalWeightInMg.toFixed(9) !== totalMaterialWeight.toFixed(9)) {
        setLoading(false);
        message.error(
          `The total weight of materials does not match the Part weight of the component.`
        );
        throw new Error(
          `The total weight of materials does not match the Part weight of the component.`
        );
      }
    }
    if (props.type === "Substance") {
      const substanceWeight = convertToMg(
        form.getFieldValue("weight"),
        form.getFieldValue("unitOfMeasure")
      );
      const substanceMaterialWeight = convertToMg(
        props.formData.material.weight,
        props.formData.material.unitOfMeasure
      );
      if (substanceWeight.toFixed(9) !== substanceMaterialWeight.toFixed(9)) {
        setLoading(false);
        message.error(
          `The weight of ${props.formData.material?.name} does not match the sum of its substance weight.`
        );
        throw new Error(
          `The weight of ${props.formData.material?.name} does not match the sum of its substance weight.`
        );
      }
    }
    if (props.type === "Material") {
      if (totalWeightInMg.toFixed(9) < totalMaterialWeight.toFixed(9)) {
        setLoading(false);
        message.error(
          `The total weight of materials does not match the Part weight of the component.`
        );
        throw new Error(
          `The total weight of materials does not match the Part weight of the component.`
        );
      }
    }
    return {
      ...data,
      materials,
      totalMaterialWeight,
    };
  };
  const convertToMg = (weight: number, unit: string): number => {
    const parsedWeight = parseFloat(weight.toString());
    let weightInMg: number;
    switch (unit) {
      case "kg":
        weightInMg = parsedWeight * 1_000_000;
        break;
      case "g":
        weightInMg = parsedWeight * 1_000;
        break;
      case "mg":
        weightInMg = parsedWeight;
        break;
      default:
        message.error(`provide unit of measure: ${unit}.`);
        setLoading(false);
        throw new Error(`provide unit of measure: ${unit}`);
    }

    return parseFloat(weightInMg.toFixed(9));
  };

  const [form] = Form.useForm();
  const [activeTab, setActiveTab] = useState<string>("Component");
  const [selectedSubstanceData, setselectedSubstanceData] =
    useState<Component | null>(null);
  const handleTabChange = (key: any) => {
    setActiveTab(key);
  };
  const [executeOnce, setexecuteOnce] = useState<boolean>(true);
  const [materialIndex, setmaterialIndex] = useState<number>(0);

  const [initialMaterialCount, setinitialMaterialCount] = useState<number>(
    props?.formData?.component?.materials?.length || 0
  );
  const [initialsubstanceCount, setinitialsubstanceCount] = useState<number>(
    selectedSubstanceData?.substances?.length
  );
  const [materialCount, setmaterialCount] = useState<number>(0);
  const [substanceCount, setsubstanceCount] = useState<number>(0);
  const [addmat, setaddmat] = useState<boolean>(false);
  const [editSubstance, seteditSubstance] = useState<boolean>(false);

  const handleEditSubstanceButton = (index: any) => {
    const substance = form.getFieldValue("materials");
    const substanceSelected = componentData?.materials?.find(
      (material: { name: any }) => material?.name === substance[index].name
    );
    setselectedSubstanceData(
      substanceSelected ? substanceSelected : substance[index]
    );
    setActiveTab("Substance");
    form.setFieldsValue({
      substances: substanceSelected
        ? substanceSelected?.substances
        : substance[index]?.substances,
    });
  };

  const [fieldMaterialData, setfieldMaterialData] = useState<any>([]);
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    if (open) {
      setcomponentData(props.formData.component);
      setmaterialCount(componentData?.materials?.length);
      setsubstanceCount(
        selectedSubstanceData
          ? selectedSubstanceData?.substances?.length
          : props.formData?.material &&
              props.formData?.material[0]?.substances?.length
      );
      setinitialsubstanceCount(
        selectedSubstanceData
          ? selectedSubstanceData?.substances?.length
          : props.formData?.component[0]?.substances?.length
      );
    }

    if (componentData?.materials?.length > 0 && executeOnce) {
      setselectedSubstanceData(componentData?.materials[0]);
      form.setFieldsValue({
        substances: componentData?.materials[0]?.substances,
      });
      setexecuteOnce(false);
      setinitialMaterialCount(
        props?.formData?.component?.materials?.length
          ? props?.formData?.component?.materials?.length
          : 0
      );
      setinitialMaterialData(
        props?.formData?.component?.materials
          ? props?.formData?.component?.materials
          : []
      );
    }

    if (props.type === "Component") {
      setfieldMaterialData(
        form.getFieldValue("materials") && form.getFieldValue("materials")
      );
      if (addmat) {
        handleFormValuesChange(formValues);
        setaddmat(false);
      }

      const updatedMaterials = componentData?.materials?.map(
        (material: { name: any }) => {
          if (material?.name === selectedSubstanceData?.name) {
            return {
              ...material,
              substances: form.getFieldValue("substances"),
            };
          }
          return material;
        }
      );
      setcomponentData({
        ...componentData,
        materials: updatedMaterials,
      });
    }
  }, [open, activeTab, form, addmat, formValues]);

  const handleFormValuesChange = (allFields: any) => {
    if (props.type === "Component") {
      setFormValues(allFields);

      const newMaterialsValues = form.getFieldValue("materials");
      const updatedMaterials = componentData?.materials?.map(
        (material: { name: any }) => {
          const matchingNewMaterial = newMaterialsValues?.find(
            (newmaterial: any) => newmaterial?.name === material?.name
          );
          if (matchingNewMaterial) {
            return { ...material, ...matchingNewMaterial };
          }
          return material;
        }
      );

      const newMaterialToAdd = newMaterialsValues?.filter(
        (newMaterial: any) =>
          !componentData?.materials?.some(
            (material: any) => material?.name === newMaterial?.name
          )
      );

      setcomponentData({
        ...componentData,
        materials: updatedMaterials
          ? [...updatedMaterials, ...newMaterialToAdd]
          : newMaterialToAdd,
      });

      filterMaterials();
      setaddmat(true);
    }
  };

  const filterMaterials = () => {
    setaddmat(true);

    const filterComponentMaterial = componentData?.materials?.filter(
      (material: { name: any; weight: any; unitOfMeasure: any }) =>
        fieldMaterialData?.some(
          (newMaterial: any) =>
            newMaterial?.name === material?.name &&
            newMaterial?.weight === material?.weight &&
            newMaterial?.unitOfMeasure === material?.unitOfMeasure
        )
    );

    const filterFieldMaterialsData = fieldMaterialData?.filter(
      (newMaterial: { name: any; weight: any; unitOfMeasure: any }) =>
        !componentData?.materials?.some(
          (material: any) =>
            material?.name === newMaterial?.name &&
            material?.weight === newMaterial?.weight &&
            material?.unitOfMeasure === newMaterial?.unitOfMeasure
        )
    );
    setcomponentData({
      ...componentData,
      materials: filterComponentMaterial
        ? [...filterComponentMaterial, ...filterFieldMaterialsData]
        : filterFieldMaterialsData,
    });
  };

  const [disabledFields, setDisabledFields] = useState<{
    [key: string]: boolean;
  }>({});

  const handleCheckboxChange = (index: number, checked: boolean) => {
    if (props.type === "Component") {
      const fieldKey = form.getFieldValue("substances")[index];
      setDisabledFields({
        ...disabledFields,
        [fieldKey]: checked,
      });

      if (checked) {
        form.setFieldsValue(
          typeof form.getFieldValue("substances")[index]?.name === "object"
            ? {
                substances: {
                  [index]: {
                    name: { substance: "Confidential data" },
                    casNumber: "Confidential data",
                  },
                },
              }
            : {
                substances: {
                  [index]: {
                    name: "Confidential data",
                    casNumber: "Confidential data",
                  },
                },
              }
        );
      } else {
        form.setFieldsValue(
          typeof form.getFieldValue("substances")[index]?.name === "object"
            ? {
                substances: {
                  [index]: {
                    name: {
                      substance: selectedSubstanceData?.substances[index]?.name
                        ?.substance
                        ? selectedSubstanceData?.substances[index]?.name
                            ?.substance
                        : "",
                    },
                    casNumber: selectedSubstanceData?.substances[index]
                      ?.casNumber
                      ? selectedSubstanceData?.substances[index]?.casNumber
                      : "",
                  },
                },
              }
            : {
                substances: {
                  [index]: {
                    name: selectedSubstanceData?.substances[index]?.name
                      ? selectedSubstanceData?.substances[index]?.name
                      : "",
                    casNumber: selectedSubstanceData?.substances[index]
                      ?.casNumber
                      ? selectedSubstanceData?.substances[index]?.casNumber
                      : "",
                  },
                },
              }
        );
      }
    }
    if (props.type === "Substance") {
      const fieldKey = `${index}`;
      setDisabledFields({
        ...disabledFields,
        [fieldKey]: checked,
      });
      if (checked) {
        form.setFieldsValue(
          typeof selectedSubstanceData?.substances?.name === "object"
            ? {
                name: { substance: "Confidential data" },
                casNumber: "Confidential data",
              }
            : {
                name: "Confidential data",
                casNumber: "Confidential data",
              }
        );
      } else {
        form.setFieldsValue(
          typeof selectedSubstanceData?.substances?.name === "object"
            ? {
                name: {
                  substance: selectedSubstanceData?.substances?.name?.substance,
                },
                casNumber: selectedSubstanceData?.substances?.casNumber,
              }
            : {
                name: selectedSubstanceData?.substances?.name,
                casNumber: selectedSubstanceData?.substances?.casNumber,
              }
        );
      }
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "Component",
      label: "Component",
      children: (
        <>
          <br></br>
          <Form.List name="manufacturer">
            {(fields, operation, meta) => (
              <>
                <Row gutter={[24, 24]}>
                  <Col span={12}>
                    <Form.Item
                      name="itemNumber"
                      label="Manufacturer Part Number"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="name" label="Manufacturer Name">
                      <Input disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Form.List>
          <Row gutter={[24, 24]}>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="internalItemNumber" label="Internal Item Number">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name="partWeight"
                label="Part Weight"
                rules={[
                  { required: true, message: "Please input Part weight" },
                ]}
              >
                <InputNumber
                  placeholder="Part Weight"
                  style={{ width: "100%" }}
                  min={0}
                  step={0.1}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name="partUnitOfMeasure"
                label="Part Weight Unit"
                rules={[
                  { required: true, message: "Please input Part weight Unit" },
                ]}
              >
                <Select
                  // defaultValue={"mg"}
                  allowClear
                  placeholder="Select Part Weight Unit"
                  options={[
                    { label: "mg", value: "mg" },
                    { label: "g", value: "g" },
                    { label: "kg", value: "kg" },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name="fmdDocument"
                label={
                  props.formData.component.compliances.some(
                    (obj: { regulationNumber: string }) =>
                      obj.regulationNumber === "gadsl_(mds)"
                  )
                    ? "MDS Document"
                    : "FMD Document"
                }
              >
                <FileUploader
                  postUrl="materials/document/upload"
                  tenantNumber={tenantNumber}
                  setFormField={(value: string) => {
                    form.setFieldValue("fmdDocument", value);
                  }}
                  onUploadStatusChange={setIsUploading}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item name={"fmdComments"} label={"FMD Comments"}>
                <TextArea placeholder="Enter" rows={5}></TextArea>
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
    {
      key: "Material",
      label: "Materials",
      children: (
        <>
          <br></br>
          <Form.List name="materials">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key}>
                    <Row gutter={[24, 24]} key={key}>
                      <Col span={8} sm={8} md={8}>
                        <Form.Item
                          {...restField}
                          label={`${name + 1}.Material Name`}
                          name={[name, "name"]}
                        >
                          <Input disabled={key < initialMaterialCount} />
                        </Form.Item>
                      </Col>
                      <Col span={8} sm={8} md={8}>
                        <Form.Item
                          name={[name, "weight"]}
                          label="Material Weight"
                          rules={[
                            {
                              required: true,
                              message: "Please input Material Weight",
                            },
                          ]}
                        >
                          <InputNumber
                            style={{ width: "auto" }}
                            min={0}
                            step={0.1}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={7} sm={7} md={7}>
                        <Form.Item
                          name={[name, "unitOfMeasure"]}
                          label="Material Weight Unit "
                          rules={[
                            {
                              required: true,
                              message: "Please input Material weight unit!",
                            },
                          ]}
                        >
                          <Select
                            // defaultValue={"mg"}
                            allowClear
                            options={[
                              { label: "mg", value: "mg" },
                              { label: "g", value: "g" },
                              { label: "kg", value: "kg" },
                            ]}
                          />
                        </Form.Item>

                        <Row justify="end">
                          <Col>
                            <Form.Item>
                              <Button
                                type="primary"
                                ghost
                                block
                                style={{
                                  width: "150px",
                                }}
                                onClick={() => {
                                  handleEditSubstanceButton(name);
                                  seteditSubstance(true);
                                  setmaterialIndex(name);
                                }}
                              >
                                <SettingOutlined />
                                Edit Substance
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={1}>
                        {key >= initialMaterialCount && (
                          <CloseOutlined
                            onClick={() => {
                              remove(name);
                              setaddmat(true);
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                ))}

                <Form.Item>
                  <Button
                    type="primary"
                    onClick={() => {
                      add();
                      setaddmat(true);
                    }}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add New Material
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </>
      ),
    },
    {
      key: "Substance",
      label: "Substances",
      children: (
        <>
          {" "}
          <br></br>
          {selectedSubstanceData && (
            <Card style={{ backgroundColor: "#eeeeee" }}>
              {" "}
              <Row>
                <Col span={20}>
                  {
                    <CodeSandboxOutlined
                      style={{
                        marginRight: 5,
                        color:
                          selectedSubstanceData?.substances &&
                          selectedSubstanceData?.substances?.some(
                            (subItem: { compliantalert: string }) =>
                              subItem?.compliantalert?.toLowerCase() === "yes"
                          )
                            ? "red"
                            : "black",
                      }}
                    />
                  }
                  <b>{selectedSubstanceData?.name}</b>
                </Col>
                <Col span={4}>
                  <b>{parseFloat(selectedSubstanceData?.weight).toString()}</b>
                  <b>{selectedSubstanceData?.unitOfMeasure}</b>
                </Col>
              </Row>
            </Card>
          )}
          <br></br>
          <Form.List name="substances">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key}>
                    <Row justify="start">
                      <Col>
                        <Form.Item>
                          <Checkbox
                            checked={
                              selectedSubstanceData?.substances?.length > 0 &&
                              (disabledFields[name] ||
                                form
                                  .getFieldValue(
                                    typeof selectedSubstanceData?.substances[
                                      name
                                    ]?.name === "object"
                                      ? [name, "name", "substance"]
                                      : [name, "name"]
                                  )
                                  ?.toLowerCase()
                                  ?.includes("proprietary") ||
                                form
                                  .getFieldValue(
                                    typeof selectedSubstanceData?.substances[
                                      name
                                    ]?.name === "object"
                                      ? [name, "name", "substance"]
                                      : [name, "name"]
                                  )
                                  ?.toLowerCase()
                                  ?.includes("confidential") ||
                                form
                                  .getFieldValue([
                                    "substances",
                                    name,
                                    "casNumber",
                                  ])
                                  ?.toLowerCase()
                                  ?.includes("proprietary") ||
                                form
                                  .getFieldValue([
                                    "substances",
                                    name,
                                    "casNumber",
                                  ])
                                  ?.toLowerCase()
                                  ?.includes("confidential"))
                            }
                            onChange={(e) =>
                              handleCheckboxChange(name, e.target.checked)
                            }
                          >
                            {"Confidential/Proprietary"}
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[24, 24]} key={key}>
                      <Col span={6} sm={6} md={6}>
                        <Form.Item
                          {...restField}
                          label={`${name + 1}.Substance Name`}
                          name={
                            selectedSubstanceData?.substances?.length > 0 &&
                            typeof selectedSubstanceData?.substances[name]
                              ?.name === "object"
                              ? [name, "name", "substance"]
                              : [name, "name"]
                          }
                        >
                          <Input
                            disabled={
                              // key < initialsubstanceCount ||
                              disabledFields[`${name}`]
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6} sm={6} md={6}>
                        <Form.Item
                          {...restField}
                          label={"CAS # Number"}
                          name={[name, "casNumber"]}
                          rules={[
                            {
                              required: true,
                              message: "Please input CAS Number",
                            },
                            ...(disabledFields
                              ? []
                              : [
                                  {
                                    pattern: /^(?=.*\d)[0-9-]+$/,
                                    message: "Not allowed",
                                  },
                                ]),
                            // {
                            //   pattern: /^(?=.*\d)[0-9-]+$/,
                            //   message: "Not allowed",
                            // },
                          ]}
                        >
                          <Input
                            disabled={
                              // key < initialsubstanceCount ||
                              disabledFields[`${name}`]
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6} sm={6} md={6}>
                        <Form.Item
                          name={[name, "weight"]}
                          label="Substance Weight"
                          rules={[
                            {
                              required: true,
                              message: "Please input Substance weight!",
                            },
                          ]}
                        >
                          <InputNumber
                            style={{ width: "auto" }}
                            min={0}
                            step={0.1}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={5} sm={5} md={5}>
                        <Form.Item
                          name={[name, "unitOfMeasure"]}
                          label="Weight Unit"
                          rules={[
                            {
                              required: true,
                              message: "Please input Substance weight Unit!",
                            },
                          ]}
                        >
                          <Select
                            // defaultValue={"mg"}
                            allowClear
                            options={[
                              { label: "mg", value: "mg" },
                              { label: "g", value: "g" },
                              { label: "kg", value: "kg" },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        {/* {key >= initialsubstanceCount && ( */}
                        <CloseOutlined onClick={() => remove(name)} />
                        {/* )} */}
                      </Col>
                    </Row>
                  </div>
                ))}
                {editSubstance && (
                  <Form.Item>
                    <Button
                      type="primary"
                      onClick={() => {
                        add();
                      }}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add New Substance
                    </Button>
                  </Form.Item>
                )}
              </>
            )}
          </Form.List>
        </>
      ),
    },
  ];
  return (
    <>
      <Button
        type={"text"}
        onClick={showDrawer}
        icon={
          props.type === "Component" ? (
            <EditOutlined
              style={{
                color: props.editPage === "components" ? "black" : "white",
              }}
            />
          ) : (
            <Icon
              name="plus-circle"
              style={{ color: "#1890FF", fontSize: 20 }}
            />
          )
        }
      ></Button>
      {props.type && props.type === "Material" ? (
        <Modal
          open={open}
          centered
          width={"auto"}
          title={`Add Material for ${componentData?.manufacturer?.itemNumber} (${componentData?.manufacturer?.name})`}
          onCancel={handleCancel}
          footer={[
            <Button key="onCancel" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              key="continue"
              type="primary"
              onClick={form.submit}
              loading={loading}
            >
              Add
            </Button>,
          ]}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
          >
            <br></br>
            <Row gutter={[24, 24]}>
              <Col span={8} sm={8} md={8}>
                <Form.Item
                  name={["name"]}
                  label="Material Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input Material Name",
                    },
                  ]}
                >
                  <Input placeholder="Enter Material Name" />
                </Form.Item>
              </Col>
              <Col span={8} sm={8} md={8}>
                <Form.Item
                  name="weight"
                  label="Material Weight"
                  rules={[
                    { required: true, message: "Please input Material Weight" },
                  ]}
                >
                  <InputNumber
                    placeholder="Enter Part Weight"
                    min={0}
                    style={{
                      width: "auto",
                    }}
                    step={0.1}
                  />
                </Form.Item>
              </Col>
              <Col span={8} sm={8} md={8}>
                <Form.Item
                  name="unitOfMeasure"
                  label="Material Weight Unit"
                  rules={[
                    {
                      required: true,
                      message: "Please input Material Weight Unit",
                    },
                  ]}
                >
                  <Select
                    // defaultValue={"mg"}
                    allowClear
                    placeholder="Select Unit"
                    options={[
                      { label: "mg", value: "mg" },
                      { label: "g", value: "g" },
                      { label: "kg", value: "kg" },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      ) : props.type === "Substance" ? (
        <Modal
          open={open}
          title={`Add Substance for ${props.formData.material?.name} (${componentData?.manufacturer?.itemNumber}/${componentData?.manufacturer?.name})`}
          centered
          onCancel={handleCancel}
          footer={[
            <Button key="onCancel" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              key="continue"
              type="primary"
              onClick={form.submit}
              loading={loading}
            >
              Add
            </Button>,
          ]}
        >
          {" "}
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
          >
            <br></br>
            <Checkbox
              defaultChecked={false}
              onChange={(e) => handleCheckboxChange(0, e.target.checked)}
            >
              {"Confidential/Proprietary"}
            </Checkbox>
            <br></br>
            <br></br>
            <Row gutter={[24, 24]}>
              <Col span={12} sm={12} md={12}>
                <Form.Item
                  name={["name"]}
                  label="Substance Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input Substance Name",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Substance Name"
                    disabled={disabledFields[0]}
                    style={{
                      marginRight: "12px",
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12} sm={12} md={12}>
                <Form.Item
                  name={["casNumber"]}
                  label="CAS # Number"
                  rules={[
                    {
                      required: true,
                      message: "Please input CAS Number",
                    },
                    // {
                    //   pattern: /^(?=.*\d)[0-9-]+$/,
                    //   message: "Not allowed",
                    // },
                  ]}
                >
                  <Input
                    placeholder="Enter CAS Number"
                    disabled={disabledFields[0]}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <Col span={12} sm={12} md={12}>
                <Form.Item
                  name="weight"
                  label="Substance Weight"
                  rules={[
                    {
                      required: true,
                      message: "Please input Substance Weight",
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Enter Weight"
                    min={0}
                    step={0.1}
                    style={{
                      width: "auto",
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12} sm={12} md={12}>
                <Form.Item
                  name="unitOfMeasure"
                  label="Substance Weight Unit"
                  rules={[
                    {
                      required: true,
                      message: "Please input Substance Weight Unit",
                    },
                  ]}
                >
                  <Select
                    // defaultValue={"mg"}
                    allowClear
                    placeholder="Select Unit"
                    options={[
                      { label: "mg", value: "mg" },
                      { label: "g", value: "g" },
                      { label: "kg", value: "kg" },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      ) : (
        <Drawer
          title={`Edit ${activeTab}`}
          width={720}
          onClose={handleCancel}
          open={open}
          extra={
            <Space>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button
                onClick={form.submit}
                type="primary"
                loading={loading}
                disabled={isUploading}
              >
                Save
              </Button>
            </Space>
          }
        >
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              ...componentData,
            }}
            onValuesChange={handleFormValuesChange}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Tabs
              defaultActiveKey="Component"
              activeKey={activeTab}
              onChange={handleTabChange}
              items={items}
            />
          </Form>
        </Drawer>
      )}
    </>
  );
};

export default EditMaterialDisclosureComponents;
