import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Notification } from "../../utils/types/notification";
import { getLocalStorage } from "../../utils/localStore";

const user = getLocalStorage("user");

export interface NotificationsState {
  notifications: Notification[];
  unreadCount: number;
  loadingNotifs: boolean;
}

const initialState: NotificationsState = {
  notifications: [],
  unreadCount: 0,
  loadingNotifs: false,
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<Notification[]>) => {
      state.notifications = action.payload;
    },
    setUnreadCount: (state, action: PayloadAction<number>) => {
      state.unreadCount = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loadingNotifs = action.payload;
    },
    markAsRead: (state, action: PayloadAction<string[]>) => {
      state.notifications = state.notifications.map((notif) =>
        action.payload.includes(notif._id)
          ? { ...notif, readBy: [...notif.readBy, user.emailAddress] }
          : notif
      );
      state.unreadCount = Math.max(0, state.unreadCount - action.payload.length);
    },
    clearNotifications: (state) => {
      state.notifications = [];
      state.unreadCount = 0;
    },
  },
});

export const { setNotifications, setUnreadCount, setLoading, markAsRead, clearNotifications } =
  notificationsSlice.actions;
export default notificationsSlice.reducer;
