// This component is a list of notifications that displays the notification message, time, and buttons to mark as read or take action on the notification.

import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../redux/hooks";
import { List, Badge, Button, Spin, Tooltip } from "antd";
import {
  InfoCircleOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import {
  markNotificationsAsRead,
  fetchNotificationHistory,
  downloadNotificationFile,
} from "../../../../redux/actions/notificationsAction";
import { Notification, NotificationListProps } from "../../../../utils/types/notification";
import styles from "./notificationList.module.css";
import { useNavigate } from "react-router-dom";
import { getLocalStorage } from "../../../../utils/localStore";

const NotificationList: React.FC<NotificationListProps> = ({
  notifications,
  setIsDrawerOpen,
  hasMore,
  setHasMore,
  page,
  setPage,
}) => {
  const [loadingMore, setLoadingMore] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = getLocalStorage("user");

  const handleLoadMore = () => {
    setLoadingMore(true);
    setPage(page + 1);
  };

  // Fetch more notifications when page changes
  useEffect(() => {
    if (page > 1) {
      dispatch(fetchNotificationHistory(page)).then((response) => {
        if (response.payload && response.payload?.length < 10) {
          setHasMore(false); // No more notifications to load
        } else if (!response.payload) {
          setHasMore(false); // No more notifications to load
        }
        setLoadingMore(false); // Reset loading more state
      });
    }
  }, [page, dispatch, setHasMore]);

  const loadMore =
    hasMore && notifications?.length > 0 ? (
      <div className={styles.loadMoreContainer}>
        {!loadingMore ? (
          <Button onClick={handleLoadMore}>Load More</Button>
        ) : (
          <Spin tip="Loading more notifications...">
            <div className={styles.loadingSpinner} />
          </Spin>
        )}
      </div>
    ) : (
      notifications?.length === 0 && (
        <div className={styles.noMoreNotifications}>No more notifications to load</div>
      )
    );

  const onClickViewButton = (item: Notification) => {
    dispatch(markNotificationsAsRead([item._id]));
    if (item?.metadata?.urlToOpen.startsWith("/app/reports/generator/")) {
      // If reports URL, then navigate to "Previous Downloads" tab
      navigate(`${item?.metadata?.urlToOpen}?tab=3`);
    } else {
      navigate(`${item?.metadata?.urlToOpen}`);
    }
    setIsDrawerOpen(false);
  };

  return (
    <div className={styles.notificationList}>
      <List
        dataSource={notifications}
        renderItem={(item) => (
          <List.Item key={item._id} className={styles.notificationItem}>
            <div className={styles.notificationContent}>
              <div className={styles.notificationMessage}>
                {/* Icon based on notification type */}
                <div className={styles.notificationIcon}>
                  {item.type === "info" && (
                    <Tooltip title="Info">
                      <InfoCircleOutlined className={styles.infoIcon} />
                    </Tooltip>
                  )}
                  {item.type === "alert" && (
                    <Tooltip title="Alert">
                      <ExclamationCircleOutlined className={styles.alertIcon} />
                    </Tooltip>
                  )}
                  {item.type === "error" && (
                    <Tooltip title="Error">
                      <CloseCircleOutlined className={styles.errorIcon} />
                    </Tooltip>
                  )}
                  {item.type === "success" && (
                    <Tooltip title="Success">
                      <CheckCircleOutlined className={styles.successIcon} />
                    </Tooltip>
                  )}
                </div>
                <div>
                  <div className={styles.notificationTitleContainer}>
                    <div
                      className={`${styles.notificationTitle} ${
                        user?.emailAddress && !item.readBy.includes(user?.emailAddress)
                          ? styles.titleBold
                          : ""
                      }`}
                    >
                      {item.message}
                    </div>
                  </div>
                  <div
                    className={`${styles.notificationDescription} ${
                      user?.emailAddress && !item.readBy.includes(user?.emailAddress)
                        ? styles.titleBold
                        : ""
                    }`}
                  >
                    {item.metadata?.startDate
                      ? `${item.metadata?.description} ${new Date(
                          item.metadata?.startDate
                        ).toLocaleString()}.`
                      : item.metadata?.description || "No details"}
                  </div>
                </div>
              </div>

              {/* Orange Badge for Unread */}
              {user?.emailAddress && !item.readBy.includes(user?.emailAddress) && (
                <Badge color="orange" className={styles.unreadBadge} />
              )}
            </div>
            <div className={styles.notificationTimeAndButtons}>
              <div className={styles.notificationTime}>{item.timeAgo}</div>
              <div className={styles.notificationButtons}>
                {user?.emailAddress && !item.readBy.includes(user?.emailAddress) ? (
                  <Button
                    type="text"
                    size="small"
                    onClick={() => dispatch(markNotificationsAsRead([item._id]))}
                    className={styles.readButton}
                  >
                    Mark as Read
                  </Button>
                ) : null}

                {item?.metadata?.urlToOpen ? (
                  <Button
                    type="text"
                    size="small"
                    onClick={() => {
                      onClickViewButton(item);
                    }}
                    className={styles.actionButton}
                  >
                    View
                  </Button>
                ) : null}
                {item?.metadata?.fileName && item?.metadata?.folderName ? (
                  <Button
                    type="text"
                    size="small"
                    onClick={() => {
                      if (item?.metadata?.folderName && item?.metadata?.fileName) {
                        dispatch(
                          downloadNotificationFile(
                            item.metadata.folderName,
                            item.metadata.fileName,
                            user
                          )
                        );
                      }
                    }}
                    className={styles.actionButton}
                  >
                    Download File
                  </Button>
                ) : null}
              </div>
            </div>
          </List.Item>
        )}
        loadMore={loadMore}
      />
    </div>
  );
};

export default NotificationList;
