import React, { FC, useEffect, useRef, useState } from "react";
import {
  message,
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Progress,
  Spin,
  DatePicker,
  Dropdown,
  MenuProps,
  Typography,
  Modal,
} from "antd";
import { ExclamationCircleFilled, FileTextOutlined } from "@ant-design/icons";
import {
  listRegulationExemptions,
  listRegulations,
  listRegulationSubtances,
} from "../../redux/actions/regulationsAction";
import { useAppDispatch } from "../../redux/hooks";
import {
  createDeclarationCertificate,
  productAnalytics,
  uploadDeclaration,
} from "../../redux/actions/productsAction";
import { baseUrl } from "../../utils/apiHelper";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import ReactFC from "react-fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { getLocalStorage } from "../../utils/localStore";
import axios from "axios";
import logo from "../../assets/logo-icon.png";
import jsPDF from "jspdf";
import { myfont } from "../../assets/font/WaterBrush-Regular-normal.js";
import { Rubik } from "../../assets/font/Rubik-Regular-normal.js";
import { RubikBold } from "../../assets/font/RubikBold-normal.js";
import logoIcon from "../../assets/logo-icon.png";
import dayjs from "dayjs";

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

interface ChartProps {
  data: any[];
  chart: any;
  id: any;
}

interface CertificateProps {
  yesDescription: string;
  noDescription: string;
  commonDescription: string;
  title: string;
  authorisedBy: string;
  designation: string;
  signature: string;
  certificateLogo: null;
}
const FushionChart: FC<ChartProps> = ({ data, chart, id }) => {
  useEffect(() => {
    FusionCharts.ready(function () {
      new FusionCharts({
        type: "doughnut2d",
        width: "100%",
        height: "400",
        dataFormat: "json",
        dataSource: { data, chart: { ...chart } },
      }).render(`productChart-${id}`);
    });
  }, [data, chart, id]);

  return <div id={`productChart-${id}`} />;
};

const { Text } = Typography;

const CreateDeclarationModal: React.FC<{
  product: any;
}> = (props) => {
  const [open, setOpen] = useState(false);
  const [childrenDrawer, setChildrenDrawer] = useState(false);
  const user = getLocalStorage("user");
  const [form] = Form.useForm();
  const [companyInfoForm] = Form.useForm();
  const dispatch = useAppDispatch();
  const pdfRef = useRef<any>(null);
  const [regulationOptions, setRegulationOptions] = useState<any[]>([]);
  const [chartdataLoading, setChartdataLoading] = useState(false);
  const [completedStatus, setCompletedStatus] = useState<number>(0);
  const [productAnalytic, setProductAnalytic] = useState<any>();
  const [regulationDescription, setRegulationDescription] = useState("");
  const [regulationName, setRegulationName] = useState("");
  const [regulationNumber, setRegulationNumber] = useState("");
  const [complianceFlag, setComplianceFlag] = useState(false);
  const [exemption, setExemption] = useState(false);
  const [substance, setSubstance] = useState(false);
  const [certificate, setCertificate] = useState(false);
  const [compliantCertificate, setCompliantCertificate] = useState(false);
  const [nonCompliantCertificate, setNonCompliantCertificate] = useState(false);
  const [certificateData, setCertificateData] = useState<CertificateProps>({
    yesDescription: "",
    noDescription: "",
    commonDescription: "",
    title: "",
    authorisedBy: "",
    designation: "",
    signature: "",
    certificateLogo: null,
  });
  const [certificateLogo, setCertificateLogo] = React.useState<string>();
  const [analyticsLoading, setAnalyticsLoading] = useState<boolean>(false);
  const [editStatus, setEditStatus] = useState<boolean>(false);
  const [forceEdit, setForceEdit] = useState<boolean>(false);
  const [compliantStatus, setCompliantStatus] = useState<string>("");
  const [fileType, setFileType] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;
  const [editExemptions, setEditExemptions] = useState<boolean>(false);
  const [exemptions, setExemptions] = useState("");
  const [editSubstances, setEditSubstances] = useState<boolean>(false);
  const [substances, setSubstances] = useState("");
  const [searchExemptions, setSearchExemptions] = useState<any[]>([]);
  const [searchSubstances, setSearchSubstances] = useState<any[]>([]);
  const [forceDeclaration, setforceDeclaration] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    form.resetFields();
    setOpen(false);
    setChildrenDrawer(false);
    setCertificate(false);
    setChartdataLoading(false);
    setEditStatus(false);
    setForceEdit(false);
    setSubstances("");
    setExemptions("");
    setEditExemptions(false);
    setEditSubstances(false);
    setforceDeclaration(false);
  };

  const getCurrentDate = (): string => {
    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;
    return date;
  };

  const currentDate = getCurrentDate();

  const showChildrenDrawer = () => {
    setChildrenDrawer(true);
  };

  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
    setforceDeclaration(false);
  };

  const fetchRegulations = (value: string) => {
    const values = value.split(", ");
    let filters = {
      name: { $regex: value, $options: "i" },
    };
    if (values.length > 1) {
      filters = {
        name: { $in: values } as {
          $in: string[];
          $regex: string;
          $options: string;
        },
      };
    }
    dispatch(
      listRegulations({
        pageSize: 0,
        pageNum: 1,
        sortBy: ["createdAt"],
        sortDesc: true,
        filters: JSON.stringify(filters),
      })
    ).then((response) => {
      setRegulationOptions([
        ...response.regulations.map((regulation: any) => ({
          label: regulation.name,
          value: regulation.regulationNumber,
          default: regulation,
        })),
        ...(permissions?.read.includes("materials")
          ? [
              {
                label: "Material Disclosure (FMD)",
                value: "Material Disclosure (FMD)",
                default: {
                  label: "Material Disclosure (FMD)",
                  value: "Material Disclosure (FMD)",
                  description:
                    "IPC-1752A is the material declaration standard used by companies in the supply chain to share information on materials in products. The standard is supported by an XML schema and a data model.",
                },
              },
            ]
          : []),
      ]);
    });
  };

  const fetchProductAnalytics = (id: string) => {
    setAnalyticsLoading(true);
    dispatch(productAnalytics({ skipMutation: true }, id)).then(
      (response: any) => {
        setProductAnalytic(response);
        setAnalyticsLoading(false);
      }
    );
  };

  const handleRegulations = (value: any, option: any) => {
    setRegulationName(option?.default?.name);
    setRegulationNumber(value);
    setRegulationDescription(option?.default?.description);
    let analytics;
    if (value === "Material Disclosure (FMD)") {
      setChartdataLoading(
        productAnalytic?.materials.find((e: any) => e.name === value)
          ? true
          : false
      );
      analytics = productAnalytic?.materials.find((e: any) => e.name === value);
    } else {
      setChartdataLoading(
        productAnalytic?.regulations.find((e: any) => e.number === value)
          ? true
          : false
      );
      analytics = productAnalytic?.regulations.find(
        (e: any) => e.number === value
      );
    }

    setCompletedStatus(analytics.completedStatus);

    if (parseFloat(analytics.completedStatus.toString()) !== 100.0) {
      setCompliantStatus("Un-determined/ Under review");
    } else if (
      parseFloat(analytics.completedStatus) === 100.0 &&
      parseFloat(analytics.nonCompliantStatus) > 0
    ) {
      setCompliantStatus("Non-Compliant");
    } else if (
      parseFloat(analytics.completedStatus) === 100.0 &&
      parseFloat(analytics.compliantStatus) === 100.0 &&
      analytics.exemptions.length > 0
    ) {
      setCompliantStatus("Compliant with Exemption");
    } else {
      setCompliantStatus("Compliant");
    }
    setComplianceFlag(
      value === "Material Disclosure (FMD)"
        ? parseFloat(analytics.completedStatus) !== 0.0
          ? true
          : false
        : value !== "Material Disclosure (FMD)" && true
    );
    if (
      option.default?.certification?.certificateLogo ||
      user.declaration?.certificateLogo ||
      user.companyLogo
    ) {
      axios
        .post(
          baseUrl + "/tenants/media/download",
          {
            tenantNumber: user.tenantNumber,
            filename: option.default?.certification?.certificateLogo
              ? option.default?.certification?.certificateLogo
              : user.declaration?.certificateLogo
              ? user.declaration?.certificateLogo
              : user.companyLogo && user.companyLogo,
          },
          {
            responseType: "arraybuffer",
            headers: {
              Authorization: `Bearer ${getLocalStorage("accessToken")}`,
            },
          }
        )
        .then((response) => {
          const imageBlob = new Blob([response.data], { type: "image/png" });
          const URL = window.URL || window.webkitURL;
          setCertificateLogo(URL.createObjectURL(imageBlob));
        });
    }

    if (
      parseFloat(analytics.completedStatus) === 100.0 &&
      parseFloat(analytics.compliantStatus) === 100.0 &&
      analytics.exemptions.length > 0
    ) {
      setExemption(true);
    }
    if (
      parseFloat(analytics.completedStatus) === 100.0 &&
      parseFloat(analytics.nonCompliantStatus) > 0 &&
      analytics.substances.length > 0
    ) {
      setSubstance(true);
    }
  };

  const onSubmit = () => {
    if (regulationNumber !== "Material Disclosure (FMD)") {
      const user = getLocalStorage("user");
      const certificatefilterData = regulationOptions?.find(
        (val: any) => val.value === regulationNumber
      )?.default;
      const declarationData = user?.declaration;
      setCertificateData({
        yesDescription: certificatefilterData?.certification?.yesDescription,
        noDescription: certificatefilterData?.certification?.noDescription,
        commonDescription:
          certificatefilterData?.certification?.commonDescription,
        title: certificatefilterData?.certification?.title,
        authorisedBy: certificatefilterData?.certification?.authorisedBy
          ? certificatefilterData?.certification?.authorisedBy
          : declarationData?.authorisedBy,
        designation: certificatefilterData?.certification?.designation
          ? certificatefilterData?.certification?.designation
          : declarationData?.designation,
        signature: certificatefilterData?.certification?.signature
          ? certificatefilterData?.certification?.signature
          : true,
        certificateLogo: certificatefilterData?.certificateLogo
          ? certificatefilterData?.certificateLogo
          : declarationData?.certificateLogo,
      });

      if (
        productAnalytic?.regulations?.find(
          (e: any) => e.number === regulationNumber
        ).completedStatus !== "100.0"
      ) {
        message.success(
          "Insufficient / Incomplete Data, Force declaring the compliance reports ."
        );
        setforceDeclaration(true);
      } else if (
        productAnalytic?.regulations?.find(
          (e: any) =>
            e.number === regulationNumber &&
            parseFloat(e.completedStatus) === 100.0 &&
            parseFloat(e.nonCompliantStatus) > 0
        )
      ) {
        message.success(
          "Compliance successful, generate non compliance reports."
        );
        setCertificate(true);
        setNonCompliantCertificate(true);
        setChildrenDrawer(true);
      } else if (
        productAnalytic?.regulations?.find(
          (e: any) =>
            e.number === regulationNumber &&
            parseFloat(e.completedStatus) === 100.0 &&
            parseFloat(e.compliantStatus) === 100.0
        )
      ) {
        message.success("Compliance successful, generate compliance reports.");
        setCertificate(true);
        setCompliantCertificate(true);
        setChildrenDrawer(true);
      } else {
        message.success("Compliance successful, generate compliance reports.");
        setCertificate(true);
        setCompliantCertificate(false);
        setNonCompliantCertificate(false);
        setChildrenDrawer(true);
      }
    } else {
      setChildrenDrawer(true);
    }
  };

  const onDownload = (values?: any) => {
    if (regulationNumber === "Material Disclosure (FMD)") {
      setLoading(true);
      values.responseDate = values.responseDate.format("YYYY-MM-DD");
      values.effectiveDate = values.effectiveDate.format("YYYY-MM-DD");
      const productData = {
        type: fileType,
        supplierInformation: values,
        productId: props.product._id,
      };
      axios
        .put(
          baseUrl + "/products/fmdproductdeclaration",
          {
            productData,
          },
          {
            responseType: fileType === "excel" ? "arraybuffer" : "blob",
            headers: {
              "x-service-call": "products",
              Authorization: `Bearer ${getLocalStorage("accessToken")}`,
            },
          }
        )
        .then((response: any) => {
          let buffer;
          let fileName = "";
          if (fileType === "excel") {
            buffer = response.data;
            fileName = `FMD_RollUp_${props.product.name}-${props.product.number}.xlsx`;
          } else if (fileType === "xml") {
            buffer = response.data;
            fileName = `FMD_RollUp_${props.product.name}-${props.product.number}.xml`;
          }

          downloadFile(buffer, fileName);
          companyInfoForm.resetFields();
          form.resetFields();
          setOpen(false);
          setChildrenDrawer(false);
          setChartdataLoading(false);
          setRegulationNumber("");
          setCertificate(false);
          setLoading(false);
        });
    } else {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: "a4",
        putOnlyUsedFonts: true,
        floatPrecision: 3,
      });
      doc.addFileToVFS("WaterBrush-Regular-normal.ttf", myfont);
      doc.addFont("WaterBrush-Regular-normal.ttf", "myfont", "normal");
      doc.setFont("myfont");
      doc.addFileToVFS("Rubik-Regular-normal.ttf", Rubik);
      doc.addFont("Rubik-Regular-normal.ttf", "Rubik", "normal");
      doc.setFont("Rubik");
      doc.addFileToVFS("RubikBold-normal.ttf", RubikBold);
      doc.addFont("RubikBold-normal.ttf", "Rubik", "bold");
      doc.setFont("RubikBold");

      const pdf = pdfRef?.current;
      const descriptionElement = pdf.querySelector("#description");
      const removeStyle = descriptionElement.querySelectorAll("[style]");

      removeStyle.forEach((element: any) => {
        element.removeAttribute("style");
      });

      const list = descriptionElement.querySelectorAll("ul, ol");

      list.forEach((e: any) => {
        e.style.paddingLeft = "20px";
        e.style.marginBottom = "10px";
      });

      doc.html(pdf, {
        callback: (doc) => {
          doc.save(`${user.companyName}-${regulationName}.pdf`);
          const arrayBuffer = doc.output("arraybuffer");
          const blob = new Blob([arrayBuffer], { type: "application/pdf" });
          dispatch(
            uploadDeclaration({
              file: blob,
              filename: `${user.companyName}-${regulationName}.pdf`,
            })
          ).then((response: any) => {
            const declaration = [
              {
                exportType: "Declaration",
                exportTypeData: {
                  regulationNumber: regulationName,
                  type: "Individual",
                },
                path: props.product._id,
                status: compliantStatus,
                filename: response.filename,
                updatedBy: forceEdit ? "User" : "",
              },
            ];
            dispatch(createDeclarationCertificate({ declaration }));
          });
          onClose();
        },
        margin: [10, 0, 10, 0],
        // font: 12,
        image: {
          type: "webp",
          quality: 90,
        },
        html2canvas: {
          width: 170,
        },
        // fontFaces: [{
        //   family: "Helvetica",
        //   weight: "100"
        // }, {
        //   family: "Courier",
        //   weight: "100"
        // }],
        x: 15,
        y: 10,
      });
    }
  };

  const downloadFile = (Content: any, fileName: string) => {
    const blob = new Blob([Content], {
      type:
        fileType === "excel"
          ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          : "application/xml",
    });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const headerStyle: React.CSSProperties = {
    padding: "8px",
    textAlign: "center",
    border: "1px solid black",
    color: "white",
    fontFamily: "Rubik, sans-serif",
  };

  const bodyStyle: React.CSSProperties = {
    padding: "8px",
    textAlign: "center",
    border: "1px solid black",
    fontFamily: "Rubik, sans-serif",
  };

  const handleDoubleClick = () => {
    setEditStatus(true);
  };

  const handleBlur = () => {
    setEditStatus(false);
  };

  const handleComplaintChange = (value: string) => {
    setCompliantStatus(value);
    setForceEdit(true);
    setEditStatus(false);
    if (value === "Compliant with Exemption") {
      setEditExemptions(true);
    }
    if (value === "Non-Compliant") {
      setEditSubstances(true);
    }
  };

  const chartStyle: React.CSSProperties = {
    padding: 50,
    background: "rgba(0, 0, 0, 0.05)",
    borderRadius: 4,
  };
  const chart = <div style={chartStyle} />;

  useEffect(() => {
    if (open) {
      fetchRegulations("");
      fetchProductAnalytics(props.product?._id);
    }
  }, [open]);

  useEffect(() => {
    if (forceEdit) {
      handleSearchExemptions("", regulationNumber);
      handleSearchSubstances("", regulationNumber);
    }
  }, [forceEdit]);

  const items: MenuProps["items"] = [
    {
      key: "excel",
      label: "Excel",
    },
    {
      key: "xml",
      label: "XML",
    },
  ];

  const onClick: MenuProps["onClick"] = ({ key }) => {
    setFileType(key);
    companyInfoForm.submit();
  };

  const handleSearchExemptions = (search: any, regulation: any) => {
    dispatch(
      listRegulationExemptions({
        search: true,
        pageNum: 1,
        pageSize: 10,
        query: search,
        softDelete: false,
        filters: search
          ? JSON.stringify({
              softDelete: false,
              $or: [
                {
                  exemption: {
                    $regex: search.replace(/[()/[\]]/g, "\\$&"),
                    $options: "i",
                  },
                },
              ],
              regulationNumber: regulation,
            })
          : JSON.stringify({
              regulationNumber: regulation,
            }),
      })
    )
      .then((response: any) => {
        setSearchExemptions(
          response.exemptions.map((e: any) => ({
            value: e.exemption,
            ...e,
          }))
        );
      })
      .catch((e) => console.log(e));
  };

  const handleSearchSubstances = (search: any, regulation: any) => {
    dispatch(
      listRegulationSubtances({
        search: true,
        pageNum: 1,
        pageSize: 10,
        query: search,
        softDelete: false,
        filters: search
          ? JSON.stringify({
              softDelete: false,
              $or: [
                {
                  substance: {
                    $regex: search.replace(/[()/[\]]/g, "\\$&"),
                    $options: "i",
                  },
                },
              ],
              regulationNumber: regulation,
            })
          : JSON.stringify({
              regulationNumber: regulation,
            }),
      })
    )
      .then((response: any) => {
        setSearchSubstances(
          response.substances.map((e: any) => ({
            value: e.substance,
            ...e,
          }))
        );
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <Button
        type="text"
        onClick={showDrawer}
        title="Declaration"
        icon={<FileTextOutlined />}
        data-testid="declaration-button"
      />
      <Drawer
        title="Declaration"
        onClose={onClose}
        width={720}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose} data-testid="cancel-declaration-button">
              Cancel
            </Button>
            <Button
              onClick={onSubmit}
              type="primary"
              disabled={!complianceFlag}
              data-testid="create-declaration-button"
            >
              Create Declaration
            </Button>
          </Space>
        }
      >
        {analyticsLoading ? (
          <Spin
            spinning={analyticsLoading}
            size="large"
            tip="Please wait preparing the data ... "
          >
            {chart}
          </Spin>
        ) : (
          <>
            <Form
              form={form}
              layout="vertical"
              initialValues={{ ...props.product }}
              autoComplete="off"
            >
              <Row gutter={24}>
                <Col span={12} sm={12} md={12}>
                  <Form.Item name="number" label="Product Number">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={12} sm={12} md={12}>
                  <Form.Item
                    name="regulations"
                    label="Regulations Type"
                    rules={[
                      {
                        required: true,
                        message: "Please select regulation Type!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Regulations"
                      options={regulationOptions}
                      // onSearch={(text) => fetchRegulations(text)}
                      onChange={handleRegulations}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            {chartdataLoading && (
              <Row>
                <Col span={24}>
                  <Space style={{ marginTop: "1rem" }}>
                    <FushionChart
                      data={
                        regulationNumber === "Material Disclosure (FMD)"
                          ? productAnalytic?.materials.find(
                              (e: any) => e.name === regulationNumber
                            )?.statistics
                          : productAnalytic?.regulations.find(
                              (e: any) => e.number === regulationNumber
                            )?.statistics
                      }
                      chart={{
                        caption: regulationName,
                        subcaption: regulationDescription,
                        enablesmartlabels: "1",
                        showlabels: "1",
                        numbersuffix: " %",
                        usedataplotcolorforlabels: "1",
                        plottooltext: "$label, <b>$value</b>",
                        theme: "fusion",
                      }}
                      id={props.product?.number}
                    />
                  </Space>
                </Col>
                <Col span={24}>
                  <Progress
                    percent={completedStatus}
                    strokeWidth={30}
                    strokeLinecap="square"
                  ></Progress>
                  {regulationNumber !== "Material Disclosure (FMD)" ? (
                    <small>
                      The product should be 100% complete to generate compliant
                      or non-compliant declaration.{" "}
                    </small>
                  ) : (
                    <small>
                      The product must be more than 0.0% complete to generate a
                      material declaration.
                    </small>
                  )}
                </Col>
              </Row>
            )}
            {forceDeclaration && (
              <Modal
                title={
                  <Space
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      gap: "8px",
                    }}
                  >
                    <ExclamationCircleFilled style={{ color: "#faad14" }} />
                    Warning
                  </Space>
                }
                open={forceDeclaration}
                onCancel={() => setforceDeclaration(false)}
                onOk={() => {
                  setChildrenDrawer(true);
                  setforceDeclaration(false);
                  setCertificate(true);
                }}
                mask={true}
                width={400}
                closable={false}
                okText="Continue"
                cancelText="Discard"
                style={{
                  position: "absolute",
                  top: "55%",
                  right: "5%",
                  transform: "translateY(-50%)",
                  width: "60%",
                }}
              >
                <Row style={{ marginTop: "16px" }}>
                  <Col span={24}>
                    <p>
                      Please note: <strong>{props.product?.name}</strong> has
                      only <strong>{completedStatus}%</strong> of the required
                      100% data completed for roll-up. <br />
                      Do you want to proceed with creating the declaration
                      anyway?
                    </p>
                  </Col>
                </Row>
              </Modal>
            )}
            <Drawer
              title={
                regulationNumber === "Material Disclosure (FMD)"
                  ? "Company Information"
                  : "Declaration Template"
              }
              width={
                regulationNumber === "Material Disclosure (FMD)" ? 600 : 500
              }
              closable={false}
              onClose={onChildrenDrawerClose}
              open={childrenDrawer}
              extra={
                <Space>
                  <Button
                    onClick={onChildrenDrawerClose}
                    data-testid="cancel-download-button"
                  >
                    Cancel
                  </Button>

                  {regulationNumber === "Material Disclosure (FMD)" ? (
                    <Dropdown menu={{ items, onClick }} placement="bottomRight">
                      <Button
                        onClick={(e) => e.preventDefault()}
                        type="primary"
                        data-testid="download-declaration-button"
                        loading={loading}
                      >
                        Download Template
                      </Button>
                    </Dropdown>
                  ) : (
                    <Button
                      onClick={onDownload}
                      type="primary"
                      data-testid="download-declaration-button"
                    >
                      Download Template
                    </Button>
                  )}
                </Space>
              }
            >
              {regulationNumber === "Material Disclosure (FMD)" ? (
                <Form
                  form={companyInfoForm}
                  layout="vertical"
                  autoComplete="off"
                  onFinish={onDownload}
                >
                  <Row gutter={24}>
                    <Col span={12} sm={12} md={12}>
                      <Form.Item
                        name="companyName"
                        label="Customer Name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter customer name",
                          },
                        ]}
                        initialValue={user.companyName}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12} sm={12} md={12}>
                      <Form.Item
                        name="mfrItemName"
                        label="Product Name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter product name",
                          },
                        ]}
                        initialValue={props.product.name}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={12} sm={12} md={12}>
                      <Form.Item
                        name="mfrItemNumber"
                        label="Product Number"
                        rules={[
                          {
                            required: true,
                            message: "Please enter product number",
                          },
                        ]}
                        initialValue={props.product.number}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12} sm={12} md={12}>
                      <Form.Item
                        name="responseDate"
                        label="Response Date"
                        rules={[
                          {
                            required: true,
                            message: "Please select response date",
                          },
                        ]}
                        initialValue={dayjs()}
                      >
                        <DatePicker style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={12} sm={12} md={12}>
                      <Form.Item
                        name="contactName"
                        label="Contact Name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter contact name",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12} sm={12} md={12}>
                      <Form.Item
                        name="phoneContact"
                        label="Contact Phone"
                        rules={[
                          {
                            required: true,
                            message: "Please enter contact phone",
                          },
                          {
                            pattern:
                              /^(\+\d{1,3})?[-.\s]?(\(?\d{3}\)?)[-.\s]?\d{3}[-.\s]?\d{4}$/,
                            message: "Enter valid phone number",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={12} sm={12} md={12}>
                      <Form.Item
                        name="emailContact"
                        label="Contact Email"
                        rules={[
                          {
                            required: true,
                            message: "Please enter contact email",
                          },
                          {
                            pattern: new RegExp(
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            ),
                            message: "Please enter a valid email address",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12} sm={12} md={12}>
                      <Form.Item
                        name="effectiveDate"
                        label="Effective Date"
                        rules={[
                          {
                            required: true,
                            message: "Please select effective date",
                          },
                        ]}
                        initialValue={dayjs()}
                      >
                        <DatePicker style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* <Row gutter={24}>
                    <Col span={12} sm={12} md={12}>
                      <Form.Item
                        name="weight"
                        label="Total Weight"
                        rules={[
                          {
                            required: true,
                            message: "Please enter total weight",
                          },
                        ]}
                      >
                        <InputNumber min={0} style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                  </Row> */}
                </Form>
              ) : (
                certificate && (
                  <div>
                    <Row>
                      <Col ref={pdfRef} style={{ width: "420px" }} id="pdfRef">
                        <Row
                          justify="space-between"
                          align="middle"
                          style={{
                            background: user.declaration?.backgroundColor,
                            color: user?.declaration?.fontColor,
                          }}
                        >
                          <Col
                            style={{
                              width: "210px",
                              marginTop: "10px",
                              marginLeft: "1rem",
                            }}
                          >
                            <h2
                              style={{
                                fontFamily: "RubikBold, sans-serif",
                                fontSize: "14px",
                              }}
                            >
                              {user.declaration?.companyName
                                ? user.declaration?.companyName
                                : user.companyName}
                            </h2>
                            <p
                              style={{
                                fontFamily: "RubikBold, sans-serif",
                                fontSize: "8px",
                              }}
                            >
                              {user.declaration?.addressLine1
                                ? user.declaration?.addressLine1 + ","
                                : ""}
                              {user.declaration?.addressLine2
                                ? user.declaration?.addressLine2 + ","
                                : ""}
                              {user.declaration?.state
                                ? user.declaration?.state + ","
                                : ""}
                              {user.declaration?.country
                                ? user.declaration?.country + ","
                                : ""}
                              {user.declaration?.pincode
                                ? user.declaration?.pincode + ","
                                : ""}
                              <br></br>
                              {user?.declaration?.companyPhoneNumber
                                ? " Ph:" + user?.declaration?.companyPhoneNumber
                                : ""}
                            </p>
                          </Col>
                          <Col
                            style={{
                              textAlign: "right",
                              marginRight: "1rem",
                            }}
                          >
                            {certificateLogo ? (
                              <img
                                src={certificateLogo ? certificateLogo : logo}
                                alt="Logo"
                                height={30}
                              />
                            ) : (
                              <img src={logoIcon} alt="Logo" height={30} />
                            )}
                            <p
                              style={{
                                fontFamily: "RubikBold, sans-serif",
                                fontSize: "8px",
                              }}
                            >
                              {user.declaration?.website
                                ? user.declaration?.website
                                : ""}{" "}
                              <br></br>
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col style={{ width: "420px" }}>
                            <p
                              style={{
                                textAlign: "right",
                                fontFamily: "Rubik, sans-serif",
                                fontSize: "10px",
                                marginTop: "2px",
                              }}
                            >
                              Date: {currentDate}
                            </p>
                          </Col>
                        </Row>
                        <h3
                          style={{
                            fontFamily: "RubikBold, sans-serif",
                            textAlign: "center",
                            fontSize: "12px",
                            marginBottom: "15px",
                          }}
                        >
                          {certificateData.title}
                        </h3>
                        <p
                          id="description"
                          style={{
                            fontFamily: "Rubik, RubikBold , sans-serif",
                            fontSize: "8px",
                            marginBottom: "15px",
                            lineHeight: "1.5",
                          }}
                        >
                          {!nonCompliantCertificate ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: certificateData.yesDescription
                                  ? certificateData.yesDescription
                                  : certificateData.commonDescription,
                              }}
                            />
                          ) : (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: certificateData.noDescription
                                  ? certificateData.noDescription
                                  : certificateData.commonDescription,
                              }}
                            />
                          )}
                        </p>
                        <div>
                          <table
                            style={{
                              width: "100%",
                              borderCollapse: "collapse",
                              marginTop: "20px",
                              fontSize: "8px",
                            }}
                          >
                            <thead>
                              <tr
                                style={{
                                  background: "#151B54",
                                  borderLeft: "1px solid black",
                                }}
                              >
                                <th style={headerStyle}>Product Number</th>
                                <th style={headerStyle}>Product Name</th>
                                <th style={headerStyle}>
                                  {regulationName} Compliant Status
                                </th>
                                {productAnalytic?.regulations?.find(
                                  (e: any) => e.number === regulationNumber
                                )?.exemptions?.length > 0 &&
                                exemption &&
                                compliantStatus ===
                                  "Compliant with Exemption" ? (
                                  <th style={headerStyle}>Exemptions</th>
                                ) : (
                                  regulationNumber === "eu_rohs" &&
                                  forceEdit &&
                                  compliantStatus ===
                                    "Compliant with Exemption" && (
                                    <th style={headerStyle}>Exemptions</th>
                                  )
                                )}
                                {productAnalytic?.regulations?.find(
                                  (e: any) => e.number === regulationNumber
                                )?.substances?.length > 0 &&
                                substance &&
                                compliantStatus === "Non-Compliant" ? (
                                  <th style={headerStyle}>Substances</th>
                                ) : (
                                  compliantStatus === "Non-Compliant" &&
                                  forceEdit && (
                                    <th style={headerStyle}>Substances</th>
                                  )
                                )}
                                {permissions?.read?.includes("scip") &&
                                  regulationNumber === "eu_reach" &&
                                  nonCompliantCertificate &&
                                  compliantStatus === "Non-Compliant" &&
                                  productAnalytic?.scipNumber && (
                                    <th style={headerStyle}>SCIP #</th>
                                  )}
                              </tr>
                            </thead>
                            <tbody>
                              <tr style={{ borderBottom: "1px solid #ddd" }}>
                                <td style={bodyStyle}>
                                  {props.product?.number}
                                </td>
                                <td style={bodyStyle}>{props.product?.name}</td>
                                <td
                                  style={bodyStyle}
                                  onDoubleClick={handleDoubleClick}
                                >
                                  {editStatus ? (
                                    <Select
                                      value={compliantStatus}
                                      onChange={(value) =>
                                        handleComplaintChange(value)
                                      }
                                      style={{
                                        width: "100%",
                                        fontSize: "8px",
                                      }}
                                      onBlur={handleBlur}
                                      autoFocus
                                      options={[
                                        {
                                          value: "Compliant",
                                          label: "Compliant",
                                        },
                                        {
                                          value: "Non-Compliant",
                                          label: "Non-Compliant",
                                        },
                                        {
                                          value: "Compliant with Exemption",
                                          label: "Compliant with Exemption",
                                          disabled:
                                            regulationNumber !== "eu_rohs",
                                        },
                                        {
                                          value: "Un-determined/ Under review",
                                          label: "Un-determined/ Under review",
                                        },
                                      ]}
                                    />
                                  ) : (
                                    compliantStatus
                                  )}
                                </td>
                                {productAnalytic?.regulations?.find(
                                  (e: any) => e.number === regulationNumber
                                )?.exemptions?.length > 0 &&
                                exemption &&
                                compliantStatus ===
                                  "Compliant with Exemption" ? (
                                  <td style={bodyStyle}>
                                    {productAnalytic?.regulations
                                      ?.find(
                                        (e: any) =>
                                          e.number === regulationNumber
                                      )
                                      ?.exemptions.toString()}
                                  </td>
                                ) : (
                                  regulationNumber === "eu_rohs" &&
                                  forceEdit &&
                                  compliantStatus ===
                                    "Compliant with Exemption" && (
                                    <td style={bodyStyle}>
                                      {editExemptions ? (
                                        <Select
                                          options={searchExemptions.map(
                                            (option) => ({
                                              ...option,
                                              label: (
                                                <Text>{option.exemption}</Text>
                                              ),
                                            })
                                          )}
                                          style={{
                                            width: "100%",
                                            fontSize: "8px",
                                          }}
                                          mode="multiple"
                                          onChange={(value: any) => {
                                            setExemptions(value);
                                          }}
                                          onSearch={(value) =>
                                            handleSearchExemptions(
                                              value,
                                              regulationNumber
                                            )
                                          }
                                          onBlur={() => {
                                            setEditExemptions(false);
                                          }}
                                          placeholder="Type to search Exemptions"
                                          allowClear
                                          showSearch
                                        />
                                      ) : (
                                        exemptions?.toString()
                                      )}
                                    </td>
                                  )
                                )}
                                {productAnalytic?.regulations?.find(
                                  (e: any) => e.number === regulationNumber
                                )?.substances?.length > 0 &&
                                substance &&
                                compliantStatus === "Non-Compliant" ? (
                                  <td style={bodyStyle}>
                                    {productAnalytic?.regulations
                                      ?.find(
                                        (e: any) =>
                                          e.number === regulationNumber
                                      )
                                      ?.substances.toString()}
                                  </td>
                                ) : (
                                  forceEdit &&
                                  compliantStatus === "Non-Compliant" && (
                                    <td
                                      style={{
                                        ...bodyStyle,
                                        maxWidth: "200px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {editSubstances ? (
                                        <Select
                                          options={searchSubstances.map(
                                            (option) => ({
                                              ...option,
                                              label: (
                                                <Text
                                                  style={{
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                  }}
                                                >
                                                  {option.substance}
                                                  <br></br>
                                                  <small>
                                                    {option.casNumber}
                                                  </small>
                                                </Text>
                                              ),
                                            })
                                          )}
                                          style={{
                                            width: "100%",
                                            fontSize: "8px",
                                          }}
                                          mode="multiple"
                                          onChange={(value: any) => {
                                            setSubstances(value);
                                          }}
                                          onSearch={(value) =>
                                            handleSearchSubstances(
                                              value,
                                              regulationNumber
                                            )
                                          }
                                          onBlur={() => {
                                            setEditSubstances(false);
                                          }}
                                          placeholder="Type to search Substance of Concern"
                                          allowClear
                                          showSearch
                                        />
                                      ) : (
                                        substances?.toString()
                                      )}
                                    </td>
                                  )
                                )}
                                {permissions?.read?.includes("scip") &&
                                  regulationNumber === "eu_reach" &&
                                  nonCompliantCertificate &&
                                  compliantStatus === "Non-Compliant" &&
                                  productAnalytic?.scipNumber && (
                                    <td style={bodyStyle}>
                                      {productAnalytic?.scipNumber}
                                    </td>
                                  )}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <p
                          style={{
                            fontFamily: "Rubik, sans-serif",
                            fontSize: "8px",
                            marginTop: "50px",
                            marginBottom: "0px",
                          }}
                        >
                          Authorised Representative
                        </p>
                        {Boolean(certificateData?.signature) && (
                          <p
                            style={{
                              fontFamily: "myFont, Water Brush",
                              fontSize: "18px",
                              marginTop: "0px",
                              marginBottom: "0px",
                            }}
                          >
                            {certificateData.authorisedBy
                              ? certificateData.authorisedBy
                              : user.declaration?.authorisedBy}
                          </p>
                        )}
                        <p
                          style={{
                            fontFamily: "Rubik, sans-serif",
                            fontSize: "8px",
                            marginTop: "0px",
                            marginBottom: "0px",
                          }}
                        >
                          {certificateData.authorisedBy
                            ? certificateData.authorisedBy
                            : user.declaration?.authorisedBy}
                        </p>
                        {certificateData.designation && (
                          <p
                            style={{
                              fontFamily: "Rubik, sans-serif",
                              fontSize: "8px",
                              marginTop: "0px",
                              marginBottom: "0px",
                            }}
                          >
                            {certificateData.designation
                              ? certificateData.designation
                              : user.declaration?.designation}
                          </p>
                        )}
                        {user.declaration?.emailAddress && (
                          <p
                            style={{
                              fontFamily: "Rubik, sans-serif",
                              fontSize: "8px",
                              marginTop: "0px",
                              marginBottom: "0px",
                            }}
                          >
                            {user.declaration?.emailAddress}
                          </p>
                        )}
                        {user.declaration?.phoneNumber && (
                          <p
                            style={{
                              fontFamily: "Rubik, sans-serif",
                              fontSize: "8px",
                              marginTop: "0px",
                              marginBottom: "0px",
                            }}
                          >
                            {user.declaration?.phoneNumber}
                          </p>
                        )}
                      </Col>
                    </Row>
                  </div>
                )
              )}
            </Drawer>
          </>
        )}
      </Drawer>
    </>
  );
};

export default CreateDeclarationModal;
