import React, { useState, useEffect } from "react";
import { ConfigProvider, Layout } from "antd";
import { Outlet, useNavigate } from "react-router-dom";

import HeaderLayout from "../../components/layouts/Header";
import FooterLayout from "../../components/layouts/Footer";
import SiderLayout from "../../components/layouts/Sider";
import ReactivateSessionToken from "../../components/modals/ReactivateSessionToken";
import { connectSSE, fetchUnreadCount } from "../../redux/actions/notificationsAction";
import { useAppDispatch } from "../../redux/hooks";
import useVisibilityChange from "../../utils/notificationHelper/useVisibilityChange";

import { clearLocalStorage, getLocalStorage } from "../../utils/localStore";
import styles from "./app.module.css";
import "./../../index.css";

const { Content } = Layout;

const AppLayout: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const user = getLocalStorage("user");
  const expireAt = parseInt(getLocalStorage("expireAt"));
  const dispatch = useAppDispatch();

  const openModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (!user) {
      navigate("/");
    } else if (expireAt - new Date().getTime() <= 0) {
      clearLocalStorage();
      navigate("/");
    } else {
      const timer = setTimeout(() => {
        console.log("Session expired");
        setOpen(true);
      }, expireAt - new Date().getTime());

      return () => clearTimeout(timer);
    }
  }, [user, expireAt, navigate]);

  useEffect(() => {
    // Update the CSS variable for secondary color
    if (user?.secondaryColor) {
      document.documentElement.style.setProperty("--secondary-color", user.secondaryColor);
      document.documentElement.style.setProperty("--primary-color", user.primaryColor);
    }
  }, [user]);

  // Start SSE on App Load if User is Authenticated
  useEffect(() => {
    if (user) {
      dispatch(connectSSE());
      dispatch(fetchUnreadCount());
    }
  }, [dispatch, user]); // Runs only once when the user is set

  // Use custom hook to handle visibility change and focus events
  useVisibilityChange("/notifications", dispatch);

  return (
    user && (
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: user?.secondaryColor || "#F39237",
            borderRadius: 4,
            fontFamily: "Onest",
          },
          components: {
            Layout: {
              headerBg: "#ffffff",
              siderBg: user?.primaryColor || "#045B7C",
            },
            Table: {
              rowSelectedBg: "#fff",
              rowSelectedHoverBg: "#fafafa",
            },
            Menu: {
              darkItemBg: user?.primaryColor || "#045B7C",
              darkSubMenuItemBg: user?.primaryColor || "#045B7C",
            },
            Card: {
              headerBg: user?.primaryColor || "#F39237",
              extraColor: "#fff",
            },
          },
        }}
      >
        <Layout hasSider>
          <SiderLayout />
          <Layout>
            <HeaderLayout
              primaryColor={user?.primaryColor || "#045B7C"}
              secondaryColor={user?.secondaryColor || "#F39237"}
            />
            <Content className={styles.content}>
              <Outlet />
              {open && <ReactivateSessionToken isModalOpen={isModalOpen} openModal={openModal} />}
            </Content>
            <FooterLayout />
          </Layout>
        </Layout>
      </ConfigProvider>
    )
  );
};

export default AppLayout;
