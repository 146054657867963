import { publicPostApi } from "../../utils/apiHelper";
import { message } from "antd";
import {
  setLocalStorage,
  storeRegulationsInLocalStorage,
} from "../../utils/localStore";
import * as Sentry from "@sentry/react";
import { savePhrasesBatch } from "../../utils/constants/indexedDb";

const handleApiRequest = async (
  apiEndpoint: string,
  payload: any,
  successMessage: string
): Promise<{ success: boolean; message?: string }> => {
  try {
    const response = await publicPostApi(apiEndpoint, payload);
    if (response?.status === 200) {
      if (successMessage) {
        message.success(successMessage);
      }
      return { success: true };
    } else {
      throw new Error(response.data.message);
    }
  } catch (error: any) {
    message.error(error.message || "Network error or other issues", 6);
    return {
      success: false,
      message: error.message || "Network error or other issues",
    };
  }
};

export const scipPhrases = (
  payload: any
): { success: boolean; message?: string } => {
  publicPostApi("/scip/phrases", payload)
    .then(async (response) => {
      if (response?.status === 200) {
        await savePhrasesBatch(response.data.data);
      } else {
        throw new Error(response.data.message);
      }
    })
    .catch((error: any) => {
      return {
        success: false,
        message: error.message || "Network error or other issues",
      };
    });
  return { success: true };
};

// resending OTP

export const authRegenerateOTP =
  (payload: any) =>
  async (): Promise<{ success: boolean; message?: string }> => {
    try {
      await publicPostApi("/login/resendotp", payload);
      return { success: true };
    } catch (error: any) {
      return {
        success: false,
        message: error.message || "Network error or other issues",
      };
    }
  };

// API request to /login/verifyotp to check the otp and complete the login process

export const authVerifyOTP =
  (payload: any) =>
  async (): Promise<{ success: boolean; message?: string }> => {
    try {
      const response = await publicPostApi("/login/verifyotp", payload);
      const { token, user, _id, name, role, expiresAt } = response.data.data;

      setLocalStorage("accessToken", token);
      setLocalStorage("user", {
        ...user,
        workspace: user.tenantNumber,
        id: _id,
        name: name,
      });
      setLocalStorage("role", role);
      setLocalStorage("expireAt", new Date(expiresAt).getTime());

      return { success: true };
    } catch (error: any) {
      return {
        success: false,
        message: error.message || "Network error or other issues",
      };
    }
  };

export const authLogin =
  (payload: any) =>
  async (): Promise<{
    success: boolean;
    message?: string;
    mfa?: boolean;
    emailAddress?: string;
  }> => {
    try {
      const response = await publicPostApi("/login", payload);
      if (response.status === 200) {
        if (response.data.message) {
          return {
            success: true,
            mfa: response.data.message,
            emailAddress: response.data.emailAddress,
          };
        } else {
          const {
            token,
            user,
            _id,
            name,
            role,
            expiresAt,
            cmrtVersions,
            emrtVersions,
            regulations,
          } = response.data.response.data;
          setLocalStorage("accessToken", token);
          setLocalStorage("user", {
            ...user,
            workspace: user.tenantNumber,
            id: _id,
            name: name,
          });
          setLocalStorage("role", role);
          setLocalStorage("expireAt", new Date(expiresAt).getTime());
          if (role?.permissions?.read?.includes("conflictminerals")) {
            setLocalStorage("cmrtversion", cmrtVersions);
          }
          if (role?.permissions?.read?.includes("extendedminerals")) {
            setLocalStorage("emrtversion", emrtVersions);
          }
          if (role?.permissions?.read?.includes("regulations")) {
            storeRegulationsInLocalStorage(regulations);
          }
          if (role?.permissions?.read?.includes("scip")) {
            return scipPhrases({});
          }
          Sentry.setUser({ email: user.emailAddress });
          Sentry.setTag("tenant", user.tenantNumber);
          return {
            success: true,
            mfa: response.data.message,
          };
        }
      }
      return {
        success: false,
        message: "Something went wrong",
      };
    } catch (error: any) {
      return {
        success: false,
        message: error.message || "Network error or other issues",
      };
    }
  };

export const authForgotPassword =
  (payload: any) =>
  async (): Promise<{ success: boolean; message?: string }> => {
    const successMessage =
      "Reset Password Link Sent. Check your email for the reset password link.";
    return handleApiRequest("/public/forgot-password", payload, successMessage);
  };

export const authChangePassword =
  (payload: any) =>
  async (): Promise<{ success: boolean; message?: string }> => {
    return handleApiRequest(
      "/public/reset-password",
      payload,
      "Password reset successfully."
    );
  };

export const authResetPassword =
  (payload: any, message?: string) =>
  async (): Promise<{ success: boolean; message?: string }> => {
    return handleApiRequest(
      "/public/accept-invite",
      payload,
      message || "Password set successfully."
    );
  };
