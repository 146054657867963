import {
  privatePostApi,
  privateGetApi,
  privatePutApi,
  privateDeleteApi,
  publicGetApi,
} from "../../utils/apiHelper";
import { TenantsState } from "../../utils/types/tenant";
import { setTenants } from "../slices/tenantsSlice";
import { Dispatch } from "redux";

/**
 * Bulk Operations
 */
export const listTenants = (payload: any) => (dispatch: Dispatch) => {
  return new Promise<TenantsState>((resolve, reject) => {
    privateGetApi("/admins/tenants", payload)
      .then((response: any) => {
        if (response.status === 200) {
          dispatch(setTenants(response.data.data));
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

/**
 * List all the Tenants
 * @param payload 
 * @returns 
 */
export const getAllTenants = (payload?: any) => () => {
  return new Promise<any>((resolve, reject) => {
    publicGetApi("/public/tenants", payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data); // Resolving with tenants data
        } else {
          reject("No tenants found or error in fetching data.");
        }
      })
      .catch((error) => {
        reject(error); // Rejecting with the error if something goes wrong
      });
  });
};

/**
 * CRUD Operations
 */
export const createTenant = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/admins/tenants", payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const readTenant = (id: string, payload?: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privateGetApi("/admins/tenants/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateTenant = (payload: any, id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePutApi("/admins/tenants/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteTenant = (id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privateDeleteApi("/admins/tenants/" + id)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const listTenantRegulations = (id: string, payload?: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privateGetApi("/admins/tenants/" + id + "/regulations", payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateTenantRegulations = (id: string, payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePutApi("/admins/tenants/" + id + "/regulations", payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const listTenantUsers = (id: string, payload?: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privateGetApi("/admins/tenants/" + id + "/users", payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateTenantDetails = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePutApi("/tenants/update", payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const readTenantUserActivity = (
  payload: any,
  id: string
): Promise<any> => {
  return new Promise<any>((resolve, reject) => {
    privateGetApi("/admins/tenants/activity/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const readTenantDetails = (id: string, payload?: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privateGetApi("/tenants/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteTenantsRegulation = (id: string, payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePutApi("/admins/tenants/regulations/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
