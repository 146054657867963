import React, { useState } from "react";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Card,
} from "antd";

import { useAppDispatch } from "../../../../redux/hooks";
import { updateTenant } from "../../../../redux/actions/tenantsAction";

const { Option } = Select;

const TenantConfiguration: React.FC<{
  id: string;
  tenantNumber: string;
  productSpecifications?: any;
  specifications?: any;
  gridConfiguration?: any;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [formProductSpecification] = Form.useForm();
  const [formComponentSpecification] = Form.useForm();
  const [formProductConfiguration] = Form.useForm();
  const [formComponentConfiguration] = Form.useForm();
  const [formSupplierConfiguration] = Form.useForm();
  const [loadingProductSpecification, setLoadingProductSpecification] =
    useState<boolean>(false);
  const [loadingComponentConfiguration, setLoadingComponentConfiguration] =
    useState<boolean>(false);
  const [loadingSupplierConfiguration, setLoadingSupplierConfiguration] =
    useState<boolean>(false);
  const [loadingProductConfiguration, setLoadingProductConfiguration] =
    useState<boolean>(false);
  const [loadingComponentSpecification, setLoadingComponentSpecification] =
    useState<boolean>(false);
  const [refreshState, setRefreshState] = useState("");
  const onFormProductSpecificationSubmit = (values: any) => {
    setLoadingProductSpecification(true);
    dispatch(
      updateTenant(
        {
          updates: {
            productSpecifications: values.productSpecifications?.map(
              (e: any) => ({
                ...e,
                value: e.text.replace(/\s/g, "").toLowerCase(),
              })
            ),
          },
        },
        props.id
      )
    )
      .then(() => {
        formProductSpecification.resetFields();
        setLoadingProductSpecification(false);
      })
      .catch((e) => setLoadingProductSpecification(false));
  };
  const onFormConfigurationSubmit = (values: any) => {
    setLoadingProductConfiguration(true);
    setLoadingComponentConfiguration(true);
    setLoadingSupplierConfiguration(true);
    const initialgridConfiguration = props.gridConfiguration;

    const updatedgridConfiguration = initialgridConfiguration
      ?.map((e: any) => {
        const initialgrid = values.gridConfiguration.filter(
          (i: any) => i.text === e.text
        );
        if (initialgrid.length > 0) {
          return initialgrid.map((o: any) => ({ ...e, ...o }));
        } else {
          return e;
        }
      }, [])
      .flat();

    dispatch(
      updateTenant(
        {
          updates: {
            gridConfiguration: updatedgridConfiguration,
          },
        },
        props.id
      )
    )
      .then(() => {
        formProductConfiguration.resetFields();
        formComponentConfiguration.resetFields();
        formSupplierConfiguration.resetFields();
        setLoadingProductConfiguration(false);
        setLoadingComponentConfiguration(false);
        setLoadingSupplierConfiguration(false);
      })
      .catch(() => {
        setLoadingProductConfiguration(false);
        setLoadingComponentConfiguration(false);
        setLoadingSupplierConfiguration(false);
      });
  };
  const onFormComponentSpecificationSubmit = (values: any) => {
    setLoadingComponentSpecification(true);
    dispatch(
      updateTenant(
        {
          updates: {
            specifications: values.specifications?.map((e: any) => ({
              ...e,
              value: e.text.replace(/\s/g, "").toLowerCase(),
            })),
          },
        },
        props.id
      )
    )
      .then(() => {
        formComponentSpecification.resetFields();
        setLoadingComponentSpecification(false);
      })
      .catch((e) => setLoadingComponentSpecification(false));
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Card
          title="Product Specifications"
          extra={
            <Button
              type="primary"
              onClick={formProductSpecification.submit}
              loading={loadingProductSpecification}
            >
              Save
            </Button>
          }
        >
          <Form
            form={formProductSpecification}
            layout="vertical"
            onFinish={onFormProductSpecificationSubmit}
            initialValues={{
              productSpecifications: props.productSpecifications,
            }}
          >
            <Form.List name="productSpecifications">
              {(fields, { add, remove }) => (
                <Space
                  size="middle"
                  direction="vertical"
                  style={{ display: "flex" }}
                >
                  {fields.map((field) => (
                    <Card
                      type="inner"
                      size="small"
                      title={`Item ${field.name + 1}`}
                      key={field.key}
                      extra={
                        <CloseOutlined
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      }
                    >
                      <Row gutter={16}>
                        <Col span={8}>
                          <Form.Item
                            label="Name"
                            name={[field.name, "text"]}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: "Missing name",
                              },
                            ]}
                          >
                            <Input placeholder="Please enter name" />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            label="Data Type"
                            name={[field.name, "dataType"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing data type",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Please choose data type"
                              onChange={(value) => {
                                setRefreshState(
                                  field.name + value + "productSpecifications"
                                );
                              }}
                            >
                              <Option value="Textfield">Textfield</Option>
                              <Option value="Fileinput">Fileinput</Option>
                              <Option value="Dropdown">Dropdown</Option>
                              <Option value="Multilist">Multilist</Option>
                              <Option value="Dateinput">Dateinput</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            label="Data Value"
                            name={[field.name, "dataValue"]}
                            rules={
                              formProductSpecification.getFieldValue(
                                "productSpecifications"
                              )[field.key].dataType === "Dropdown"
                                ? [
                                    {
                                      required: true,
                                      message: `Please input data value !`,
                                    },
                                  ]
                                : []
                            }
                          >
                            <Input
                              placeholder="Please enter data value"
                              disabled={
                                formProductSpecification.getFieldValue(
                                  "productSpecifications"
                                )[field.key].dataType !== "Dropdown"
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name={[field.name, "supplierPortal"]}
                            label="Supplier"
                            extra="Show to supplier"
                          >
                            <Switch />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name={[field.name, "hidden"]}
                            label="Table"
                            extra="Hide in table"
                          >
                            <Switch />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name={[field.name, "analytics"]}
                            label="Analytics"
                            extra="Show in analytics"
                          >
                            <Switch
                              disabled={
                                formProductSpecification.getFieldValue(
                                  "productSpecifications"
                                )[field.key].dataType !== "Dropdown"
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name={[field.name, "filter"]}
                            label="Filter"
                            extra="Show in filter"
                          >
                            <Switch />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  ))}
                  <Button
                    type="dashed"
                    onClick={() =>
                      add({
                        dataType: "Textfield",
                        dataValue: null,
                        hidden: false,
                        text: null,
                        supplierPortal: false,
                        value: null,
                      })
                    }
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Configuration
                  </Button>
                </Space>
              )}
            </Form.List>
          </Form>
        </Card>
      </Col>
      <Col span={12}>
        <Card
          title="Component Specifications"
          extra={
            <Button
              type="primary"
              onClick={formComponentSpecification.submit}
              loading={loadingComponentSpecification}
            >
              Save
            </Button>
          }
        >
          <Form
            form={formComponentSpecification}
            layout="vertical"
            onFinish={onFormComponentSpecificationSubmit}
            initialValues={{
              specifications: props.specifications,
            }}
          >
            <Form.List name="specifications">
              {(fields, { add, remove }) => (
                <Space
                  size="middle"
                  direction="vertical"
                  style={{ display: "flex" }}
                >
                  {fields.map((field) => (
                    <Card
                      type="inner"
                      size="small"
                      title={`Item ${field.name + 1}`}
                      key={field.key}
                      extra={
                        <CloseOutlined
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      }
                    >
                      <Row gutter={16}>
                        <Col span={8}>
                          <Form.Item
                            label="Name"
                            name={[field.name, "name"]}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: "Missing name",
                              },
                            ]}
                          >
                            <Input placeholder="Please enter name" />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            label="Data Type"
                            name={[field.name, "dataType"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing data type",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Please choose data type"
                              onChange={(value) => {
                                setRefreshState(
                                  field.name + value + "specifications"
                                );
                              }}
                            >
                              <Option value="Textfield">Textfield</Option>
                              <Option value="Fileinput">Fileinput</Option>
                              <Option value="Dropdown">Dropdown</Option>
                              <Option value="Multilist">Multilist</Option>
                              <Option value="Dateinput">Dateinput</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            label="Data Value"
                            name={[field.name, "dataValue"]}
                            rules={
                              formComponentSpecification.getFieldValue(
                                "specifications"
                              )[field.key].dataType === "Dropdown"
                                ? [
                                    {
                                      required: true,
                                      message: `Please input data value !`,
                                    },
                                  ]
                                : []
                            }
                          >
                            <Input
                              placeholder="Please enter data value"
                              disabled={
                                formComponentSpecification.getFieldValue(
                                  "specifications"
                                )[field.key].dataType !== "Dropdown"
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name={[field.name, "supplierPortal"]}
                            label="Supplier"
                            extra="Show to supplier"
                          >
                            <Switch />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name={[field.name, "hidden"]}
                            label="Table"
                            extra="Hide in table"
                          >
                            <Switch />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name={[field.name, "analytics"]}
                            label="Analytics"
                            extra="Show in analytics"
                          >
                            <Switch
                              disabled={
                                formComponentSpecification.getFieldValue(
                                  "specifications"
                                )[field.key].dataType !== "Dropdown"
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name={[field.name, "filter"]}
                            label="Filter"
                            extra="Show in filter"
                          >
                            <Switch />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  ))}
                  <Button
                    type="dashed"
                    onClick={() =>
                      add({
                        dataType: "Textfield",
                        dataValue: null,
                        hidden: false,
                        text: null,
                        supplierPortal: false,
                        value: null,
                      })
                    }
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Configuration
                  </Button>
                </Space>
              )}
            </Form.List>
          </Form>
        </Card>
      </Col>
      <Col span={12}>
        <Card
          title="Product Table"
          extra={
            <Button
              type="primary"
              onClick={formProductConfiguration.submit}
              loading={loadingProductConfiguration}
            >
              Save
            </Button>
          }
        >
          <Form
            form={formProductConfiguration}
            layout="vertical"
            onFinish={onFormConfigurationSubmit}
            initialValues={{
              gridConfiguration: props.gridConfiguration.filter((e: any) =>
                e.type?.includes("products")
              ),
            }}
          >
            <Form.List name="gridConfiguration">
              {(fields) => (
                <Space
                  size="middle"
                  direction="vertical"
                  style={{ display: "flex" }}
                >
                  {fields.map((field) => (
                    <Row gutter={16} key={field.key}>
                      <Col span={12}>
                        <Form.Item
                          label="Name"
                          name={[field.name, "text"]}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: "Missing name",
                            },
                          ]}
                        >
                          <Input placeholder="Please enter text" />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name={[field.name, "grid"]}
                          label="Table"
                          extra="Show in table"
                        >
                          <Switch />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name={[field.name, "filter"]}
                          label="Filter"
                          extra="Show in filter"
                        >
                          <Switch />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                </Space>
              )}
            </Form.List>
          </Form>
        </Card>
      </Col>
      <Col span={12}>
        <Card
          title="Component Table"
          extra={
            <Button
              type="primary"
              onClick={formComponentConfiguration.submit}
              loading={loadingComponentConfiguration}
            >
              Save
            </Button>
          }
        >
          <Form
            form={formComponentConfiguration}
            layout="vertical"
            onFinish={onFormConfigurationSubmit}
            initialValues={{
              gridConfiguration: props.gridConfiguration.filter((e: any) =>
                e.type?.includes("components")
              ),
            }}
          >
            <Form.List name="gridConfiguration">
              {(fields) => (
                <Space
                  size="middle"
                  direction="vertical"
                  style={{ display: "flex" }}
                >
                  {fields.map((field) => (
                    <Row gutter={16} key={field.key}>
                      <Col span={12}>
                        <Form.Item
                          label="Name"
                          name={[field.name, "text"]}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: "Missing name",
                            },
                          ]}
                        >
                          <Input placeholder="Please enter text" />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name={[field.name, "grid"]}
                          label="Table"
                          extra="Show in table"
                        >
                          <Switch />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name={[field.name, "filter"]}
                          label="Filter"
                          extra="Show in filter"
                        >
                          <Switch />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                </Space>
              )}
            </Form.List>
          </Form>
        </Card>
      </Col>
      <Col span={12}>
        <Card
          title="Supplier Table"
          extra={
            <Button
              type="primary"
              onClick={formSupplierConfiguration.submit}
              loading={loadingSupplierConfiguration}
            >
              Save
            </Button>
          }
        >
          <Form
            form={formSupplierConfiguration}
            layout="vertical"
            onFinish={onFormConfigurationSubmit}
            initialValues={{
              gridConfiguration: props.gridConfiguration.filter((e: any) =>
                e.type?.includes("suppliers")
              ),
            }}
          >
            <Form.List name="gridConfiguration">
              {(fields) => (
                <Space
                  size="middle"
                  direction="vertical"
                  style={{ display: "flex" }}
                >
                  {fields.map((field) => (
                    <Row gutter={16} key={field.key}>
                      <Col span={12}>
                        <Form.Item
                          label="Name"
                          name={[field.name, "text"]}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: "Missing name",
                            },
                          ]}
                        >
                          <Input placeholder="Please enter text" />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name={[field.name, "grid"]}
                          label="Table"
                          extra="Show in table"
                        >
                          <Switch />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name={[field.name, "filter"]}
                          label="Filter"
                          extra="Show in filter"
                        >
                          <Switch />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                </Space>
              )}
            </Form.List>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default TenantConfiguration;
