import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
  Space,
  Typography,
  Form,
  Radio,
  Checkbox,
  Select,
  Input,
  Tooltip,
  Button,
  Tabs,
  Table,
  Tag,
  Spin,
  Card,
  Row,
  Col,
} from "antd";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import type { TabsProps } from "antd";
import type { TableProps, ColumnsType, TablePaginationConfig } from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import ErrorBoundary from "antd/es/alert/ErrorBoundary";
import { Export } from "../../../utils/types/export";
import { useAppDispatch } from "../../../redux/hooks";
import { listProducts } from "../../../redux/actions/productsAction";
import { listComponents, listComponentFilters } from "../../../redux/actions/componentsAction";
import { listSuppliers } from "../../../redux/actions/suppliersAction";
import { listExportRecords, createExportRecord } from "../../../redux/actions/exportDataAction";
import { baseUrl } from "../../../utils/apiHelper";
import { getLocalStorage, getRegulationsFromLocalStorage } from "../../../utils/localStore";
import axios from "axios";

const { Title, Paragraph, Text } = Typography;

const GenerateReport: React.FC = () => {
  const { regulationNumber } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [formReportLevel] = Form.useForm();
  const [searchProduct, setSearchProduct] = useState<any[]>([]);
  const [searchComponent, setSearchComponent] = useState<any[]>([]);
  const [searchSupplier, setSearchSupplier] = useState<any[]>([]);
  const [refreshState, setRefreshState] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingDropdown, setLoadingDropdown] = useState(false);
  const [exports, setExports] = useState<Export[]>([]);
  const [exportCount, setExportCount] = useState<number>(0);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
  });
  const [tableSorter, setTableSorter] = useState<SorterResult<Export>>({});
  const [tableFilters, setTableFilters] = useState<Record<string, FilterValue | null>>();
  const [currentTab, setCurrentTab] = useState("1");
  const [exportDataFields, setExportDataFields] = useState<[] | any>([]);
  const [fileDownloadLoading, setFileDownloadLoading] = useState(false);

  const user = getLocalStorage("user");
  const regulations = getRegulationsFromLocalStorage() || [];

  const handleProductSearch = (search?: any) => {
    setLoadingDropdown(true);
    dispatch(
      listProducts({
        search: true,
        pageNum: 1,
        pageSize: 10,
        searchField: search?.length ? search : "",
        softDelete: false,
        filters: encodeURIComponent(
          JSON.stringify({
            $or: [
              { number: { $regex: search, $options: "i" } },
              { name: { $regex: search, $options: "i" } },
            ],
          })
        ),
      })
    )
      .then((response: any) => {
        setSearchProduct(
          response.products.map((e: any) => ({
            label: e.name + ": " + e.number,
            value: e._id,
            ...e,
          }))
        );
        setLoadingDropdown(false);
      })
      .catch((e) => {
        console.log(e);
        setLoadingDropdown(false);
      });
  };

  const handleComponentSearch = (search?: any) => {
    setLoadingDropdown(true);
    dispatch(
      listComponents({
        search: true,
        pageNum: 1,
        pageSize: 10,
        searchField: search?.length ? search : "",
        softDelete: false,
        filters: encodeURIComponent(
          JSON.stringify({
            $or: [
              { internalItemNumber: { $regex: search, $options: "i" } },
              { "manufacturer.name": { $regex: search, $options: "i" } },
              { "manufacturer.itemNumber": { $regex: search, $options: "i" } },
            ],
          })
        ),
      })
    )
      .then((response: any) => {
        setSearchComponent(
          response.components.map((e: any) => ({
            label: e.manufacturer.name + ": " + e.manufacturer.itemNumber,
            value: e._id,
            ...e,
          }))
        );
        setLoadingDropdown(false);
      })
      .catch((e) => {
        console.log(e);
        setLoadingDropdown(false);
      });
  };

  const handleSupplierSearch = (search?: any) => {
    setLoadingDropdown(true);
    dispatch(
      listSuppliers({
        search: true,
        pageNum: 1,
        pageSize: 10,
        searchField: search?.length ? search : "",
        softDelete: false,
        filters: encodeURIComponent(
          JSON.stringify({
            $or: [
              { name: { $regex: search, $options: "i" } },
              { number: { $regex: search, $options: "i" } },
            ],
          })
        ),
      })
    )
      .then((response: any) => {
        setSearchSupplier(
          response.suppliers.map((e: any) => ({
            label: e.name + ": " + e.number,
            value: e.name,
            ...e,
          }))
        );
        setLoadingDropdown(false);
      })
      .catch((e) => {
        console.log(e);
        setLoadingDropdown(false);
      });
  };

  const fetchData = (payload?: any) => {
    setLoading(true);
    dispatch(
      listExportRecords({
        pageSize: tablePagination.pageSize,
        pageNum: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.column ? (tableSorter.order === "descend" ? false : true) : false,
        filters: payload ? JSON.stringify(payload) : null,
      })
    )
      .then((response) => {
        setExports(response.exports);
        setExportCount(response.exportCount);
        if (
          response.exports.filter((e) => e.status === "initiated" || e.status === "processing")
            .length
        ) {
          setTimeout(() => {
            fetchData({
              exportTypeData: regulationNumber,
            });
          }, 6000);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onClickDownload = (record: any) => {
    setFileDownloadLoading(true);
    axios
      .post(
        baseUrl + "/exportrecords/document/download",
        {
          tenantNumber: getLocalStorage("user").tenantNumber,
          filename: record.filename,
          foldername: record.exportType,
        },
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: `Bearer ${getLocalStorage("accessToken")}`,
          },
        }
      )
      .then((response: any) => {
        const blobPDF = new Blob([response.data], {
          type: "text/csv;charset=utf-8;",
        });
        const blobURL = window.URL.createObjectURL(blobPDF);
        const fileLink = document.createElement("a");
        fileLink.href = blobURL;
        fileLink.download = record.filename;
        fileLink.click();
        setFileDownloadLoading(false);
      })
      .catch(() => {
        setFileDownloadLoading(false);
      });
  };

  const columns: ColumnsType<Export> = [
    {
      title: "Action",
      key: "_id",
      width: 150,
      render: (_, record: Export) =>
        (record.status === "complete" || record.status === "incomplete") && (
          <>
            <Button
              type="text"
              icon={<DownloadOutlined />}
              title="Download"
              onClick={() => onClickDownload(record)}
            />
            <Spin fullscreen spinning={fileDownloadLoading} tip="Downloading..." />
          </>
        ),
    },
    {
      title: "Filename",
      dataIndex: "filename",
      key: "filename",
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (text: string) => {
        let color = "blue";
        if (text === "complete") {
          color = "green";
        } else if (text === "initiated") {
          color = "orange";
        } else if (text === "failed") {
          color = "red";
        }
        return <Tag color={color}>{text.toUpperCase()}</Tag>;
      },
    },
    {
      title: "Module",
      dataIndex: "exportType",
      key: "exportType",
      render: (_, record: Export) => (
        <Text>
          {" "}
          {record.exportTypeData && record.exportType
            ? record.exportType + "-" + record.exportTypeData.toUpperCase()
            : record.exportType
            ? record.exportType
            : record.exportTypeData}
        </Text>
      ),
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string, record: any) => (
        <Text>
          {new Date(text).toLocaleString()}
          <br />
          {record.createdBy}
        </Text>
      ),
    },
  ];

  const handleTableChange: TableProps<Export>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Export> | SorterResult<Export>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<Export>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      setExports([]);
    }
  };

  const onFormSubmit = (values: any) => {
    setLoading(true);
    dispatch(
      createExportRecord({
        exportType: values.reportLevel,
        exportTypeData: regulationNumber,
        exportFilters: {
          products: values.productRefs || null,
          components: values.componentRefs || null,
          suppliers: values.supplierRefs || null,
        },
        exportDataFields: exportDataFields,
      })
    ).then(() => {
      fetchData({
        exportTypeData: regulationNumber,
      });
      setCurrentTab("3");
      setLoading(false);
    });
  };

  const [filterData, setFilterData] = useState<any>({});
  const [loadingData, setLoadingData] = useState<boolean>(false);

  const fetchFilterData = (columnName: string, columnSearch?: string, filterValue?: any) => {
    if (filterData[columnName]?.length > 0) {
      return;
    }
    setLoadingData(true);
    dispatch(
      listComponentFilters({
        column: columnName,
        value: columnSearch ?? "",
        filter: JSON.stringify(filterValue),
      })
    ).then((response: any) => {
      setFilterData((prev: any) => ({ ...prev, [columnName]: response }));
      setLoadingData(false);
    });
  };

  useEffect(() => {
    fetchData({
      exportTypeData: regulationNumber,
    });
  }, [JSON.stringify(tablePagination), JSON.stringify(tableFilters), JSON.stringify(tableSorter)]);

  useEffect(() => {
    setExportDataFields([
      ...user.gridConfiguration
        .filter((e: any) => e.type === formReportLevel.getFieldsValue().reportLevel)
        .map((e: any) => {
          if (formReportLevel.getFieldsValue().reportLevel !== "suppliers") {
            return {
              label: e.text,
              value: e.value,
              width: 25,
              style: {
                alignment: { wrapText: true },
              },
            };
          } else {
            return [];
          }
        })
        .flat(),
      ...user.productSpecifications
        .filter((e: any) => e.type === formReportLevel.getFieldsValue().reportLevel)
        .map((e: any) => ({
          label: e.text,
          value: e.value,
          width: 25,
          style: {
            alignment: { wrapText: true },
          },
        })),
      ...user.specifications
        .filter((e: any) => e.type === formReportLevel.getFieldsValue().reportLevel)
        .map((e: any) => ({
          label: e.text,
          value: e.value,
          width: 25,
          style: {
            alignment: { wrapText: true },
          },
        })),
      ...regulations
        .find((e: any) => e.regulationNumber === regulationNumber)
        .configuration.map((e: any) => {
          if (e.value === "internalItemNumber") {
            if (
              !user.gridConfiguration
                .filter((u: any) => u.type === formReportLevel.getFieldsValue().reportLevel)
                .find((u: any) => u.text === e.text)
            ) {
              return {
                label: e.text,
                value: e.value,
                width: 40,
                style: {
                  alignment: { wrapText: true },
                },
              };
            } else {
              return [];
            }
          } else if (e.value === "manufacturer") {
            if (
              !user.gridConfiguration
                .filter((u: any) => u.type === formReportLevel.getFieldsValue().reportLevel)
                .find((u: any) => u.text === "Manufacturer Name")
            ) {
              return [
                {
                  label: "Manufacturer Name",
                  value: "manufacturer.name",
                  width: 40,
                  style: {
                    alignment: { wrapText: true },
                  },
                },
                {
                  label: "Manufacturer Item Number",
                  value: "manufacturer.itemNumber",
                  width: 40,
                  style: {
                    alignment: { wrapText: true },
                  },
                },
              ];
            } else {
              return [];
            }
          } else {
            return {
              label: e.text,
              value: "compliance." + e.value,
              width: 25,
              style: {
                alignment: { wrapText: true },
              },
            };
          }
        })
        .flat(),
    ]);
  }, [formReportLevel.getFieldsValue().reportLevel]);

  useEffect(() => {
    // Check for 'tab' query parameter and set the active tab
    const params = new URLSearchParams(location.search);
    const tab = params.get("tab");
    if (tab) {
      setCurrentTab(tab);
    }
  }, [location.search]);

  const handleTabChange = (key: string) => {
    setCurrentTab(key);
    // Update the URL query parameter when the tab changes
    const params = new URLSearchParams(location.search);
    params.set("tab", key);
    navigate({ search: params.toString() });
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Report Level",
      children: (
        <Form form={formReportLevel} layout="vertical" initialValues={{}} onFinish={onFormSubmit}>
          <Paragraph>
            <strong>Step 1.</strong> Choose your desired report level.
          </Paragraph>
          <Form.Item
            // label="Step 1. Select your report level"
            name="reportLevel"
            rules={[
              {
                required: true,
                message: "Please select reporting level",
              },
            ]}
          >
            <Radio.Group
              onChange={(value: any) => {
                if (value.target.value === "products") {
                  handleProductSearch();
                }
                if (value.target.value === "components") {
                  handleComponentSearch();
                }
                if (value.target.value === "suppliers") {
                  handleSupplierSearch();
                }
                setRefreshState(value.target.value);
              }}
            >
              <Radio value="products">Product</Radio>
              <Radio value="components">Component</Radio>
              <Radio value="suppliers">Supplier</Radio>
            </Radio.Group>
          </Form.Item>
          {formReportLevel.getFieldValue("reportLevel") === null ||
          formReportLevel.getFieldValue("reportLevel") === "" ||
          !formReportLevel.getFieldValue("reportLevel") ? (
            <Text type="warning">Please select report level to continue</Text>
          ) : (
            <>
              <Paragraph>
                <strong>Step 2.</strong> Search for and select the desired name and number of the
                product, component, or supplier.
              </Paragraph>
              {formReportLevel.getFieldValue("reportLevel") === "products" ? (
                <Form.Item
                  label="Products"
                  name="productRefs"
                  rules={[
                    {
                      required: true,
                      message: "Please select atleast one product",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    options={searchProduct}
                    onSearch={handleProductSearch}
                    allowClear
                    notFoundContent={loadingDropdown ? <Spin size="small" /> : null}
                    defaultActiveFirstOption={false}
                    suffixIcon={null}
                    filterOption={false}
                    placeholder="Search and select single product"
                  />
                </Form.Item>
              ) : null}
              {formReportLevel.getFieldValue("reportLevel") === "components" ? (
                <Form.Item
                  label="Components"
                  name="componentRefs"
                  rules={[
                    {
                      required: true,
                      message: "Please select atleast one component",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    options={searchComponent}
                    onSearch={handleComponentSearch}
                    allowClear
                    mode="multiple"
                    // maxTagCount="responsive"
                    // maxTagPlaceholder={(searchComponent: any) => (
                    //   <Tooltip
                    //     title={searchComponent
                    //       .map((component: any) => component.label)
                    //       .join(", ")}
                    //   >
                    //     <span>
                    //       {"+ "}
                    //       {searchComponent.length}
                    //       {""}
                    //     </span>
                    //   </Tooltip>
                    // )}
                    notFoundContent={loadingDropdown ? <Spin size="small" /> : null}
                    defaultActiveFirstOption={false}
                    suffixIcon={null}
                    filterOption={false}
                    placeholder="Search and select components"
                  />
                </Form.Item>
              ) : null}
              {formReportLevel.getFieldValue("reportLevel") === "suppliers" ? (
                <Form.Item
                  label="Suppliers"
                  name="supplierRefs"
                  rules={[
                    {
                      required: true,
                      message: "Please select atleast one supplier",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    options={searchSupplier}
                    onSearch={handleSupplierSearch}
                    allowClear
                    mode="multiple"
                    // maxTagCount="responsive"
                    // maxTagPlaceholder={(searchSupplier: any) => (
                    //   <Tooltip
                    //     title={searchSupplier
                    //       .map((supplier: any) => supplier.label)
                    //       .join(", ")}
                    //   >
                    //     <span>
                    //       {"+ "}
                    //       {searchSupplier.length}
                    //       {""}
                    //     </span>
                    //   </Tooltip>
                    // )}
                    notFoundContent={loadingDropdown ? <Spin size="small" /> : null}
                    defaultActiveFirstOption={false}
                    suffixIcon={null}
                    filterOption={false}
                    placeholder="Search and select suppliers"
                  />
                </Form.Item>
              ) : null}
              <Paragraph>
                <strong>Step 3.</strong> Click on the &#39;Generate Report&#39; button to proceed.
                If you&#39;d like more customization, feel free to explore the &#39;Advanced
                Options&#39; button for additional settings.
              </Paragraph>
              <Form.Item>
                <Space>
                  <Button type="primary" htmlType="submit">
                    Generate Report
                  </Button>
                  <Button type="default" onClick={() => setCurrentTab("2")}>
                    Advance Options
                  </Button>
                </Space>
              </Form.Item>
            </>
          )}
        </Form>
      ),
    },
    {
      key: "2",
      label: "Advance Options (Customize Report)",
      children: (
        <Space direction="vertical">
          <Card>
            <Title level={5}>Choose the fields to include in your report.</Title>
            {exportDataFields.length > 0 &&
              exportDataFields.map((e: any) => (
                <Tag
                  key={e.label}
                  color="processing"
                  closable
                  onClose={() => {
                    setExportDataFields((prev: any) => {
                      const index = prev.findIndex((p: any) => p.label === e.label);
                      prev.splice(index, 1);
                      return prev;
                    });
                  }}
                  style={{ margin: "0.25rem" }}
                >
                  {e.label}
                </Tag>
              ))}
          </Card>
          {/* <Card>
            <Title level={5}>Apply additional filters to refine the data</Title>
            <Form layout="vertical">
              <Row gutter={[15, 15]}>
                {regulations
                  .find((e: any) => e.regulationNumber === regulationNumber)
                  .configuration.map((item: any) => {
                    if (
                      item.value !== "internalItemNumber" &&
                      item.value !== "manufacturer"
                    ) {
                      return (
                        <Col span={6} key={item.value}>
                          <Form.Item name={item.value} label={item.text}>
                            <Select
                              showSearch
                              options={(filterData[item.value] || []).map(
                                (filter: any) => ({
                                  label: filter.text,
                                  value: filter.value,
                                })
                              )}
                              onSearch={() =>
                                fetchFilterData(
                                  item.value.includes("complianceOutputs")
                                    ? "compliances." + item.value
                                    : item.value,
                                  "",
                                  {
                                    "compliances.regulationNumber":
                                      regulationNumber,
                                  }
                                )
                              }
                              allowClear
                              notFoundContent={
                                loadingDropdown ? <Spin size="small" /> : null
                              }
                              defaultActiveFirstOption={false}
                              suffixIcon={null}
                              filterOption={false}
                              placeholder={"Select " + item.text}
                            />
                          </Form.Item>
                        </Col>
                      );
                    }
                  })}
              </Row>
            </Form>
          </Card> */}
          {formReportLevel.getFieldsValue().reportLevel === "products" &&
            !formReportLevel.getFieldsValue().productRefs && (
              <Text type="warning">Please select product in report level to continue</Text>
            )}
          {formReportLevel.getFieldsValue().reportLevel === "components" &&
            !formReportLevel.getFieldsValue().componentRefs && (
              <Text type="warning">Please select components in report level to continue</Text>
            )}
          {formReportLevel.getFieldsValue().reportLevel === "suppliers" &&
            !formReportLevel.getFieldsValue().supplierRefs && (
              <Text type="warning">Please select suppliers in report level to continue</Text>
            )}
          <Space>
            <Button type="primary" onClick={() => formReportLevel.submit()}>
              Generate Report
            </Button>
            <Button type="default" onClick={() => setCurrentTab("1")}>
              Change Report Level
            </Button>
          </Space>
          {/* <Checkbox.Group
            options={user.gridConfiguration
              .filter(
                (e: any) =>
                  e.type === formReportLevel.getFieldsValue().reportLevel
              )
              .map((e: any) => ({
                label: e.text,
                value: e.value,
              }))}
            defaultValue={[]}
            onChange={(value: any) => console.log(value)}
          /> */}
        </Space>
      ),
      disabled: !formReportLevel.getFieldsValue().reportLevel,
    },
    {
      key: "3",
      label: "Previous Downloads",
      children: (
        <Table
          loading={loading}
          dataSource={exports}
          scroll={{
            x: 1000,
            y: "calc(100vh - 285px)",
          }}
          columns={columns}
          rowKey="_id"
          pagination={{
            ...tablePagination,
            total: exportCount,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (totalCount) => `Total  ${totalCount}  items`,
          }}
          onChange={handleTableChange}
        />
      ),
      icon:
        exports.filter((e: any) => e.status === "initiated" || e.status === "processing").length >
        0 ? (
          <LoadingOutlined />
        ) : null,
    },
  ];

  return (
    <>
      <ErrorBoundary>
        <Space
          align="end"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <div>
            <Title level={5} style={{ marginBottom: 0 }}>
              Export Reports
            </Title>
          </div>
        </Space>
        <Tabs
          items={items}
          activeKey={currentTab}
          onChange={handleTabChange} // Use the updated handler
        />
      </ErrorBoundary>
    </>
  );
};

export default GenerateReport;
