import { privateGetApi, privatePostApi } from "../../utils/apiHelper";
import {
  privateConnectSSE,
  disconnectSSE,
} from "../../utils/notificationHelper/notificationHelper";
import {
  setNotifications,
  setUnreadCount,
  setLoading,
  markAsRead,
} from "../../redux/slices/notificationsSlice";
import { AppDispatch, RootState } from "../../redux/store";
import { addTimestamp } from "../../utils/notificationHelper/notificationHelper";

// To connect to the SSE Server
export const connectSSE = () => async (dispatch: AppDispatch) => {
  await privateConnectSSE("/notifications", dispatch);
};

// Fetch notification history with pagination
export const fetchNotificationHistory =
  (page = 1, unreadOnly = false) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const params = {
        page: page.toString(),
        limit: "10",
        ...(unreadOnly && { status: "unread" }),
      };

      const response = await privateGetApi(`/notifications/history`, params);
      let notifications = response.data.data.notifications || [];

      // Group notifications before dispatching
      notifications = addTimestamp(notifications);

      if (response.status === 200) {
        const currentNotifications = getState().notifications.notifications;
        const allNotifications =
          page === 1 ? notifications : [...currentNotifications, ...notifications];
        dispatch(setNotifications(allNotifications));
        dispatch(setUnreadCount(response.data.data.unreadCount || 0));
        return notifications; // Return the fetched notifications
      }
    } catch (error) {
      console.error("Failed to fetch notifications:", error);
    } finally {
      dispatch(setLoading(false));
    }
  };

// Fetch unread notification count
export const fetchUnreadCount = () => async (dispatch: AppDispatch) => {
  try {
    const response = await privateGetApi("/notifications/unread-count", { status: "unread" });
    if (response.status === 200) {
      dispatch(setUnreadCount(response.data.unreadCount || 0));
    }
  } catch (error) {
    console.error("Failed to fetch unread count:", error);
  }
};

// Mark notifications as read
export const markNotificationsAsRead =
  (notificationIds: string[]) => async (dispatch: AppDispatch) => {
    try {
      await privatePostApi("/notifications/mark-as-read", { notificationIds });
      dispatch(markAsRead(notificationIds));
    } catch (error) {
      console.error("Error marking notifications as read:", error);
    }
  };

// Download a file associated with a notification
export const downloadNotificationFile =
  (folderName: string, fileName: string, user: any) => async () => {
    try {
      const response = await privatePostApi(
        "/exportrecords/document/download",
        {
          tenantNumber: user.tenantNumber,
          filename: fileName,
          foldername: folderName,
        },
        {
          responseType: "arraybuffer",
        }
      );

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: "text/csv;charset=utf-8;" });
        const blobURL = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobURL;
        link.download = fileName;
        link.click();
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

// To disconnect from SSE server (on logout)
export const disconnectSSEServer = () => async () => {
  await disconnectSSE();
};
