/**
 * Redux store configuration.
 * @module redux/store
 */
import { configureStore } from "@reduxjs/toolkit";
import productsSlice from "./slices/productsSlice";
import tenantsSlice from "./slices/tenantsSlice";
import ConflictMineralsSlice from "./slices/conflictMineralsSlice";
import extendedmineralsSlice from "./slices/extendedMineralsSlice";
import componentsSlice from "./slices/componentsSlice";
import suppliersSlice from "./slices/suppliersSlice";
import campaignManagers from "./slices/CampaignManager/campaignManagersSlice";
import lists from "./slices/CampaignManager/listsSlice";
import supplierPortalSlice from "./slices/supplierPortalSlice";
import blogReducer from "./slices/blogSlice";
import blogsCategoryReducer from "./slices/blogsCategoriesSlice";
import bulletinReducer from "./slices/bulletinsSlice";
import notificationsReducer from "./slices/notificationsSlice";
/**
 * Redux store instance.
 * @type {Object}
 * @property {Object} reducer - The root reducer object that manages the state of the store.
 * @property {Object} reducer.products - The products slice reducer.
 */
const store = configureStore({
  reducer: {
    products: productsSlice,
    tenants: tenantsSlice,
    ConflictMinerals: ConflictMineralsSlice,
    extendeMinerals: extendedmineralsSlice,
    components: componentsSlice,
    suppliers: suppliersSlice,
    campaignManagers: campaignManagers,
    lists: lists,
    supplierPortal: supplierPortalSlice,
    blogs: blogReducer,
    blogsCategory: blogsCategoryReducer,
    bulletinsData: bulletinReducer,
    notifications: notificationsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
