import React, { useEffect, useState } from "react";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  Space,
  Button,
  Drawer,
  AutoComplete,
  Typography,
  Tooltip,
  InputNumber,
  notification,
} from "antd";

import { useAppDispatch } from "../../redux/hooks";
import {
  updateComponent,
  getAutoCompleteData,
} from "../../redux/actions/componentsAction";
import {
  createCompliance,
  createBulkCompliance,
  updateBulkCompliance,
} from "../../redux/actions/compliancesAction";
import {
  updateSupplierCompliances,
  updateSupplierCompliancesBulk,
} from "../../redux/actions/supplierPortalAction";
import {
  listRegulationSubtances,
  listRegulationExemptions,
} from "../../redux/actions/regulationsAction";
import FileUploader from "../layouts/FileUploader";
import {
  getLocalStorage,
  getRegulationsFromLocalStorage,
} from "../../utils/localStore";
import { Tour } from "antd";
import type { TourProps } from "antd";
import { useRef } from "react";
import Compliance3 from "../../assets/supplier portal/compliance/env-compliance3.png";
import Compliance4 from "../../assets/supplier portal/compliance/env-compliance4.png";
import Compliance5 from "../../assets/supplier portal/compliance/env-compliance5.png";
import Compliance6 from "../../assets/supplier portal/compliance/env-compliance6.png";
import Compliance7 from "../../assets/supplier portal/compliance/env-compliance7.png";
import Compliance1 from "../../assets/supplier portal/compliance/env-compliance1.png";
import Compliance2 from "../../assets/supplier portal/compliance/env-compliance2.png";
import Support1 from "../../assets/supplier portal/support/1.png";

const { Text } = Typography;

const ComplianceForm: React.FC<{
  type: string;
  fetchData: any;
  formData?: any;
  formRegulations?: any;
  campaignId?: any;
  onChildClick: any;
  show?: any;
  setShow?: any;
  showTourGuide?: boolean;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [searchProduct, setSearchProduct] = useState<any[]>([]);
  const [searchExemptions, setSearchExemptions] = useState<any[]>([]);
  const [searchSubstances, setSearchSubstances] = useState<any[]>([]);
  const [configuration, setConfiguration] = useState<any[]>([]);
  const { tenantNumber } = getLocalStorage("user");
  const regulations = getRegulationsFromLocalStorage() ?? [];
  const [dropdownDisabled, setDropdownDisabled] = useState("");
  const [substanceDisabled, setSubstanceDisabled] = useState("");
  const [exemptionFilled, setExemptionFilled] = useState([]);
  const [substanceFilled, setSubstanceFilled] = useState([]);
  const editButtonRef = useRef(null);
  const substanceOfConcernRef = useRef(null);
  const compliantRef = useRef(null);
  const versionRef = useRef(null);
  const uploadRef = useRef(null);
  const submitRef = useRef(null);
  const componentRef = useRef(null);
  const exemptionRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const steps1: TourProps["steps"] = [
    {
      title: "Step 1: Submit Compliance Information",
      description: (
        <p>
          Click on the{" "}
          <b>
            <i>Edit</i>
          </b>{" "}
          button to submit compliance information for each requested part.
        </p>
      ),
      cover: <img alt="" src={Compliance1} />,
      target: () => editButtonRef.current,
      placement: "right",
    },
    {
      title: "Step 2: Select Multiple Parts for Bulk Submission (Optional)",
      description: (
        <p>
          Select the checkbox for parts with common declarations and click on{" "}
          <b>
            <i>‘Submit Compliance’</i>
          </b>{" "}
          button to submit data for multiple parts at once.
        </p>
      ),
      nextButtonProps: { children: "Next", onClick: () => setOpen(true) },
      cover: <img alt="" src={Compliance2} />,
    },
    {
      title: "Step 3: Fill Compliance Fields",
      description: (
        <>
          <p>In the Edit Compliance sidebar:</p>
          <ul>
            <li>
              Review the selected Part Number(s) in <b>Component Reference</b>{" "}
              field
            </li>
            <li>
              Use the dropdown to fill applicable compliance data fields (e.g.,
              Compliance Status, Version, Exemptions, Substances of Concern…etc)
            </li>
          </ul>
        </>
      ),
      cover: <img alt="" src={Compliance3} />,
      // target: () => componentRef.current,
      prevButtonProps: { children: "Previous", onClick: () => setOpen(false) },
      placement: "center",
    },
    {
      title: "Step 4: Upload Compliance Document",
      description: (
        <p>
          Click{" "}
          <b>
            <i>&lsquo;Click to Upload&rsquo;</i>
          </b>{" "}
          button to attach the respective Certificate of Compliance/Statement of
          Compliance to the selected component(s).
        </p>
      ),
      cover: <img alt="" src={Compliance4} />,
      target: () => uploadRef.current,
      placement: "left",
    },
    {
      title: "Step 5: Finish Submission",
      description: (
        <p>
          Once all applicable fields are updated, click the{" "}
          <b>
            <i>‘Submit’</i>
          </b>{" "}
          button to complete the submission process.
        </p>
      ),
      cover: <img alt="" src={Compliance5} />,
      target: () => submitRef.current,
      placement: "left",
      nextButtonProps: { children: "Next", onClick: () => setOpen(false) },
    },
    {
      title: "Step 6: Reviewing the Submitted Data",
      description: (
        <>
          <p>Ensure everything is in order!</p>
          <ul>
            <li>Make sure your uploaded document is visible and correct.</li>
            <li>
              Verify that all required compliance details are accurately
              displayed for each component in the relevant regulation&apos;s
              campaign tab like EU ROHS, EU REACH, CA Prop 65…etc.
            </li>
          </ul>
        </>
      ),
      cover: <img alt="" src={Compliance6} />,
      prevButtonProps: { children: "Previous", onClick: () => setOpen(true) },
      placement: "center",
    },
    {
      title: "Step 7: Important: Get Help If Needed",
      description: (
        <>
          <p>
            Double-check your upload for completeness. If you need assistance or
            have questions:
          </p>
          <ul>
            <li>
              <b>Compliance/Part Queries:</b> Click Contact Support
            </li>
            <li>
              <b>Technical Issues/Feedback:</b> Click Technical Support
            </li>
          </ul>
          <p>Our support team is here to help you via phone or email.</p>
        </>
      ),
      cover: <img alt="" src={Compliance7} />,
      placement: "center",
    },
  ];

  const handleSearch = (search?: any) => {
    if (search.length > 0) {
      dispatch(
        getAutoCompleteData({
          search: true,
          pageNum: 1,
          pageSize: 10,
          query: search,
          softDelete: false,
          filters:
            search && search?.length
              ? encodeURIComponent(
                  JSON.stringify({
                    $or: [
                      {
                        "manufacturer.itemNumber": {
                          $regex: search,
                          $options: "i",
                        },
                      },
                      {
                        "manufacturer.name": { $regex: search, $options: "i" },
                      },
                    ],
                  })
                )
              : [],
        })
      )
        .then((response: any) => {
          setSearchProduct(
            response?.autoCompleteComponents[0]?.paginatedResults?.map(
              (e: any) => ({
                value: e.manufacturer?.itemNumber,
                ...e,
              })
            )
          );
        })
        .catch((error: any) => console.log(error));
    }
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    form.resetFields();
    setOpen(false);
    setDropdownDisabled("");
    props.fetchData();
    props.onChildClick();
  };

  const handleSearchExemptions = (search: any, regulation: any) => {
    dispatch(
      listRegulationExemptions({
        search: true,
        pageNum: 1,
        pageSize: 10,
        query: search,
        softDelete: false,
        filters: search
          ? JSON.stringify({
              softDelete: false,
              $or: [
                {
                  exemption: {
                    $regex: search.replace(/[()/[\]]/g, "\\$&"),
                    $options: "i",
                  },
                },
              ],
              regulationNumber: regulation,
            })
          : JSON.stringify({
              regulationNumber: regulation,
            }),
      })
    )
      .then((response: any) => {
        setSearchExemptions(
          response.exemptions.map((e: any) => ({
            value: e.exemption,
            ...e,
          }))
        );
      })
      .catch((error: any) => console.log(error));
  };

  const handleSearchSubstances = (search: any, regulation: any) => {
    dispatch(
      listRegulationSubtances({
        search: true,
        pageNum: 1,
        pageSize: 10,
        query: search,
        softDelete: false,
        filters: search
          ? JSON.stringify({
              softDelete: false,
              $or: [
                {
                  substance: {
                    $regex: search.replace(/[()/[\]]/g, "\\$&"),
                    $options: "i",
                  },
                },
              ],
              regulationNumber: regulation,
            })
          : JSON.stringify({
              regulationNumber: regulation,
            }),
      })
    )
      .then((response: any) => {
        setSearchSubstances(
          response.substances.map((e: any) => ({
            value: e.substance,
            ...e,
          }))
        );
      })
      .catch((error: any) => console.log(error));
  };

  const onFormSubmit = (values: any) => {
    setLoading(true);
    if (props.type === "create") {
      const componentRefs = searchProduct.filter((item: any) =>
        values?.componentRefs.includes(item?.manufacturer?.itemNumber)
      );
      const compliances = {
        ...values,
        componentId: componentRefs.map((item: any) => item._id).toString(),
        manufacturer: componentRefs?.map((item: any) => item?.manufacturer)[0],
        regulationNumber: props.formRegulations?.regulationNumber,
      };
      dispatch(createCompliance(compliances))
        .then(() => {
          props.fetchData();
          form.resetFields();
          setLoading(false);
          setOpen(false);
          onClose();
        })
        .catch(() => setLoading(false));
    } else if (props.type === "update" && props.campaignId && props.formData) {
      delete values.componentId;
      dispatch(
        updateSupplierCompliances({
          updates: {
            ...values,
            regulationNumber: props.formRegulations?.regulationNumber,
          },
          componentId: props?.formData.map((e: any) => e._id),
          campaignId: props.campaignId,
          dates: getLocalStorage("expireAt"),
          tenantNumber: tenantNumber,
          updateType: "compliance",
        })
      )
        .then(() => {
          props.fetchData();
          form.resetFields();
          setLoading(false);
          setOpen(false);
          onClose();
        })
        .catch(() => setLoading(false));
      // } else if (props.type === "bulk" && props.campaignId && props.formData) {
      //   console.log(values);
    } else if (props.type === "bulk" && props.campaignId && props.formData) {
      delete values.componentId;
      dispatch(
        updateSupplierCompliancesBulk({
          updates: {
            ...values,
            regulationNumber: props.formRegulations?.regulationNumber,
          },
          componentId: props?.formData.map((e: any) => e._id),
          campaignId: props.campaignId,
          dates: getLocalStorage("expireAt"),
          tenantNumber: tenantNumber,
          updateType: "compliance",
        })
      )
        .then(() => {
          props.fetchData();
          form.resetFields();
          setLoading(false);
          setOpen(false);
          onClose();
        })
        .catch(() => setLoading(false));
      // } else if (props.type === "bulk" && props.campaignId && props.formData) {
      //   console.log(values);
    } else if (props.type === "bulk") {
      const updates = Object.fromEntries(
        Object.entries(values).filter(([key, value]) => value !== undefined)
      );
      updates["regulationNumber"] = props.formRegulations?.regulationNumber;
      if (
        props?.formData
          .filter((e: any) => !e.regulationNumber)
          .map((e: any) => e.componentId).length
      ) {
        dispatch(
          createBulkCompliance({
            id: props?.formData
              ?.filter((e: any) => !e.regulationNumber)
              .map((e: any) => e.componentId),
            updates,
          })
        ).then(() => {
          props.fetchData();
          form.resetFields();
          setLoading(false);
          setOpen(false);
          onClose();
        });
      }
      if (
        props?.formData
          .filter((e: any) => e.regulationNumber)
          .map((e: any) => e.regulationNumber).length
      ) {
        if (
          props?.formData
            .filter((e: any) => e.regulationNumber)
            .map((e: any) => e.regulationNumber)
            .includes("gadsl_(mds)")
        ) {
          form.resetFields();
          setLoading(false);
          setOpen(false);
          onClose();
          notification.info({
            message: "Processing",
            description: "Updating Component data. Please wait...",
          });
        }
        dispatch(
          updateBulkCompliance({
            id: {
              component: props?.formData
                ?.filter((e: any) => e._id)
                .map((e: any) => e.componentId),
              compliance: props?.formData
                ?.filter((e: any) => e.regulationNumber)
                .map((e: any) => e.regulationNumber),
            },
            updates: updates,
          })
        ).then(() => {
          props.fetchData();
          if (
            !props?.formData
              .filter((e: any) => e.regulationNumber)
              .map((e: any) => e.regulationNumber)
              .includes("gadsl_(mds)")
          ) {
            form.resetFields();
            setLoading(false);
            setOpen(false);
            onClose();
          } else {
            notification.success({
              message: "Success",
              description: "Component data successfully updated.",
            });
          }
        });
      }
    } else if (props.formData) {
      dispatch(updateComponent({ updates: values }, props.formData._id))
        .then(() => {
          props.fetchData();
          form.resetFields();
          setLoading(false);
          setOpen(false);
          onClose();
        })
        .catch(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (open && props.formRegulations.regulationNumber) {
      if (props.type === "create") {
        handleSearch("aa");
        handleSearchExemptions("", props?.formRegulations?.regulationNumber);
        handleSearchSubstances("", props?.formRegulations?.regulationNumber);
        setConfiguration(
          regulations?.find(
            (v: any) =>
              v.regulationNumber === props?.formRegulations?.regulationNumber
          ).configuration
        );
      } else if (
        (props.type === "bulk" || props.type === "update") &&
        props.campaignId
      ) {
        handleSearchExemptions("", props?.formRegulations?.regulationNumber);
        handleSearchSubstances("", props?.formRegulations?.regulationNumber);
        setConfiguration(props.formRegulations.configuration);
      } else if (props.type === "bulk") {
        setSearchProduct(
          props?.formData.map((item: any) => ({
            ...item,
            value: item?.manufacturer?.itemNumber,
          }))
        );
        handleSearchExemptions("", props?.formRegulations?.regulationNumber);
        handleSearchSubstances("", props?.formRegulations?.regulationNumber);
        setConfiguration(
          regulations?.find(
            (v: any) =>
              v.regulationNumber === props?.formRegulations?.regulationNumber
          ).configuration
        );
      } else if (props.type === "update") {
        handleSearchExemptions("", props?.formRegulations?.regulationNumber);
        handleSearchSubstances("", props?.formRegulations?.regulationNumber);
        setConfiguration(
          regulations?.find(
            (v: any) =>
              v.regulationNumber === props?.formRegulations?.regulationNumber
          ).configuration
        );
      }
    }
  }, [open]);

  return (
    <div>
      {props.showTourGuide && (
        <Tour
          open={props.show}
          onClose={() => {
            props.setShow(false);
            setOpen(false);
          }}
          steps={steps1}
          disabledInteraction={true}
        />
      )}
      <Button
        type={
          props.type === "create" || props.type === "bulk" ? "primary" : "text"
        }
        data-testid={
          props.type === "create"
            ? "create-compliance-button"
            : "edit-compliance-button"
        }
        title={props.type === "create" ? "New Compliance" : "Edit Compliance"}
        onClick={() => showDrawer()}
        icon={props.type === "create" ? <PlusOutlined /> : <EditOutlined />}
        ref={editButtonRef}
      >
        {props.type === "create"
          ? "Add Compliance"
          : props.type === "bulk"
          ? "Submit Compliance"
          : null}
      </Button>
      <Drawer
        title={props.type === "create" ? "Add Compliance" : "Edit Compliance"}
        width={720}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button onClick={onClose} data-testid="cancel-compliance-button">
              Cancel
            </Button>
            <Button
              onClick={form.submit}
              type="primary"
              loading={loading}
              data-testid="submit-compliance-button"
              ref={submitRef}
              disabled={isUploading}
            >
              Submit
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={props.type === "create" ? {} : { ...props.formData }}
          onFinish={onFormSubmit}
        >
          {(props.type === "bulk" || props.type === "update") && (
            <Row gutter={24}>
              <Col span={24} sm={24} md={24} ref={componentRef}>
                <Form.Item
                  name="componentId"
                  label="Component Reference"
                  initialValue={props.formData?.map(
                    (item: any) => item?.manufacturer?.itemNumber
                  )}
                >
                  <Select
                    options={searchProduct}
                    onSearch={handleSearch}
                    allowClear
                    mode="multiple"
                    disabled
                    maxTagCount="responsive"
                    maxTagPlaceholder={(searchProduct) => (
                      <Tooltip
                        title={searchProduct
                          .map(({ label }) => label)
                          .join(", ")}
                      >
                        <span>
                          {"+ "}
                          {searchProduct.length}
                          {""}
                        </span>
                      </Tooltip>
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          {props.type === "create" && (
            <Row gutter={24}>
              <Col span={12} sm={12} md={12}>
                <Form.Item
                  name="componentRefs"
                  label="Component Reference"
                  rules={[
                    {
                      required: true,
                      message: "Please input Component Reference!",
                    },
                  ]}
                >
                  <AutoComplete
                    options={searchProduct.map((option) => ({
                      ...option,
                      label: (
                        <>
                          <div>{option?.manufacturer?.name} </div>
                          <div> {option?.manufacturer?.itemNumber} </div>
                        </>
                      ),
                    }))}
                    onSearch={handleSearch}
                    placeholder="Type to select Component Reference"
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={12} sm={12} md={12}>
                <Form.Item
                  name="regulationNumber"
                  label="Regulation Number"
                  initialValue={props.formRegulations?.name}
                >
                  <AutoComplete allowClear disabled />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row gutter={24}>
            {configuration
              ?.filter((e: any) => e.edit)
              .map((item: any) => (
                <Col
                  key={`${item.regulationNumber}.${item.value}`}
                  span={12}
                  sm={12}
                  md={12}
                  ref={
                    item.value === "complianceOutputs.version"
                      ? versionRef
                      : null || item.value === "complianceOutputs.compliant"
                      ? compliantRef
                      : null || item.dataValue === "substance"
                      ? substanceOfConcernRef
                      : null || item.dataValue === "exemption"
                      ? exemptionRef
                      : null
                  }
                >
                  <Form.Item
                    name={item.value}
                    label={
                      item.regulationNumber === "gadsl_(mds)" &&
                      item.value === "complianceDocument" ? (
                        <Text>
                          {item.text}{" "}
                          <small>
                            <b>{"(PDF)"}</b>
                          </small>
                        </Text>
                      ) : (
                        item.text
                      )
                    }
                    rules={
                      item.value === "complianceOutputs.compliant" ||
                      (dropdownDisabled.toLowerCase().includes("exemption") &&
                        item.dataValue === "exemption" &&
                        !exemptionFilled.length)
                        ? [
                            {
                              required: true,
                              message: `Please input ${item.text}!`,
                            },
                          ]
                        : dropdownDisabled.toLowerCase() === "no" &&
                          item.dataValue === "substance" &&
                          !substanceFilled.length
                        ? [
                            {
                              required: true,
                              message: `Please input ${item.text}!`,
                            },
                          ]
                        : item.regulationNumber === "gadsl_(mds)" &&
                          item.value === "complianceDocument"
                        ? [
                            {
                              required: true,
                              message: `Please input ${item.text}!`,
                            },
                          ]
                        : []
                    }
                  >
                    {item.dataType === "Multilist" &&
                    item.dataValue === "exemption" ? (
                      <Select
                        options={searchExemptions.map((option) => ({
                          ...option,
                          label: (
                            <Text>
                              {option.exemption}
                              {/* <br></br>
                              <small>{option.description}</small> */}
                            </Text>
                          ),
                        }))}
                        mode="multiple"
                        onSearch={(value) =>
                          handleSearchExemptions(
                            value,
                            props?.formRegulations?.regulationNumber
                          )
                        }
                        placeholder="Type to search Exemptions"
                        allowClear
                        showSearch
                        disabled={
                          dropdownDisabled.toLowerCase().includes("exemption")
                            ? false
                            : true
                        }
                        onChange={(value) => {
                          setExemptionFilled(value);
                          form.validateFields([item.value]);
                        }}
                      />
                    ) : item.dataType === "Multilist" &&
                      item.dataValue === "substance" ? (
                      <Select
                        options={searchSubstances.map((option) => ({
                          ...option,
                          label: (
                            <Text>
                              {option.substance}
                              <br></br>
                              <small>{option.casNumber}</small>
                            </Text>
                          ),
                        }))}
                        mode="tags"
                        onSearch={(value) =>
                          handleSearchSubstances(
                            value,
                            props?.formRegulations?.regulationNumber
                          )
                        }
                        placeholder="Type to search Substance of Concern"
                        allowClear
                        showSearch
                        disabled={
                          dropdownDisabled.toLowerCase() === "no" ? false : true
                        }
                        onChange={(value: any) => {
                          setSubstanceDisabled(value);
                          setSubstanceFilled(value);
                          form.validateFields([item.value]);
                        }}
                      />
                    ) : item.dataType === "Dropdown" ? (
                      <Select
                        placeholder={`Select ${item.text}`}
                        options={item.dataValue
                          .split(",")
                          .map((value: string) => ({ label: value, value }))}
                        onChange={(value) => {
                          item.value === "complianceOutputs.compliant" &&
                            setDropdownDisabled(value);
                          if (
                            item.value === "complianceOutputs.compliant" &&
                            value.toLowerCase() !== "no"
                          ) {
                            form.setFieldValue(
                              ["complianceOutputs.substance"],
                              []
                            );
                            setDropdownDisabled(value);
                          }
                          if (
                            item.value === "complianceOutputs.compliant" &&
                            !value.toLowerCase().includes("exemption")
                          ) {
                            form.setFieldValue(
                              ["complianceOutputs.exemption"],
                              []
                            );
                            setDropdownDisabled(value);
                          }
                        }}
                        disabled={
                          item.value ===
                            "complianceOutputs.concentrationUnit" &&
                          (dropdownDisabled.toLowerCase() === "no" &&
                          substanceDisabled.length
                            ? false
                            : true)
                        }
                      />
                    ) : item.dataType === "Fileinput" ? (
                      <span ref={uploadRef}>
                        <FileUploader
                          postUrl={
                            props.campaignId
                              ? "public/suppliers/document/upload"
                              : "compliances/document/upload"
                          }
                          tenantNumber={tenantNumber}
                          additionalData={{
                            campaignId: props.campaignId,
                            foldername: "campaign",
                          }}
                          setFormField={(value: string) =>
                            form.setFieldValue(`${item.value}`, value)
                          }
                          onUploadStatusChange={setIsUploading}
                          fileValidations={{
                            acceptedTypes:
                              props.formRegulations?.regulationNumber ===
                              "gadsl_(mds)"
                                ? ["application/pdf"]
                                : null,
                            maxSizeMB: 25,
                          }}
                          importType={
                            props.formRegulations?.regulationNumber ===
                            "gadsl_(mds)"
                              ? "gadsl_(mds)"
                              : ""
                          }
                        />
                      </span>
                    ) : item.dataType === "Number" ? (
                      <InputNumber
                        disabled={
                          dropdownDisabled.toLowerCase() === "no" &&
                          substanceDisabled.length
                            ? false
                            : true
                        }
                        style={{ width: 324 }}
                        min={0}
                      />
                    ) : (
                      <Input />
                    )}
                  </Form.Item>
                </Col>
              ))}
          </Row>
        </Form>
      </Drawer>
    </div>
  );
};
export default ComplianceForm;
