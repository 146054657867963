import React, { useEffect, useState } from "react";
import { Component } from "../../../../utils/types/component";
import { useParams, useNavigate } from "react-router-dom";

import { useAppDispatch } from "../../../../redux/hooks";
import {
  Space,
  Typography,
  Button,
  Table,
  Input,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";

import type {
  FilterValue,
  SorterResult,
  TableRowSelection,
} from "antd/es/table/interface";

import { readSupplierComponents } from "../../../../redux/actions/suppliersAction";
import {
  readComponentChildren,
  listComponentFilters,
} from "../../../../redux/actions/componentsAction";
import { Regulation } from "../../../../utils/types/regulation";
import {
  getLocalStorage,
  getRegulationsFromLocalStorage,
} from "../../../../utils/localStore";
import {
  ControlOutlined,
  ExportOutlined,
  EyeOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import ReadSupportingDocument from "../../../../components/modals/ReadSupportingDocuments";
import AdvanceFilters from "../../../../components/modals/AdvanceFilters";

// const [advanceFilter, setAdvanceFilter] = useState<boolean>(false);

const { Search } = Input;
const { Title, Text } = Typography;

const SupplierComponents: React.FC<{
  components: Component[];
  count: number;
  childLoading: boolean;
  type: string;
}> = ({ components, count, childLoading, type }) => {
  const [supplierComponents, setSupplierComponents] = useState<Component[]>([]);
  const [supplierComponentsLength, setSupplierComponentsLength] =
    useState<number>(0);
  const [activeTab, setActiveTab] = useState<string>("all");

  const regulations = getRegulationsFromLocalStorage() ?? [];
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState<any>();
  const navigate = useNavigate();
  const user = getLocalStorage("user");
  const parseUser = user ? user : null;
  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;
  const onSearch = (value: any) => {
    setSearch({
      searchField: true,
      query:
        value?.key === "Enter" ? value?.target?.value.trim() : value.trim(),
    });
    setTablePagination({
      pageSize: tablePagination.pageSize,
      current: 1,
    });
  };
  const [loading, setLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<Component>>({});

  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [filterData, setFilterData] = useState<any>({});
  const [advanceFilter, setAdvanceFilter] = useState<boolean>(false);
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});
  const [selectedSupplier, setSelectedSupplier] = useState<{
    key: string;
    value: any;
  } | null>(null);
  const [maxDisplayCompliance, setmaxDisplayCompliance] = useState<number>(4);
  const [activeKey, setActiveKey] = useState<string>("all");

  const fetchData = () => {
    const filters: { [x: string]: FilterValue | null }[] = [];
    Object.keys(tableFilters).forEach((key) => {
      if (tableFilters[key] && tableFilters[key]?.length) {
        filters.push({ [key]: tableFilters[key] });
      }
    });
    console.log("filters", filters);
    setLoading(true);
    dispatch(
      readSupplierComponents({
        id: id,
        pageSize: tablePagination.pageSize,
        pageNum: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.order === "descend" ? false : true,
        softDelete: false,
        searchField: search?.searchField ? search?.query : "",
        filtersUsed: filters.length ? "useFilters" : false,
        dataType: JSON.stringify({
          compliant: "Dropdown",
          version: "Dropdown",
        }),
        filters:
          search?.query && search?.query.length
            ? encodeURIComponent(
                JSON.stringify({
                  elements: search?.query,
                  path: search?.suppliersPath,
                })
              )
            : filters.length
            ? JSON.stringify({
                elements: filters,
                path: [],
              })
            : [],
      })
    )
      .then((response: any) => {
        setSupplierComponents(response.documentComponents);
        setSupplierComponentsLength(response.documentComponentsLength);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const fetchFilterData = (
    columnName: string,
    columnSearch?: string,
    filterValue?: any
  ) => {
    setLoadingData(true);
    dispatch(
      listComponentFilters({
        column: columnName,
        value: columnSearch ?? "",
        filter: JSON.stringify(filterValue),
        supplierId: id,
      })
    ).then((response: any) => {
      setFilterData((prev: any) => ({ ...prev, [columnName]: response })); // Update state dynamically
      setLoadingData(false);
    });
  };

  const fetchAdvanceFilterData = (
    columnName: string,
    columnSearch?: string,
    filterValue?: any
  ) => {
    let updatedColumnName = columnName;
    if (columnName.includes("compliance")) {
      updatedColumnName = `compliances.${columnName}`;
    }
    setLoadingData(true);
    dispatch(
      listComponentFilters({
        column: updatedColumnName,
        value: columnSearch ?? "",
        filter: JSON.stringify(filterValue),
        supplierId: id,
      })
    ).then((response: any) => {
      setFilterData((prev: any) => ({ ...prev, [columnName]: response })); // Update state dynamically
      setLoadingData(false);
    });
  };
  const onSelectsupplier = (
    newSelectedRowKeys: React.Key[],
    selectedRows: Component[]
  ) => {
    setSelectedSupplier({
      key: activeTab,
      value: selectedRows,
    });
  };

  // const supplierSelection: TableRowSelection<Component> = {
  //   selectedRowKeys:
  //     selectedSupplier && selectedSupplier.value
  //       ? selectedSupplier?.value?.map((e: any) =>
  //           e.supplierId ? e.supplierId : e._id
  //         )
  //       : [],
  //   onChange: onSelectsupplier,
  //   preserveSelectedRowKeys: true,
  // };
  // const onChildClick = () => {
  //   setSelectedSupplier([]);
  // };

  // const handleTabChange = (key: any) => {
  //   setActiveTab(key);
  //   setSelectedSupplier(null);
  // };

  const handleTableChange: TableProps<Component>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Component> | SorterResult<Component>[]
  ) => {
    setTablePagination(pagination);
    const entries = Object.entries(filters);
    const filteredEntries = entries.filter(([key, value]) => value !== null);
    const cleanFilters = Object.fromEntries(filteredEntries);
    setTablePagination(pagination);
    !advanceFilter && setTableFilters(cleanFilters);
    setTableSorter(sorter as SorterResult<Component>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      // setComponents([]);
    }
  };
  // const onChildClick = () => {
  //   setSelectedSupplier(null);
  // };
  const onClickViewLink = (filename: string) => {
    window.open(filename, "_blank");
  };

  useEffect(() => {
    fetchData();
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setmaxDisplayCompliance(2);
      } else if (window.innerWidth < 1024) {
        setmaxDisplayCompliance(3);
      } else {
        setmaxDisplayCompliance(7);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
    search,
  ]);

  interface Item {
    _id: string;
    componentId: string;
    internalItemNumber: string;
    manufacturer: object;
    compliances?: Compliance[];
    [key: string]: any;
  }

  interface Compliance {
    regulationNumber: string;
    complianceOutputs?: object;
    complianceDocument?: string;
  }

  interface componentProfileParams {
    id: string;
  }

  const componentsList = (
    type === "emitChild" ? components : supplierComponents
  )?.map((item: any) => {
    const itemValue: Item = { ...item, componentId: item._id };

    regulations?.forEach((e: Regulation) => {
      const compliance = item.compliances?.find(
        (o: any) => o.regulationNumber === e.regulationNumber
      );
      itemValue[e.regulationNumber] = compliance
        ? {
            ...compliance,
            internalItemNumber: item.internalItemNumber,
            manufacturer: item.manufacturer,
            componentId: item._id,
          }
        : {
            internalItemNumber: item.internalItemNumber,
            manufacturer: item.manufacturer,
            componentId: item._id,
          };
    });

    return itemValue;
  });

  const handlePreviewClick = (record: any) => {
    const params: componentProfileParams = {
      id: record?.componentId ? record?.componentId : record._id,
    };
    const route = `/components/${params.id}`;
    const currentRoute = window.location.pathname;
    const basePath = currentRoute.split("/").slice(0, 2).join("/");
    const finalPath = basePath + route;
    navigate(finalPath);
  };

  const columns: ColumnsType<Component> = [
    {
      title: "Action",
      key: "operation",
      width: 150,
      fixed: "left",
      render: (text: string, record: any) => (
        <Space>
          <Button
            type="text"
            icon={<EyeOutlined />}
            onClick={() => handlePreviewClick(record)}
            data-testid="preview-component-button"
            title="Preview"
          />
          {/* <Dropdown menu={menu(record)}>
            <Space>
              <Button type="text" icon={<MoreOutlined />} />
            </Space>
          </Dropdown> */}
        </Space>
      ),
    },
    {
      title: "Internal #",
      dataIndex: "internalItemNumber",
      key: "internalItemNumber",
      sorter: true,
      // onFilterDropdownOpenChange: (open: boolean) =>
      //   open && fetchFilterData("internalItemNumber"),
      // filters: filterData?.internalItemNumber || [], // Use fetched filter data
      // filteredValue: tableFilters.internalItemNumber || null,
      // filterSearch: true,
      fixed: "left",
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
      sorter: true,
      fixed: "left",
      render: (manufacturer: any) => (
        <Text>
          {manufacturer.itemNumber}
          <br></br>
          <small>{manufacturer.name}</small>
        </Text>
      ),
    },
    ...(parseUser?.specifications || [])
      .filter((o: any) => !o.hidden)
      .map((e: any) => ({
        title: e.text.charAt(0).toUpperCase() + e.text.slice(1),
        dataIndex: `specification.${e.value}`,
        key: `specification.${e.value}`,
        sorter: true,
        render(_: any, record: any) {
          if (e.dataType === "Dropdown") {
            if (
              record.specification?.lifecycle?.toUpperCase().trim() === "ACTIVE"
            ) {
              return <Tag color="green">{record.specification?.lifecycle}</Tag>;
            } else if (
              record.specification?.lifecycle?.toUpperCase().trim() === "NRND"
            ) {
              return (
                <Tag color="orange">{record.specification?.lifecycle}</Tag>
              );
            } else if (
              record.specification?.lifecycle?.toUpperCase().trim() ===
              "UNKNOWN"
            ) {
              return <Tag color="grey">{record.specification?.lifecycle}</Tag>;
            } else {
              return record.specification && record.specification?.lifecycle ? (
                <Tag color="red">{record.specification?.lifecycle}</Tag>
              ) : (
                ""
              );
            }
          } else if (e.dataType === "Fileinput") {
            return (
              <>
                {record?.specification &&
                record?.specification[e.value]?.includes("http") ? (
                  <span>
                    <ExportOutlined
                      onClick={() =>
                        onClickViewLink(record?.specification[e.value])
                      }
                    />{" "}
                    Link
                  </span>
                ) : (
                  <ReadSupportingDocument
                    postUrl="products/components/document/download"
                    filename={
                      record?.specification && record?.specification[e.value]
                    }
                    foldername="components"
                  />
                )}
              </>
            );
          } else if (e.dataType === "Dateinput") {
            return record?.specification && record?.specification[e.value]
              ? new Date(record?.specification[e.value])?.toLocaleDateString()
              : "";
          } else {
            return record.specification ? record.specification[e.value] : "";
          }
        },
      })),
    permissions?.read.includes("materials") && {
      title: "FMD Document",
      dataIndex: "fmdDocument",
      key: "fmdDocument",
      render: (text: string) => {
        return text ? (
          text?.includes("http") ? (
            <span>
              <ExportOutlined onClick={() => onClickViewLink(text)} /> Link
            </span>
          ) : (
            <ReadSupportingDocument
              postUrl="materials/document/download"
              filename={text}
              foldername="materials"
            />
          )
        ) : null;
      },
    },
    permissions?.read.includes("materials") && {
      title: "Part Weight",
      dataIndex: "partWeight",
      key: "partWeight",
    },
    ...(regulations || []).map((e: Regulation) => ({
      title: e.name,
      children: e.configuration
        .filter(
          (o) =>
            !o.hidden &&
            o.value !== "manufacturer" &&
            o.value !== "internalItemNumber" &&
            o.value !== "operations"
        )
        .map((k: any) => ({
          title: k.text,
          dataIndex: `${e.regulationNumber}.${k.value}`,
          key: `${e.regulationNumber}.${k.value}`,
          render: (value: any, record: any) => {
            const compliances = record.compliances.find(
              (item: any) => item.regulationNumber === e.regulationNumber
            );
            if (k.value === "complianceDocument") {
              return compliances && compliances.complianceDocument ? (
                compliances?.complianceDocument?.includes("http") ? (
                  <span>
                    <ExportOutlined
                      onClick={() =>
                        onClickViewLink(compliances?.complianceDocument)
                      }
                    />{" "}
                    Link
                  </span>
                ) : (
                  <ReadSupportingDocument
                    postUrl="compliances/document/download"
                    filename={compliances.complianceDocument}
                    foldername="compliances"
                  />
                )
              ) : null;
            } else if (k.value.includes("complianceOutputs.compliant")) {
              if (
                compliances &&
                compliances.complianceOutputs &&
                compliances.complianceOutputs?.compliant?.toUpperCase() ===
                  "YES"
              ) {
                return <Tag color="green"> YES </Tag>;
              } else if (
                compliances &&
                compliances.complianceOutputs &&
                compliances.complianceOutputs?.compliant?.toUpperCase() === "NO"
              ) {
                return <Tag color="red"> NO </Tag>;
              } else if (
                compliances &&
                compliances.complianceOutputs &&
                compliances.complianceOutputs.compliant?.toUpperCase() ===
                  "YES WITH EXEMPTION"
              ) {
                return <Tag color="orange"> YES WITH EXEMPTION </Tag>;
              } else if (
                compliances &&
                compliances.complianceOutputs &&
                compliances.complianceOutputs?.compliant?.toUpperCase() ===
                  "UNKNOWN"
              ) {
                return <Tag color="default"> UNKNOWN </Tag>;
              } else {
                return compliances && compliances.complianceOutputs ? (
                  <Tag color="grey">
                    {compliances &&
                      compliances.complianceOutputs &&
                      compliances.complianceOutputs.compliant}
                  </Tag>
                ) : (
                  ""
                );
              }
            } else if (k.value.includes("complianceOutputs.status")) {
              if (
                compliances &&
                compliances.complianceOutputs &&
                compliances.complianceOutputs.status?.toUpperCase() ===
                  "ACCEPTED"
              ) {
                return <Tag color="green"> ACCEPTED </Tag>;
              } else if (
                compliances &&
                compliances.complianceOutputs &&
                compliances.complianceOutputs.status?.toUpperCase() ===
                  "REJECTED"
              ) {
                return <Tag color="red"> REJECTED </Tag>;
              } else if (
                compliances &&
                compliances.complianceOutputs &&
                compliances.complianceOutputs.status?.toUpperCase() ===
                  "MODIFIED"
              ) {
                return <Tag color="orange"> MODIFIED </Tag>;
              } else if (
                compliances &&
                compliances.complianceOutputs &&
                compliances.complianceOutputs.status?.toUpperCase() ===
                  "BROWSED"
              ) {
                return <Tag color="orange"> BROWSED </Tag>;
              } else if (
                compliances &&
                compliances.complianceOutputs &&
                compliances.complianceOutputs.status?.toUpperCase() ===
                  "NOT YET BROWSED"
              ) {
                return <Tag color="red"> NOT YET BROWSED </Tag>;
              }
            } else if (k.value.includes("complianceOutputs.version")) {
              return (
                <Text>{`${
                  compliances &&
                  compliances.complianceOutputs &&
                  compliances.complianceOutputs.version
                    ? compliances.complianceOutputs.version
                    : ""
                }`}</Text>
              );
            } else if (k.value.includes("complianceOutputs.exemption")) {
              return (
                <Text
                  style={{ width: 200 }}
                  ellipsis={{
                    tooltip:
                      compliances?.complianceOutputs?.exemption?.toString(),
                  }}
                >
                  {compliances &&
                  compliances.complianceOutputs &&
                  compliances.complianceOutputs.exemption
                    ? compliances.complianceOutputs.exemption?.toString()
                    : ""}
                </Text>
              );
            } else if (k.value.includes("complianceOutputs.substance")) {
              return (
                <Text
                  style={{ width: 200 }}
                  ellipsis={{
                    tooltip:
                      compliances?.complianceOutputs?.substance?.toString(),
                  }}
                >
                  {compliances &&
                  compliances.complianceOutputs &&
                  compliances.complianceOutputs.substance
                    ? compliances.complianceOutputs.substance?.toString()
                    : ""}
                </Text>
              );
            } else {
              return (
                <Text>
                  {compliances && compliances?.complianceOutputs
                    ? compliances?.complianceOutputs[
                        k.value?.split(".").pop().toString()
                      ]
                    : ""}
                </Text>
              );
            }
          },
        })),
    })),
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      render: (text: string, record: any) => (
        <Text>{new Date(text).toLocaleString()}</Text>
      ),
    },
  ].filter(Boolean);

  const fmdHeaders = [
    {
      title: "Action",
      key: "operation",
      width: 150,
      render: (text: any, record: any) => (
        <Space>
          <Button
            type="text"
            icon={<EyeOutlined />}
            onClick={() => handlePreviewClick(record)}
          />
        </Space>
      ),
    },
    {
      title: "Internal #",
      dataIndex: "internalItemNumber",
      key: "internalItemNumber",
      sorter: true,
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
      sorter: true,
      render: (manufacturer: any) => (
        <Text>
          {" "}
          {manufacturer.itemNumber} <br></br>
          <small>{manufacturer.name}</small>
        </Text>
      ),
      // `${manufacturer.name} ${manufacturer.itemNumber}`,
    },
    {
      title: "FMD Document",
      dataIndex: "fmdDocument",
      key: "fmdDocument",
      render: (text: string) => {
        return text ? (
          text?.includes("http") ? (
            <span>
              <ExportOutlined onClick={() => onClickViewLink(text)} /> Link
            </span>
          ) : (
            <ReadSupportingDocument
              postUrl="materials/document/download"
              filename={text}
              foldername="materials"
            />
          )
        ) : null;
      },
    },
    {
      title: "Weight",
      dataIndex: "partWeight",
      key: "partWeight",
    },
  ];
  const regulationsTabs = regulations.map((regulation: any) => ({
    key: regulation.regulationNumber,
    label: regulation.name,
    children: (
      <Table
        loading={type === "emitChild" ? childLoading : loading}
        dataSource={
          componentsList &&
          componentsList?.map((e: any) => e[regulation.regulationNumber])
        }
        scroll={{
          x: 1000,
          y: "calc(100vh - 285px)",
        }}
        columns={[
          {
            title: "Action",
            key: "operation",
            width: 150,
            render: (_, record) => (
              <Space>
                <Button
                  type="text"
                  icon={<EyeOutlined />}
                  onClick={() => handlePreviewClick(record)}
                />
              </Space>
            ),
          },
          ...regulation.configuration
            .filter((e: any) => !e.hidden)
            .map((k: any, i: any) => ({
              title: `${k.text}`,
              dataIndex: `${k.value}`,
              key: `${k.value}`,
              width: k.value === "complianceOutputs.substance" ? 255 : 180,
              render: (value: any, record: any) => {
                if (k.value === "internalItemNumber") {
                  return (
                    <>
                      <Text>{`${record?.internalItemNumber}`}</Text>
                    </>
                  );
                }
                if (k.value === "manufacturer") {
                  return (
                    <Text>
                      {`${value?.name}`} <br></br>
                      {`${value?.itemNumber}`}
                    </Text>
                  );
                } else if (k.value === "complianceDocument") {
                  return value ? (
                    value?.includes("http") ? (
                      <span>
                        <ExportOutlined
                          onClick={() => onClickViewLink(value)}
                        />{" "}
                        Link
                      </span>
                    ) : (
                      <ReadSupportingDocument
                        postUrl="compliances/document/download"
                        filename={value}
                        foldername="compliances"
                      />
                    )
                  ) : null;
                } else if (k.value.includes("complianceOutputs.compliant")) {
                  if (
                    record &&
                    record.complianceOutputs &&
                    record.complianceOutputs.compliant.toUpperCase() === "YES"
                  ) {
                    return <Tag color="green"> YES </Tag>;
                  } else if (
                    record &&
                    record.complianceOutputs &&
                    record.complianceOutputs.compliant.toUpperCase() === "NO"
                  ) {
                    return <Tag color="red"> NO </Tag>;
                  } else if (
                    record &&
                    record.complianceOutputs &&
                    record.complianceOutputs.compliant?.toUpperCase() ===
                      "YES WITH EXEMPTION"
                  ) {
                    return <Tag color="orange"> YES WITH EXEMPTION </Tag>;
                  } else if (
                    record &&
                    record.complianceOutputs &&
                    record.complianceOutputs.compliant.toUpperCase() ===
                      "UNKNOWN"
                  ) {
                    return <Tag color="default"> UNKNOWN </Tag>;
                  } else {
                    return record &&
                      record.complianceOutputs &&
                      record.complianceOutputs.compliant &&
                      record.complianceOutputs.compliant ? (
                      <Tag color="grey">
                        {" "}
                        {record &&
                          record.complianceOutputs &&
                          record.complianceOutputs.compliant &&
                          record.complianceOutputs.compliant}{" "}
                      </Tag>
                    ) : (
                      ""
                    );
                  }
                } else if (k.value.includes("complianceOutputs.status")) {
                  if (
                    record &&
                    record.complianceOutputs &&
                    record.complianceOutputs.status?.toUpperCase() ===
                      "ACCEPTED"
                  ) {
                    return <Tag color="green"> ACCEPTED </Tag>;
                  } else if (
                    record &&
                    record.complianceOutputs &&
                    record.complianceOutputs.status?.toUpperCase() ===
                      "REJECTED"
                  ) {
                    return <Tag color="red"> REJECTED </Tag>;
                  } else if (
                    record &&
                    record.complianceOutputs &&
                    record.complianceOutputs.status?.toUpperCase() ===
                      "MODIFIED"
                  ) {
                    return <Tag color="orange"> MODIFIED </Tag>;
                  } else if (
                    record &&
                    record.complianceOutputs &&
                    record.complianceOutputs.status?.toUpperCase() === "BROWSED"
                  ) {
                    return <Tag color="orange"> BROWSED </Tag>;
                  } else if (
                    record &&
                    record.complianceOutputs &&
                    record.complianceOutputs.status?.toUpperCase() ===
                      "NOT YET BROWSED"
                  ) {
                    return <Tag color="red"> NOT YET BROWSED </Tag>;
                  }
                } else if (k.value.includes("complianceOutputs.version")) {
                  return (
                    <Text>{`${
                      record &&
                      record.complianceOutputs &&
                      record.complianceOutputs.version
                        ? record.complianceOutputs.version
                        : ""
                    }`}</Text>
                  );
                } else if (k.value.includes("complianceOutputs.exemption")) {
                  return (
                    <Text
                      style={{ width: 200 }}
                      ellipsis={{
                        tooltip:
                          record?.complianceOutputs?.exemption?.toString(),
                      }}
                    >
                      {record &&
                      record.complianceOutputs &&
                      record.complianceOutputs.exemption
                        ? record.complianceOutputs.exemption?.toString()
                        : ""}
                    </Text>
                  );
                } else if (k.value.includes("complianceOutputs.substance")) {
                  return (
                    <Text
                      style={{ width: 200 }}
                      ellipsis={{
                        tooltip:
                          record?.complianceOutputs?.substance?.toString(),
                      }}
                    >
                      {record &&
                      record.complianceOutputs &&
                      record.complianceOutputs.substance
                        ? record.complianceOutputs.substance?.toString()
                        : ""}
                    </Text>
                  );
                } else {
                  return (
                    <Text>
                      {record && record?.complianceOutputs
                        ? record?.complianceOutputs[
                            k.value?.split(".").pop().toString()
                          ]
                        : ""}
                    </Text>
                  );
                }
              },
            })),
        ]}
        rowKey="_id"
        pagination={{
          ...tablePagination,
          total: type === "emitChild" ? count : supplierComponentsLength,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (totalCount) => `Total  ${totalCount}  items`,
        }}
        onChange={handleTableChange}
      />
    ),
  }));

  const items = [
    {
      key: "all",
      label: "Specifications",
      children: (
        <Table
          loading={type === "emitChild" ? childLoading : loading}
          dataSource={type === "emitChild" ? components : supplierComponents}
          bordered
          scroll={{
            x: 1000 + 1000 * regulations.length,
            y: "calc(70vh - 285px)",
          }}
          columns={columns}
          pagination={{
            ...tablePagination,
            total: type === "emitChild" ? count : supplierComponentsLength,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (totalCount) => `Total  ${totalCount}  items`,
          }}
          rowKey="_id"
          onChange={handleTableChange}
        />
      ),
    },
    ...regulationsTabs,
    permissions?.read.includes("materials") && {
      key: "materials",
      label: "Material Disclosure",
      children: (
        <Table
          loading={type === "emitChild" ? childLoading : loading}
          dataSource={type === "emitChild" ? components : supplierComponents}
          scroll={{
            x: 1000,
            y: "calc(100vh - 285px)",
          }}
          columns={fmdHeaders}
          rowKey="_id"
          pagination={{
            ...tablePagination,
            total: type === "emitChild" ? count : supplierComponentsLength,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (totalCount) => `Total  ${totalCount}  items`,
          }}
          onChange={handleTableChange}
        />
      ),
    },
  ];

  const onChange = (key: string) => {
    setActiveKey(key);
  };
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const showDrawer = () => {
    setIsDrawerVisible(true);
  };

  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };
  const handleFiltersApply = (filters: Record<string, string[]>) => {
    setAdvanceFilter(true);
    setTableFilters(filters);
  };
  return (
    <div>
      <Space
        align="end"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <div>
          <Title level={5} style={{ marginBottom: 0 }}>
            Components
          </Title>
        </div>
        <Space>
          {/* {selectedSupplier?.length > 0 && (
            <Tag>Selections {selectedSupplier?.length}</Tag>
          )} */}
          {Object.values(tableFilters).some(
            (arr) => Array.isArray(arr) && arr.length > 0
          ) ? (
            <Button
              icon={<FilterOutlined />}
              onClick={() => {
                setTableFilters({});
                setSearch({});
                setAdvanceFilter(false);
              }}
            >
              Reset Filters
            </Button>
          ) : (
            <Search
              placeholder="Search using product name, number and description"
              onSearch={onSearch}
              onPressEnter={onSearch}
              allowClear
              style={{ width: 350 }}
            />
          )}
          <div>
            <Button
              type="primary"
              onClick={showDrawer}
              icon={<ControlOutlined />}
            >
              Advanced Filters
            </Button>

            <AdvanceFilters
              visible={isDrawerVisible}
              onClose={closeDrawer}
              onFiltersApply={handleFiltersApply}
              parentType="components"
              fetchFilterData={fetchAdvanceFilterData}
              filterData={filterData}
            />
          </div>
          {/* <AddToCampaignListModal
              selectedItems={selectedProducts}
              onCancel={() => setSelectedProducts([])}
              type="productAdd"
            /> */}
          {/* {permissions?.create.includes("products") && (
              <ProductModal type="create" fetchData={fetchData} />
            )}
            {selectedProducts.length > 0 && (
              <CreateBulkDeclarationModal
                products={selectedProducts}
                fetchData={onChildClick}
              />
            )} */}
          {/* <Button type="text" icon={<FilterOutlined />} /> */}
        </Space>
      </Space>
      {Object.keys(tableFilters).length > 0 &&
        Object.keys(tableFilters).reduce<JSX.Element[]>((acc, key) => {
          const elements =
            tableFilters[key]?.map((item, idx) => (
              <Tag
                className="filter-tag"
                key={`${key}-${idx}`}
                bordered={false}
                closable
                onClose={() => {
                  const tablefiltrs = { ...tableFilters };
                  const arr = tablefiltrs[key];
                  if (arr) {
                    const arrIndex = arr?.indexOf(item);
                    if (arrIndex !== -1) {
                      arr.splice(arrIndex, 1);
                    }
                    setTableFilters({ ...tablefiltrs });
                  }
                }}
              >
                {`${item}`}
                {/* {typeof item === "string" && item} */}
              </Tag>
            )) || [];
          acc.push(...elements);
          return acc;
        }, [])}
      <Tabs
        defaultActiveKey="all"
        activeKey={activeKey}
        items={items}
        tabPosition="top"
        onChange={onChange}
      />
    </div>
  );
};

export default SupplierComponents;
