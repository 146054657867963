import React, { useState, useEffect } from "react";
import { Form, Input, Button, Card, Typography, Spin, Flex } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../redux/hooks";
import {
  authResetPassword,
  authChangePassword,
} from "../../../redux/actions/authAction";
import ErrorBoundary from "../../../utils/errorBoundary";
import { getAllTenants } from "../../../redux/actions/tenantsAction";
import { useMsal } from "@azure/msal-react";
import publicClientApplication from "../../../utils/SSO/msalInstance";
import { msalConfig } from "../../../utils/SSO/authConfig";
import MicroSoftLogo from "../../../assets/icons/MicrosoftLogo.svg";

const { Paragraph } = Typography;

const ResetPassword: React.FC = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const resetToken = query.get("token");
  const emailAddress = query.get("emailAddress");
  const tenantNumber = query.get("tenantNumber");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [tenantLoading, setTenantLoading] = useState(false);
  const [enableSso, setEnableSso] = useState(false);
  const { instance } = useMsal();

  /**
   * Fetches the tenant details based on the provided email address and tenant number.
   * If both `emailAddress` and `tenantNumber` are provided, it fetches the tenant data and checks if a matching tenant exists.
   * @function
   * @returns {void}
   * @throws {Error} If an error occurs during the tenant data fetching process, it will be logged in the console.
   */
  useEffect(() => {
    if (tenantNumber && emailAddress) {
      setTenantLoading(true);

      dispatch(getAllTenants({ tenantNumber, emailAddress }))
        .then((data: any) => {
          console.log("sso enabled", data.enableSso);

          if (data) {
            setEnableSso(data.enableSso);
          } else {
            setEnableSso(false);
          }
        })
        .catch((error: any) => {
          console.error("Error fetching tenants:", error);
        })
        .finally(() => {
          setTenantLoading(false); // Stop loading after request
        });
    }
  }, [emailAddress, tenantNumber]);

  /**
   * Verifies the user via Single Sign-On (SSO) and navigates to the login page after successful authentication.
   * @async
   * @function
   * @returns {Promise<void>} A promise that resolves once the login process is complete.
   * @throws {Error} If there is any error during the login process or while navigating, it is logged to the console.
   */
  const VerifyWithSso = async (): Promise<void> => {
    setLoading(true); // Set loading process to true

    try {
      // Check if there's already an active account
      const currentAccount = instance?.getAllAccounts()?.[0];

      if (!currentAccount) {
        try {
          // Trigger the MSAL login popup
          const loginResponse = await publicClientApplication.loginPopup({
            scopes: msalConfig.auth.scopes, // Define the necessary scopes for login
          });

          if (!loginResponse.account) {
            console.error("Login response does not contain account info.");
            setLoading(false); // Stop loading on error
            return;
          }

          // Set the active account after successful login
          publicClientApplication.setActiveAccount(loginResponse.account);

          // // Navigate to the login page after successful login
          // navigate(`/login/${tenantNumber}`);
          // setLoading(false); // Stop loading after navigation
          // Re-run authentication with the newly set account
          return VerifyWithSso(); // Continue with new account info
        } catch (error) {
          console.error("Login failed:", error);
          setLoading(false); // Stop loading on login failure
        }
      }

      // Try to acquire the token silently, and renew it if expired
      let tokenResponse;
      try {
        tokenResponse = await publicClientApplication.acquireTokenSilent({
          scopes: msalConfig.auth.scopes,
          account: currentAccount,
        });
      } catch (error) {
        // Token acquisition failed (refresh token might have expired), proceed with login via popup
        try {
          const loginResponse = await publicClientApplication.loginPopup({
            scopes: msalConfig.auth.scopes,
          });

          if (!loginResponse.account) {
            console.error("Login response does not contain account info.");
            setLoading(false); // Stop loading state on error
            return; // Exit if no account info is found in the response
          }

          publicClientApplication.setActiveAccount(loginResponse.account); // Set the active account
          // Retry acquiring the token after successful login
          tokenResponse = await publicClientApplication.acquireTokenSilent({
            scopes: msalConfig.auth.scopes,
            account: loginResponse.account,
          });
        } catch (loginError) {
          console.error("Login failed during token renewal:", loginError);
          setLoading(false); // Stop loading state on error
          return; // Exit on login failure
        }
      }

      dispatch(
        authResetPassword(
          {
            name: currentAccount.name,
            emailAddress: emailAddress,
            enableSso: enableSso,
            ssoToken: tokenResponse?.idToken,
            tenantNumber,
            resetToken,
            currentLocationOrigin: window.location.origin,
          },
          "Account successfully verified using Microsoft Single Sign-On."
        )
      )
        .then((response: any) => {
          if (response.success) {
            navigate(`/login/${tenantNumber}`);
          }
        })
        .catch(() => {
          form.resetFields();
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error("Error during SSO login:", error); // Log error if something goes wrong
      setLoading(false); // Stop loading on error
    }
  };

  const onSubmit = (values: any) => {
    setLoading(true);
    const path = window.location.pathname.includes("setpassword");

    if (path) {
      dispatch(
        authResetPassword({
          ...values,
          resetToken,
          emailAddress,
          tenantNumber,
          currentLocationOrigin: window.location.origin,
        })
      )
        .then(() => {
          navigate(`/login/${tenantNumber}`);
        })
        .catch(() => {
          form.resetFields();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      dispatch(
        authChangePassword({
          ...values,
          resetToken,
          emailAddress,
          tenantNumber,
        })
      )
        .then(() => {
          navigate(`/login/${tenantNumber}`);
        })
        .catch(() => {
          form.resetFields();
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const path = window.location.pathname.includes("setpassword");

  return (
    <ErrorBoundary>
      {tenantLoading || enableSso === null ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <Card>
          <Paragraph
            strong
            style={{
              textAlign: "center",
            }}
          >
            {enableSso
              ? "Verify your account with Single Sign-On"
              : path
              ? "Create Password"
              : "Reset Password"}
          </Paragraph>

          <Form
            form={form}
            initialValues={{ emailAddress }}
            onFinish={!enableSso ? onSubmit : VerifyWithSso}
            style={{
              marginTop: "3rem",
              width: 400,
            }}
          >
            <Form.Item
              name="emailAddress"
              rules={[
                {
                  required: true,
                  message: "Please input your email address!",
                },
                {
                  type: "email",
                  message: "Please enter a valid email address",
                },
              ]}
            >
              <Input placeholder="Email Address" readOnly />
            </Form.Item>

            {!enableSso && (
              <>
                {" "}
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                    {
                      pattern:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^+-_#])[A-Za-z\d@$!%*?&^+-_#]{8,}$/,
                      message:
                        "Password must be minimum 8 characters, at least one uppercase letter, one lowercase letter, one number, and one special character",
                    },
                  ]}
                >
                  <Input.Password placeholder="Enter Password" />
                </Form.Item>
                <Form.Item
                  name="confirmPassword"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject("The passwords do not match!");
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="Confirm Password" />
                </Form.Item>
              </>
            )}

            <Flex justify={!enableSso ? "space-between" : "center"}>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                {!enableSso ? (
                  path ? (
                    "Create Password"
                  ) : (
                    "Reset Password"
                  )
                ) : (
                  <>
                    <img
                      src={MicroSoftLogo}
                      alt="Microsoft Logo"
                      width={20}
                      height={20}
                    />{" "}
                    Verfiy using Microsoft
                  </>
                )}
              </Button>
            </Flex>
          </Form>
          {/* )} */}
        </Card>
      )}
    </ErrorBoundary>
  );
};

export default ResetPassword;
