import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import "./index.css";

import { RouterProvider } from "react-router-dom";
import router from "./utils/router";

import { Provider } from "react-redux";
import store from "./redux/store";

import FusionCharts from "fusioncharts";
import Hotjar from "@hotjar/browser";
import * as Sentry from "@sentry/react";

import { ConfigProvider, App } from "antd";
import { MsalProvider } from "@azure/msal-react";
import msalInstance from "./utils/SSO/msalInstance";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
FusionCharts.options["license"]({
  key: process.env.REACT_APP_FUSION_CHARTS_KEY,
  creditLabel: false,
});

if (process.env.NODE_ENV === "production") {
  Hotjar.init(2820243, 6);
}

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://a8fe519b058554d47380c21d61cc775d@o578295.ingest.us.sentry.io/4507615725158400",
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    environment: process.env.NODE_ENV,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store as any}>
        <ConfigProvider>
          <App>
            <RouterProvider router={router} />
          </App>
        </ConfigProvider>
      </Provider>
    </MsalProvider>
  </React.StrictMode>
);

reportWebVitals();
