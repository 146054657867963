import React, { useState } from "react";
import { CheckCircleOutlined, RobotFilled } from "@ant-design/icons";
import { Button, notification, Popconfirm } from "antd";

import { useAppDispatch } from "../../redux/hooks";
import {
  actAiValidator,
  updateMaterial,
} from "../../redux/actions/materialsAction";
import {
  updateCampaignResponse,
  uploadCampaignDocument,
} from "../../redux/actions/CampaignManager/campaignManagerAction";
// import { PendingResponse } from '../../utils/types/CampaignManager/campaignManager';

const AddMaterialApprove: React.FC<{
  type: string;
  fetchData: any;
  formData?: any;
  sendgridCampaignId?: string;
  componentId?: string;
  subscriptionEnable?: boolean;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const confirm = () => {
    const formValues = props?.formData;
    const { internalItemNumber, createdAt, manufacturer, _id, ...updates } =
      formValues;

    dispatch(
      updateMaterial({
        updates: updates,
        campaignData: props.sendgridCampaignId,
      })
    )
      .then(() => {
        dispatch(
          updateCampaignResponse({
            filter: {
              sendgridCampaignId: props?.sendgridCampaignId,
              componentId: props?.formData?.componentId,
            },
            updates: { ...props?.formData, type: "approved" },
            responseType: "Material",
          })
        );
      })
      .then(() => {
        props?.formData?.fmdDocument &&
          dispatch(
            uploadCampaignDocument({
              foldername: "campaign",
              subfolder: "materials",
              campaignData: [
                {
                  supplierMaterialsResponse: props?.formData,
                  sendgridCampaignId: props?.sendgridCampaignId,
                },
              ],
            })
          );
      })
      .then(() => {
        props.fetchData();
      })
      .catch((e) => console.log(e));
  };

  const cancel = () => {
    props.fetchData();
  };
  const actAI = () => {
    setLoading(true);
    notification.info({
      message: "Processing",
      description: "Act AI is validating your data. Please wait...",
    });

    dispatch(
      actAiValidator({
        updates: props?.formData,
        campaignId: props?.sendgridCampaignId,
        componentId: props?.componentId,
      })
    )
      .then((response: any) => {
        setLoading(false);
        notification.success({
          message: "Success",
          description: `Validation complete! For Manufacturer Name: ${response.manufacturer.name} and Item Number: ${response.manufacturer.itemNumber}.`,
        });
        props.fetchData();
      })
      .catch(() => {
        console.log("Error");
        setLoading(false);
        notification.error({
          message: "Error",
          description:
            "An error occurred while validating your data. Please try again.",
        });
      });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
          marginBottom: "10px",
        }}
      >
        {props.formData?.fmdDocument &&
          !props.formData?.fmdDocument?.includes("xml") &&
          props.subscriptionEnable &&
          props.formData.status === "pending" && (
            <Button
              type={props?.type === "icon" ? "text" : "primary"}
              icon={<RobotFilled />}
              data-testid="act-ai-validator"
              title="Act AI Validator"
              onClick={actAI}
            >
              {props?.type === "button" && "Run ACT AI Validator"}
            </Button>
          )}
      </div>
      <div>
        <Popconfirm
          title="Approve Materials"
          description="Are you sure you want to approve selected Component Materials ?"
          onConfirm={confirm}
          onCancel={cancel}
          okText="Ok"
          cancelText="Cancel"
          placement="topLeft"
        >
          <Button
            type={props?.type === "icon" ? "text" : "primary"}
            icon={
              <CheckCircleOutlined
                style={{
                  fontSize: "16px",
                }}
              />
            }
            disabled={props.formData.status === "approved"}
            style={{
              backgroundColor:
                props.formData.status === "approved" ? "white" : "#52C41A",
              borderColor:
                props.formData.status === "approved" ? "#52C41A" : "#52C41A",
              color: props.formData.status === "approved" ? "#52C41A" : "white",
              width: props.formData.status === "approved" ? "200px" : "",
            }}
            data-testid="approve-materials-button"
            title={
              props.formData.status === "approved"
                ? undefined
                : "Approve Materials"
            }
          >
            {props?.formData.status === "approved"
              ? "Approved"
              : props?.type === "button" && "Approve"}
          </Button>
        </Popconfirm>
      </div>
    </>
  );
};

export default AddMaterialApprove;
