import React, { useEffect, useState } from "react";
import {
  ExportOutlined,
  ArrowRightOutlined,
  ExclamationCircleFilled,
  PicLeftOutlined,
  WarningFilled,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import {
  Space,
  Typography,
  List,
  Row,
  Col,
  Card,
  Table,
  Tooltip,
  Modal,
  Button,
  message,
  Avatar,
} from "antd";

import { PaginationConfig } from "antd/es/pagination";
import type { CollapseProps } from "antd";

import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";

import { useAppDispatch } from "../../../../../redux/hooks";
import {
  readCampaignComplianceResponse,
  updateCampaignResponse,
} from "../../../../../redux/actions/CampaignManager/campaignManagerAction";
import { useParams } from "react-router-dom";
import { SupplierResponse } from "../../../../../utils/types/CampaignManager/campaignManager";
import AddComplianceApprove from "../../../../../components/modals/addApproveComplianceModal";
import AddMaterialApprove from "../../../../../components/modals/addApproveMaterialsModal";
import ReadSupportingDocument from "../../../../../components/modals/ReadSupportingDocuments";
import ErrorBoundary from "../../../../../utils/errorBoundary";
import TableExpandMaterials from "../../../../../components/modals/table-expand-materials";
import validatorIcon from "../../../../../assets/timealert.svg";
import RejectModal from "../../../../../components/modals/RejectModal";

const { Text, Title } = Typography;

const CampaignResponse: React.FC = () => {
  const { id, name } = useParams<{ id: any; name: string }>();
  const dispatch = useAppDispatch();
  const [campaignResponse, setCampaignResponse] = useState<any[]>([]);
  const [campaignResponseCount, setCampaignResponseCount] = useState<number>(0);
  const [items, setItems] = useState<CollapseProps["items"]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [materialData, setMaterialData] = useState<any>();
  const [selectedItem, setSelectedItem] = useState<any>();
  const [itemStatus, setItemStatus] = useState<any>();

  const [listPagination, setListPagination] = useState<PaginationConfig>({
    pageSize: 10,
    current: 1,
    position: "bottom",
    align: "end",
  });
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<
    SorterResult<SupplierResponse>
  >({});
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});
  const [component, setComponent] = useState<SupplierResponse>();

  const columns: ColumnsType<SupplierResponse> = [
    {
      title: "Component",
      dataIndex: "manufacturer",
      key: "manufacturer",
      sorter: true,
      width: 150,
      render: (_, record: any) => {
        const imdsRegulation = record?.supplierComplianceResponse?.find(
          (item: { regulationNumber: string }) =>
            item.regulationNumber === "gadsl_(mds)"
        );
        return (
          <List.Item
            key={record._id}
            onClick={() => setComponent(record)}
            style={{ cursor: "pointer" }}
          >
            <List.Item.Meta
              title={
                <>
                  <Space
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Space>
                      <Text>
                        {record.manufacturer.name}{" "}
                        {subscriptionEnable &&
                        ((record?.supplierMaterialsResponse
                          ?.supplierPdfReader &&
                          record?.supplierMaterialsResponse
                            ?.supplierPdfReader !== null &&
                          record.manufacturer.name &&
                          record.supplierMaterialsResponse?.supplierPdfReader?.answers?.manufacturerName
                            ?.toLowerCase()
                            ?.includes("not provided")) ||
                          (imdsRegulation?.supplierPdfReader &&
                            imdsRegulation?.supplierPdfReader !== null &&
                            record.manufacturer.name &&
                            imdsRegulation?.supplierPdfReader?.answers?.manufacturerName
                              ?.toLowerCase()
                              ?.includes("not provided"))) ? (
                          <Tooltip
                            title={"Act AI: Error extracting manufacturer name"}
                          >
                            <WarningFilled
                              style={{ color: "orange", fontSize: "16px" }}
                            />
                          </Tooltip>
                        ) : (
                          subscriptionEnable &&
                          ((record?.supplierMaterialsResponse
                            ?.supplierPdfReader &&
                            record?.supplierMaterialsResponse
                              ?.supplierPdfReader !== null &&
                            record.manufacturer.name &&
                            !record.supplierMaterialsResponse?.supplierPdfReader?.answers?.manufacturerName
                              ?.toLowerCase()
                              ?.includes(
                                record.manufacturer.name.toLowerCase()
                              )) ||
                            (imdsRegulation?.supplierPdfReader &&
                              imdsRegulation?.supplierPdfReader !== null &&
                              record.manufacturer.name &&
                              !imdsRegulation?.supplierPdfReader?.answers?.manufacturerName
                                ?.toLowerCase()
                                ?.includes(
                                  record.manufacturer.name.toLowerCase()
                                ))) && (
                            <Tooltip
                              title={
                                "Act AI: Exact match not found for manufacturer name. Please verify."
                              }
                            >
                              <ExclamationCircleFilled
                                style={{ color: "red", marginLeft: "5px" }}
                              />
                            </Tooltip>
                          )
                        )}
                        <br></br>
                        {record.manufacturer.itemNumber}{" "}
                        {subscriptionEnable &&
                        ((record?.supplierMaterialsResponse
                          ?.supplierPdfReader &&
                          record.supplierMaterialsResponse.supplierPdfReader !==
                            null &&
                          record.manufacturer.itemNumber &&
                          record.supplierMaterialsResponse?.supplierPdfReader?.answers?.manufacturerItemNumber
                            ?.toLowerCase()
                            ?.includes("not provided")) ||
                          (imdsRegulation?.supplierPdfReader &&
                            imdsRegulation.supplierPdfReader !== null &&
                            record.manufacturer.itemNumber &&
                            imdsRegulation?.supplierPdfReader?.answers?.manufacturerItemNumber
                              ?.toLowerCase()
                              ?.includes("not provided"))) ? (
                          <Tooltip
                            title={
                              "Act AI: Error extracting manufacturer item number"
                            }
                          >
                            <WarningFilled
                              style={{ color: "orange", fontSize: "16px" }}
                            />
                          </Tooltip>
                        ) : (
                          subscriptionEnable &&
                          ((record?.supplierMaterialsResponse
                            ?.supplierPdfReader &&
                            record.supplierMaterialsResponse
                              .supplierPdfReader !== null &&
                            record.manufacturer.itemNumber &&
                            !record.supplierMaterialsResponse?.supplierPdfReader?.answers?.manufacturerItemNumber
                              ?.toLowerCase()
                              ?.includes(
                                record.manufacturer.itemNumber.toLowerCase()
                              )) ||
                            (imdsRegulation?.supplierPdfReader &&
                              imdsRegulation.supplierPdfReader !== null &&
                              record.manufacturer.itemNumber &&
                              !imdsRegulation?.supplierPdfReader?.answers?.manufacturerItemNumber
                                ?.toLowerCase()
                                ?.includes(
                                  record.manufacturer.itemNumber.toLowerCase()
                                ))) && (
                            <Tooltip
                              title={
                                "Act AI: Exact match not found for manufacturer item number. Please verify."
                              }
                            >
                              <ExclamationCircleFilled
                                style={{ color: "red", marginLeft: "5px" }}
                              />
                            </Tooltip>
                          )
                        )}
                      </Text>
                    </Space>
                    {component?._id === record._id && <ArrowRightOutlined />}
                  </Space>
                </>
              }
              description={
                <div>
                  {/* {record.supplierComplianceResponse.filter(
                  (e: any) => e.status !== "approved"
                ).length +
                  (record.supplierMaterialsResponse &&
                  record.supplierMaterialsResponse.status !== "approved"
                    ? 1
                    : 0) +
                  " Pending approvals and " +
                  (record.supplierComplianceResponse.filter(
                    (e: any) => e.status === "approved"
                  ).length +
                    (record.supplierMaterialsResponse &&
                    record.supplierMaterialsResponse.status !== "approved"
                      ? 1
                      : 0)) +
                  " approved"} */}
                  <span style={{ color: "#FFC72C", fontSize: "13px" }}>
                    <ExclamationCircleOutlined style={{ marginRight: 4 }} />
                    {record.supplierComplianceResponse.filter(
                      (e: any) =>
                        e.status !== "approved" && e.status !== "rejected"
                    ).length +
                      (record.supplierMaterialsResponse &&
                      record.supplierMaterialsResponse.status !== "approved" &&
                      record.supplierMaterialsResponse.status !== "rejected"
                        ? 1
                        : 0)}{" "}
                    Pending
                  </span>
                  <span
                    style={{
                      color: "#03C03C",
                      fontSize: "13px",
                      marginLeft: 15,
                    }}
                  >
                    <CheckCircleOutlined style={{ marginRight: 4 }} />
                    {record.supplierComplianceResponse.filter(
                      (e: any) => e.status === "approved"
                    ).length +
                      (record.supplierMaterialsResponse &&
                      record.supplierMaterialsResponse.status === "approved"
                        ? 1
                        : 0) +
                      " "}
                    Approved
                  </span>
                  <span
                    style={{
                      color: "#EF0107",
                      fontSize: "13px",
                      marginLeft: 15,
                    }}
                  >
                    <CloseCircleOutlined style={{ marginRight: 4 }} />
                    {record.supplierComplianceResponse.filter(
                      (e: any) => e.status === "rejected"
                    ).length +
                      (record.supplierMaterialsResponse &&
                      record.supplierMaterialsResponse.status === "rejected"
                        ? 1
                        : 0) +
                      " "}
                    Rejected
                  </span>
                </div>
              }
            />
          </List.Item>
        );
      },
    },
  ];

  const onClickViewLink = (filename: string) => {
    window.open(filename, "_blank");
  };
  const [subscriptionEnable, setSubscriptionEnable] = useState<any>();
  const fetchData = (search?: any) => {
    setLoading(true);
    dispatch(
      readCampaignComplianceResponse({
        sendgridCampaignId: id,
        limit: tablePagination.pageSize,
        page: tablePagination.current,
        sortBy: ["createdAt"],
        sortDesc: tableSorter.column
          ? tableSorter.order === "descend"
            ? false
            : true
          : false,
        softDelete: false,
        search: search?.query ? search?.query : "",
      })
    )
      .then((response: any) => {
        setSubscriptionEnable(response?.subscriptionEnable);
        setCampaignResponse(response?.campaignResponse);
        setCampaignResponseCount(response?.count);
        const finalValues: any[] = [];
        response?.campaignResponse[0]?.supplierComplianceResponse.forEach(
          (e: any) => {
            finalValues.push({
              componentId: response?.campaignResponse[0].componentId,
              ...e,
            });
          }
        );
        handleSelection(response?.campaignResponse[0]);
        setComponent(response?.campaignResponse[0]);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleTableChange: TableProps<SupplierResponse>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<SupplierResponse> | SorterResult<SupplierResponse>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<SupplierResponse>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      return;
    }
  };

  const showModal = (item: any) => {
    setSelectedItem(item);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const handleReject = (feedback?: string) => {
    if (selectedItem) {
      let bulkApprovePayload;
      let responseType;
      if (
        selectedItem?.regulationNumber !== undefined
        // selectedItem?.regulationNumber !== "Material"
        // &&selectedItem.regulationNumber !== "Scip"
      ) {
        bulkApprovePayload = {
          supplierComplianceResponse: selectedItem,
          type: "rejected",
          ...(feedback && { feedback: feedback || null }),
          componentId: selectedItem.componentId,
        };
        responseType = selectedItem.regulationNumber;
      } else {
        bulkApprovePayload = {
          type: "rejected",
          ...(feedback && { feedback: feedback || null }),
          componentId: selectedItem.componentId,
        };
        responseType = "Material";
      }
      dispatch(
        updateCampaignResponse({
          filter: {
            sendgridCampaignId: id,
            componentId: selectedItem.componentId,
          },
          updates: bulkApprovePayload,
          responseType: responseType,
        })
      )
        .then(() => {
          message.success("Component rejected successfully");
          fetchData();
          setIsModalVisible(false);
          setSelectedItem(null);
        })
        .catch((error) => {
          console.error("Reject error:", error);
          message.error(
            `Rejection failed: ${
              error.response?.data?.message || error.message
            }`
          );
        });
    }
  };

  const handleSelection = (value: any) => {
    const finalValues: any[] = [];
    value?.supplierComplianceResponse.forEach((e: any) => {
      finalValues.push({
        componentId: value.componentId,
        manufacturer: value.manufacturer,
        ...e,
      });
    });
    const materials =
      value?.supplierMaterialsResponse &&
      Object.assign({}, value?.supplierMaterialsResponse, {
        componentId: value.componentId,
        manufacturer: value.manufacturer,
      });
    const complianceData = finalValues.map((item: any) => ({
      key: item.regulationNumber,
      label: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginBottom: "10px",
          }}
        >
          <Space
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Space
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bold",
                color: "#003366",
                width: "100%",
              }}
            >
              {item.regulationNumber.replace(/_/g, " ").toUpperCase() +
                " Compliances"}
            </Space>
            {/* {item?.status === "approved" && (
              <CheckOutlined style={{ fontSize: "18px", color: "#52c41a" }} />
            )}
            {item?.status === "pending" &&
            subscriptionEnable &&
            item.regulationNumber === "gadsl_(mds)" &&
            item?.supplierPdfReader &&
            item?.supplierPdfReader !== null &&
            ((value.manufacturer.name &&
              !item?.supplierPdfReader?.answers?.manufacturerName
                ?.toLowerCase()
                ?.includes(value.manufacturer.name.toLowerCase())) ||
              (value.manufacturer.itemNumber &&
                !item?.supplierPdfReader?.answers?.manufacturerItemNumber
                  ?.toLowerCase()
                  ?.includes(value.manufacturer.itemNumber.toLowerCase())) ||
              !item?.supplierPdfReader?.answers?.complianceOutputsCompliant
                ?.toLowerCase()
                ?.includes(item.complianceOutputs.compliant) ||
              !item?.supplierPdfReader?.answers?.complianceOutputsVersion?.includes(
                item.complianceOutputs.version
              )) ? (
              <Avatar src={<img src={validatorIcon} />} />
            ) : (
              item?.status === "pending" && (
                <ClockCircleTwoTone
                  style={{ fontSize: "18px" }}
                  twoToneColor="blue"
                />
              )
            )}
            {item?.status === "initiated" && (
              <ExclamationCircleTwoTone
                style={{ fontSize: "18px" }}
                twoToneColor="grey"
              />
            )} */}
          </Space>
        </div>
      ),
      children: (
        <div>
          <Space
            direction="vertical"
            style={{
              display: "flex",
              justifyContent: "start",
              width: "100%",
            }}
          >
            {item.regulationNumber !== "gadsl_(mds)" && (
              <Title level={5}>
                {" "}
                {"Status :"}{" "}
                <Text>
                  {" "}
                  {(item.complianceOutputs &&
                    item.complianceOutputs.compliant) ||
                    "NA"}{" "}
                </Text>{" "}
              </Title>
            )}
            {item.regulationNumber === "eu_reach" && (
              <Title level={5}>
                {" "}
                Substance :{" "}
                <Text>
                  {" "}
                  {(item.complianceOutputs &&
                    item.complianceOutputs.substance &&
                    item.complianceOutputs.substance.toString()) ||
                    "NA"}{" "}
                </Text>{" "}
              </Title>
            )}
            {item.regulationNumber === "eu_rohs" && (
              <Title level={5}>
                {" "}
                Exemptions :{" "}
                <Text>
                  {" "}
                  {(item.complianceOutputs &&
                    item.complianceOutputs.exemption &&
                    item.complianceOutputs.exemption.toString()) ||
                    "NA"}{" "}
                </Text>{" "}
              </Title>
            )}
            {item.regulationNumber !== "gadsl_(mds)" && (
              <Title level={5}>
                {" "}
                Version :{" "}
                <Text>
                  {" "}
                  {(item.complianceOutputs &&
                    item.complianceOutputs.version &&
                    item.complianceOutputs?.version?.toString()) ||
                    "NA"}{" "}
                </Text>{" "}
                {subscriptionEnable &&
                item.regulationNumber === "gadsl_(mds)" &&
                item?.supplierPdfReader &&
                item?.supplierPdfReader !== null &&
                item.complianceOutputs.version &&
                item?.supplierPdfReader?.answers?.complianceOutputsVersion
                  ?.toLowerCase()
                  ?.includes("not provided") ? (
                  <Tooltip title={"Act AI: Error extracting version"}>
                    <WarningFilled
                      style={{ color: "orange", fontSize: "16px" }}
                    />
                  </Tooltip>
                ) : (
                  subscriptionEnable &&
                  item.regulationNumber === "gadsl_(mds)" &&
                  item?.supplierPdfReader &&
                  item?.supplierPdfReader !== null &&
                  item.complianceOutputs.version &&
                  !item?.supplierPdfReader?.answers?.complianceOutputsVersion?.includes(
                    item.complianceOutputs.version
                  ) && (
                    <Tooltip
                      title={
                        "Act AI: Exact match not found for version. Please verify."
                      }
                    >
                      <ExclamationCircleFilled
                        style={{ color: "red", marginLeft: "5px" }}
                      />
                    </Tooltip>
                  )
                )}
              </Title>
            )}

            {item.complianceOutputs &&
              item.complianceOutputs.concentrationOfPfas && (
                <Title level={5}>
                  {" "}
                  Concentration of PFAS :{" "}
                  <Text>
                    {" "}
                    {(item.complianceOutputs &&
                      item.complianceOutputs.concentrationOfPfas) ||
                      "NA"}{" "}
                  </Text>{" "}
                </Title>
              )}
            {item.complianceOutputs &&
              item.complianceOutputs.concentrationUnit && (
                <Title level={5}>
                  {" "}
                  Concentration Unit :{" "}
                  <Text>
                    {" "}
                    {(item.complianceOutputs &&
                      item.complianceOutputs.concentrationUnit) ||
                      "NA"}{" "}
                  </Text>{" "}
                </Title>
              )}
          </Space>
          {/* {item &&
            item?.supplierPdfReader?.answers?.materials &&
            item?.supplierPdfReader?.answers?.materials?.length > 0 && (
              <Title level={5}>
                {" "}
                <Tooltip title={"Material List"}>
                  <Button
                    type="primary"
                    ghost
                    style={{ padding: "5px" }}
                    icon={<PicLeftOutlined />}
                    onClick={() => {
                      setIsModalOpen(true);
                      setMaterialData(item);
                    }}
                  >
                    View Material List
                  </Button>
                </Tooltip>
              </Title>
            )} */}
          {item && item?.complianceDocument && (
            <Title level={5}>
              Compliance Document :
              {item?.complianceDocument?.includes("http") ? (
                <span>
                  <ExportOutlined
                    onClick={() => onClickViewLink(item?.complianceDocument)}
                  />{" "}
                  Link
                </span>
              ) : (
                <ReadSupportingDocument
                  postUrl="campaignmanager/document/download"
                  filename={item?.complianceDocument}
                  foldername="campaign"
                  campaignId={id}
                />
              )}
            </Title>
          )}
          {item && item.status === "initiated" && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                marginTop: "20px",
              }}
            >
              <Button
                type="default"
                style={{
                  backgroundColor: "#f0f0f0",
                  color: "#bfbfbf",
                  borderColor: "#d9d9d9",
                  cursor: "not-allowed",
                  width: "auto",
                  padding: "6px 12px",
                }}
                disabled
              >
                Action: Yet to submit
              </Button>
            </div>
          )}
          <Space
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "8px",
              marginBottom: "20px",
            }}
          >
            {item && item?.status === "pending" && (
              <>
                <AddComplianceApprove
                  formData={{
                    sendgridCampaignId: id,
                    componentId: item.componentId,
                    supplierComplianceResponse: item,
                    manufacturer: item.manufacturer,
                    regulation: item.regulationNumber,
                    status: item.status,
                  }}
                  fetchData={fetchData}
                  type="button"
                  sendgridCampaignId={id}
                  subscriptionEnable={subscriptionEnable}
                />
                <Button
                  type="primary"
                  icon={
                    <CloseCircleOutlined
                      style={{
                        color: "white",
                        fontSize: "16px",
                      }}
                    />
                  }
                  onClick={() => showModal(item)}
                  style={{
                    backgroundColor: "red",
                    borderColor: "red",
                  }}
                >
                  Reject
                </Button>
              </>
            )}

            {item && item?.status === "approved" && (
              <AddComplianceApprove
                formData={{
                  sendgridCampaignId: id,
                  componentId: item.componentId,
                  supplierComplianceResponse: item,
                  manufacturer: item.manufacturer,
                  regulation: item.regulationNumber,
                  status: item.status,
                }}
                fetchData={fetchData}
                type="button"
                sendgridCampaignId={id}
                subscriptionEnable={subscriptionEnable}
              />
            )}

            {item && item?.status === "rejected" && (
              <Button
                type="default"
                icon={
                  <CloseCircleOutlined
                    style={{
                      color: "red",
                      fontSize: "16px",
                    }}
                  />
                }
                onClick={() => showModal(item)}
                disabled={true}
                style={{
                  backgroundColor: "white",
                  borderColor: "red",
                  color: "red",
                  width: "200px",
                }}
              >
                Rejected
              </Button>
            )}
          </Space>

          {item && item.status !== "initiated" && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-end",
                fontSize: "12px",
                fontWeight: "normal",
                height: "100%",
              }}
            >
              <span>
                Submitted:{" "}
                <span>{item && new Date(item.updatedAt).toLocaleString()}</span>
              </span>
            </div>
          )}
        </div>
      ),
    }));
    const materialData = {
      key: "material",
      label: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginBottom: "10px",
          }}
        >
          <Space
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Space
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bold",
                color: "#003366",
                width: "100%",
              }}
            >
              Full Material Disclosure
            </Space>
            {/* {materials?.status === "approved" && (
              <CheckOutlined style={{ fontSize: "18px", color: "#52c41a" }} />
            )} */}
            {
              materials?.status === "pending" &&
              subscriptionEnable &&
              materials?.supplierPdfReader &&
              materials?.supplierPdfReader !== null &&
              ((value.manufacturer.name &&
                !materials?.supplierPdfReader?.answers?.manufacturerName
                  ?.toLowerCase()
                  ?.includes(value.manufacturer.name.toLowerCase())) ||
                (value.manufacturer.itemNumber &&
                  !materials?.supplierPdfReader?.answers?.manufacturerItemNumber
                    ?.toLowerCase()
                    ?.includes(value.manufacturer.itemNumber.toLowerCase())) ||
                (materials.partWeight &&
                  !materials?.supplierPdfReader?.answers?.partWeight?.includes(
                    materials.partWeight
                  ))) ? (
                <Avatar src={<img src={validatorIcon} />} />
              ) : (
                ""
              )
              //  (
              //   materials?.status === "pending" && (
              //     <ClockCircleTwoTone
              //       style={{ fontSize: "18px" }}
              //       twoToneColor="blue"
              //     />
              //   )
              // )
            }
            {/* {materials?.status === "initiated" && (
              <ExclamationCircleTwoTone
                style={{ fontSize: "18px" }}
                twoToneColor="grey"
              />
            )} */}
          </Space>
        </div>
      ),
      children: (
        <div>
          <Title level={5}>
            {" "}
            Part Weight :{" "}
            <Text>
              {" "}
              {(materials && materials?.partWeight) +
                (materials && materials?.partWeightUnit) || "NA"}{" "}
              {subscriptionEnable &&
              materials?.supplierPdfReader &&
              materials?.supplierPdfReader !== null &&
              materials?.partWeight &&
              materials?.supplierPdfReader?.answers?.partWeight
                ?.toLowerCase()
                ?.includes("not provided") ? (
                <Tooltip title={"Act AI: Error extracting part weight"}>
                  <WarningFilled
                    style={{ color: "orange", fontSize: "16px" }}
                  />
                </Tooltip>
              ) : (
                subscriptionEnable &&
                materials?.supplierPdfReader &&
                materials?.supplierPdfReader !== null &&
                materials.partWeight &&
                Math.abs(
                  materials?.partWeight -
                    materials?.supplierPdfReader?.answers?.partWeight
                ) > 1e-9 && (
                  // !materials?.supplierPdfReader?.answers?.partWeight??.includes(
                  //   materials.partWeight
                  // ) &&
                  <Tooltip
                    title={
                      "Act AI: Exact match not found for part weight. Please verify."
                    }
                  >
                    <ExclamationCircleFilled
                      style={{ color: "red", marginLeft: "5px" }}
                    />
                  </Tooltip>
                )
              )}
            </Text>{" "}
          </Title>
          {materials &&
            materials?.supplierPdfReader?.answers?.materials &&
            materials?.supplierPdfReader?.answers?.materials?.length > 0 && (
              <Title level={5}>
                {" "}
                <Tooltip title={"Material List"}>
                  <Button
                    type="primary"
                    ghost
                    style={{ padding: "5px" }}
                    icon={<PicLeftOutlined />}
                    onClick={() => {
                      setIsModalOpen(true);
                      setMaterialData(materials);
                    }}
                  >
                    View Material List
                  </Button>
                </Tooltip>
              </Title>
            )}
          {materials && materials?.fmdDocument && (
            <div>
              <Title level={5} style={{ marginTop: "20px" }}>
                {" "}
                FMD Document:{" "}
                <ReadSupportingDocument
                  postUrl="campaignmanager/document/download"
                  filename={materials?.fmdDocument}
                  foldername="campaign"
                  campaignId={id}
                  subfolder="materials"
                />
              </Title>
            </div>
          )}

          {materials && materials?.status === "initiated" && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                marginTop: "20px",
              }}
            >
              <Button
                type="default"
                style={{
                  backgroundColor: "#f0f0f0",
                  color: "#bfbfbf",
                  borderColor: "#d9d9d9",
                  cursor: "not-allowed",
                  width: "auto",
                  padding: "6px 12px",
                }}
                disabled
              >
                Action: Yet to submit
              </Button>
            </div>
          )}

          <Space
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "20px",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "10px",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              {materials && materials?.status === "pending" && (
                <AddMaterialApprove
                  formData={materials}
                  fetchData={fetchData}
                  type="button"
                  sendgridCampaignId={id}
                  componentId={value.componentId}
                  subscriptionEnable={subscriptionEnable}
                />
              )}
              {materials && materials?.status === "pending" && (
                <Button
                  type="primary"
                  icon={
                    <CloseCircleOutlined
                      style={{
                        color: "white",
                        fontSize: "16px",
                      }}
                    />
                  }
                  onClick={() => showModal(materials)}
                  style={{
                    backgroundColor: "red",
                    borderColor: "red",
                  }}
                >
                  Reject
                </Button>
              )}
            </div>
            {materials && materials?.status === "approved" && (
              <AddMaterialApprove
                formData={materials}
                fetchData={fetchData}
                type="button"
                sendgridCampaignId={id}
                componentId={value.componentId}
                subscriptionEnable={subscriptionEnable}
              />
            )}
            {materials && materials?.status === "rejected" && (
              <Button
                type="primary"
                icon={
                  <CloseCircleOutlined
                    style={{
                      color: "red",
                      fontSize: "16px",
                    }}
                  />
                }
                onClick={() => showModal(materials)}
                disabled={true}
                style={{
                  backgroundColor: "white",
                  borderColor: "red",
                  color: "red",
                  width: "200px",
                }}
              >
                Rejected
              </Button>
            )}
          </Space>
          {materials && materials?.status !== "initiated" && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-end",
                fontSize: "12px",
                fontWeight: "normal",
                height: "100%",
              }}
            >
              <span>
                Submitted:{" "}
                <span>
                  {(materials &&
                    materials?.updatedAt &&
                    new Date(materials?.updatedAt).toLocaleString()) ||
                    ""}
                </span>
              </span>
            </div>
          )}
        </div>
      ),
    };

    const dataValues = materials
      ? [...complianceData, materialData]
      : complianceData;
    setItems(dataValues);
  };

  useEffect(() => {
    fetchData();
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
  ]);
  return (
    <div>
      <ErrorBoundary>
        <Row style={{ background: "white", display: "flex" }}>
          <Col span={6}>
            <Table
              loading={loading}
              dataSource={campaignResponse}
              scroll={{
                // x: 1000,
                y: "calc(100vh - 285px)",
              }}
              columns={columns}
              rowKey="_id"
              pagination={{
                ...tablePagination,
                total: campaignResponseCount,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (totalCount) => `Total  ${totalCount}  items`,
              }}
              onChange={handleTableChange}
              onRow={(record) => ({
                onClick: () => handleSelection(record),
              })}
            />
          </Col>
          <Col
            span={18}
            style={{
              backgroundColor: "whitesmoke",
              paddingLeft: "10px",
              overflowY: "auto",
              maxHeight: "calc(100vh - 190px)",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "10px",
              }}
            >
              {items?.map((item) => (
                <Card key={item.key} style={{ backgroundColor: "white" }}>
                  {item.label}
                  {item.children}
                </Card>
              ))}
            </div>
          </Col>
        </Row>

        <Modal
          title={`Material List Overview : ${
            materialData?.fmdDocument
              ? materialData?.fmdDocument
              : materialData?.complianceDocument
          } `}
          open={isModalOpen}
          footer={null}
          closable={true}
          centered
          onCancel={() => setIsModalOpen(false)}
          onClose={() => setIsModalOpen(false)}
          width="80%"
        >
          <div style={{ overflow: "auto" }}>
            <TableExpandMaterials
              materials={materialData?.supplierPdfReader?.answers?.materials}
            />
          </div>
        </Modal>
        <RejectModal
          isModalVisible={isModalVisible}
          handleReject={handleReject}
          closeModal={closeModal}
        />
      </ErrorBoundary>
    </div>
  );
};

export default CampaignResponse;
function setFieldError(arg0: string) {
  throw new Error("Function not implemented.");
}
