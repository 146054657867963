import React, { useState, Suspense, useEffect } from "react";
import { Form, Input, Button, Typography, Card, Modal, Flex, Spin } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../redux/hooks";
import { authLogin, authForgotPassword } from "../../../redux/actions/authAction";
import { setLocalStorage } from "../../../utils/localStore";
import ErrorBoundary from "../../../utils/errorBoundary";
import { connectSSE } from "../../../redux/actions/notificationsAction";
import { useMsal } from "@azure/msal-react";
import { msalConfig } from "../../../utils/SSO/authConfig";
import publicClientApplication from "../../../utils/SSO/msalInstance";
import MicroSoftLogo from "../../../assets/icons/MicrosoftLogo.svg";
import { getAllTenants } from "../../../redux/actions/tenantsAction";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

const { Paragraph, Text } = Typography;

type AuthResponse = {
  success: boolean;
  message?: string;
  status?: number;
  mfa?: boolean;
  emailAddress?: string;
};

const Login = () => {
  const { workspace } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
  const [isInvitationModalVisible, setInvitationModalVisible] = useState(false);
  const { instance } = useMsal();
  const [enableSso, setEnableSso] = useState(false);
  const [tenantLoading, setTenantLoading] = useState(false);

  useEffect(() => {
    // Initialize MSAL instance on component mount
    if (!instance) {
      publicClientApplication.initialize().catch((error) => {
        console.error("Error initializing MSAL", error);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [instance]);

  useEffect(() => {
    if (workspace) {
      setTenantLoading(true);

      dispatch(getAllTenants({ tenantNumber: workspace }))
        .then((data: any) => {
          if (data) {
            setEnableSso(data.enableSso);
          } else {
            setEnableSso(false);
          }
        })
        .catch(() => {
          navigate("/");
        })
        .finally(() => {
          setTenantLoading(false); // Stop loading after request
        });
    }
  }, [workspace]);

  /**
   * Handles the Single Sign-On (SSO) login process using MSAL (Microsoft Authentication Library).
   * @returns {Promise<void>} A promise that resolves when the login process is complete.
   */
  const handleSSOLogin = async (): Promise<void> => {
    setLoading(true); // Start loading process

    try {
      // Clear existing accounts using MSAL methods
      const existingAccounts = publicClientApplication.getAllAccounts();
      publicClientApplication.clearCache();
      try {
        const loginResponse = await publicClientApplication.loginPopup({
          scopes: msalConfig.auth.scopes, // Define the necessary scopes for the login
          prompt: "select_account", // Allow user to choose from multiple accounts or enter new credentials
        });

        if (!loginResponse.account) {
          console.error("Login response does not contain account info.");
          setLoading(false);
          return;
        }

        // Set the newly logged in account as active
        publicClientApplication.setActiveAccount(loginResponse.account);

        // Try to acquire token silently for the new account
        let tokenResponse;
        try {
          tokenResponse = await publicClientApplication.acquireTokenSilent({
            scopes: msalConfig.auth.scopes,
            account: loginResponse.account,
            forceRefresh: true, // Force token refresh to handle potential expiration
          });
        } catch (tokenError) {
          // Check if the error is specifically due to token expiration
          if (tokenError instanceof InteractionRequiredAuthError) {
            console.warn(
              "Token expired or requires interaction. Attempting interactive login."
            );

            try {
              // Attempt interactive token acquisition
              tokenResponse = await publicClientApplication.acquireTokenPopup({
                scopes: msalConfig.auth.scopes,
                prompt: "select_account", // Ensures user can choose account if needed
              });
            } catch (interactiveError) {
              console.error(
                "Interactive token acquisition failed:",
                interactiveError
              );
              publicClientApplication.clearCache();
              setLoading(false);

              throw interactiveError;
            }
          } else {
            console.error("Unexpected token acquisition error:", tokenError);
            publicClientApplication.clearCache();
            setLoading(false);

            throw tokenError;
          }
        }

        // Verify token is valid
        if (!tokenResponse || !tokenResponse.accessToken) {
          console.error("No valid access token acquired");
          publicClientApplication.clearCache();
          setLoading(false);
          return;
        }

        // Prepare user info for authentication
        const userInfo = {
          name: loginResponse.account.name,
          emailAddress: loginResponse.account.username,
          enableSso: enableSso,
          ssoToken: tokenResponse.idToken,
          accessToken: tokenResponse.accessToken, // Include access token
          tenantNumber: workspace,
        };

        const response = await dispatch(authLogin(userInfo));

        if (response.success) {
          // If login is successful, navigate to the dashboard
          navigate(`/app`);

          dispatch(connectSSE()); // Start SSE after successful login
        } else {
          // If login action fails, clear cache and stop loading
          console.error("Login failed: ", response.message);
          publicClientApplication.clearCache();
          setLoading(false);
        }
      } catch (loginError) {
        console.error("Login popup failed:", loginError);
        // Clear MSAL cache on login error
        publicClientApplication.clearCache();
        setLoading(false);
        // Optionally handle specific error types
        if (loginError instanceof InteractionRequiredAuthError) {
          console.warn("Interactive login required");
        }
      }
    } catch (error) {
      console.error("Unexpected error during SSO login:", error);
      publicClientApplication.clearCache();
      setLoading(false);
    }
  };

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      dispatch(authLogin({ ...values, tenantNumber: workspace })).then((response: AuthResponse) => {
        if (response.success) {
          if (response.mfa) {
            setLocalStorage("emailAddress", response.emailAddress);
            navigate(`/login/authentication/${workspace}`);
          } else {
            navigate(`/app`);
            dispatch(connectSSE()); // Start SSE after successful login
          }
        } else {
          console.log("Login failed:", response);
          setLoading(false);
        }
      });
    } catch (error) {
      console.error("Unexpected error during login:", error);
      setLoading(false);
      Modal.error({
        title: "Login Failed",
        content: "An unexpected error occurred. Please try again.",
      });
    }
  };

  const onForgotPasswordClick = async () => {
    try {
      const values = await form.validateFields(["emailAddress"]);
      Modal.confirm({
        title: "Are you sure?",
        content: (
          <Paragraph>
            You will receive an email with a reset password link which expires in 24 hrs. Please
            click &#34;Yes&#34; to confirm.
          </Paragraph>
        ),
        onOk: async () => {
          try {
            await dispatch(
              authForgotPassword({
                emailAddress: values.emailAddress,
                tenantNumber: workspace,
                currentLocationOrigin: window.location.origin,
              })
            );
            setResetPasswordSuccess(true);
          } catch (error) {
            console.error("Error sending reset password link:", error);
          }
        },
      });
    } catch (error) {
      form.setFields([{ name: "emailAddress", errors: ["Please input your email address!"] }]);
    }
  };

  const onSendInvitation = async () => {
    try {
      const values = await form.validateFields(["invitationEmailAddress"]);
      setInvitationModalVisible(false);
      Modal.success({
        title: "Invitation Sent",
        content: `Invitation sent to ${values.invitationEmailAddress}.`,
      });
    } catch (error) {
      console.error("Error sending invitation:", error);
    }
  };

  return (
    <ErrorBoundary>
      {tenantLoading || enableSso === null ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <Card>
          <Paragraph strong style={{ textAlign: "center" }}>
            Login to {workspace}
          </Paragraph>

          <Form
            form={form}
            onFinish={!enableSso ? onFinish : handleSSOLogin}
            style={{ marginTop: "1.5rem", minWidth: 400 }}
          >
            {!enableSso ? (
              <>
                <Form.Item
                  name="emailAddress"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email address!",
                    },
                    {
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                  ]}
                >
                  <Input placeholder="Email Address" />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[{ required: true, message: "Please input your password!" }]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>
              </>
            ) : (
              <Flex justify="center" vertical align="center" style={{ marginBottom: "1.5rem" }}>
                <Text type="warning">
                  This tenant has enabled Single Sign-On (SSO) using Microsoft.
                </Text>
                <Text disabled>Other login methods are disabled for this workspace.</Text>
                {/* <Text mark>
                  Please click on &quot;Login with Microsoft&quot; to proceed.
                </Text> */}
              </Flex>
            )}

            <Flex justify={!enableSso ? "space-between" : "center"}>
              {!enableSso && (
                <Button type="link" onClick={onForgotPasswordClick}>
                  Forgot Password?
                </Button>
              )}
              <Button type="primary" htmlType="submit" loading={loading}>
                {!enableSso ? (
                  ""
                ) : (
                  <img
                    src={MicroSoftLogo}
                    alt="Microsoft Logo"
                    style={{ margin: "5px" }}
                    width={20}
                    height={20}
                  />
                )}
                Login {!enableSso ? "" : "with Microsoft"}
              </Button>
            </Flex>
          </Form>

          <Suspense fallback={<div>Loading...</div>}>
            <Modal
              open={resetPasswordSuccess}
              title="Reset Password Email Sent"
              onOk={() => setResetPasswordSuccess(false)}
              onCancel={() => setResetPasswordSuccess(false)}
            >
              <Paragraph>
                Please check your email for further instructions on resetting your password.
              </Paragraph>
            </Modal>
          </Suspense>

          <Modal
            open={isInvitationModalVisible}
            title="Send Invitation"
            onCancel={() => setInvitationModalVisible(false)}
            onOk={onSendInvitation}
          >
            <Paragraph>
              You are about to send an invitation to this email address. Please click &quot;OK&quot;
              to confirm.
            </Paragraph>

            <Form.Item
              name="invitationEmailAddress"
              rules={[
                { required: true, message: "Please input the email address!" },
                {
                  type: "email",
                  message: "Please enter a valid email address",
                },
              ]}
            >
              <Input placeholder="Email Address" />
            </Form.Item>
          </Modal>
        </Card>
      )}
    </ErrorBoundary>
  );
};

export default Login;
